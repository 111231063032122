import React, { useState } from 'react';
import { Timer } from 'lucide-react';
import { DurationInput } from './DurationInput';

interface DurationFilterProps {
  minDuration: number | null;
  maxDuration: number | null;
  onMinChange: (duration: number | null) => void;
  onMaxChange: (duration: number | null) => void;
}

export const DurationFilter: React.FC<DurationFilterProps> = ({
  onMinChange,
  onMaxChange,
  minDuration,
  maxDuration
}) => {
  const handleMinChange = (value: number| null) => onMinChange(value === 0 ? null : value);
  const handleMaxChange = (value: number | null) => onMaxChange(value === 0 ? null : value);

  return (
    <div className="flex flex-col gap-1 w-full">
        <DurationInput
            label="Over:"
            value={minDuration}
            onChange={handleMinChange}
        />

        <DurationInput
            label="Under:"
            value={maxDuration}
            onChange={handleMaxChange}
        />
    </div>
  );
};