import { CustomDefinition, CustomMetricSource, CustomMetricValueType, PartyRole } from '../../interfaces/db'

export const COLORS = {
  primary: {
    light: '#e3f2fd',
    main: '#2196f3',
    dark: '#1976d2',
    gradient: 'linear-gradient(45deg, #1976d2, #2196f3)',
    hover: '#1565c0'
  },
  secondary: {
    light: '#f3e5f5',
    main: '#9c27b0',
    dark: '#7b1fa2',
    gradient: 'linear-gradient(45deg, #7b1fa2, #9c27b0)'
  },
  success: {
    light: '#e8f5e9',
    main: '#4caf50',
    gradient: 'linear-gradient(45deg, #388e3c, #4caf50)'
  },
  background: {
    gradient: 'linear-gradient(to bottom right, #f5f9ff 0%, #ffffff 100%)',
    card: '#ffffff',
    hover: 'rgba(33, 150, 243, 0.04)'
  }
}

export const formatMetricSource = (source: CustomMetricSource): string => {
  const formats: Record<CustomMetricSource, string> = {
    [CustomMetricSource.GEN_AI]: 'Generative AI',
    [CustomMetricSource.PHRASE]: 'Phrase Match',
    [CustomMetricSource.AVERAGE]: 'Average Score',
    [CustomMetricSource.CODE]: 'Custom Code'
  }
  return formats[source]
}

export const formatMetricType = (type: CustomMetricValueType): string => {
  const formats: Record<CustomMetricValueType, string> = {
    [CustomMetricValueType.BOOLEAN]: 'Yes/No',
    [CustomMetricValueType.PERCENT]: 'Percentage',
    [CustomMetricValueType.TEXT]: 'Text',
    [CustomMetricValueType.ENUM]: 'Multiple Choice'
  }
  return formats[type]
}

export function validateDefinition(def: CustomDefinition): boolean {
  if (!def.label || def.label.includes(',')) return false
  if (!def.description) return false

  switch (def.value_source) {
    case CustomMetricSource.GEN_AI:
      if (def.value_type === CustomMetricValueType.BOOLEAN) {
        const [yes, no] = def.value_gen_desc_str?.split(',') || []
        if (!yes?.replace('yes:', '') || !no?.replace('no:', '')) return false
      } else if (def.value_type === CustomMetricValueType.PERCENT) {
        const [zero, fifty, hundred] = def.value_gen_desc_str?.split(',') || []
        if (!zero?.replace('0:', '') || !fifty?.replace('50:', '') || !hundred?.replace('100:', '')) return false
      } else if (def.value_type === CustomMetricValueType.ENUM) {
        if (!def.value_type_enum_str || def.value_type_enum_str.split(',').some(c => !c.trim())) return false
        const categories = def.value_type_enum_str.split(',').map(c => c.trim())
        const descriptions = def.value_gen_desc_str?.split(',') || []
        if (categories.some((cat, i) => !descriptions[i]?.replace(`${cat}:`, ''))) return false
      }
      break
    case CustomMetricSource.PHRASE:
      // For phrase match, we only need the phrases string and a speaker role (which can be null for "Anyone")
      return Boolean(def.value_phrase_str)
    case CustomMetricSource.AVERAGE:
      return Boolean(def.value_average_str)
    default:
      return false
  }

  return true
}

export const defaultMetrics = {
  qa: [
    {
      label: 'Customer Satisfaction',
      description: 'Evaluates overall customer satisfaction based on the conversation',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.PERCENT,
      value_gen_str: 'Evaluates overall customer satisfaction based on the conversation',
      value_gen_desc_str: '0:Complete dissatisfaction with service and resolution,50:Mixed or neutral satisfaction with partial resolution,100:Complete satisfaction with excellent service and resolution'
    },
    {
      label: 'Problem Resolution',
      description: 'Determines if the customer\'s issue was fully resolved',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.BOOLEAN,
      value_gen_str: 'Determines if the customer\'s issue was fully resolved',
      value_gen_desc_str: 'yes:The customer\'s issue was completely resolved and they acknowledged the resolution,no:The issue remains unresolved or requires further assistance'
    },
    {
      label: 'Agent Professionalism',
      description: 'Assesses the agent\'s level of professionalism and courtesy',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.ENUM,
      value_type_enum_str: 'Excellent, Good, Fair, Poor',
      value_gen_str: 'Assesses the agent\'s level of professionalism and courtesy',
      value_gen_desc_str: 'Excellent:Consistently professional, courteous, and goes above and beyond,Good:Generally professional and courteous throughout the interaction,Fair:Basic level of professionalism with room for improvement,Poor:Lacks professionalism or displays inappropriate behavior'
    },
    {
      label: 'First Contact Resolution',
      description: 'Evaluates if the issue was resolved in the first interaction',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.BOOLEAN,
      value_gen_str: 'Evaluates if the issue was resolved in the first interaction',
      value_gen_desc_str: 'yes:Issue was completely resolved within this single interaction without need for follow-up,no:Issue requires additional interactions or escalation to resolve'
    },
    {
      label: 'Technical Knowledge',
      description: 'Measures the agent\'s technical expertise and ability to explain complex concepts',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.PERCENT,
      value_gen_str: 'Measures the agent\'s technical expertise and ability to explain complex concepts',
      value_gen_desc_str: 'Low (<40%):Demonstrates poor technical knowledge and inability to explain concepts,Middle (40-70%):Shows basic technical understanding but struggles with complex issues,High (>70%):Exhibits excellent technical expertise and clear explanations'
    }
  ],
  sales: [
    {
      label: 'Opening Quality',
      description: 'Evaluates the effectiveness of the sales rep\'s opening approach in cold calls',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.PERCENT,
      value_gen_str: 'Evaluates the effectiveness of the sales rep\'s opening approach in cold calls',
      value_gen_desc_str: 'Low (<40%):Poor opener lacking clarity and engagement,Middle (40-70%):Clear opener but lacks personalization or research,High (>70%):Excellent opener with strong personalization showing thorough research'
    },
    {
      label: 'Objection Handling Quality',
      description: 'Assesses how effectively the sales rep handles and overcomes objections',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.PERCENT,
      value_gen_str: 'Assesses how effectively the sales rep handles and overcomes objections',
      value_gen_desc_str: 'Low (<40%):No attempt to address or overcome objections,Middle (40-70%):Attempted to handle objections but responses were ineffective,High (>70%):Successfully identified and overcame multiple objections'
    },
    {
      label: 'Qualification Quality',
      description: 'Measures the thoroughness of prospect qualification and pain point discovery',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.PERCENT,
      value_gen_str: 'Measures the thoroughness of prospect qualification and pain point discovery',
      value_gen_desc_str: 'Low (<40%):No qualification questions or pain discovery attempted,Middle (40-70%):Basic qualification with 1-2 questions but insufficient exploration,High (>70%):Comprehensive qualification with thorough pain point discovery'
    },
    {
      label: 'Closing Quality',
      description: 'Evaluates the effectiveness of meeting scheduling and commitment securing',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.PERCENT,
      value_gen_str: 'Evaluates the effectiveness of meeting scheduling and commitment securing',
      value_gen_desc_str: 'Low (<40%):Failed to secure any meeting or next steps,Middle (40-70%):Secured a tentative meeting without strong commitment,High (>70%):Successfully booked a meeting with clear next steps'
    },
    {
      label: 'Overcame Objection',
      description: 'Determines if the sales rep successfully overcame a prospect objection',
      value_source: CustomMetricSource.GEN_AI,
      value_type: CustomMetricValueType.BOOLEAN,
      value_gen_str: 'Determines if the sales rep successfully overcame a prospect objection',
      value_gen_desc_str: 'yes:Successfully overcame at least one significant objection with an effective response,no:Failed to overcome objections or did not address them effectively'
    }
  ]
}

export const isDefinitionValid = (def: CustomDefinition): { valid: boolean, reason: string } => {
  // Basic validation for all metrics
  if (!def.label?.trim()) {
    return { valid: false, reason: 'Label is required' }
  }
  if (!def.value_source) {
    return { valid: false, reason: 'Value source is required' }
  }
  if (!def.value_type) {
    return { valid: false, reason: 'Value type is required' }
  }

  // Description is required for all sources except CODE
  if (def.value_source !== CustomMetricSource.CODE && !def.description?.trim()) {
    return { valid: false, reason: 'Description is required' }
  }

  // Source-specific validation
  switch (def.value_source) {
    case CustomMetricSource.CODE:
      if (!def.value_code_str) {
        return { valid: false, reason: 'Please select a code metric' }
      }
      break;

    case CustomMetricSource.GEN_AI:
      if (!def.value_gen_str?.trim() && !def.value_gen_desc_str?.trim()) {
        return { valid: false, reason: 'Generation prompt or description is required' }
      }
      break;

    case CustomMetricSource.PHRASE:
      if (!def.value_phrase_str?.trim()) {
        return { valid: false, reason: 'Phrase is required' }
      }
      break;

    case CustomMetricSource.AVERAGE:
      if (!def.value_average_str?.trim()) {
        return { valid: false, reason: 'Metrics to average are required' }
      }
      break;
  }

  // Type-specific validation
  switch (def.value_type) {
    case CustomMetricValueType.ENUM:
      if (def.value_source !== CustomMetricSource.CODE && !def.value_type_enum_str?.trim()) {
        return { valid: false, reason: 'Categories are required for enum type' }
      }
      break;
  }

  return { valid: true, reason: '' }
} 