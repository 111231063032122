import { Loader } from '../../components/Loader';
import { convertFromReduxSafeUserState, convertFromReduxSafeVisibleAccounts, reloadAdminWrite, reloadAuthorizationInfoInitialState, reloadDispositionOptions, reloadExternalAccounts, reloadGroups, reloadProspectInfoOptions, reloadTeam, reloadUser, reloadVisibleAccounts } from '../../lib/redux/store';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AnyAction } from 'redux';
import { AuthorizationInfo } from './utils';
import { connect } from 'react-redux';
import { ExternalAccount } from '../../interfaces/db';
import { UserDataResult, VisibleAccountsResult, ProspectInfoChoices, TeamResult } from '../../interfaces/services';
import { CustomDefinition } from '../../interfaces/db';
import { reloadCustomDefinitions } from '../../lib/redux/store';
import { RootState } from '../../store';

// Define the types for the props
interface PrivateRouteProps {
    authentication_info: AuthorizationInfo | null;
    dispatch: Dispatch<AnyAction>;
    component: React.ComponentType<any>;
    user: UserDataResult | null
    isAdmin: boolean | null
    visibleAccounts: VisibleAccountsResult | null
    externalAccounts: ExternalAccount[] | null
    prospectInfoOptions: ProspectInfoChoices | null
    dispositionOptions: ProspectInfoChoices | null
    userGroupsLoaded: boolean
    team: TeamResult | null
    customDefinitions: CustomDefinition[] | null
}

const PrivateRouteImpl: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }: PrivateRouteProps) => {
    // Effect to run only once on mount
    useEffect(() => {
        if (!rest.authentication_info) {
            reloadAuthorizationInfoInitialState(rest.dispatch);
        }
        if (!rest.user) reloadUser(rest.dispatch);
        if (!rest.userGroupsLoaded) reloadGroups(rest.dispatch);
        if (rest.isAdmin === null) reloadAdminWrite(rest.dispatch);
        if (rest.visibleAccounts === null) reloadVisibleAccounts(rest.dispatch);
        if (rest.externalAccounts === null) reloadExternalAccounts(rest.dispatch);
        if (rest.prospectInfoOptions === null) reloadProspectInfoOptions(rest.dispatch);
        if (rest.dispositionOptions === null) reloadDispositionOptions(rest.dispatch);
        if (rest.team === null) reloadTeam(rest.dispatch);
        if (rest.customDefinitions === null) reloadCustomDefinitions(rest.dispatch);
    }, []); // Empty array ensures this runs only once on mount

    if (!rest.authentication_info) return <Loader />;
    if (rest.authentication_info && rest.authentication_info.apiKey == null) return <Navigate to={'/login'}/>
    return <Component {...rest} />;
};

const mapStateToProps = (state: RootState) => {
    return {
        authentication_info: state.authenticationInfo.value,
        visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts, (u) => u.team_is_active && u.can_dial),
        userGroupsLoaded: state.userGroupInfo.hasLoaded,
        externalAccounts: state.externalAccounts.accounts,
        user: convertFromReduxSafeUserState(state.user),
        isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : null,
        dispositionOptions: state.dispositionOptions.value,
        prospectInfoOptions: state.prospectInfoOptions.value,
        team: state.team.value,
        customDefinitions: state.customDefinitions.value
    };
  };
  
const ReduxWrapped = connect(mapStateToProps)(PrivateRouteImpl)

export { ReduxWrapped as PrivateRoute}