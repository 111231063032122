import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AnalyzeState {
  analyzed: Record<string, boolean>;
  analyzing: Record<string, boolean>;
}

const initialState: AnalyzeState = {
  analyzing: {},
  analyzed: {}
};

export const analyzeSlice = createSlice({
  name: 'linkedinAnalyze',
  initialState,
  reducers: {
    setAnalyzing: (state, action: PayloadAction<{
      conversationId: string;
      isAnalyzing: boolean;
    }>) => {
      const { conversationId, isAnalyzing } = action.payload;
      state.analyzing[conversationId] = isAnalyzing;
    },
    setAnalyzed: (state, action: PayloadAction<{
      conversationId: string;
      isAnalyzed: boolean;
    }>) => {
      const { conversationId, isAnalyzed } = action.payload;
      state.analyzed[conversationId] = isAnalyzed;
    },
    resetAnalyzing: (state) => {
      state.analyzing = {};
    }
  }
});

export const { setAnalyzing, setAnalyzed, resetAnalyzing } = analyzeSlice.actions;
