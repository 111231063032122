import { Action, AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Filter, FilterOption, FilterType } from "components/Filter";
import { Loader } from "components/Loader";
import { PromptOnLeave } from "components/PromptOnLeave";
import { SwitchElement } from "components/SwitchElement";
import { Table, TableProps } from "components/Table";
import { Debouncer } from "components/Widgets/Transcript/Debouncer";
import { AUTOMATION_TYPE_TO_HUMAN_READABLE, AutoDialerMapping, AutoDialerMappingOptions, AutoDialerSetting, AutomationType, DEFAULT_POWER_RANKINGS, NUMBER_ROTATION_TYPES, NUMBER_ROTATION_TYPES_TO_HUMAN_READABLE, NumberRotationPreference, PHONE_TYPE, PHONE_TYPE_TO_HUMAN_READABLE, PLATFORM_TO_HUMAN_READABLE, PhonePreference, Platform, SETTING, SETTING_TO_ADJUSTMENT_TO_BACKEND, SETTING_TO_ADJUSTMENT_TO_USER_READABLE, SETTING_TO_DEFAULT_VALUE, SETTING_TO_SETTING_TYPE, SETTING_TYPES, SPECIAL_SETTINGS, TrellusDisposition, TrellusDispositionIsPrediction, TrellusDispositionToHumanReadable, getSettingInfo } from "interfaces/db";
import { UserDataResult } from "interfaces/services";
import { Typography } from "interfaces/typography";
import { UserGroupInfo, convertFromReduxSafeUserState } from "lib/redux/store";
import React from "react";
import { connect } from "react-redux";
import { ItemInterface, ReactSortable } from "react-sortablejs";
import { getServicesManager } from "services";
import { RootState } from "store";

const SPECIAL_NO_MATCH = '___NO_MATCH__'

type AutodialerSettingState = {
    settings?: AutoDialerSetting[]
    savedSettings?: AutoDialerSetting[]
    options?: AutoDialerMappingOptions[]
    mappings?: AutoDialerMapping[]
    savedMappings?: AutoDialerMapping[]
    selectedDialer?: Platform;
    user?: UserDataResult
    pendingSave?: boolean
    selectedUserGroup?: string
}

type SettingRow = {
    rowLabel: string,
    settings: (SETTING | SPECIAL_SETTINGS)[]
    fields?: (AutomationType)[]
}

type Section = {
    section: string
    options: SettingRow[]
}

const LOCKED_SVG = <svg width="" height="" viewBox="0 0 682 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.039 800H629.735C658.48 800 681.783 776.697 681.783 747.952V326.4C681.783 297.655 658.48 274.35 629.735 274.35H619.739C617.248 122.66 493.159 0 340.886 0C188.615 0 64.5247 122.66 62.0444 274.352H52.048C23.3034 274.352 0 297.655 0 326.401V747.963C0 776.697 23.2943 800 52.039 800ZM391.45 539.933V666.282C391.45 691.742 370.803 712.392 345.341 712.392H336.423C310.962 712.392 290.314 691.744 290.314 666.282V539.933C263.248 523.028 245.172 493.06 245.172 458.79C245.172 405.938 288.017 363.084 340.876 363.084C393.735 363.084 436.58 405.929 436.58 458.79C436.592 493.06 418.518 523.03 391.45 539.933ZM340.886 110.663C432.127 110.663 506.475 183.691 508.956 274.352H172.817C175.299 183.701 249.645 110.663 340.886 110.663Z" fill="black"/>
</svg>

const UNLOCKED_SVG = <svg width="" height="" viewBox="0 0 600 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M549.993 399.995H166.664V233.331C166.664 196.523 179.683 165.094 205.723 139.056C231.761 113.018 263.18 99.9994 299.997 99.9994C336.795 99.9994 368.224 113.018 394.262 139.056C420.3 165.094 433.319 196.533 433.319 233.331C433.319 242.366 436.615 250.179 443.219 256.765C449.814 263.379 457.627 266.653 466.652 266.653H499.987C509.012 266.653 516.823 263.379 523.42 256.765C530.016 250.168 533.32 242.357 533.32 233.331C533.32 169.104 510.484 114.151 464.832 68.4793C419.175 22.8255 364.23 0 299.997 0C235.753 0 180.81 22.8255 135.156 68.4793C89.4932 114.151 66.6663 169.104 66.6663 233.331V399.995H49.999C36.1083 399.995 24.2961 404.857 14.5785 414.584C4.8521 424.31 0 436.105 0 449.986V749.991C0 763.89 4.85358 775.685 14.5785 785.411C24.2961 795.138 36.0994 800 49.999 800H549.993C563.874 800 575.687 795.138 585.414 785.411C595.131 775.685 599.995 763.89 599.995 749.991V449.985C599.995 436.114 595.131 424.31 585.414 414.584C575.687 404.859 563.874 399.995 549.993 399.995ZM339.146 608.094V705.924C339.146 725.64 323.16 741.625 303.445 741.625H296.55C276.835 741.625 260.849 725.64 260.849 705.924V608.094C239.892 595.002 225.902 571.796 225.902 545.266C225.902 504.339 259.081 471.171 299.998 471.171C340.916 471.171 374.095 504.35 374.095 545.266C374.093 571.805 360.102 595.013 339.146 608.094Z" fill="black"/>
</svg>

const checkMarkSvg = <svg width="" height="" viewBox="0 0 270 270" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30 180L90 240L240 30" stroke="white" strokeWidth="30"/>
</svg>

type AutodialerSettingsProps<A extends Action = AnyAction> = {
  dispatch: Dispatch<A>
  isAdmin: boolean | null,
  user: UserDataResult | null
  userGroupInfo: UserGroupInfo[]
  adminUserGroups: string[]
  userGroupLoaded: boolean
}

class AutodialerSettingsImpl extends React.PureComponent<AutodialerSettingsProps, AutodialerSettingState> {

    constructor(props: AutodialerSettingsProps) {
        super(props)
        this.state = {}
    }

    _constructEmptySettings(platform: Platform, user: UserDataResult, isTeam: boolean, userGroupId?: string): AutoDialerSetting {
      const nullSettings: {[k in SETTING]: null} = {
        [SETTING.TIME_TO_DIAL]: null,
        [SETTING.TIME_TO_LOG]: null,
        [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: null,
        [SETTING.AUTO_PROGRESS_TIME]: null,
        [SETTING.HANGUP_RINGING_ACTIVE]: null,
        [SETTING.HANGUP_RINGING_TIME]: null,
        [SETTING.HANGUP_VOICEMAIL_ACTIVE]: null,
        [SETTING.HANGUP_VOICEMAIL_TIME]: null,
        [SETTING.HANGUP_MENU_ACTIVE]: null,
        [SETTING.HANGUP_MENU_TIME]: null,
        [SETTING.MUSIC_WEBSITE]: null,
        [SETTING.LOCAL_CALL_TIME_MIN]: null,
        [SETTING.LOCAL_CALL_TIME_MAX]: null,
        [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: null,
        [SETTING.DIALING_MODE]: null,
        [SETTING.PHONE_PREFERENCE]: null,
        [SETTING.LINKEDIN_AUTO_OPEN]: null,
        [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: null,
        [SETTING.SALESFORCE_URL_AUTO_OPEN]: null,
        [SETTING.NO_AUTO_COMPLETE_TASK]: null,
        [SETTING.ACTION_ON_BAD_NUMBER]: null,
        [SETTING.NO_SUMMARY_AUTOMATION]: null,
        [SETTING.NUMBER_ROTATION_PREFERENCE]: null,
        [SETTING.PRIMARY_ALWAYS]: null,
        [SETTING.PRIMARY_FIRST]: null,
        [SETTING.VERIFIED_ALWAYS]: null,
        [SETTING.OUTREACH_MODAL_CLICK_SETTING]: null,
        [SETTING.ALL_RECORDS]: null,
        [SETTING.USE_CUSTOM_PHONE_FIELDS]: null,
        [SETTING.DNC_ALWAYS]: null,
        [SETTING.SKIP_TASK_WITHOUT_NUMBER]: null,
        [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: null,
        [SETTING.NO_LOG_ONLY]: null,
        [SETTING.MAX_NUMBER_LINES]: null,
      }

      return {
        user_id: isTeam ? '' : user.user_id,
        user_group_id: userGroupId ?? '',
        team_id: isTeam ? user.team_id : '',
        platform: platform,
        ...nullSettings
        }
    }

    async onSave() {
      if (!this.state.settings || !this.state.savedSettings || !this.state.mappings || !this.state.savedMappings) return;
      let savedSettings: AutoDialerSetting[] = []
      const hasSettingsToSave = this._jsonifyAutodialerSettings(this.state.savedSettings) !== this._jsonifyAutodialerSettings(this.state.settings)
      if (!hasSettingsToSave) savedSettings = this.state.savedSettings
      else {
        // need to find all settings that have an update, and all settings that are now additionally added
        for (const setting of this.state.settings) {
          // find matching 
          let hasToUpdate = false
          const matchingSetting = this.state.savedSettings.find((v) => v.platform === setting.platform && v.team_id === setting.team_id && v.user_id === setting.user_id)
          if (!matchingSetting || (this._jsonifyAutodialerSetting(matchingSetting) !== this._jsonifyAutodialerSetting(setting))) hasToUpdate = true;
          if (hasToUpdate) {
            const success = await getServicesManager().putAutodialerSettings(setting.team_id !== '', setting.user_group_id === '' ? null : setting.user_group_id, setting)
            if (success) savedSettings.push({...setting})
          } else {
            savedSettings.push({...setting})
          }
        }
      }    

      let savedMappings: AutoDialerMapping[] = []
      const hasMappingsToSave = this._jsonifyParams(this.state.savedMappings) !== this._jsonifyParams(this.state.mappings)
      if (!hasMappingsToSave) savedMappings = this.state.savedMappings
      else {
        // need to check if we've removed any mappings, and if we've adjusted any others... 
        const removedMappings = this.state.savedMappings.filter((v) => this.state.mappings?.find((x) => x.automation_type === v.automation_type && x.platform === v.platform && x.trellus_disposition === v.trellus_disposition && x.user_id === v.user_id && x.team_id === v.team_id) === undefined)
        // ensure they are removed... 
        for (const mapping of removedMappings) {
          const success = await getServicesManager().putAutodialerMappings(mapping.team_id !== '', mapping.user_group_id !== '' ? mapping.user_group_id : null, true, mapping.platform, mapping.automation_type, mapping.trellus_disposition, null)
          if (!success) savedMappings.push(mapping)
        }

        for (const mapping of this.state.mappings) {
          let hasToUpdate = false;
          const matchingMapping = this.state.savedMappings.find((x) => x.automation_type === mapping.automation_type && x.platform === mapping.platform && x.trellus_disposition === mapping.trellus_disposition && x.user_id === mapping.user_id && x.team_id === mapping.team_id)
          if (!matchingMapping || matchingMapping.value !== mapping.value) hasToUpdate = true;
          if (hasToUpdate) {

            const success = await getServicesManager().putAutodialerMappings(mapping.team_id !== '', mapping.user_group_id !== '' ? mapping.user_group_id : null, false, mapping.platform, mapping.automation_type, mapping.trellus_disposition, mapping.value)
            if (success) savedMappings.push({...mapping})
          } else {
            savedMappings.push({...mapping})
          }
        }
      }

      this.setState({'pendingSave': false, 'savedSettings': savedSettings, 'savedMappings': savedMappings})
    }

    async onSettingUpdate(dialer: Platform, toUpdate: Map<SETTING, any>, forceLock?: boolean) {
      if (!this.state.settings || !this.props.user) return;
      
      if (forceLock !== undefined) {
        if (!this.props.isAdmin && !this.state.selectedUserGroup) return;
        if (this.state.selectedUserGroup && !this.props.adminUserGroups.includes(this.state.selectedUserGroup)) return;
        let update: AutoDialerSetting;
        if (forceLock) {
        // if we are locking the setting, we need to copy over all the settings from toUpdate....
        const matchingUserSetting = this.state.settings.find((v) => v.platform === dialer && v.user_id === this.props.user?.user_id) ?? this._constructEmptySettings(dialer, this.props.user, false)
        const matchingTeamSetting = !this.state.selectedUserGroup ? this.state.settings.find((v) => v.platform === dialer && v.team_id !== '') ?? this._constructEmptySettings(dialer, this.props.user, true) : this.state.settings.find((v) => v.platform === dialer && v.user_group_id === this.state.selectedUserGroup) ?? this._constructEmptySettings(dialer, this.props.user, false, this.state.selectedUserGroup)
        update = {...matchingTeamSetting, ...Object.fromEntries(Array.from(toUpdate.keys()).map((v) => [v, matchingUserSetting[v]]))}
        Array.from(toUpdate.keys()).map((v) => v as SETTING).forEach((v) => {if (update[v] === null || update[v] === undefined) update[v] = SETTING_TO_DEFAULT_VALUE[v](dialer)})
        } else {
          // need to clear all of it out from the team setting
          const matchingTeamSetting = !this.state.selectedUserGroup ? this.state.settings.find((v) => v.platform === dialer && v.team_id !== '') : this.state.settings.find((v) => v.platform === dialer && v.user_group_id === this.state.selectedUserGroup)
          if (!matchingTeamSetting) return;
          update = {...matchingTeamSetting, ...Object.fromEntries(Array.from(toUpdate.keys()).map((v) => [v, null]))}
        }

        this.setState((state) => {
          if (!state.settings) return null
          const idx = state.settings.findIndex((v) => v.platform === dialer && ((!state.selectedUserGroup && v.team_id !== '') || (state.selectedUserGroup && v.user_group_id === state.selectedUserGroup)))
          let adjustedSettings: AutoDialerSetting[]
          if (!forceLock && idx === -1) return null; // turning off lock but couldn't find team setting to clear... 
          if (idx === -1) adjustedSettings = [...state.settings, update]
          else adjustedSettings = [...state.settings.slice(0, idx), update, ...state.settings.slice(idx + 1)]
          return {'settings': adjustedSettings}
        })
      } else {
        const matchingTeamSetting = this.state.settings.find((v) => v.platform === dialer && ((!this.state.selectedUserGroup && v.team_id !== '') || (this.state.selectedUserGroup && v.user_group_id === this.state.selectedUserGroup)))
        
        let isTeam: boolean = false;
        if (matchingTeamSetting && Array.from(toUpdate.keys()).some((v) => matchingTeamSetting[v] !== null && matchingTeamSetting[v] !== undefined)) isTeam = true;
        if (isTeam && (!this.props.isAdmin && !this.state.selectedUserGroup)) return;
        if (isTeam && this.state.selectedUserGroup && !this.props.adminUserGroups.includes(this.state.selectedUserGroup)) return;
   
        const userId = this.props.user.user_id
        const autodialerSetting: AutoDialerSetting = (isTeam ? this.state.settings.find((v) => v.platform === dialer && ((this.state.selectedUserGroup && v.user_group_id === this.state.selectedUserGroup) || (!this.state.selectedUserGroup && v.team_id !== ''))) : this.state.settings.find((v) => v.user_id === userId && v.platform === dialer)) ?? this._constructEmptySettings(dialer, this.props.user, isTeam)
        const update: AutoDialerSetting = {...autodialerSetting, ...Object.fromEntries(Array.from(toUpdate.entries()).map((v) => [v[0], v[1]]))}
        this.setState((state) => {
          if (!state.settings) return null; 
          const idx = state.settings.findIndex((v) => v.platform === dialer && ((isTeam && (state.selectedUserGroup ? v.user_group_id === state.selectedUserGroup : v.team_id !== '')) || (!isTeam && v.user_id === this.props.user?.user_id))) 
          let adjustedSettings: AutoDialerSetting[];
          if (idx === -1) adjustedSettings = [...state.settings, update]
          else adjustedSettings = [...state.settings.slice(0, idx), update, ...state.settings.slice(idx + 1)]
          return {'settings': adjustedSettings}
        })
      }
    }
    
    _getSettingSections(dialer: Platform): Section[] {
        const toReturn = []
        const dialingPreference = [
          {'rowLabel': 'Time to Dial', 'settings': [SETTING.TIME_TO_DIAL]},
          {'rowLabel': 'Time to Log', 'settings': [SETTING.TIME_TO_LOG]},
          {'rowLabel': 'Time to Hangup (ringing)', 'settings': [SETTING.HANGUP_RINGING_ACTIVE, SETTING.HANGUP_RINGING_TIME]},
          {'rowLabel': 'Time to Hangup (voicemail)', 'settings': [SETTING.HANGUP_VOICEMAIL_ACTIVE, SETTING.HANGUP_VOICEMAIL_TIME]},
          {'rowLabel': 'Time to Hangup (menu)', 'settings': [SETTING.HANGUP_MENU_ACTIVE, SETTING.HANGUP_MENU_TIME]},
        ]
      
        toReturn.push({'options': dialingPreference, 'section': 'Timer preference'})
      
        const prospectPreference = [
          {'rowLabel': 'Timezone', 'separator': ['to'], 'settings': [SETTING.LOCAL_CALL_TIME_MIN, SETTING.LOCAL_CALL_TIME_MAX]},
          {'rowLabel': 'Detect timezone via area code', 'settings': [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER] },
          {'rowLabel': 'Dialing mode', 'settings': [SETTING.DIALING_MODE]},
          {'rowLabel': 'Order/Option', 'settings': [SPECIAL_SETTINGS.DIALING_ORDER]},
        ]

        if ([Platform.OUTREACH, Platform.HUBSPOT, Platform.GONG_ENGAGE].includes(dialer)) prospectPreference.push({'rowLabel': 'Number rotation', 'settings': [SPECIAL_SETTINGS.NUMBER_ROTATION]})
     
        if ([Platform.SALESLOFT].includes(dialer)) {
          prospectPreference.push({'rowLabel': 'Use custom phone fields', 'settings': [SETTING.USE_CUSTOM_PHONE_FIELDS]})
        }

        toReturn.push({'options': prospectPreference, 'section': 'Prospect preference'})
      
        const autodialerPreference = []
      
        autodialerPreference.push(...[
          {'rowLabel': 'Open Company URL', 'settings': [SETTING.COMPANY_WEBSITE_AUTO_OPEN]},
          {'rowLabel': 'Open LinkedIn URL', 'settings': [SETTING.LINKEDIN_AUTO_OPEN]},
        ])
        if ([Platform.OUTREACH, Platform.SALESLOFT, Platform.APOLLO, Platform.GONG_ENGAGE].includes(dialer)) {
          autodialerPreference.push({'rowLabel': 'Open Salesforce URL', 'settings': [SETTING.SALESFORCE_URL_AUTO_OPEN]})
        }
        autodialerPreference.push({'rowLabel': 'Music Website', 'settings': [SETTING.MUSIC_WEBSITE]},)
        if ([Platform.OUTREACH].includes(dialer)) {
          autodialerPreference.push({'rowLabel': 'Default recording setting', settings: [SETTING.OUTREACH_MODAL_CLICK_SETTING]})
        }
      
        toReturn.push({'options': autodialerPreference, 'section': 'Companion mode preference'})

        if (dialer === Platform.APOLLO) {
          const advancedApolloPreferences = [
            {'rowLabel': 'Always dial primary', settings: [SETTING.PRIMARY_ALWAYS]},
            {'rowLabel': 'Primary # first', settings: [SETTING.PRIMARY_FIRST]},
            {'rowLabel': 'Always dial validated #\'s', settings: [SETTING.VERIFIED_ALWAYS]},
            {'rowLabel': 'Call DNC (Ensure legal compliance before selecting)', settings: [SETTING.DNC_ALWAYS]}
          ]
          toReturn.push({'options': advancedApolloPreferences, 'section': 'Apollo Specific Preferences'})
        } else if (dialer === Platform.HUBSPOT) {
          const advancedHubspotPreferences = [
            {'rowLabel': 'Call account #\'s and deal #\'s', 'settings': [SETTING.ALL_RECORDS] }
          ]
          toReturn.push({'options': advancedHubspotPreferences, 'section': 'Hubspot Specific Preferences'})
        }

        const outcomePreferences = [
          {'rowLabel': 'Don\'t auto-complete tasks', 'settings': [SETTING.NO_AUTO_COMPLETE_TASK]},
          {'rowLabel': 'Don\'t log only', 'settings': [SETTING.NO_LOG_ONLY]},
          {'rowLabel': 'Don\'t auto-add AI summary:', 'settings': [SETTING.NO_SUMMARY_AUTOMATION]},
        ]

        if ([Platform.SALESLOFT, Platform.OUTREACH, Platform.APOLLO, Platform.GONG_ENGAGE].includes(dialer)) {
          const rowLabelBadNumber = dialer === Platform.SALESLOFT ? 'Remove wrong # on detection' : 'Label wrong # as invalid on detection'
          outcomePreferences.push({'rowLabel': rowLabelBadNumber, 'settings': [SETTING.ACTION_ON_BAD_NUMBER]})
          outcomePreferences.push({'rowLabel': 'Hide wrong number element', 'settings': [SETTING.HIDE_WRONG_NUMBER_ELEMENT]})
          outcomePreferences.push({'rowLabel': 'Skip tasks without numbers', 'settings': [SETTING.SKIP_TASK_WITHOUT_NUMBER]})
        }
        outcomePreferences.push({'rowLabel': 'Progress if live connect <', 'settings': [SETTING.AUTO_PROGRESS_LIVE_ACTIVE, SETTING.AUTO_PROGRESS_TIME]})
        outcomePreferences.push({'rowLabel': 'Max number of lines', 'settings': [SETTING.MAX_NUMBER_LINES]})
  
        toReturn.push({'options': outcomePreferences, 'section': 'Logging preference'})
        
        const automationPreferences = []
        if ([Platform.APOLLO, Platform.OUTREACH].includes(dialer)) automationPreferences.push({'rowLabel': 'Default Purpose', settings: [], 'fields': [AutomationType.PURPOSE]})
        if (automationPreferences.length > 0)  toReturn.push({'options': automationPreferences, 'section': 'Automation preferences'})
      
        return toReturn
      }

    componentDidMount(): void {
        getServicesManager().getAutodialerSettings().then((v) => {
          this.setState({'settings': v ?? undefined, 'savedSettings': v ?? undefined})
        })
        getServicesManager().getAutodialerMappingOptions().then((v) => this.setState({'options': v ?? undefined}))
        getServicesManager().getAutodialerMappings().then((v) => this.setState({'mappings': v ?? undefined, 'savedMappings' : v ?? undefined}))
    }

    componentDidUpdate(prevProps: Readonly<AutodialerSettingsProps>, prevState: Readonly<AutodialerSettingState>, snapshot?: any): void {
      if (!this.state.selectedDialer && this.state.settings && this.state.settings.length > 0 && this.state.mappings && this.state.options && this.props.isAdmin !== null && 
        !(prevProps.isAdmin !== null && prevState.settings && prevState.mappings && prevState.options)) {
          const dialer = this.state.settings[0].platform
          this.setState({'selectedDialer': dialer})
      }

      if (!prevState.pendingSave && this.state.pendingSave) {this.onSave()}
    }

    _getValueForSetting(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[]) {
      const nonNulValue: AutoDialerSetting | undefined = applicableSettings.find((v) => v[setting] !== null && v[setting] !== undefined)
      const value = nonNulValue === undefined ? SETTING_TO_DEFAULT_VALUE[setting](dialer) : nonNulValue[setting]
      return SETTING_TO_ADJUSTMENT_TO_USER_READABLE[setting](value)
    }

    constructRangeInput(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[]) {
          const getInfo = getSettingInfo(setting)
          return <div className="w-full flex flex-row gap-1 items-center">
            {this.constructNumberInput(dialer, setting, applicableSettings)}
            <input type="range" min={getInfo?.min} max={getInfo?.max} 
            onChange={(event) => {
              const value = parseInt(event.target.value)
              if (isNaN(value) || (getInfo?.min && value < getInfo?.min) || (getInfo?.max && value > getInfo?.max)) return
              const update = new Map<SETTING, number>([[setting, SETTING_TO_ADJUSTMENT_TO_BACKEND[setting](value)]])
              this.onSettingUpdate(dialer, update)
            }}
            value={parseInt(this._getValueForSetting(dialer, setting, applicableSettings))} className="w-full cursor-pointer bg-black" style={{'borderRadius': '4px'}}/>
          </div>
    }

      constructToggleInput(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[], getValue?: (dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[]) => any, updateValue?: (updatedValue: any) => void) {
        const isOn = getValue ? getValue(dialer, setting, applicableSettings) :  this._getValueForSetting(dialer, setting, applicableSettings)
        return <SwitchElement 
        isOn={isOn} 
        onClick={() => {
          if (updateValue) {
            updateValue(!isOn)
            return
          }
          const update = new Map<SETTING, any>([[setting, !isOn]])
          this.onSettingUpdate(dialer, update)
        }}/>
      }

      constructSelectorInput(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[], canToggle: boolean, isLocked: boolean, settingInfoForced?: any, getValue?: (dialer: Platform, setting: SETTING, 
        applicableSettings: AutoDialerSetting[]) => any, 
        updateValue?: (updatedValue: any) => void) {
        const selected = getValue ? getValue(dialer, setting, applicableSettings) : this._getValueForSetting(dialer, setting, applicableSettings)
        const settingInfo = settingInfoForced ?? getSettingInfo(setting)
        // @ts-ignore
        const options: FilterOption[] = (settingInfo?.options ?? []).map((v) => {return {'label': v.label, 'value': v.value, 'selected': selected === v.value}})
        const selectedLabel = options.find((v) => v.selected)?.label ?? 'None Selected'
        if (options.length === 0) return this._renderFilterHeaderElement("No options available", true)
        if (!canToggle && isLocked) return this._renderFilterHeaderElement(selectedLabel.toString(), true)
        return <Filter
          filterType={FilterType.SINGLE}
          closeOnSelect={true}
          variant="largeParagraph"
          selectableFilterProps={{
            'usesRelativeAtParent': true,
            'filterOptions': options,
            'onFilterUpdate': (updatedFilters: FilterOption[]) => {
                const selected = updatedFilters.find((v) => v.selected)
                if (!selected) return 
                const update = new Map<SETTING, any>([[setting, selected.value]])
                if (updateValue) updateValue(selected.value)
                else this.onSettingUpdate(dialer, update)
            },
            'headerElement': this._renderFilterHeaderElement(selectedLabel.toString())
          }}
        />
      }

    constructNumberInput(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[], 
      settingInfoForced?: any, getValue?: (dialer: Platform, setting: SETTING, 
        applicableSettings: AutoDialerSetting[]) => any, 
        updateValue?: (updatedValue: any) => void) {
      const settingInfo = settingInfoForced ?? getSettingInfo(setting)
      return <div className="flex flex-row gap-1 items-center">
              <input type="number" min={settingInfo?.min} max={settingInfo?.max} 
              onChange={(event) => {
                const value = parseInt(event.target.value)
                if (isNaN(value) || (settingInfo?.min && value < settingInfo?.min) || (settingInfo?.max && value > settingInfo?.max)) return
                if (updateValue) {
                  updateValue(value); return;
                }
                const update = new Map<SETTING, number>([[setting, SETTING_TO_ADJUSTMENT_TO_BACKEND[setting](value)]])
                this.onSettingUpdate(dialer, update)
              }}
              value={parseInt(getValue ? getValue(dialer, setting, applicableSettings) : this._getValueForSetting(dialer, setting, applicableSettings))} className="text-center flex" 
              style={{'width': '50px', 'height': '30px', 'fontSize': 'min(12px, max(11px, 1.8vw))', 'borderRadius': '10px; border: 10px solid #cfcfcf; padding: 0px; margin: 0px;'}} />
              <Typography variant="largeParagraph">{settingInfo?.text ?? ''}</Typography>
              {settingInfo && settingInfo['separator'] ? <div className="pl-1 pr-1"><Typography variant="largeParagraph">{settingInfo['separator']}</Typography></div> : null}
          </div>
    }

    constructSetting(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[], canToggle: boolean, isLocked: boolean, isLockedByPredecessor?: boolean): JSX.Element | null {
        switch (SETTING_TO_SETTING_TYPE[setting]) {
          case SETTING_TYPES.RANGE:
            return this.constructRangeInput(dialer, setting, applicableSettings)
          case SETTING_TYPES.TOGGLE:
            return this.constructToggleInput(dialer, setting, applicableSettings)
          case SETTING_TYPES.SELECTOR:
            return this.constructSelectorInput(dialer, setting, applicableSettings, canToggle, isLocked)
          case SETTING_TYPES.NUMBER_INPUT:
            return this.constructNumberInput(dialer, setting, applicableSettings)
        }
        return null
      }

    _getIsLocked(row: SettingRow, applicableSettings: AutoDialerSetting[]): { locked: boolean, predecessor_locked: boolean, can_toggle: boolean } {
      const settings = this._getSettingsForRow(row)
      const locked = settings.some((v) => applicableSettings.find((x) => {
        if (!this.state.selectedUserGroup) return x.team_id !== '' && (x[v as SETTING] !== null && x[v as SETTING] !== undefined)
        if (this.state.selectedUserGroup) return x.user_group_id === this.state.selectedUserGroup && (x[v as SETTING] !== null && x[v as SETTING] !== undefined)
      }))
      if (this.state.selectedUserGroup) {
        const predecessor_locked = settings.some((v) => applicableSettings.find((x) => x.team_id !== '' && x[v as SETTING] !== null && x[v as SETTING] !== undefined))
        return {'locked': locked, 'predecessor_locked': predecessor_locked, 'can_toggle': true }
      }
      else return {'locked': locked, 'predecessor_locked': false, 'can_toggle': this.props.isAdmin !== null} 
    }

    renderLockGraphic(isLocked: boolean, onClick: () => void) {
      return (<Debouncer elementToShow={
        <div 
        onClick={onClick.bind(this)}
        className="w-3 h-3" 
        style={{
          'cursor': this.props.isAdmin ? 'pointer': 'not-allowed',
          'opacity': isLocked ? '100%' : '40%',
          'borderRadius': '10px',
          'boxShadow': 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
      }}>{isLocked ? LOCKED_SVG: UNLOCKED_SVG}</div>
      }
      stringToShow={this.props.isAdmin ? (isLocked ? "Click to unlock for reps" : "Click to lock team setting") : (isLocked ? 'Ask admin to unlock' : 'Unlocked by admin')}
      />)
    }

    _getCorrespondingSettingsForSpecialSetting(specialSetting: SPECIAL_SETTINGS): SETTING[] {
      switch (specialSetting) {
        case SPECIAL_SETTINGS.DIALING_ORDER: return [
          SETTING.PHONE_PREFERENCE]
        case SPECIAL_SETTINGS.NUMBER_ROTATION: return [SETTING.NUMBER_ROTATION_PREFERENCE]
        default: return []
      } 
    }

    _getSettingsForRow(row: SettingRow): SETTING[] {
      return row.settings.map((v) => {
        if (Object.values(SPECIAL_SETTINGS).map((x) => x.toString()).includes(v)) {
          return this._getCorrespondingSettingsForSpecialSetting(v as SPECIAL_SETTINGS)
        } else {
          return [v as SETTING]
        }
      }).flat(1).flat(1)
    }

    renderLockForSetting(dialer: Platform, row: SettingRow, isLocked: boolean) {
      return this.renderLockGraphic(isLocked, () => {
        if (!this.props.isAdmin) return;
        const settingsToLock: SETTING[] = this._getSettingsForRow(row)
        this.onSettingUpdate(dialer, new Map<SETTING, any>(settingsToLock.map((v) => [v, null])), !isLocked)
      }) 
    }

    cleanUpPhoneSave(dialer: Platform, toUpdate: Map<PHONE_TYPE, PhonePreference>): Map<PHONE_TYPE, PhonePreference> {
      const cleanedUp = new Map<PHONE_TYPE, PhonePreference>()
      const eligiblePhoneOptions = this._getPhoneTypesForDialer(dialer)
      toUpdate.forEach((v, k) => {
        if (!eligiblePhoneOptions.includes(k)) return
        cleanedUp.set(k, v)
      })
      return cleanedUp
    }

    renderPhoneOrderRow(dialer: Platform, v: PHONE_TYPE, applicableSettings: AutoDialerSetting[]) {
      return <div className="p-2 w-full cursor-pointer flex items-center justify-between flex-row text-center gap-1.5"
              style={{
                'boxShadow': 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                'borderRadius': '10px',
              }}
            >
            {
            this.constructToggleInput(dialer, SETTING.PHONE_PREFERENCE, applicableSettings, 
            (dialer, setting, applicableSettings) => this._getValueForPhonePreference(dialer, v, 'active', applicableSettings), 
            (updatedValue: any) => {
              const currentOption = this._getPhonePreferenceMap(dialer, applicableSettings)
              const currentPhonePreference = currentOption.get(v)
              if (!currentPhonePreference) return
              const toUpdate = this.cleanUpPhoneSave(dialer, new Map(currentOption).set(v, {...currentPhonePreference, 'active': updatedValue}))
              this.onSettingUpdate(dialer, new Map<SETTING, any>([[SETTING.PHONE_PREFERENCE, toUpdate]]))
            }
            )}
            <Typography variant="largeParagraph">
              {PHONE_TYPE_TO_HUMAN_READABLE[v]}
            </Typography>
            {this.constructNumberInput(dialer, SETTING.PHONE_PREFERENCE, applicableSettings, 
            {'type': SETTING_TYPES.NUMBER_INPUT, 'text': 'attempt(s)', 'min': 1, 'max': 3}, 
            (dialer: Platform, setting, applicableSettings) => this._getValueForPhonePreference(dialer, v, 'double_taps', applicableSettings), 
            (updatedValue: any) => {
              const currentOption = this._getPhonePreferenceMap(dialer, applicableSettings)
              const currentPhonePreference = currentOption.get(v)
              if (!currentPhonePreference) return
              const toUpdate = this.cleanUpPhoneSave(dialer, new Map(currentOption).set(v, {...currentPhonePreference, 'double_taps': updatedValue}))
              this.onSettingUpdate(dialer, new Map<SETTING, any>([[SETTING.PHONE_PREFERENCE, toUpdate]]))
            })}
      </div>
    }

    _getPhonePreferenceMap(dialer: Platform, applicableSettings: AutoDialerSetting[]): Map<PHONE_TYPE, PhonePreference> {
      const nonNullValue: AutoDialerSetting | undefined = applicableSettings.sort((a, b) => {
        if (this.state.selectedUserGroup || !this.props.isAdmin) return (a.user_group_id !== '' ? 0 : a.team_id !== '' ? 1 : 2) - (b.user_group_id !== '' ? 0 : b.team_id !== '' ? 1 : 2)
        return (a.team_id !== '' ? 0 : 1) - (b.team_id !== '' ? 0 : 1)
      }).find((v) => v[SETTING.PHONE_PREFERENCE] !== null && v[SETTING.PHONE_PREFERENCE] !== undefined)
      return nonNullValue && nonNullValue[SETTING.PHONE_PREFERENCE]? nonNullValue[SETTING.PHONE_PREFERENCE] : SETTING_TO_DEFAULT_VALUE[SETTING.PHONE_PREFERENCE](dialer)
    }
    
    _getValueForPhonePreference(dialer: Platform, phone_type: PHONE_TYPE, key: 'active' | 'order' | 'double_taps', applicableSettings: AutoDialerSetting[]) {
      return (this._getPhonePreferenceMap(dialer, applicableSettings).get(phone_type) ?? {})[key]
    }

    _getPhoneTypesForDialer(dialer: Platform) {
      return DEFAULT_POWER_RANKINGS.filter((phone_type: PHONE_TYPE) => {
        if (phone_type === PHONE_TYPE.MOBILE) return true;
        if (phone_type === PHONE_TYPE.VOIP) return dialer === Platform.OUTREACH
        if (phone_type === PHONE_TYPE.WORK) return true
        if (phone_type === PHONE_TYPE.HOME) return [Platform.OUTREACH, Platform.SALESLOFT, Platform.GONG_ENGAGE].includes(dialer)
        if (phone_type === PHONE_TYPE.OTHER) return dialer !== Platform.SALESLOFT;
        return false;
      })
    }

    constructDialingOrder(dialer: Platform) {
      if (!this.state.settings) return null
      const matchingSettings = this.state.settings.filter((v) => v.platform === dialer && v[SETTING.PHONE_PREFERENCE] !== null).sort((a, b) => (a.team_id !== '' ? 0 : 1) - (b.team_id !== '' ? 0 : 1))
      const items: ItemInterface[] = this._getPhoneTypesForDialer(dialer).map((v) => {
        return {'id': v, 'value': this._getValueForPhonePreference(dialer, v as PHONE_TYPE, 'order', matchingSettings)}}).sort((a, b) => parseInt(a.value?.toString() ?? '') - parseInt(b.value?.toString() ?? '')) 
        return <ReactSortable 
      list={items}
      setList={(newState: ItemInterface[]) => {
        const updatedOrder = Object.fromEntries(newState.map((v, idx) => {return [v.id as PHONE_TYPE, idx]}))
        const currentOption = this._getPhonePreferenceMap(dialer, matchingSettings)
        const toUpdate = this.cleanUpPhoneSave(dialer, new Map(Array.from(currentOption.entries()).map((v) => {return [v[0], {...v[1], 'order': updatedOrder[v[0]]}]})))
        this.onSettingUpdate(dialer, new Map<SETTING, any>([[SETTING.PHONE_PREFERENCE, toUpdate]]))
      }}
      className="flex flex-col gap-1.5"
      animation={250}
      delay={2}
      >
        {items.map((v) => this.renderPhoneOrderRow(dialer, v.id as PHONE_TYPE, matchingSettings))}
        </ReactSortable>
    }

    
    _getValueForRotationPref(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[]) {
      return applicableSettings.length > 0 ? (applicableSettings[0][setting] as NumberRotationPreference) : SETTING_TO_DEFAULT_VALUE[SETTING.NUMBER_ROTATION_PREFERENCE](dialer)
    }

    _getValueForRotationPreference(dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[], key: 'rotation_active' | 'rotation_style' | 'switch_frequency') {
      return applicableSettings.length > 0 ? (applicableSettings[0][setting] as NumberRotationPreference)[key] : SETTING_TO_DEFAULT_VALUE[SETTING.NUMBER_ROTATION_PREFERENCE](dialer)[key]
    }

    constructRotationPreference(dialer: Platform, canToggle: boolean, isLocked: boolean) {
      if (!this.state.settings) return null
      const matchingSettings = this.state.settings.filter((v) => v.platform === dialer && v[SETTING.NUMBER_ROTATION_PREFERENCE] !== null).sort((a, b) => (a.team_id !== '' ? 0 : 1) - (b.team_id !== '' ? 0 : 1))
      return <div className="w-full flex flex-row gap-1.5 items-center">
            {this.constructToggleInput(dialer, SETTING.NUMBER_ROTATION_PREFERENCE, matchingSettings, (dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[]) => this._getValueForRotationPreference(dialer, setting, applicableSettings, 'rotation_active'), 
            (updatedValue) => this.onSettingUpdate(dialer, new Map<SETTING, any>([[SETTING.NUMBER_ROTATION_PREFERENCE, {...this._getValueForRotationPref(dialer, SETTING.NUMBER_ROTATION_PREFERENCE, matchingSettings), 'rotation_active': updatedValue}]])))}
            {this.constructSelectorInput(dialer, SETTING.NUMBER_ROTATION_PREFERENCE, matchingSettings, canToggle, isLocked, {'type': SETTING_TYPES.SELECTOR, 'options': Object.values(NUMBER_ROTATION_TYPES).map((v) => {return {'value': v, 'label': NUMBER_ROTATION_TYPES_TO_HUMAN_READABLE[v]}})}, 
              (dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[]) => this._getValueForRotationPreference(dialer,setting, applicableSettings, 'rotation_style'), 
              (updatedValue) => this.onSettingUpdate(dialer, new Map<SETTING, any>([[SETTING.NUMBER_ROTATION_PREFERENCE, {...this._getValueForRotationPref(dialer, SETTING.NUMBER_ROTATION_PREFERENCE, matchingSettings), 'rotation_style': updatedValue}]]))
              )}
            {this._getValueForRotationPreference(dialer, SETTING.NUMBER_ROTATION_PREFERENCE, matchingSettings, 'rotation_style') === NUMBER_ROTATION_TYPES.DIAL_LIMIT_ROTATION ? this.constructNumberInput(dialer, SETTING.NUMBER_ROTATION_PREFERENCE, matchingSettings, {'type': SETTING_TYPES.NUMBER_INPUT, 'text': 'dial(s)', 'min': 1, 'max': 50}, (dialer: Platform, setting: SETTING, applicableSettings: AutoDialerSetting[]) => {
            return applicableSettings.length > 0 ? (applicableSettings[0][setting] as NumberRotationPreference)['switch_frequency'] : SETTING_TO_DEFAULT_VALUE[SETTING.NUMBER_ROTATION_PREFERENCE](dialer)['switch_frequency']
            }, 
            (updatedValue) => this.onSettingUpdate(dialer, new Map<SETTING, any>([[SETTING.NUMBER_ROTATION_PREFERENCE, {...this._getValueForRotationPref(dialer, SETTING.NUMBER_ROTATION_PREFERENCE, matchingSettings), 'switch_frequency': updatedValue}]]))
            ) : null}
        </div>
    }

    constructSpecialSetting(dialer: Platform, specialSetting: SPECIAL_SETTINGS, canToggle: boolean, isLocked: boolean): JSX.Element| null {
      switch(specialSetting) {
        case SPECIAL_SETTINGS.DIALING_ORDER: return this.constructDialingOrder(dialer)
        case SPECIAL_SETTINGS.NUMBER_ROTATION: return this.constructRotationPreference(dialer, canToggle, isLocked)
      }
      return null
    }

    renderSectionRow(dialer: Platform, row: SettingRow, applicableSettings: AutoDialerSetting[]) {
      const {can_toggle, locked, predecessor_locked} = this._getIsLocked(row, applicableSettings)
      return <div className="w-full flex flex-row gap-1.5 items-center">
           <div style={{'width': '25%', 'maxWidth': '150px'}}><Typography variant="largeParagraph">{row.rowLabel}</Typography></div>
           {row.settings && row.settings.length > 0 ? this.renderLockForSetting(dialer, row, locked) : null}
           <div className="w-full flex flex-start pl-1 gap-1.5 items-center" style={{'opacity': locked && !can_toggle ? '40%': '100%'}}>
           {row.settings.map((v) => {
            return <>
            {Object.values(SETTING).map((z) => z.toString()).includes(v.toString()) ? this.constructSetting(dialer, v as SETTING, applicableSettings, can_toggle, locked) : this.constructSpecialSetting(dialer, v as SPECIAL_SETTINGS, can_toggle, locked)}
            {predecessor_locked ? <Typography fontWeight={650}>Will default to team-level unless locked</Typography> : null}
            </>
           })}
           {row.fields ? row.fields.map((v) =>  {
            return this._constructMappingOption(dialer, TrellusDisposition.SPECIAL_MATCH_ALL, AutomationType.PURPOSE)
           }) : null}
           </div>
      </div>
    }

    renderSettingSection(dialer: Platform, section: Section, applicableSettings: AutoDialerSetting[]) {
      return <div className="w-full flex flex-col gap-1.5 p-2" style={{
        'border': '1px solid lightgrey',
        'borderRadius': '10px'
      }}>
        <Typography variant="largeParagraph" fontWeight={650}>
          {section.section}
        </Typography>
        {section.options.map((v) => this.renderSectionRow(dialer, v, applicableSettings))}
      </div>
    }

    
    _getMappingOptionsViaDialer(dialer: Platform): AutomationType[] {
      switch (dialer) {
        case Platform.SALESLOFT: return [AutomationType.DISPOSITION, AutomationType.SENTIMENT]
        default: return [AutomationType.DISPOSITION]
      }
    }

    _renderFilterHeaderElement(selected?: string, locked?: boolean): JSX.Element {
      return <div className="overflow-hidden w-full flex-row gap-1 p-1 text-center items-center"  
      style={{
        'cursor': locked ? 'not-allowed' : 'pointer',
        'opacity': locked ? '50%' :'100%',
        'width': '150px',
        'boxShadow': 'rgba(0, 0, 0, 0.16) 0px 1px 4px', 'borderRadius': '5px', 
        "backgroundColor": locked ? 'lightgrey' : "white"}} >
          <Typography variant="largeParagraph">
          {selected && selected !== SPECIAL_NO_MATCH ? selected : 'None Selected'}
          </Typography>
      </div>
    }

    onAutomationMappingUpdate(dialer: Platform, disposition: TrellusDisposition, automationType: AutomationType, valueSet: string | null, isLocked: boolean, forceLock?: boolean) {
      this.setState((state) => {
        if (!state.mappings || !this.props.user) return null
        const userId = this.props.user.user_id
        const teamId = this.props.user.team_id
        const selectedUserGroup = this.state.selectedUserGroup ?? ''
        let toUpdate: AutoDialerMapping[] = []
        let value: string | null = valueSet
        if (forceLock !== undefined && value === null) {
          if ((!this.state.selectedUserGroup && this.props.isAdmin) || (this.state.selectedUserGroup && this.props.adminUserGroups.includes(this.state.selectedUserGroup))) value = '___NO_MATCH__'
        }
        if (forceLock !== undefined) {
          if (value === null) return null
          if (forceLock) {
            // if we are locking it, we need to add it in as a locked value
            // make sure there isn't a matching value
            const matchingIdx = state.mappings.findIndex((v) => v.platform === dialer && v.trellus_disposition === disposition && v.automation_type === automationType && (this.state.selectedUserGroup ? v.user_group_id === this.state.selectedUserGroup : v.team_id === teamId))
            if (matchingIdx === -1) toUpdate = [...state.mappings,  {'platform': dialer, 'automation_type': automationType, 'trellus_disposition': disposition, 'value': value, 'user_id': '', 'team_id': this.state.selectedUserGroup ? '' : teamId, 'user_group_id': this.state.selectedUserGroup ? selectedUserGroup : ''}]
            else toUpdate = [...state.mappings.slice(0, matchingIdx), {...state.mappings[matchingIdx], 'value': value}, ...state.mappings.slice(matchingIdx + 1)]    
          } else {
            // need to find the matchng value and remove it... 
            const matchingIdx = state.mappings.findIndex((v) => v.platform === dialer && v.trellus_disposition === disposition && v.automation_type === automationType && (this.state.selectedUserGroup ? v.user_group_id === this.state.selectedUserGroup : v.team_id === teamId))
            if (matchingIdx === -1) return null
            toUpdate = [...state.mappings.slice(0, matchingIdx), ...state.mappings.slice(matchingIdx + 1)] 
          }
          // if we are force locking it, we need to find the user
        } else {
          const matchingIdx = state.mappings.findIndex((v) => v.platform === dialer && v.trellus_disposition === disposition && v.automation_type === automationType && (((!isLocked && v.user_id === this.props.user?.user_id) || (isLocked && (this.state.selectedUserGroup ? v.user_group_id === this.state.selectedUserGroup : v.team_id === this.props.user?.team_id)))))
          if (matchingIdx !== -1) {
            if (value === null)  toUpdate = [...state.mappings.slice(0, matchingIdx), ...state.mappings.slice(matchingIdx + 1)] 
            else toUpdate = [...state.mappings.slice(0, matchingIdx), {...state.mappings[matchingIdx], 'value': value}, ...state.mappings.slice(matchingIdx + 1)]          
          } else {
            if (value === null) return null
            toUpdate = [...state.mappings, {'platform': dialer, 'automation_type': automationType, 'trellus_disposition': disposition, 'value': value, 'user_id': isLocked ? '' : userId, 'team_id': isLocked && !this.state.selectedUserGroup ? teamId : '', 'user_group_id': isLocked && this.state.selectedUserGroup ? selectedUserGroup : ''}]
          }
        }
        return {'mappings': toUpdate}
      })
    }

    _getAutomationTypeOptions(dialer: Platform, automationType: AutomationType) {
      return Array.from(new Set((this.state.options ?? []).filter((v) => v.automation_type === automationType && v.platform === dialer).map((v) => v.options).flat(1)))
    }

    _constructMappingOption(dialer: Platform, disposition: TrellusDisposition, automationType: AutomationType) {
      const teamMapping = this.state.mappings?.find((v) => v.automation_type === automationType && v.platform === dialer && v.trellus_disposition === disposition && (this.state.selectedUserGroup ? v.user_group_id === this.state.selectedUserGroup : v.team_id === this.props.user?.team_id))
      const userMapping = this.state.mappings?.find((v) => v.automation_type === automationType && v.platform === dialer && v.trellus_disposition === disposition && v.user_id !== '')
      const mapping = teamMapping ?? userMapping
      const isLocked = mapping !== undefined && teamMapping !== undefined
      const automationOptions = this._getAutomationTypeOptions(dialer, automationType)
      
      return <div className="pt-1 pb-1 pl-1 pr-1 flex flex-row items-center gap-1 justify-around" style={{'width': '200px'}}>
        {this.renderLockGraphic(isLocked, 
          () => this.onAutomationMappingUpdate(dialer, disposition, automationType, mapping ? mapping.value : null, isLocked, !isLocked)
          )}
          {isLocked && !this.props.isAdmin ? this._renderFilterHeaderElement(mapping?.value, true) :
            <Filter 
          filterType={FilterType.SINGLE}
          closeOnSelect={true}
          variant="largeParagraph"
          selectableFilterProps={{
            groupById: true,
            filterOptions: automationOptions.length > 0 ? [{'label': 'Do Not Map', 'id': 'Special', 'value': 'NONE', 'selected': mapping === undefined || mapping.value === SPECIAL_NO_MATCH}, ...automationOptions.map((v) => {return {'label': v, 'selected': mapping !== undefined && mapping.value === v, 'value': v, 'id': 'Options'}})] : [],
            onFilterUpdate: (updatedFilters: FilterOption[]) => {
              const selected = updatedFilters.find((v) => v.selected)
              if (!selected) return;
              this.onAutomationMappingUpdate(dialer, disposition, automationType, selected.value === 'NONE' ? null : selected.value, isLocked, undefined)
            },
            usesRelativeAtParent: true,
            headerElement: this._renderFilterHeaderElement(automationOptions.length > 0 ? mapping?.value : 'No options available')
          }}
      />
        }</div>
    }
    
    _getAutodialerTableProps(dialer: Platform): TableProps<TrellusDisposition | null> {
      const mappingOptions = this._getMappingOptionsViaDialer(dialer)
      return {
        'columnFuncs': [
          (item: TrellusDisposition | null) => <div className="text-left pr-1" style={{'minWidth': '150px', 'width': 'fit-content'}}><Typography variant="largeParagraph" fontWeight={item ? undefined : '650'}>{item ? TrellusDispositionToHumanReadable[item] : 'Predicted'}</Typography></div>,
          ...mappingOptions.map((v) => (item: TrellusDisposition | null) => item ? this._constructMappingOption(dialer, item, v) : <div style={{'width': '200px'}} className="flex justify-center items-center text-center"><Typography fontWeight={650} variant="largeParagraph">{AUTOMATION_TYPE_TO_HUMAN_READABLE[v]}</Typography></div>)
        ],
        'keyFunc': (item: TrellusDisposition | null) => item ?? '',
        'items': [null, ...Object.values(TrellusDisposition).filter((v) => TrellusDispositionIsPrediction[v])],
        'highlightedItemIdx': -1,
        'equalWidth': false,
        'noPadding': true,
        'tdClassAdditionalStyle': {'maxWidth': undefined},
        'trClassStyleNonHighlighted': {'backgroundColor': 'transparent'},
      }
    }

    renderAutodialerMappings(dialer: Platform) {
      return <div className="w-full flex flex-col gap-1 p-2" style={{
        'border': '1px solid lightgrey',
        'borderRadius': '10px'
      }}>
        <Table {...this._getAutodialerTableProps(dialer)}/>
      </div>
    }

    renderSettingsForDialer(dialer: Platform) {
      const sections = this._getSettingSections(dialer)
      const applicableSettings = (this.state.settings ?? []).filter((v) => (v.platform === dialer)).sort((a, b) => {
        if (this.state.selectedUserGroup || !this.props.isAdmin) return (a.user_group_id !== '' ? 0 : a.team_id !== '' ? 1 : 2) - (b.user_group_id !== '' ? 0 : b.team_id !== '' ? 1 : 2)
        return (a.team_id !== '' ? 0 : 1) - (b.team_id !== '' ? 0 : 1)
      })
      return <div className="w-full flex flex-col gap-2 p-1">
        {sections.map((v) => this.renderSettingSection(dialer, v, applicableSettings))}
        {this.renderAutodialerMappings(dialer)}
      </div>
    }

    _jsonifyParams(params: any) {
      return Array.from(Object.entries({...params})).map(([key, value]) => JSON.stringify(value)).join('')
  }

  _jsonifyAutodialerSetting(params: AutoDialerSetting) {
    //@ts-ignore
    return Object.values(SETTING).map((s) => s === SETTING.PHONE_PREFERENCE && params[s] ? JSON.stringify(Array.from(params[s].entries())) : JSON.stringify(params[s])).join('')
  }

  _jsonifyAutodialerSettings(params: AutoDialerSetting[] | undefined) {
    if (params === undefined) return undefined
    //@ts-ignore
    return Object.values(SETTING).map((s) => params.map((v) => v[s]).map((v) => s === SETTING.PHONE_PREFERENCE && v ? JSON.stringify(Array.from(v.entries())) : JSON.stringify(v))).join('')
  }

    requiresSave() {
      return this._jsonifyParams(this.state.savedMappings) !== this._jsonifyParams(this.state.mappings) || this._jsonifyAutodialerSettings(this.state.savedSettings) !== this._jsonifyAutodialerSettings(this.state.settings);
    }

    renderSettings() {
        if (!this.state.selectedDialer) return <div className="w-full h-full flex justify-center items-center text-center"><Typography variant="largeParagraph" fontWeight={650}> Select a dialer</Typography></div>
        else return <div className="w-full h-full flex flex-col overflow-auto">
            {this.renderSettingsForDialer(this.state.selectedDialer)}
            {this.renderSaveBar()}
        </div>
    }

    renderSaveBar() {
      const pendingSave = this.requiresSave()
      return <div className="fixed right-6 bottom-3 p-2.5 w-full flex flex-row gap-2 justify-end items-center text-center"
       style={{
        'pointerEvents': 'none',
        'zIndex': '999',
       }}
       >
        {pendingSave ? <div onClick={() => this.setState({'pendingSave': true})} className={'flex opacity-80 p-3 w-fit  justify-center items-center hover:opacity-100 cursor-pointer'}  
        style={{
            'pointerEvents': 'all',
            'borderRadius': '10px', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)', 'backgroundColor': 'rgb(3, 33, 78)'}}> 
          <Typography variant="h2" color="white">
            {this.state.pendingSave ? `Saving...` : 'Save'}
          </Typography>
        </div> : <div className="w-5 h-5 flex flex-row justify-center items-center p-1.5" style={{'backgroundColor': 'green', 'borderRadius': '50%'}}>
          {checkMarkSvg}
        </div>
      }
      </div>
    }

    renderUserGroupSelector() {
      // render the user groups along with the overall team at the top
      const userGroups = this.props.userGroupInfo.filter((v) => this.props.adminUserGroups.includes(v.group.user_group_id))
      if (!userGroups || userGroups.length === 0) return null
      if (!this.props.isAdmin) return null
      return <div>
      <h2 className="mb-2 text-sm font-semibold text-gray-600">Team</h2>
      <nav className="space-y-1">
        {[{'label': 'Overall Team', 'value': undefined}, 
        ...userGroups.map((v) => {return {'label': v.group.user_group_name, 'value': v.group.user_group_id}})].map((v) => {
          return <button
          key={v.value ?? ''}
          className={`w-full text-left px-2 py-1 rounded ${
            this.state.selectedUserGroup === v.value ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-gray-100'
          }`}
          onClick={() => this.setState({selectedUserGroup: v.value})}>
            {v.label}
          </button>
        })}
      </nav>
      </div>
    }

    render() {
        if (!this.props.user || 
          !this.state.settings || 
          !this.state.options || 
          !this.state.mappings || 
          this.props.isAdmin === null ||
          !this.props.userGroupLoaded
          ) return <Loader />
        return  <div className="flex h-full">
          <PromptOnLeave message="Make sure to save your autodialer settings!" when={this.requiresSave()}/>
          {/* Sidebar */}
          <div className="w-48 p-4 shadow-sm">
            <div className="mb-4">
            <h2 className="mb-2 text-sm font-semibold text-gray-600">Platform</h2>
            <nav className="space-y-1">
              {[Platform.SALESLOFT, Platform.OUTREACH, Platform.HUBSPOT, Platform.APOLLO, Platform.OPENPHONE, Platform.GONG_ENGAGE].map((dialer) => (
                <button
                key={dialer}
                className={`w-full text-left px-2 py-1 rounded ${
                  this.state.selectedDialer === dialer ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-gray-100'
                }`}
                onClick={() => this.setState({ selectedDialer: dialer })}
              >
                {PLATFORM_TO_HUMAN_READABLE[dialer]}
              </button>
            ))}
          </nav>
        </div>
        {this.renderUserGroupSelector()}
      </div>
        {/* Main Content */}
        <div className="flex-1 p-6">
          <div className="mx-auto max-w-4xl space-y-6">
            {this.renderSettings()}
          </div>
        </div>
      </div>
    }
}


const ReduxWrapped = connect((state: RootState) => {
  return {
    isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : null,
    userGroupInfo: state.userGroupInfo.sortedGroupInfo,
    userGroupLoaded: state.userGroupInfo.hasLoaded,
    adminUserGroups: state.adminWrite.value ? state.adminWrite.value.user_group_ids : [],
    user: convertFromReduxSafeUserState(state.user)
  }
})(AutodialerSettingsImpl)

export { ReduxWrapped as AutodialerSettings}