import React, { useEffect, useState } from 'react'
import { 
  Typography, 
  Button, 
  Card,
  Select,
  MenuItem,
  TextField,
  Box,
  FormControl,
  InputLabel,
  CardContent,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  Alert,
  Tooltip,
  Menu,
  Tabs,
  Tab,
  SelectChangeEvent,
  Snackbar,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogContentText
} from '@mui/material'
import { 
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  AddCircle as AddCircleIcon,
  Info as InfoIcon,
  Calculate as CalculateIcon,
  Tune as TuneIcon
} from '@mui/icons-material'
import { User2 } from 'lucide-react'
import { 
  CustomDefinition, 
  CustomMetricSource, 
  CustomMetricValueType, 
  PartyRole,
  SESSION_METRICS_COLUMNS,
  CustomMetric
} from '../../interfaces/db'
import {
  ListUserSessionParamsV3,
  UserSessionsResultItem,
  SessionDataResult,
  CnfFilterType,
  StaticFilterDisjunction,
  MetricResult
} from '../../interfaces/services'
import { getServicesManager } from '../../services'
import { formatMetricSource, formatMetricType, isDefinitionValid, COLORS, defaultMetrics } from './utils'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'
import { RootState } from '../../store'
import { getCustomCode } from './cfg'
import { CustomCode } from './cfg'
import { DateOptions, StaticFilterKeys } from '../../lib/redux/store'
import { durationToString, shortenedDateToString } from '../../time'
import { MIN_TO_SEC, SEC_TO_MS } from '../../cfg/const'
import { TranscriptBody } from '../Widgets/Transcript'

interface MetricChange {
  label: string
  type: 'removed' | 'modified'
}

interface EditModalProps {
  open: boolean
  definition: CustomDefinition | null
  definitions: CustomDefinition[]
  codeOptions: CustomCode[]
  onClose: () => void
  onSave: (def: CustomDefinition) => void
}

interface ConfirmDialogProps {
  open: boolean
  changes: MetricChange[]
  onClose: () => void
  onConfirm: () => void
}

type MetricTemplate = {
  label?: string
  description?: string
  value_source?: CustomMetricSource
  value_type?: CustomMetricValueType
  value_type_enum_str?: string | null
  value_gen_str?: string | null
  value_gen_desc_str?: string | null
  value_phrase_str?: string | null
  value_phrase_role?: PartyRole | null
  value_average_str?: string | null
  value_code_str?: string | null
  where_min_duration?: number | null
  where_max_duration?: number | null
}

const MODAL_STYLES = {
  paper: {
    borderRadius: 2,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 8px 24px, rgba(0, 0, 0, 0.05) 0px 2px 6px',
    backgroundImage: COLORS.background.gradient,
    maxHeight: '90vh',
    maxWidth: '800px !important'
  },
  fieldWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    gap: 0.75,
    '& .MuiFormControl-root': {
      flex: 1
    }
  },
  input: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 1,
      backgroundColor: COLORS.background.card,
      '&:hover': {
        backgroundColor: COLORS.background.hover
      },
      '&.Mui-focused': {
        backgroundColor: COLORS.background.card,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 4px'
      },
      padding: '0 !important'
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.9rem'
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 14px'
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '0 !important',
      '& > div': {
        padding: '0 !important'
      },
      '& fieldset': {
        padding: '0 !important'
      },
      '& textarea': {
        padding: '12px 14px !important'
      }
    }
  },
  helpIcon: {
    width: 22,
    height: 22,
    color: 'text.secondary',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: '50%',
    flexShrink: 0,
    mt: 0.75,
    '&:hover': {
      color: 'primary.main',
      backgroundColor: 'rgba(33, 150, 243, 0.08)'
    }
  }
}

function TabPanel({ children, value, index }: { children: React.ReactNode, value: number, index: number }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`metric-tabpanel-${index}`}
      aria-labelledby={`metric-tab-${index}`}
    >
      {value === index && children}
    </div>
  )
}

function ConfirmDialog({ open, changes, onClose, onConfirm }: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Warning: Modifying Existing Metrics</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The following metrics will be affected:
        </DialogContentText>
        <List>
          {changes.map((change, idx) => (
            <ListItem key={idx}>
              <ListItemIcon>
                {change.type === 'removed' ? <DeleteIcon color="error" /> : <EditIcon color="warning" />}
              </ListItemIcon>
              <ListItemText 
                primary={change.label}
                secondary={change.type === 'removed' ? 'Will be removed' : 'Will be modified'}
              />
            </ListItem>
          ))}
        </List>
        <DialogContentText sx={{ mt: 2 }}>
          These changes may affect metric stability. Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="primary">Save Changes</Button>
      </DialogActions>
    </Dialog>
  )
}

function EditModal({ open, definition, definitions, codeOptions, onClose, onSave }: EditModalProps) {
  const [editedDef, setEditedDef] = useState<CustomDefinition | null>(null)
  const [validationError, setValidationError] = useState<string | null>(null)

  useEffect(() => {
    if (definition) {
      setEditedDef(definition)
    } else {
      setEditedDef(null)
    }
    setValidationError(null)
  }, [definition])

  if (!editedDef) return null

  const updateDef = (updates: Partial<CustomDefinition>) => {
    setEditedDef(prev => {
      if (!prev) return prev
      return {
        ...prev,
        ...updates,
        custom_definition_id: prev.custom_definition_id,
        team_id: prev.team_id,
        metric_idx: prev.metric_idx
      }
    })
    setValidationError(null)
  }

  const handleSave = () => {
    if (!editedDef) return
    
    if (editedDef.value_type === CustomMetricValueType.ENUM) {
      const categories = editedDef.value_type_enum_str?.split(',').map(c => c.trim()) || []
      if (categories.some(c => !c)) {
        setValidationError('Categories cannot be empty')
        return
      }
    }
    
    const validation = isDefinitionValid(editedDef)
    if (!validation.valid) {
      setValidationError(validation.reason)
      return
    }
    
    onSave(editedDef)
    onClose()
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{ sx: MODAL_STYLES.paper }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        px: 2,
        py: 1.5,
        mb: 2
      }}>
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          {definition?.custom_definition_id ? 'Edit Metric' : 'Create New Metric'}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ px: 2, pb: 2 }}>
        {validationError && (
          <Alert 
            severity="error"
            sx={{ 
              borderRadius: 1,
              mb: 2
            }}
          >
            {validationError}
          </Alert>
        )}

        {/* Main Configuration Section */}
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: '1fr 1fr', 
          gap: 2,
          p: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          {/* Left Column - Basic Info */}
          <Stack spacing={1.5}>
            <Typography variant="subtitle2" color="primary.main" sx={{ fontWeight: 500, fontSize: '0.85rem' }}>
              Basic Information
            </Typography>
            <Box sx={MODAL_STYLES.fieldWrapper}>
              <TextField
                required
                label="Metric Label"
                value={editedDef.label || ''}
                onChange={(e) => updateDef({ label: e.target.value })}
                fullWidth
                error={!editedDef.label || editedDef.label.includes(',')}
                helperText={editedDef.label?.includes(',') ? 'Label cannot contain commas' : ''}
                sx={MODAL_STYLES.input}
              />
              <Tooltip title="Give your metric a clear name that describes what it measures" placement="right">
                <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>

            <Box sx={MODAL_STYLES.fieldWrapper}>
              <TextField
                required={editedDef.value_source === CustomMetricSource.GEN_AI}
                label="Description"
                value={editedDef.description || ''}
                onChange={(e) => {
                  const newDescription = e.target.value
                  const newUpdates: Partial<CustomDefinition> = { description: newDescription }

                  // If the source is GEN_AI, also copy the description to value_gen_str
                  if (editedDef.value_source === CustomMetricSource.GEN_AI) {
                    newUpdates.value_gen_str = newDescription
                  }

                  updateDef(newUpdates)
                }}
                fullWidth
                multiline
                rows={2}
                placeholder="Explain what this metric measures"
                sx={MODAL_STYLES.input}
              />
              <Tooltip title="Provide a detailed explanation of what this metric represents" placement="right">
                <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>

          {/* Right Column - Calculation Method */}
          <Stack spacing={1.5}>
            <Typography variant="subtitle2" color="primary.main" sx={{ fontWeight: 500, fontSize: '0.85rem' }}>
              Calculation Method
            </Typography>
            <Box sx={MODAL_STYLES.fieldWrapper}>
              <FormControl required fullWidth sx={MODAL_STYLES.input}>
                <InputLabel>Value Source</InputLabel>
                <Select
                  value={editedDef.value_source}
                  label="Value Source"
                  onChange={(e) => {
                    const source = e.target.value as CustomMetricSource
                    let updates: Partial<CustomDefinition> = {
                      value_source: source,
                      // Clear all source-specific fields first
                      value_gen_str: null,
                      value_gen_desc_str: null,
                      value_phrase_str: null,
                      value_phrase_role: null,
                      value_average_str: null,
                      value_code_str: null
                    }

                    switch (source) {
                      case CustomMetricSource.GEN_AI:
                        updates.value_type = editedDef.value_type || CustomMetricValueType.PERCENT
                        updates.value_gen_str = editedDef.description // Set description as the gen prompt
                        if (updates.value_type === CustomMetricValueType.BOOLEAN) {
                          updates.value_gen_desc_str = 'yes:,no:'
                        } else if (updates.value_type === CustomMetricValueType.PERCENT) {
                          updates.value_gen_desc_str = '0:,50:,100:'
                        } else if (updates.value_type === CustomMetricValueType.ENUM) {
                          updates.value_type_enum_str = ''
                          updates.value_gen_desc_str = ''
                        }
                        break
                      case CustomMetricSource.PHRASE:
                        updates.value_type = CustomMetricValueType.BOOLEAN
                        break
                      case CustomMetricSource.AVERAGE:
                        updates.value_type = CustomMetricValueType.PERCENT
                        break
                      case CustomMetricSource.CODE:
                        // Set appropriate value type based on first selected code
                        if (codeOptions.length > 0) {
                          updates.value_type = codeOptions[0].output_type === 'PERCENTAGE' 
                            ? CustomMetricValueType.PERCENT 
                            : codeOptions[0].output_type === 'BOOLEAN'
                              ? CustomMetricValueType.BOOLEAN
                              : CustomMetricValueType.ENUM
                        }
                        break
                    }
                    
                    updateDef(updates)
                  }}
                >
                  <MenuItem value={CustomMetricSource.GEN_AI}>Generative AI</MenuItem>
                  <MenuItem value={CustomMetricSource.PHRASE}>Phrase Match</MenuItem>
                  <MenuItem value={CustomMetricSource.AVERAGE}>Average Score</MenuItem>
                  {codeOptions.length > 0 && (
                    <MenuItem value={CustomMetricSource.CODE}>Custom Code</MenuItem>
                  )}
                </Select>
              </FormControl>
              <Tooltip title="Choose how this metric will be calculated" placement="right">
                <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>

            {editedDef.value_source === CustomMetricSource.GEN_AI && (
              <Box sx={MODAL_STYLES.fieldWrapper}>
                <FormControl required fullWidth sx={MODAL_STYLES.input}>
                  <InputLabel>Value Type</InputLabel>
                  <Select
                    value={editedDef.value_type}
                    label="Value Type"
                    onChange={(e) => {
                      const valueType = e.target.value as CustomMetricValueType
                      let updates: Partial<CustomDefinition> = {
                        value_type: valueType,
                        value_type_enum_str: null,
                        value_gen_desc_str: null
                      }

                      if (editedDef.value_source === CustomMetricSource.GEN_AI) {
                        updates.value_gen_str = editedDef.description // Set description as the gen prompt
                        if (valueType === CustomMetricValueType.BOOLEAN) {
                          updates.value_gen_desc_str = 'yes:,no:'
                        } else if (valueType === CustomMetricValueType.PERCENT) {
                          updates.value_gen_desc_str = '0:,50:,100:'
                        } else if (valueType === CustomMetricValueType.ENUM) {
                          updates.value_type_enum_str = ''
                        }
                      }
                      
                      updateDef(updates)
                    }}
                  >
                    <MenuItem value={CustomMetricValueType.BOOLEAN}>Yes/No</MenuItem>
                    <MenuItem value={CustomMetricValueType.PERCENT}>Percentage</MenuItem>
                    <MenuItem value={CustomMetricValueType.TEXT}>Text</MenuItem>
                    <MenuItem value={CustomMetricValueType.ENUM}>Categorical</MenuItem>
                  </Select>
                </FormControl>
                <Tooltip title="Select the type of value this metric will produce" placement="right">
                  <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                    <HelpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            {editedDef.value_source === CustomMetricSource.CODE && (
              <Box sx={MODAL_STYLES.fieldWrapper}>
                <FormControl required fullWidth sx={MODAL_STYLES.input}>
                  <InputLabel>Select Codes</InputLabel>
                  <Select
                    multiple
                    value={editedDef.value_code_str ? editedDef.value_code_str.split(',') : []}
                    label="Select Codes"
                    onChange={(e) => {
                      const selectedCodes = e.target.value as string[]
                      updateDef({ 
                        value_code_str: selectedCodes.join(','),
                        // Update value_type based on the first selected code if any are selected
                        ...(selectedCodes.length > 0 && {
                          value_type: codeOptions.find(co => co.name === selectedCodes[0])?.output_type === 'PERCENTAGE'
                            ? CustomMetricValueType.PERCENT
                            : codeOptions.find(co => co.name === selectedCodes[0])?.output_type === 'BOOLEAN'
                              ? CustomMetricValueType.BOOLEAN 
                              : CustomMetricValueType.ENUM
                        })
                      })
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {(selected as string[]).map((value) => {
                          const code = codeOptions.find(co => co.name === value)
                          return (
                            <Chip 
                              key={value} 
                              label={value}
                              size="small"
                              sx={{ 
                                backgroundColor: code?.output_type === 'PERCENTAGE' 
                                  ? 'primary.50'
                                  : code?.output_type === 'BOOLEAN'
                                    ? 'success.50'
                                    : 'warning.50',
                                borderColor: code?.output_type === 'PERCENTAGE'
                                  ? 'primary.200'
                                  : code?.output_type === 'BOOLEAN'
                                    ? 'success.200'
                                    : 'warning.200',
                                color: code?.output_type === 'PERCENTAGE'
                                  ? 'primary.700'
                                  : code?.output_type === 'BOOLEAN'
                                    ? 'success.700'
                                    : 'warning.700',
                              }}
                            />
                          )
                        })}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 300,
                          '& .MuiMenuItem-root': {
                            py: 1,
                            px: 2,
                            borderRadius: 1,
                            mx: 1,
                            my: 0.5,
                            '&:hover': {
                              backgroundColor: 'primary.50',
                            },
                            '&.Mui-selected': {
                              backgroundColor: 'primary.100',
                              '&:hover': {
                                backgroundColor: 'primary.200',
                              }
                            }
                          }
                        }
                      }
                    }}
                  >
                    {codeOptions.map((code) => (
                      <MenuItem 
                        key={code.name} 
                        value={code.name}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <Chip
                          size="small"
                          label={code.output_type === 'PERCENTAGE' ? 'Percent' : code.output_type === 'BOOLEAN' ? 'Yes/No' : 'Category'}
                          sx={{
                            backgroundColor: code.output_type === 'PERCENTAGE' 
                              ? 'primary.50'
                              : code.output_type === 'BOOLEAN'
                                ? 'success.50'
                                : 'warning.50',
                            borderColor: code.output_type === 'PERCENTAGE'
                              ? 'primary.200'
                              : code.output_type === 'BOOLEAN'
                                ? 'success.200'
                                : 'warning.200',
                            color: code.output_type === 'PERCENTAGE'
                              ? 'primary.700'
                              : code.output_type === 'BOOLEAN'
                                ? 'success.700'
                                : 'warning.700',
                            minWidth: 80
                          }}
                        />
                        <Typography>{code.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Tooltip title="Select the codes to use for this metric" placement="right">
                  <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                    <HelpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Stack>
        </Box>

        {/* Output Configuration */}
        {editedDef.value_type !== CustomMetricValueType.TEXT && editedDef.value_source !== CustomMetricSource.CODE && (
          <Box sx={{ 
            mt: 2,
            p: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider'
          }}>
            <Typography variant="subtitle2" color="primary.main" sx={{ fontWeight: 500, fontSize: '0.85rem', mb: 1.5 }}>
              Output Configuration
            </Typography>

            {editedDef.value_source === CustomMetricSource.GEN_AI && (
              <>
                {editedDef.value_type === CustomMetricValueType.BOOLEAN && (
                  <Stack spacing={1.5}>
                    <Box sx={MODAL_STYLES.fieldWrapper}>
                      <TextField
                        required
                        label="Description for 'Yes'"
                        value={editedDef.value_gen_desc_str?.split(',')[0]?.replace('yes:', '') || ''}
                        onChange={(e) => {
                          const noDesc = editedDef.value_gen_desc_str?.split(',')[1] || 'no:'
                          updateDef({ value_gen_desc_str: `yes:${e.target.value},${noDesc}` })
                        }}
                        fullWidth
                        placeholder="Example: The agent demonstrated excellent professionalism"
                        helperText="Describe what criteria must be met for a 'Yes' response"
                        sx={MODAL_STYLES.input}
                      />
                      <Tooltip title="Describe what it means when the AI answers 'Yes'" placement="right">
                        <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box sx={MODAL_STYLES.fieldWrapper}>
                      <TextField
                        required
                        label="Description for 'No'"
                        value={editedDef.value_gen_desc_str?.split(',')[1]?.replace('no:', '') || ''}
                        onChange={(e) => {
                          const yesDesc = editedDef.value_gen_desc_str?.split(',')[0] || 'yes:'
                          updateDef({ value_gen_desc_str: `${yesDesc},no:${e.target.value}` })
                        }}
                        fullWidth
                        placeholder="Example: The agent's professionalism needs improvement"
                        helperText="Describe what indicates a 'No' response"
                        sx={MODAL_STYLES.input}
                      />
                      <Tooltip title="Describe what it means when the AI answers 'No'" placement="right">
                        <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                )}

                {editedDef.value_type === CustomMetricValueType.PERCENT && (
                  <Stack spacing={1.5}>
                    <Box sx={MODAL_STYLES.fieldWrapper}>
                      <TextField
                        required
                        label="Description for Low (<40%)"
                        value={(() => {
                          const str = editedDef.value_gen_desc_str?.split(',')[0] || ''
                          const match = str.match(/^Low(?:\s*\([^)]+\))?:(.*)$/)
                          return match?.[1] || ''
                        })()}
                        onChange={(e) => {
                          const parts = editedDef.value_gen_desc_str?.split(',') || ['Low (<40%):', 'Middle (40-70%):', 'High (>70%):']
                          parts[0] = `Low (<40%):${e.target.value}`
                          updateDef({ value_gen_desc_str: parts.join(',') })
                        }}
                        fullWidth
                        placeholder="Example: Complete lack of professionalism"
                        helperText="Describe what a Low score represents"
                        sx={MODAL_STYLES.input}
                      />
                      <Tooltip title="Describe what a Low score means" placement="right">
                        <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box sx={MODAL_STYLES.fieldWrapper}>
                      <TextField
                        required
                        label="Description for Middle (40-70%)"
                        value={(() => {
                          const str = editedDef.value_gen_desc_str?.split(',')[1] || ''
                          const match = str.match(/^Middle(?:\s*\([^)]+\))?:(.*)$/)
                          return match?.[1] || ''
                        })()}
                        onChange={(e) => {
                          const parts = editedDef.value_gen_desc_str?.split(',') || ['Low (<40%):', 'Middle (40-70%):', 'High (>70%):']
                          parts[1] = `Middle (40-70%):${e.target.value}`
                          updateDef({ value_gen_desc_str: parts.join(',') })
                        }}
                        fullWidth
                        placeholder="Example: Average level of professionalism"
                        helperText="Describe what a Middle score represents"
                        sx={MODAL_STYLES.input}
                      />
                      <Tooltip title="Describe what a Middle score means" placement="right">
                        <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box sx={MODAL_STYLES.fieldWrapper}>
                      <TextField
                        required
                        label="Description for High (>70%)"
                        value={(() => {
                          const str = editedDef.value_gen_desc_str?.split(',')[2] || ''
                          const match = str.match(/^High(?:\s*\([^)]+\))?:(.*)$/)
                          return match?.[1] || ''
                        })()}
                        onChange={(e) => {
                          const parts = editedDef.value_gen_desc_str?.split(',') || ['Low (<40%):', 'Middle (40-70%):', 'High (>70%):']
                          parts[2] = `High (>70%):${e.target.value}`
                          updateDef({ value_gen_desc_str: parts.join(',') })
                        }}
                        fullWidth
                        placeholder="Example: Exceptional professionalism throughout"
                        helperText="Describe what a High score represents"
                        sx={MODAL_STYLES.input}
                      />
                      <Tooltip title="Describe what a High score means" placement="right">
                        <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                )}

                {editedDef.value_type === CustomMetricValueType.ENUM && (
                  <Stack spacing={1.5}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="subtitle2" color="text.secondary" sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}>
                          Categories
                          <Tooltip title="Add categories and their descriptions to define what each option means. The AI will choose one of these categories based on the conversation." placement="right">
                            <IconButton size="small" sx={{ 
                              ...MODAL_STYLES.helpIcon,
                              mt: 0 // Remove top margin to align with text
                            }}>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => {
                          const categories = editedDef.value_type_enum_str === null || editedDef.value_type_enum_str === ''
                            ? []
                            : editedDef.value_type_enum_str.split(',')
                          const descriptions = editedDef.value_gen_desc_str === null || editedDef.value_gen_desc_str === ''
                            ? []
                            : editedDef.value_gen_desc_str.split(',')

                          // Use a placeholder name instead of an empty string
                          const newCategoryName = `New Category ${categories.length + 1}`
                          categories.push(newCategoryName)
                          descriptions.push(`${newCategoryName}:`)

                          updateDef({
                            value_type_enum_str: categories.join(','),
                            value_gen_desc_str: descriptions.join(',')
                          })
                        }}
                        size="small"
                        sx={{ 
                          textTransform: 'none',
                          color: 'primary.main',
                          '&:hover': { backgroundColor: 'primary.50' }
                        }}
                      >
                        Add Category
                      </Button>
                    </Box>

                    {editedDef.value_type_enum_str && editedDef.value_type_enum_str.split(',')
                      .map((category, index) => {
                        const descriptions = editedDef.value_gen_desc_str?.split(',') || []
                        const currentDesc = descriptions[index]?.replace(`${category}:`, '') || ''
                        
                        return (
                          <Box 
                            key={index} 
                            sx={{ 
                              display: 'grid', 
                              gridTemplateColumns: '1fr 2fr auto auto',
                              gap: 1,
                              alignItems: 'start',
                              backgroundColor: 'background.paper',
                              p: 1.5,
                              borderRadius: 1,
                              border: '1px solid',
                              borderColor: category.trim() ? 'divider' : 'error.light'
                            }}
                          >
                            <TextField
                              required
                              placeholder="Category name"
                              value={category}
                              error={!category.trim()}
                              helperText={!category.trim() ? "Category name cannot be empty" : ""}
                              onChange={(e) => {
                                const categories = editedDef.value_type_enum_str?.split(',') || []
                                categories[index] = e.target.value
                                const descriptions = editedDef.value_gen_desc_str?.split(',') || []
                                descriptions[index] = `${e.target.value}:${currentDesc}`
                                updateDef({
                                  value_type_enum_str: categories.join(','),
                                  value_gen_desc_str: descriptions.join(',')
                                })
                              }}
                              size="small"
                              sx={MODAL_STYLES.input}
                            />
                            <TextField
                              required
                              placeholder="Describe when the AI should choose this category"
                              value={currentDesc}
                              onChange={(e) => {
                                const descriptions = editedDef.value_gen_desc_str?.split(',') || []
                                descriptions[index] = `${category}:${e.target.value}`
                                updateDef({ value_gen_desc_str: descriptions.join(',') })
                              }}
                              size="small"
                              sx={MODAL_STYLES.input}
                            />
                            <Tooltip title="Explain when this category should be chosen" placement="top">
                              <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                                <HelpIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <IconButton 
                              onClick={() => {
                                const categories = editedDef.value_type_enum_str?.split(',') || []
                                const descriptions = editedDef.value_gen_desc_str?.split(',') || []
                                categories.splice(index, 1)
                                descriptions.splice(index, 1)
                                updateDef({
                                  value_type_enum_str: categories.join(','),
                                  value_gen_desc_str: descriptions.join(',')
                                })
                              }}
                              sx={{ 
                                color: 'error.main',
                                '&:hover': { backgroundColor: 'error.50' }
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        )
                      })}
                  </Stack>
                )}
              </>
            )}

            {editedDef.value_source === CustomMetricSource.PHRASE && (
              <Stack spacing={1.5}>
                <Box sx={MODAL_STYLES.fieldWrapper}>
                  <TextField
                    required
                    label="Phrases to Match"
                    value={editedDef.value_phrase_str || ''}
                    onChange={(e) => updateDef({ value_phrase_str: e.target.value })}
                    fullWidth
                    placeholder="Enter phrases separated by commas"
                    helperText="Example: thank you for resolving, issue fixed, problem solved"
                    sx={MODAL_STYLES.input}
                  />
                  <Tooltip title="Specify phrases to look for in the conversation" placement="right">
                    <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                      <HelpIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={MODAL_STYLES.fieldWrapper}>
                  <FormControl fullWidth sx={MODAL_STYLES.input}>
                    <InputLabel>Speaker Role</InputLabel>
                    <Select
                      value={
                        editedDef.value_phrase_role == null
                          ? "ANYONE"
                          : editedDef.value_phrase_role
                      }
                      label="Speaker Role"
                      onChange={(e) => {
                        const newRole =
                          e.target.value === "ANYONE" ? null : (e.target.value as PartyRole)
                        updateDef({ value_phrase_role: newRole })
                      }}
                    >
                      <MenuItem value="ANYONE">Anyone</MenuItem>
                      <MenuItem value={PartyRole.REP}>Rep</MenuItem>
                      <MenuItem value={PartyRole.PROSPECT}>Prospect</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip title="Choose which speaker's messages to search for phrases" placement="right">
                    <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                      <HelpIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            )}

            {editedDef.value_source === CustomMetricSource.AVERAGE && (
              <Box sx={MODAL_STYLES.fieldWrapper}>
                <FormControl required fullWidth sx={MODAL_STYLES.input}>
                  <InputLabel>Metrics to Average</InputLabel>
                  <Select
                    multiple
                    value={editedDef.value_average_str ? editedDef.value_average_str.split(',') : []}
                    label="Metrics to Average"
                    onChange={(e) => {
                      const value = (e.target.value as string[]).join(',')
                      updateDef({ value_average_str: value })
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {(selected as string[]).map((value) => {
                          const metric = definitions.find(d => d.metric_idx !== null && d.metric_idx.toString() === value)
                          return (
                            <Chip 
                              key={value} 
                              label={metric?.label || `Metric ${value}`}
                              size="small"
                            />
                          )
                        })}
                      </Box>
                    )}
                  >
                    {definitions
                      .filter(d => 
                        d.custom_definition_id !== editedDef.custom_definition_id && 
                        d.metric_idx !== null &&
                        [CustomMetricValueType.BOOLEAN, CustomMetricValueType.PERCENT].includes(d.value_type)
                      )
                      .map((d) => (
                        <MenuItem key={d.metric_idx} value={d.metric_idx?.toString() || ''}>
                          {d.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Tooltip title="Select the metrics you want to average together" placement="right">
                  <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                    <HelpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        )}

        {/* Calculate Only When: (Optional) */}
        <Box sx={{ 
          mt: 2,
          p: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 500, fontSize: '0.85rem', mb: 1.5 }}>
            Calculate Only When: (Optional)
          </Typography>

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, mb: 2 }}>
            <Box sx={MODAL_STYLES.fieldWrapper}>
              <TextField
                type="number"
                label="Min Duration (seconds)"
                value={editedDef.where_min_duration?.toString() || ''}
                onChange={(e) => updateDef({ where_min_duration: Number(e.target.value) })}
                fullWidth
                sx={MODAL_STYLES.input}
              />
              <Tooltip title="Minimum conversation duration in seconds" placement="right">
                <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={MODAL_STYLES.fieldWrapper}>
              <TextField
                type="number"
                label="Max Duration (seconds)"
                value={editedDef.where_max_duration?.toString() || ''}
                onChange={(e) => updateDef({ where_max_duration: Number(e.target.value) })}
                fullWidth
                sx={MODAL_STYLES.input}
              />
              <Tooltip title="Maximum conversation duration in seconds" placement="right">
                <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={MODAL_STYLES.fieldWrapper}>
            <TextField
              label="Additional Calculation Conditions"
              value={editedDef.where_gen_str || ''}
              onChange={(e) => updateDef({ where_gen_str: e.target.value })}
              fullWidth
              multiline
              rows={2}
              placeholder="Describe when this metric should be calculated. For example: 'Only calculate when an objection comes up' or 'Only calculate during the first 2 minutes of conversation'"
              helperText="Optional: Add specific conditions for when this metric should be evaluated"
              sx={{
                ...MODAL_STYLES.input,
                '& .MuiInputBase-input::placeholder': {
                  opacity: 0.8,
                  color: 'text.secondary',
                }
              }}
            />
            <Tooltip title="Additional conditions for when to calculate this metric" placement="right">
              <IconButton size="small" sx={MODAL_STYLES.helpIcon}>
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        px: 2,
        py: 1.5,
        borderTop: '1px solid',
        borderColor: 'divider',
        gap: 1.5
      }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            px: 3,
            color: 'text.secondary'
          }}
        >
          Cancel
        </Button>
        <Button 
          variant="contained"
          onClick={handleSave}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            px: 4,
            py: 1,
            backgroundImage: COLORS.primary.gradient,
            '&:hover': {
              backgroundImage: COLORS.primary.gradient,
              filter: 'brightness(0.9)'
            }
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const parseGenDescStr = (def: CustomDefinition): CustomDefinition => {
  if (!def.value_gen_desc_str) return def

  switch (def.value_type) {
    case CustomMetricValueType.BOOLEAN: {
      // Extract yes/no descriptions
      const [yesDesc, noDesc] = def.value_gen_desc_str.split(',')
      return {
        ...def,
        value_gen_desc_str: [
          `yes:${yesDesc?.replace('yes:', '') || ''}`,
          `no:${noDesc?.replace('no:', '') || ''}`
        ].join(',')
      }
    }
    case CustomMetricValueType.PERCENT: {
      // Extract Low/Middle/High descriptions with regex to handle both formats
      const [low, middle, high] = def.value_gen_desc_str.split(',')
      const extractDesc = (str: string, prefix: string) => {
        const regex = new RegExp(`^${prefix}(?:\\s*\\([^)]+\\))?:(.*)$`)
        const match = str?.match(regex)
        return match?.[1] || ''
      }
      
      return {
        ...def,
        value_gen_desc_str: [
          `Low (<40%):${extractDesc(low, 'Low')}`,
          `Middle (40-70%):${extractDesc(middle, 'Middle')}`,
          `High (>70%):${extractDesc(high, 'High')}`
        ].join(',')
      }
    }
    case CustomMetricValueType.ENUM: {
      if (!def.value_type_enum_str) return def
      const categories = def.value_type_enum_str.split(',').map(c => c.trim())
      const descriptions = def.value_gen_desc_str?.split(',') || []

      // Match each category with its description, handling empty cat names
      const formattedDescriptions = categories.map((cat, i) => {
        const catToMatch = cat ? `${cat}:` : ':'
        const desc = descriptions[i]?.replace(catToMatch, '') || ''
        return `${cat}:${desc}`
      })

      return {
        ...def,
        value_gen_desc_str: formattedDescriptions.join(',')
      }
    }
    default:
      return def
  }
}

interface TestingTabProps {
  definitions: CustomDefinition[]
  setDefinitions: React.Dispatch<React.SetStateAction<CustomDefinition[]>>
  team_id: string
  onEditSave: (def: CustomDefinition) => void
  handleDelete: (def: CustomDefinition) => void
}

function TestingTab({ definitions, setDefinitions, team_id, onEditSave, handleDelete }: TestingTabProps) {
  const [sessions, setSessions] = useState<UserSessionsResultItem[]>([])
  const [selectedSession, setSelectedSession] = useState<string | null>(null)
  const [sessionData, setSessionData] = useState<SessionDataResult | null>(null)
  const [loading, setLoading] = useState(true)
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([])
  const [testing, setTesting] = useState(false)
  const [editingDef, setEditingDef] = useState<CustomDefinition | null>(null)
  const [analysisResults, setAnalysisResults] = useState<MetricResult | null>(null)
  const [error, setError] = useState<string | null>(null)

  // Get the code options for the current team, if any
  const codeOptions: CustomCode[] = getCustomCode(team_id) ?? []

  const isDefinitionValid = (def: CustomDefinition): { valid: boolean, reason: string } => {
    // Basic validation for all metrics
    if (!def.label?.trim()) {
      return { valid: false, reason: 'Label is required' }
    }
    if (!def.value_source) {
      return { valid: false, reason: 'Value source is required' }
    }
    if (!def.value_type) {
      return { valid: false, reason: 'Value type is required' }
    }

    // Description is required for all sources except CODE
    if (def.value_source !== CustomMetricSource.CODE && !def.description?.trim()) {
      return { valid: false, reason: 'Description is required' }
    }

    // Source-specific validation
    switch (def.value_source) {
      case CustomMetricSource.CODE:
        if (!def.value_code_str) {
          return { valid: false, reason: 'Please select a code metric' }
        }
        break;

      case CustomMetricSource.GEN_AI:
        if (!def.value_gen_str?.trim()) {
          return { valid: false, reason: 'Generation prompt is required' }
        }
        break;

      case CustomMetricSource.PHRASE:
        if (!def.value_phrase_str?.trim()) {
          return { valid: false, reason: 'Phrase is required' }
        }
        // Note: value_phrase_role is optional for phrase matching
        break;

      case CustomMetricSource.AVERAGE:
        if (!def.value_average_str?.trim()) {
          return { valid: false, reason: 'Metrics to average are required' }
        }
        break;
    }

    // Type-specific validation
    switch (def.value_type) {
      case CustomMetricValueType.ENUM:
        if (def.value_source !== CustomMetricSource.CODE && !def.value_type_enum_str?.trim()) {
          return { valid: false, reason: 'Categories are required for enum type' }
        }
        break;
    }

    return { valid: true, reason: '' }
  }

  useEffect(() => {
    loadSessions()
  }, [])

  const loadSessions = async () => {
    try {
      const services = getServicesManager()
      const startDate = new Date()
      startDate.setMonth(startDate.getMonth() - 1)

      const params: ListUserSessionParamsV3 = {
        cnf: [{
          filter_type: CnfFilterType.SESSION_METRIC,
          negated: false,
          session_metric_filters: [{'field': SESSION_METRICS_COLUMNS.IS_ANSWERED, 'in_values': [true]}]
        }, {
          filter_type: CnfFilterType.METADATA,
          negated: false,
          metadata_filters: [{
            min_duration: 30
          }]
        }
      ],
        start: startDate,
        end: new Date()
      }

      const result = await services.getListUserSessionsV3(params)
      if (result) {
        setSessions(result)
      }
      setLoading(false)
    } catch (err) {
      console.error('Failed to load sessions:', err)
      setLoading(false)
    }
  }

  const loadSessionData = async (sessionId: string) => {
    try {
      const services = getServicesManager()
      const data = await services.getSessionData(sessionId)
      if (data && typeof data !== 'number') {
        setSessionData(data)
      }
    } catch (err) {
      console.error('Failed to load session data:', err)
    }
  }

  const handleSessionSelect = (sessionId: string) => {
    setSelectedSession(sessionId)
    setAnalysisResults(null)  // Clear previous results
    loadSessionData(sessionId)
  }

  const handleMetricToggle = (metricId: string) => {
    setSelectedMetrics(prev => {
      if (prev.includes(metricId)) {
        return prev.filter(id => id !== metricId)
      }
      return [...prev, metricId]
    })
  }

  const handleEditSave = (def: CustomDefinition) => {
    const validation = isDefinitionValid(def);
    if (!validation.valid) {
      setEditingDef(def); // Keep the modal open with the current definition
      return;
    }

    setDefinitions(prevDefs => 
      prevDefs.map(d => d.custom_definition_id === def.custom_definition_id ? def : d)
    );
    setEditingDef(null);
  }

  const handleTestMetrics = async () => {
    if (!selectedSession || !sessionData) return
    setTesting(true)
    try {
      // Get selected definitions
      const metricsToTest = definitions.filter(def => selectedMetrics.includes(def.custom_definition_id))
      
      // Request metrics analysis
      const services = getServicesManager()
      const result = await services.requestCustomMetricTesting(
        sessionData.session.session_id,
        metricsToTest
      )
      
      setAnalysisResults(result)
    } catch (error) {
      console.error('Error testing metrics:', error)
    } finally {
      setTesting(false)
    }
  }

  const renderMetricResult = (def: CustomDefinition) => {
    if (!analysisResults?.metric || def.metric_idx === null) {
      return (
        <Card
          key={def.custom_definition_id}
          sx={{
            p: 2,
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle2" color="text.secondary">
              {String(def.label)}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
              Pending calculation...
            </Typography>
          </Stack>
        </Card>
      );
    }

    // At this point, we know analysisResults is not null
    const metric = analysisResults.metric;
    const metricKey = `metric_${def.metric_idx}` as keyof typeof metric;
    const textKey = `text_${def.metric_idx}` as keyof typeof metric;
    const metricValue = metric[metricKey]
    const textValue = metric[textKey]

    const detail = analysisResults.detail?.metric_to_explanation_json_str
    let explanation = null
    if (detail) {
      try {
        const explanations = JSON.parse(detail)
        explanation = explanations[def.metric_idx.toString()]
      } catch (e) {
        console.error('Failed to parse explanation:', e)
      }
    }

    const renderEnumValue = () => {
      // For custom code metrics, use the code definition
      if (def.value_source === CustomMetricSource.CODE && def.value_code_str) {
        const codeOption = codeOptions.find(c => c.name === def.value_code_str);
        if (codeOption?.enum_options && metricValue !== null) {
          return String(codeOption.enum_options[Number(metricValue)] || 'Invalid category');
        }
      }
      // For other enum metrics, use the value_type_enum_str
      if (metricValue === null || !def.value_type_enum_str) return 'No result';
      const categories = def.value_type_enum_str.split(',').map(c => c.trim());
      const index = Number(metricValue);
      return String(categories[index] || 'Invalid category');
    }

    return (
      <Card
        key={def.custom_definition_id}
        sx={{
          p: 2,
          backgroundColor: 'background.paper',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
        }}
      >
        <Stack spacing={1}>
          <Typography variant="subtitle2" color="text.secondary">
            {String(def.label)}
          </Typography>
          
          {def.value_type === CustomMetricValueType.TEXT ? (
            <Typography variant="body2">
              {String(textValue || '')}
            </Typography>
          ) : def.value_type === CustomMetricValueType.BOOLEAN ? (
            <Typography 
              variant="h6" 
              color={metricValue ? 'success.main' : 'error.main'}
              sx={{ fontWeight: 500 }}
            >
              {metricValue ? 'Yes' : 'No'}
            </Typography>
          ) : def.value_type === CustomMetricValueType.PERCENT ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 500,
                  color: metricValue !== null ? (
                    Number(metricValue) * 100 >= 80 ? 'success.main' 
                    : Number(metricValue) * 100 >= 50 ? 'warning.main' 
                    : 'error.main'
                  ) : 'text.secondary'
                }}
              >
                {metricValue !== null ? `${Math.round(Number(metricValue) * 100)}%` : 'N/A'}
              </Typography>
              {metricValue !== null && (
                <Box 
                  sx={{ 
                    flex: 1, 
                    height: 4, 
                    backgroundColor: 'grey.100',
                    borderRadius: 2,
                    overflow: 'hidden'
                  }}
                >
                  <Box 
                    sx={{ 
                      width: `${Math.round(Number(metricValue) * 100)}%`,
                      height: '100%',
                      backgroundColor: Number(metricValue) * 100 >= 80 ? 'success.main' 
                        : Number(metricValue) * 100 >= 50 ? 'warning.main' 
                        : 'error.main',
                      transition: 'width 0.5s ease-in-out'
                    }} 
                  />
                </Box>
              )}
            </Box>
          ) : def.value_type === CustomMetricValueType.ENUM ? (
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {renderEnumValue()}
            </Typography>
          ) : null}

          {explanation && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                mt: 1,
                pt: 1,
                borderTop: '1px solid',
                borderColor: 'divider'
              }}
            >
              {String(explanation)}
            </Typography>
          )}
        </Stack>
      </Card>
    )
  }

  // Helper function to safely format dates
  const formatDate = (date: string | Date | null): string => {
    if (!date) return '';
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return shortenedDateToString(dateObj);
  }

  return (
    <Box sx={{ 
      display: 'grid',
      gridTemplateColumns: selectedSession ? '250px 1fr' : '300px 1fr',
      gap: 2,
      height: '100%',
      overflow: 'hidden',
      transition: 'grid-template-columns 0.2s ease-in-out'
    }}>
      {/* Sessions List */}
      <Card sx={{ 
        overflow: 'auto',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px, rgba(0, 0, 0, 0.1) 0px 2px 8px'
      }}>
        <CardContent sx={{ p: '0 !important', height: '100%' }}>
          {loading ? (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Typography>Loading sessions...</Typography>
            </Box>
          ) : (
            <Stack divider={<Divider />}>
              {sessions.map((session) => (
                <Box
                  key={session.session_id}
                  onClick={() => handleSessionSelect(session.session_id)}
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                    backgroundColor: selectedSession === session.session_id ? 'action.selected' : 'transparent',
                    '&:hover': {
                      backgroundColor: 'action.hover'
                    }
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ 
                      width: 32,
                      height: 32,
                      borderRadius: '50%',
                      backgroundColor: 'grey.100',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <User2 size={18} />
                    </Box>
                    <Box sx={{ minWidth: 0 }}>
                      <Typography variant="subtitle2" noWrap>
                        {String(session.prospect_name || 'Unknown')}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" noWrap display="block">
                        {String(formatDate(session.scheduled_start))} • {String(durationToString((session.duration ?? 0) * SEC_TO_MS))}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Stack>
          )}
        </CardContent>
      </Card>

      {/* Transcript and Analysis */}
      <Card sx={{ 
        overflow: 'hidden',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px, rgba(0, 0, 0, 0.1) 0px 2px 8px',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <CardContent sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: 2
        }}>
          {selectedSession ? (
            <>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: 2
              }}>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Analysis Results
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Select metrics to test and analyze the conversation
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  disabled={selectedMetrics.length === 0 || testing}
                  onClick={handleTestMetrics}
                  startIcon={<CalculateIcon />}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                    px: 3,
                    backgroundImage: COLORS.primary.gradient,
                    '&:hover': {
                      backgroundImage: COLORS.primary.gradient,
                      filter: 'brightness(0.9)'
                    }
                  }}
                >
                  {testing ? 'Testing...' : 'Test Selected Metrics'}
                </Button>
              </Box>

              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: '1fr 300px',
                gap: 2,
                flex: 1,
                overflow: 'hidden',
                minHeight: 0
              }}>
                {/* Transcript Section */}
              {sessionData && (
                  <Card sx={{ 
                    overflow: 'auto',
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: 'background.paper'
                  }}>
                    <CardContent sx={{ 
                      p: '24px !important',
                      height: '100%',
                      overflow: 'auto'
                    }}>
                      {/* Analysis Results Section */}
                      {analysisResults && (
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500, color: 'primary.main' }}>
                            Analysis Results
                          </Typography>
                          <Stack spacing={2}>
                            {definitions
                              .filter(def => selectedMetrics.includes(def.custom_definition_id))
                              .map(def => renderMetricResult(def))
                            }
                          </Stack>
                          <Divider sx={{ my: 3 }} />
                        </Box>
                      )}

                      {/* Transcript */}
                      <Stack spacing={3}>
                        {sessionData.turns
                          .sort((a, b) => a.start - b.start)
                          .map((turn, index) => {
                            const isRep = turn.role === PartyRole.REP;
                            return (
                              <Box 
                                key={index}
                                sx={{
                                  display: 'flex',
                                  gap: 2,
                                  alignItems: 'flex-start',
                                  maxWidth: '85%',
                                  ml: isRep ? 'auto' : 0,
                                  mr: isRep ? 0 : 'auto'
                                }}
                              >
                                <Box sx={{ 
                                  flex: 1,
                                  backgroundColor: isRep ? 'rgba(220, 242, 231, 0.8)' : 'grey.50',
                                  borderRadius: 2,
                                  p: 2,
                                  position: 'relative',
                                  ...(isRep && {
                                    boxShadow: '0 1px 2px rgba(166, 224, 195, 0.2)'
                                  })
                                }}>
                                  <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 0.5
                                  }}>
                                    <Typography variant="subtitle2" color={isRep ? 'success.800' : 'text.primary'}>
                                      {String(isRep ? 'Rep' : sessionData.prospect_info?.prospect_name || 'Prospect')}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                      {String(new Date(turn.start * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))}
                                    </Typography>
                </Box>
                                  <Typography 
                                    variant="body2" 
                                    color={isRep ? 'success.800' : 'text.primary'}
                                    sx={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    {String(turn.text)}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                      </Stack>
                    </CardContent>
                  </Card>
                )}

                {/* Metrics Selection */}
                <Card sx={{ 
                  overflow: 'auto',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  backgroundColor: 'background.paper'
                }}>
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom sx={{ mb: 2 }}>
                      Select Metrics to Test
                </Typography>
                <Stack spacing={1}>
                  {definitions.map((def) => (
                    <Card 
                      key={def.custom_definition_id}
                      sx={{ 
                        p: 2,
                            cursor: 'pointer',
                            backgroundColor: selectedMetrics.includes(def.custom_definition_id) 
                              ? 'primary.50' 
                              : 'background.default',
                        border: '1px solid',
                            borderColor: selectedMetrics.includes(def.custom_definition_id)
                              ? 'primary.200'
                              : 'divider',
                            transition: 'all 0.2s',
                            '&:hover': {
                              backgroundColor: selectedMetrics.includes(def.custom_definition_id)
                                ? 'primary.100'
                                : 'action.hover',
                              '& .metric-actions': {
                                opacity: 1
                              }
                            }
                          }}
                        >
                          <Stack direction="row" spacing={1.5} alignItems="flex-start">
                            <Box sx={{ pt: 0.5 }}>
                              <Checkbox
                                checked={selectedMetrics.includes(def.custom_definition_id)}
                                onChange={() => handleMetricToggle(def.custom_definition_id)}
                                onClick={(e) => e.stopPropagation()}
                                sx={{ p: 0 }}
                              />
                            </Box>
                            <Box sx={{ flex: 1, minWidth: 0 }}>
                              <Typography variant="subtitle2" noWrap>
                                {def.label}
                              </Typography>
                              <Typography 
                                variant="caption" 
                                color="text.secondary" 
                                sx={{
                                  display: 'block',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                            {def.description}
                          </Typography>
                              <Stack direction="row" spacing={0.5} mt={1}>
                                <Chip 
                                  label={formatMetricSource(def.value_source)} 
                                  size="small" 
                          variant="outlined"
                                  sx={{ 
                                    borderRadius: 1,
                                    backgroundColor: 'primary.50',
                                    borderColor: 'primary.100',
                                    color: 'primary.700',
                                    height: 20,
                                    '& .MuiChip-label': {
                                      px: 1,
                                      fontSize: '0.75rem'
                                    }
                                  }}
                                />
                                <Chip 
                                  label={formatMetricType(def.value_type)} 
                          size="small"
                                  variant="outlined"
                                  sx={{ 
                                    borderRadius: 1,
                                    backgroundColor: 'secondary.50',
                                    borderColor: 'secondary.100',
                                    color: 'secondary.700',
                                    height: 20,
                                    '& .MuiChip-label': {
                                      px: 1,
                                      fontSize: '0.75rem'
                                    }
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Stack 
                              direction="row" 
                              spacing={1}
                              className="metric-actions"
                              sx={{ 
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                flexShrink: 0
                              }}
                            >
                              <Tooltip title="Edit Metric">
                                <IconButton 
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setEditingDef(def)
                                  }}
                                  sx={{ 
                                    color: 'primary.main',
                                    '&:hover': { 
                                      backgroundColor: 'primary.50' 
                                    }
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Remove Metric">
                                <IconButton 
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleDelete(def)
                                  }}
                                  sx={{ 
                                    color: 'error.main',
                                    '&:hover': { 
                                      backgroundColor: 'error.50' 
                                    }
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                      </Stack>
                    </Card>
                  ))}
                </Stack>
                  </CardContent>
                </Card>
              </Box>
            </>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Typography color="text.secondary">Select a session to analyze</Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Edit Modal */}
      <EditModal
        open={!!editingDef}
        definition={editingDef}
        definitions={definitions}
        codeOptions={codeOptions}
        onClose={() => setEditingDef(null)}
        onSave={handleEditSave}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  )
}

type DefaultMetricType = {
  label: string;
  description: string;
  value_source: CustomMetricSource;
  value_type: CustomMetricValueType;
  value_gen_str: string;
  value_gen_desc_str: string;
  value_type_enum_str?: string;
};

type DefaultMetricsType = {
  qa: DefaultMetricType[];
  sales: DefaultMetricType[];
};

function CustomScoresImpl({ team_id }: { team_id: string }) {
  const [definitions, setDefinitions] = useState<CustomDefinition[]>([])
  const [originalDefinitions, setOriginalDefinitions] = useState<CustomDefinition[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [editingDef, setEditingDef] = useState<CustomDefinition | null>(null)
  const [defaultMenuAnchor, setDefaultMenuAnchor] = useState<null | HTMLElement>(null)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [pendingSave, setPendingSave] = useState<CustomDefinition[] | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [metricChanges, setMetricChanges] = useState<MetricChange[]>([])
  const [templateTab, setTemplateTab] = useState('sales')

  const hasChanges = !isEqual(definitions, originalDefinitions)

  // Get the code options for the current team, if any
  const codeOptions: CustomCode[] = getCustomCode(team_id) ?? []

  useEffect(() => {
    if (error) {
      setSnackbarOpen(true)
    }
  }, [error])

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChanges) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [hasChanges])

  useEffect(() => {
    loadDefinitions()
  }, [])

  const loadDefinitions = async () => {
    try {
      const services = getServicesManager()
      const defs = await services.getCustomDefinitions()
      if (defs) {
        // Parse the descriptions for each definition
        const parsedDefs = defs.map(parseGenDescStr)
        setDefinitions(parsedDefs)
        setOriginalDefinitions(parsedDefs)
      }
      setLoading(false)
    } catch (err) {
      setError('Failed to load custom definitions')
      setLoading(false)
    }
  }

  const handleSave = async (updatedDefs: CustomDefinition[]) => {
    try {
      const services = getServicesManager()
      const mockResult = await services.putCustomDefinitions(updatedDefs, true)

      if (typeof mockResult === 'string') {
        setError(mockResult)
        return
      }

      if (!mockResult) {
        setError('Failed to validate changes')
        return
      }

      // Track changes to existing metrics
      const changes: MetricChange[] = []
      
      // Check for removed metrics
      originalDefinitions.forEach(origDef => {
        const stillExists = updatedDefs.some(def => def.custom_definition_id === origDef.custom_definition_id)
        if (!stillExists) {
          changes.push({ label: origDef.label || 'Unnamed metric', type: 'removed' })
          return
        }

        const updatedDef = updatedDefs.find(def => def.custom_definition_id === origDef.custom_definition_id)
        if (!updatedDef) return
        
        // Check if metric was modified
        const isModified = (
          origDef.label !== updatedDef.label ||
          origDef.description !== updatedDef.description ||
          origDef.value_source !== updatedDef.value_source ||
          origDef.value_type !== updatedDef.value_type ||
          origDef.value_type_enum_str !== updatedDef.value_type_enum_str ||
          origDef.value_gen_str !== updatedDef.value_gen_str ||
          origDef.value_gen_desc_str !== updatedDef.value_gen_desc_str ||
          origDef.value_phrase_str !== updatedDef.value_phrase_str ||
          origDef.value_phrase_role !== updatedDef.value_phrase_role ||
          origDef.value_average_str !== updatedDef.value_average_str ||
          origDef.value_code_str !== updatedDef.value_code_str ||
          origDef.where_min_duration !== updatedDef.where_min_duration ||
          origDef.where_max_duration !== updatedDef.where_max_duration
        )
        
        if (isModified) {
          changes.push({ label: origDef.label || 'Unnamed metric', type: 'modified' })
        }
      })

      if (changes.length === 0) {
        // No changes to existing metrics, safe to save directly
        const defsToSave = updatedDefs.map(def => ({
          ...def,
          ...(def.value_source === CustomMetricSource.AVERAGE && def.value_average_str ? {
            value_average_labels_str: def.value_average_str.split(',')
              .map(idx => {
                const metric = definitions.find(d => d.metric_idx !== null && d.metric_idx.toString() === idx)
                return metric?.label || ''
              })
              .filter(label => label)
              .join(',')
          } : {})
        }))
        const savedDefs = await services.putCustomDefinitions(defsToSave)
        if (typeof savedDefs === 'string') {
          setError(savedDefs)
          return
        }
        if (!savedDefs) {
          setError('Failed to save changes')
          return
        }
        const parsedDefs = savedDefs.map(parseGenDescStr)
        setDefinitions(parsedDefs)
        setOriginalDefinitions(parsedDefs)
        return
      }

      // Changes detected, show confirmation dialog with the list of changes
      const defsToSave = updatedDefs.map(def => ({
        ...def,
        ...(def.value_source === CustomMetricSource.AVERAGE && def.value_average_str ? {
          value_average_labels_str: def.value_average_str.split(',')
            .map(idx => {
              const metric = definitions.find(d => d.metric_idx !== null && d.metric_idx.toString() === idx)
              return metric?.label || ''
            })
            .filter(label => label)
            .join(',')
        } : {})
      }))

      setPendingSave(defsToSave)
      setMetricChanges(changes)
      setShowConfirmDialog(true)
    } catch (err) {
      setError('Failed to validate changes')
    }
  }

  const confirmSave = async () => {
    if (!pendingSave) return

    try {
      const services = getServicesManager()
      // Save the current state of definitions, not pendingSave
      const defsToSave = definitions.map(def => ({
        ...def,
        ...(def.value_source === CustomMetricSource.AVERAGE && def.value_average_str ? {
          value_average_labels_str: def.value_average_str.split(',')
            .map(idx => {
              const metric = definitions.find(d => d.metric_idx !== null && d.metric_idx.toString() === idx)
              return metric?.label || ''
            })
            .filter(label => label)
            .join(',')
        } : {})
      }))
      const savedDefs = await services.putCustomDefinitions(defsToSave)
      if (typeof savedDefs === 'string') {
        setError(savedDefs)
        setShowConfirmDialog(false)
        setPendingSave(null)
        return
      }
      if (!savedDefs) {
        setError('Failed to save changes')
        setShowConfirmDialog(false)
        setPendingSave(null)
        return
      }

      // Parse the saved definitions
      const parsedDefs = savedDefs.map(parseGenDescStr)
      setDefinitions(parsedDefs)
      setOriginalDefinitions(parsedDefs)
      setShowConfirmDialog(false)
      setPendingSave(null)
    } catch (err) {
      setError('Failed to save changes')
      setShowConfirmDialog(false)
      setPendingSave(null)
    }
  }

  const addNewDefinition = (template?: Partial<CustomDefinition> | MetricTemplate) => {
    const newDef: CustomDefinition = {
      custom_definition_id: `new-${Date.now()}`,
      team_id: '',
      metric_idx: definitions.length,
      label: template?.label || `New Metric ${definitions.length + 1}`,
      description: template?.description || '',
      order: definitions.length,
      tags: null,
      value_source: template?.value_source || CustomMetricSource.GEN_AI,
      value_type: template?.value_type || CustomMetricValueType.PERCENT,
      value_type_enum_str: template?.value_type_enum_str ?? null,
      value_gen_str: template?.value_gen_str ?? null,
      value_gen_desc_str: template?.value_gen_desc_str ?? null,
      value_phrase_str: template?.value_phrase_str ?? null,
      value_phrase_role: template?.value_phrase_role ?? null,
      value_average_str: template?.value_average_str ?? null,
      value_code_str: template?.value_code_str ?? null,
      where_min_duration: template?.where_min_duration ?? null,
      where_max_duration: template?.where_max_duration ?? null,
      where_gen_str: null,
      modified_as_of: null,
      rebuilt_as_of: null
    }
    setDefinitions([...definitions, newDef])
    setEditingDef(newDef)
  }

  const handleDelete = (def: CustomDefinition) => {
    const newDefs = definitions.filter(d => d.custom_definition_id !== def.custom_definition_id)
    setDefinitions(newDefs)
  }

  const handleEditSave = (def: CustomDefinition) => {
    const validation = isDefinitionValid(def);
    if (!validation.valid) {
      setEditingDef(def); // Keep the modal open with the current definition
      return;
    }

    setDefinitions(prevDefs => 
      prevDefs.map(d => d.custom_definition_id === def.custom_definition_id ? def : d)
    );
    setEditingDef(null);
  }

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="64vh">
        <Typography>Loading custom scores...</Typography>
      </Box>
    )
  }

  return (
    <>
      <Box 
        role="tabpanel"
        data-has-changes={hasChanges}
        sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column' 
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 3, pt: 2 }}>
          <Tabs 
            value={activeTab} 
            onChange={(_, newValue) => setActiveTab(newValue)}
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                minHeight: 48
              }
            }}
          >
            <Tab label="Edit Metrics" />
            <Tab label="Test Metrics" />
          </Tabs>
        </Box>

        <Box sx={{ flex: 1, overflow: 'hidden', p: 3 }}>
          {activeTab === 0 ? (
            // Original metrics editing UI
            <Box sx={{ 
              display: 'grid', 
              gridTemplateColumns: '1fr 300px', 
              gap: 3,
              height: '100%',
              overflow: 'hidden'
            }}>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden'
              }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 2,
                    flexShrink: 0
                  }}
                >
                  <Typography variant="h4" sx={{ 
                    fontWeight: 300,
                    background: COLORS.primary.gradient,
                    backgroundClip: 'text',
                    color: 'transparent'
                  }}>
                    Custom Scores
                  </Typography>
                  <Button 
                    variant="contained" 
                    startIcon={<AddIcon />}
                    onClick={() => addNewDefinition()}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      px: 3,
                      backgroundImage: COLORS.primary.gradient,
                      '&:hover': {
                        backgroundImage: COLORS.primary.gradient,
                        filter: 'brightness(0.9)'
                      }
                    }}
                  >
                    Add New Metric
                  </Button>
                </Box>

                <Box sx={{ 
                  flex: 1,
                  overflow: 'auto',
                  pr: 2,
                  mr: -2,
                }}>
                  <Stack spacing={1}>
                    {definitions.map((def) => {
                      const validation = isDefinitionValid(def)
                      return (
                        <Card 
                          key={def.custom_definition_id} 
                          sx={{ 
                            position: 'relative',
                            borderRadius: 2,
                            backgroundColor: COLORS.background.card,
                            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px, rgba(0, 0, 0, 0.1) 0px 2px 8px',
                            transition: 'all 0.2s ease-in-out',
                            border: '1px solid',
                            borderColor: validation.valid ? 'divider' : 'error.light',
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px, rgba(0, 0, 0, 0.1) 0px 4px 24px',
                              '& .metric-actions': {
                                opacity: 1
                              }
                            },
                            '& .MuiCardContent-root': {
                              p: '12px !important',
                            }
                          }}
                        >
                          <CardContent sx={{ p: 2.5 }}>
                            <Stack spacing={1.5}>
                              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                    {def.label}
                                  </Typography>
                                  {!validation.valid && (
                                    <Tooltip title={validation.reason} placement="right">
                                      <IconButton 
                                        size="small" 
                                        sx={{ 
                                          color: 'error.main',
                                          p: 0.5
                                        }}
                                      >
                                        <InfoIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                                <Stack 
                                  direction="row" 
                                  spacing={1}
                                  className="metric-actions"
                                  sx={{ 
                                    opacity: 0,
                                    transition: 'opacity 0.2s'
                                  }}
                                >
                                  <Tooltip title="Edit Metric">
                                    <IconButton 
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        setEditingDef(def)
                                      }}
                                      sx={{ 
                                        color: 'primary.main',
                                        '&:hover': { 
                                          backgroundColor: 'primary.50' 
                                        }
                                      }}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Remove Metric">
                                    <IconButton 
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleDelete(def)
                                      }}
                                      sx={{ 
                                        color: 'error.main',
                                        '&:hover': { 
                                          backgroundColor: 'error.50' 
                                        }
                                      }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Box>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: 'vertical'
                                }}
                              >
                                {def.description}
                              </Typography>
                              <Stack direction="row" spacing={1}>
                                <Chip 
                                  label={formatMetricSource(def.value_source)} 
                                  size="small" 
                                  variant="outlined"
                                  sx={{ 
                                    borderRadius: 1.5,
                                    backgroundColor: 'primary.50',
                                    borderColor: 'primary.100',
                                    color: 'primary.700',
                                    '& .MuiChip-label': {
                                      px: 1
                                    }
                                  }}
                                />
                                <Chip 
                                  label={formatMetricType(def.value_type)} 
                                  size="small" 
                                  variant="outlined"
                                  sx={{ 
                                    borderRadius: 1.5,
                                    backgroundColor: 'secondary.50',
                                    borderColor: 'secondary.100',
                                    color: 'secondary.700',
                                    '& .MuiChip-label': {
                                      px: 1
                                    }
                                  }}
                                />
                              </Stack>
                            </Stack>
                          </CardContent>
                        </Card>
                      )
                    })}
                  </Stack>
                </Box>

                {hasChanges && (
                  <Box sx={{
                    position: 'sticky',
                    bottom: 0,
                    mt: 2,
                    p: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(8px)',
                    borderRadius: 2,
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px -1px 2px',
                    zIndex: 1200,
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center'
                  }}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        setDefinitions(originalDefinitions)
                        setEditingDef(null)
                      }}
                      sx={{
                        borderRadius: 2,
                        px: 4,
                        py: 1.5,
                        textTransform: 'none',
                        borderColor: 'divider',
                        color: 'text.secondary',
                        '&:hover': {
                          backgroundColor: COLORS.background.hover,
                          borderColor: 'error.100',
                          color: 'error.main'
                        }
                      }}
                    >
                      Undo Changes
                    </Button>
                    <Tooltip 
                      title={definitions.some(d => !isDefinitionValid(d).valid) ? 'Some metrics are invalid' : ''}
                      placement="top"
                    >
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => handleSave(definitions)}
                          disabled={definitions.some(d => !isDefinitionValid(d).valid)}
                          sx={{
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                            borderRadius: 2,
                            px: 4,
                            py: 1.5,
                            textTransform: 'none',
                            backgroundImage: COLORS.primary.gradient,
                            '&:hover': {
                              backgroundImage: COLORS.primary.gradient,
                              filter: 'brightness(0.9)'
                            },
                            '&.Mui-disabled': {
                              opacity: 0.5,
                              filter: 'grayscale(1)'
                            }
                          }}
                        >
                          Save Changes
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                )}
              </Box>

              <Card sx={{ 
                position: 'sticky',
                top: 24,
                height: 'fit-content',
                maxHeight: 'calc(100% - 48px)',
                overflow: 'auto',
                borderRadius: 2,
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px, rgba(0, 0, 0, 0.1) 0px 2px 8px',
                backgroundImage: COLORS.background.gradient
              }}>
                <CardContent sx={{ p: 2.5 }}>
                  <Typography variant="h6" sx={{ 
                    mb: 2, 
                    fontWeight: 500,
                    background: COLORS.primary.gradient,
                    backgroundClip: 'text',
                    color: 'transparent'
                  }}>
                    Quick Add Templates
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Get started quickly by adding pre-configured metrics to your dashboard
                  </Typography>
                  <Tabs
                    value={templateTab}
                    onChange={(_, newValue) => setTemplateTab(newValue)}
                    sx={{ mb: 2 }}
                  >
                    <Tab label="Sales" value="sales" />
                    <Tab label="QA" value="qa" />
                  </Tabs>
                  <Stack spacing={2}>
                    {defaultMetrics[templateTab as keyof DefaultMetricsType].map((metric: DefaultMetricType, index: number) => (
                      <Card 
                        key={index}
                        sx={{ 
                          cursor: 'pointer',
                          borderRadius: 2,
                          boxShadow: 'none',
                          border: '1px solid',
                          borderColor: 'divider',
                          position: 'relative',
                          overflow: 'hidden',
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            borderColor: COLORS.primary.main,
                            backgroundColor: COLORS.primary.light,
                            transform: 'translateY(-2px)',
                            '& .add-button': {
                              transform: 'translateX(0)',
                              opacity: 1
                            }
                          }
                        }}
                        onClick={() => addNewDefinition(metric)}
                      >
                        <Box 
                          className="add-button"
                          sx={{ 
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            width: '40%',
                            opacity: 0,
                            transform: 'translateX(100%)',
                            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            color: '#fff',
                            background: COLORS.primary.gradient,
                            boxShadow: 'rgba(0, 0, 0, 0.1) -4px 0px 8px'
                          }}
                        >
                          <AddCircleIcon />
                          <Typography sx={{ fontWeight: 500 }}>
                            Add
                          </Typography>
                        </Box>
                        <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                          <Typography variant="subtitle1" sx={{ 
                            fontWeight: 500, 
                            mb: 0.5,
                            color: COLORS.primary.main
                          }}>
                            {metric.label}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical'
                          }}>
                            {metric.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          ) : (
            // New testing interface
            <TestingTab 
              definitions={definitions}
              setDefinitions={setDefinitions}
              team_id={team_id}
              onEditSave={handleEditSave}
              handleDelete={handleDelete}
            />
          )}
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbarOpen(false)
          setError(null)
        }}
        message={error || ''}
      />

      {editingDef && (
        <EditModal
          open={Boolean(editingDef)}
          definition={editingDef}
          definitions={definitions}
          codeOptions={codeOptions}
          onClose={() => setEditingDef(null)}
          onSave={handleEditSave}
        />
      )}

      <ConfirmDialog
        open={showConfirmDialog}
        changes={metricChanges}
        onClose={() => {
          setShowConfirmDialog(false)
          setPendingSave(null)
          setMetricChanges([])
        }}
        onConfirm={confirmSave}
      />
    </>
  )
}

export const CustomScores = connect(
  (state: RootState) => ({
    team_id: state.user.value?.team_id ?? ''
  })
)(CustomScoresImpl)