import { CustomDefinition, CustomMetric, CustomMetricSource, CustomMetricValueType } from "../../../interfaces/db";
import { CustomDetail } from "../../../interfaces/db";
import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { getCustomCode, OutputType } from "../../CustomScores/cfg";
import { Typography } from '../../../interfaces/typography';

const COLORS = {
    success: {
        main: '#66BB6A',
        bg: 'rgba(76, 175, 80, 0.04)',
        border: 'rgba(76, 175, 80, 0.15)'
    },
    error: {
        main: '#EF5350',
        bg: 'rgba(244, 67, 54, 0.04)',
        border: 'rgba(244, 67, 54, 0.15)'
    },
    neutral: {
        main: '#9E9E9E',
        bg: 'rgba(158, 158, 158, 0.04)',
        border: 'rgba(158, 158, 158, 0.15)'
    }
};

type ScoreProps = {
    customDefinitions: CustomDefinition[]
    customDetail: CustomDetail | null
    customMetric: CustomMetric | null
    teamId?: string
}

export class Score extends React.Component<ScoreProps> {
    getMetricValue(def: CustomDefinition): { value: string | number | boolean | null, explanation: string | null } {
        if (!this.props.customMetric || !this.props.customDetail || def.metric_idx === null) {
            return { value: null, explanation: null };
        }
        
        const metricKey = `metric_${def.metric_idx}` as keyof CustomMetric;
        const value = this.props.customMetric[metricKey];

        let explanation: string | null = null;
        try {
            if (this.props.customDetail.metric_to_explanation_json_str) {
                const explanations = JSON.parse(this.props.customDetail.metric_to_explanation_json_str) as Record<string, string>;
                explanation = explanations[def.metric_idx.toString()] || null;
            }
        } catch (e) {
            console.error('Failed to parse explanation:', e);
        }

        if (value instanceof Date || typeof value === 'object') {
            return { value: null, explanation };
        }

        return { value: value as string | number | boolean | null, explanation };
    }

    renderMetricValue(def: CustomDefinition, value: any, explanation: string | null) {
        let displayValue: React.ReactNode;
        let colors = COLORS.neutral;

        // If value is null, show dash with neutral colors
        if (value === null) {
            displayValue = '-';
            return this.renderMetricBox(def, displayValue, COLORS.neutral, explanation);
        }

        // Handle custom code metrics
        if (def.value_source === CustomMetricSource.CODE && def.value_code_str && this.props.teamId) {
            const customCodes = getCustomCode(this.props.teamId);
            const code = customCodes?.find(c => c.name === def.value_code_str);
            
            if (code) {
                switch (code.output_type) {
                    case OutputType.BOOLEAN:
                        colors = Boolean(value) ? COLORS.success : COLORS.error;
                        displayValue = Boolean(value) ? '✓' : '✕';
                        break;
                    case OutputType.PERCENTAGE:
                        const percentage = value !== null ? Math.round(Number(value) * 100) : 0;
                        colors = percentage >= 50 ? COLORS.success : COLORS.error;
                        displayValue = `${percentage}%`;
                        break;
                    case OutputType.ENUM:
                        if (!code.enum_options || value === null) {
                            displayValue = '-';
                            break;
                        }
                        displayValue = code.enum_options[Number(value)] || '-';
                        break;
                }
                
                return this.renderMetricBox(def, displayValue, colors, explanation);
            }
        }

        // Handle standard metrics
        switch (def.value_type) {
            case CustomMetricValueType.BOOLEAN:
                colors = Boolean(value) ? COLORS.success : COLORS.error;
                displayValue = Boolean(value) ? '✓' : '✕';
                break;
            case CustomMetricValueType.PERCENT:
                const percentage = value !== null ? Math.round(Number(value) * 100) : 0;
                colors = percentage >= 50 ? COLORS.success : COLORS.error;
                displayValue = `${percentage}%`;
                break;
            case CustomMetricValueType.ENUM:
                if (!def.value_type_enum_str || value === null) {
                    displayValue = '-';
                    break;
                }
                const categories = def.value_type_enum_str.split(',');
                displayValue = categories[Number(value)] || '-';
                break;
            default:
                displayValue = String(value || '-');
        }

        return this.renderMetricBox(def, displayValue, colors, explanation);
    }

    renderMetricBox(def: CustomDefinition, displayValue: React.ReactNode, colors: typeof COLORS[keyof typeof COLORS], explanation: string | null) {
        const hasTooltip = def.description || explanation;
        const tooltipContent = hasTooltip ? (
            <Box>
                {def.description && (
                    <Typography variant="mediumParagraph">
                        {def.description}
                    </Typography>
                )}
                {explanation && (
                    <Typography variant="smallParagraph" color="#666">
                        {explanation}
                    </Typography>
                )}
            </Box>
        ) : null;

        const MetricBox = (
            <Box sx={{ 
                display: 'inline-flex',
                alignItems: 'center',
                height: 20,
                px: 1,
                borderRadius: 1,
                backgroundColor: colors.bg,
                border: '1px solid',
                borderColor: colors.border,
                cursor: hasTooltip ? 'help' : 'default'
            }}>
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography 
                        variant="mediumParagraph"
                        color="#666"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 4,
                            height: '100%',
                            lineHeight: '20px'
                        }}
                    >
                        {def.label}:
                    </Typography>
                    <Typography 
                        variant="mediumParagraph"
                        fontWeight={600}
                        color={colors.main}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            lineHeight: '20px',
                            ...(typeof displayValue === 'string' && displayValue.endsWith('%') && {
                                minWidth: '24px',
                                textAlign: 'right'
                            })
                        }}
                    >
                        {displayValue}
                    </Typography>
                </Box>
            </Box>
        );

        return hasTooltip ? (
            <Tooltip 
                title={tooltipContent}
                arrow
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            color: 'black',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            p: 1,
                            '& .MuiTooltip-arrow': {
                                color: 'white'
                            },
                            maxWidth: 250
                        }
                    }
                }}
            >
                {MetricBox}
            </Tooltip>
        ) : MetricBox;
    }

    render() {
        if (!this.props.customDefinitions.length) return null;

        return (
            <Box sx={{ 
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                alignItems: 'center'
            }}>
                {this.props.customDefinitions.map((def) => {
                    const { value, explanation } = this.getMetricValue(def);
                    return this.renderMetricValue(def, value, explanation);
                })}
            </Box>
        );
    }
}

