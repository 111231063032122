import { Option } from "../components/Selectors/cfg"
import { CustomDefinition, CustomMetricSource, CustomMetricValueType, PROSPECT_INFO_COLUMNS, Remark, SESSION_METRICS_COLUMNS, STAGE } from "../interfaces/db"
import { CnfFilterType, MetricSelect, MetricsDetailsResultV5, SessionMetricFilterTerm, StaticFilterDisjunction, UserDataResult } from "../interfaces/services"
import { DiagnosticResult } from "../components/MetricTable/cfg"
import { countBusinessDays } from "./timezone"
import { ValueType } from "../lib/redux/store"

export enum GRADIENT_DIRECTIONS {
  HIGHER = 'HIGHER',
  LOWER = 'LOWER',
}
  
export enum COLUMN_OPTIONS {
  DISPOSITION = 'Disposition',
  SENTIMENT = 'Sentiment',
  PURPOSE = 'Purpose',
  CUSTOM_METRIC = 'Custom Metric',
  OBJECTIONS = 'Objections',
  REP_BEHAVIOR = 'Rep Behavior',
  STAGE = 'Stage',
  ALL_CALLS = '# of Total Calls',
  TIME_ON_CALL = 'Duration on Calls',
  TALK_TIME_REP = 'Talk Time Rep',
  TALK_TIME_PROSPECT = 'Talk Time Prospect',
  COUNTERPART = 'Counterpart',
  TOUCHPOINTS = '# of Previous Touchpoints',
  INITIAL_RINGING = 'Initial Ringing',
  IS_LIVE = 'Is Live',
  EARLY_HANGUP = 'Early Hangup',
  NO_MENU_NAVIGATION = 'No Menu Navigation',
  REP_PACE = 'Rep Pace',
  AUTODIALER_CALLS = '# of Autodialer/parallel calls'
}

  export const COLUMN_OPTION_TO_SESSION_METRIC_COL: Map<COLUMN_OPTIONS, SESSION_METRICS_COLUMNS> = new Map([
    [COLUMN_OPTIONS.DISPOSITION, SESSION_METRICS_COLUMNS.USER_DISPOSITION],
    [COLUMN_OPTIONS.SENTIMENT, SESSION_METRICS_COLUMNS.USER_SENTIMENT],
    [COLUMN_OPTIONS.PURPOSE, SESSION_METRICS_COLUMNS.USER_PURPOSE],
    [COLUMN_OPTIONS.STAGE, SESSION_METRICS_COLUMNS.STAGE],
    [COLUMN_OPTIONS.TIME_ON_CALL, SESSION_METRICS_COLUMNS.CALL_DURATION],
    [COLUMN_OPTIONS.TALK_TIME_REP, SESSION_METRICS_COLUMNS.DURATION_REP_TARGET_ELIGIBLE_SPEECH],
    [COLUMN_OPTIONS.TALK_TIME_PROSPECT, SESSION_METRICS_COLUMNS.DURATION_TARGET_ELIGIBLE_SPEECH],
    [COLUMN_OPTIONS.COUNTERPART, SESSION_METRICS_COLUMNS.BEST_COUNTERPART],
    [COLUMN_OPTIONS.TOUCHPOINTS, SESSION_METRICS_COLUMNS.PRIOR_PHONE_USER_NUM_CALLS],
    [COLUMN_OPTIONS.INITIAL_RINGING, SESSION_METRICS_COLUMNS.INITIAL_RINGING_DURATION],
    [COLUMN_OPTIONS.IS_LIVE, SESSION_METRICS_COLUMNS.IS_ANSWERED],
    [COLUMN_OPTIONS.EARLY_HANGUP, SESSION_METRICS_COLUMNS.CAN_IMPROVE_EARLY_HANGUP],
    [COLUMN_OPTIONS.NO_MENU_NAVIGATION, SESSION_METRICS_COLUMNS.CAN_IMPROVE_NAVIGATE_MENU],
    [COLUMN_OPTIONS.REP_PACE, SESSION_METRICS_COLUMNS.NUMBER_WORDS_REP_TARGET_ELIGIBLE],
    [COLUMN_OPTIONS.AUTODIALER_CALLS, SESSION_METRICS_COLUMNS.IS_AUTODIALER_CREATED],
  ])
  
  export const COLUMN_OPTION_TO_TYPE: { [key in COLUMN_OPTIONS]: ValueType } = {
    [COLUMN_OPTIONS.DISPOSITION]: ValueType.CATEGORICAL,
    [COLUMN_OPTIONS.SENTIMENT]: ValueType.CATEGORICAL,
    [COLUMN_OPTIONS.PURPOSE]: ValueType.CATEGORICAL,
    [COLUMN_OPTIONS.CUSTOM_METRIC]: ValueType.CUSTOM_METRIC,
    [COLUMN_OPTIONS.OBJECTIONS]: ValueType.CATEGORICAL,
    [COLUMN_OPTIONS.REP_BEHAVIOR]: ValueType.CATEGORICAL,
    [COLUMN_OPTIONS.STAGE]: ValueType.CATEGORICAL,
    [COLUMN_OPTIONS.ALL_CALLS]: ValueType.NONE,
    [COLUMN_OPTIONS.TIME_ON_CALL]: ValueType.FLOAT,
    [COLUMN_OPTIONS.TALK_TIME_REP]: ValueType.FLOAT,
    [COLUMN_OPTIONS.TALK_TIME_PROSPECT]: ValueType.FLOAT,
    [COLUMN_OPTIONS.COUNTERPART]: ValueType.CATEGORICAL,
    [COLUMN_OPTIONS.TOUCHPOINTS]: ValueType.FLOAT,
    [COLUMN_OPTIONS.INITIAL_RINGING]: ValueType.FLOAT,
    [COLUMN_OPTIONS.IS_LIVE]: ValueType.BOOLEAN,
    [COLUMN_OPTIONS.EARLY_HANGUP]: ValueType.BOOLEAN,
    [COLUMN_OPTIONS.NO_MENU_NAVIGATION]: ValueType.BOOLEAN,
    [COLUMN_OPTIONS.REP_PACE]: ValueType.FLOAT,
    [COLUMN_OPTIONS.AUTODIALER_CALLS]: ValueType.BOOLEAN,
}

export const CATEGORY_CAN_BE_USED_IN_WHERE: { [key in ValueType]: boolean } = {
  [ValueType.CATEGORICAL]: true,
  [ValueType.CUSTOM_METRIC]: true,
  [ValueType.NONE]: false,
  [ValueType.FLOAT]: true,
  [ValueType.BOOLEAN]: true,
}

export const COLUMN_OPTIONS_CAN_BE_USED_IN_WHERE: COLUMN_OPTIONS[] = Object.values(COLUMN_OPTIONS).filter((key) => CATEGORY_CAN_BE_USED_IN_WHERE[COLUMN_OPTION_TO_TYPE[key as COLUMN_OPTIONS]])
export type CategoricalValue = {
  selected_options: Option[]
}

export enum FloatOption {
  WHERE='Where',
  TOTAL='Total',
}

export enum EqualityFn {
  LESS_THAN_OR_EQUAL_TO = '<=',
  EQUAL_TO = '=',
  GREATER_THAN_OR_EQUAL_TO = '>=',
  IN_BETWEEN = 'in between',
}

export type FloatValue = {
  fn_type: FloatOption
  equality_fn?: EqualityFn,
  number_one?: number
  number_two?: number
}

export type BooleanValue = {
  value?: boolean
}

export type Value = {
  [ValueType.FLOAT]?: FloatValue
  [ValueType.CATEGORICAL]?: CategoricalValue
  [ValueType.CUSTOM_METRIC]?: CustomMetricValue
  [ValueType.BOOLEAN]?: BooleanValue
  type: ValueType
}

export type StaticCustomMetricValue = {
  value_type: ValueType,
  metric_field: string,
  value?: Value
  isPercentage?: boolean
}

export type CustomMetricValue = {
  metric_field: string,
  value?: Value
}

export type ColumnSelectorProps = {
  selected_option: COLUMN_OPTIONS
  value?: Value
}

export function getDefaultColumnSelectorProps(selected_option: COLUMN_OPTIONS): Value | undefined {
  switch (COLUMN_OPTION_TO_TYPE[selected_option]) {
    case ValueType.CATEGORICAL: return { type: ValueType.CATEGORICAL, [ValueType.CATEGORICAL]: { selected_options: [] } }
    case ValueType.FLOAT: return { type: ValueType.FLOAT, [ValueType.FLOAT]: { fn_type: FloatOption.TOTAL } }
    case ValueType.BOOLEAN: return { type: ValueType.BOOLEAN, [ValueType.BOOLEAN]: { value: false } }
    case ValueType.NONE: return { type: ValueType.NONE }
    default: return undefined
  }
}

export type ColumnInfo = {
  numerator: ColumnSelectorProps
  denominator: ColumnSelectorProps | null
  label: string
  custom_options: CustomOptions
  wheres: ColumnSelectorProps[]
  uuid: string
  order?: number
  createdByUser?: string
  isDefault?: string
  defaultHidden?: boolean
  isTeamMetric?: boolean
  isActive?: boolean
  positiveLabel?: string
  negativeLabel?: string
}

export enum CustomApplicable {
  NUMERATOR_ONLY = 'Numerator Only',
  DENOMINATOR_REQUIRED = 'Denominator Required',
  ALL = 'All',
}

export enum CustomOptionsType {
  THRESHOLD_VALUE='Target Value',
  GROUP_BY='Group By',
  DISPLAY_NO_COLOR='Display No Color',
  DISPLAY_RAW_COUNT='Display Raw Count',
  DISPLAY_AS_PERCENTAGE='Show Percentage',
  DISPLAY_AS_DAILY_AVERAGE='Show Daily Average',
  DISPLAY_AS_WEEKLY_AVERAGE='Show Weekly Average',
  DIVIDE_BY_USER_COUNT='Divide by User Count',
  LOWER_IS_BETTER='Lower is better',
  RATIO='Ratio',
}

export const APPLICABLE_GROUP_BY_OPTIONS: (SESSION_METRICS_COLUMNS | PROSPECT_INFO_COLUMNS)[] = [
  PROSPECT_INFO_COLUMNS.PROSPECT_PHONE_TYPE,
  PROSPECT_INFO_COLUMNS.CADENCE,
  PROSPECT_INFO_COLUMNS.CADENCE_STEP,
  PROSPECT_INFO_COLUMNS.PROSPECT_TITLE,
  PROSPECT_INFO_COLUMNS.PROSPECT_OCCUPATION,
  PROSPECT_INFO_COLUMNS.PROSPECT_SENIORITY,
  PROSPECT_INFO_COLUMNS.PROSPECT_LOCATION,
  PROSPECT_INFO_COLUMNS.COMPANY_INDUSTRY,
  PROSPECT_INFO_COLUMNS.COMPANY_PERSONA,
  SESSION_METRICS_COLUMNS.STAGE,
  SESSION_METRICS_COLUMNS.USER_DISPOSITION,
  SESSION_METRICS_COLUMNS.USER_SENTIMENT,
  SESSION_METRICS_COLUMNS.USER_PURPOSE,
  SESSION_METRICS_COLUMNS.BEST_COUNTERPART,
]


export const CustomOptionTypeToCustomApplicable: {[key in CustomOptionsType]: CustomApplicable} = {
  [CustomOptionsType.LOWER_IS_BETTER]: CustomApplicable.ALL,
  [CustomOptionsType.THRESHOLD_VALUE]: CustomApplicable.ALL,
  [CustomOptionsType.DISPLAY_NO_COLOR]: CustomApplicable.ALL,
  [CustomOptionsType.DISPLAY_AS_PERCENTAGE]: CustomApplicable.DENOMINATOR_REQUIRED,
  [CustomOptionsType.DIVIDE_BY_USER_COUNT]: CustomApplicable.NUMERATOR_ONLY,
  [CustomOptionsType.DISPLAY_AS_DAILY_AVERAGE]: CustomApplicable.NUMERATOR_ONLY,
  [CustomOptionsType.DISPLAY_AS_WEEKLY_AVERAGE]: CustomApplicable.NUMERATOR_ONLY,
  [CustomOptionsType.DISPLAY_RAW_COUNT]: CustomApplicable.DENOMINATOR_REQUIRED,
  [CustomOptionsType.GROUP_BY]: CustomApplicable.ALL,
  [CustomOptionsType.RATIO]: CustomApplicable.DENOMINATOR_REQUIRED,
}

export type CustomOptions = {
  [CustomOptionsType.LOWER_IS_BETTER]?: boolean
  [CustomOptionsType.THRESHOLD_VALUE]?: number
  [CustomOptionsType.DISPLAY_NO_COLOR]?: boolean
  [CustomOptionsType.DISPLAY_AS_PERCENTAGE]?: boolean
  [CustomOptionsType.DIVIDE_BY_USER_COUNT]?: boolean,
  [CustomOptionsType.GROUP_BY]?: SESSION_METRICS_COLUMNS | PROSPECT_INFO_COLUMNS
  [CustomOptionsType.DISPLAY_AS_DAILY_AVERAGE]?: boolean,
  [CustomOptionsType.DISPLAY_AS_WEEKLY_AVERAGE]?: boolean,
  [CustomOptionsType.DISPLAY_RAW_COUNT]?: boolean,
  [CustomOptionsType.RATIO]?: boolean,
}

export type CategoricalProps = {
  selected_value?: Value | null,
  onValueChange: (v: Value) => void,
}

function convertSessionMetricsColumnToCnf(column: ColumnSelectorProps): StaticFilterDisjunction[] {
  const type = COLUMN_OPTION_TO_TYPE[column.selected_option]
  const session_metric = COLUMN_OPTION_TO_SESSION_METRIC_COL.get(column.selected_option)
  if (!session_metric) return []
  switch (type) {
    case ValueType.CATEGORICAL: 
    const selected_options = (column.value ? column.value[ValueType.CATEGORICAL]?.selected_options : []) ?? []
    if (column.selected_option === COLUMN_OPTIONS.STAGE) { 
      return [{
        'negated': false,
        'filter_type': CnfFilterType.STAGE,
        'stage_filters': selected_options.map((v) => { return { 'require_ending': true, 'stage': v.value as STAGE }})
      }]
    } 
    return [{
      'negated': false,
      'filter_type': CnfFilterType.SESSION_METRIC,
      'session_metric_filters': [{'field': session_metric, 'in_values': selected_options.map((option) => option.value as string) }]
    }]
    case ValueType.BOOLEAN:
      return [{
        'negated': false,
        'filter_type': CnfFilterType.SESSION_METRIC,
        'session_metric_filters': [{'field': session_metric, 'in_values': column.value ? [column.value[ValueType.BOOLEAN]?.value ? true : false] : []}]
      }]
    case ValueType.FLOAT:
      const equality_type = column.value ? column.value[ValueType.FLOAT]?.equality_fn : null
      if (!equality_type) return []
      const session_metric_filter: SessionMetricFilterTerm[] = []
      const number_one = column.value ? column.value[ValueType.FLOAT]?.number_one : null
      const number_two = column.value ? column.value[ValueType.FLOAT]?.number_one : null
      switch (equality_type) {
        case EqualityFn.EQUAL_TO:
          if (number_one === null || number_one === undefined) return []
          session_metric_filter.push({'field': session_metric, 'in_values': [number_one]})
          break
        case EqualityFn.LESS_THAN_OR_EQUAL_TO:
          if (number_one === null || number_one === undefined) return []
          session_metric_filter.push({'field': session_metric, 'max_value': number_one})
          break
        case EqualityFn.GREATER_THAN_OR_EQUAL_TO:
          if (number_one === null || number_one === undefined) return []
          session_metric_filter.push({'field': session_metric, 'min_value': number_one})
          break
        case EqualityFn.IN_BETWEEN:
          if (number_one === null || number_one === undefined || number_two === null || number_two === undefined) return []
          session_metric_filter.push({'field': session_metric, 'min_value': number_one, 'max_value': number_two})
          break
      }
      return [{
        'negated': false,
        'filter_type': CnfFilterType.SESSION_METRIC,
        'session_metric_filters': session_metric_filter
      }]
      break
    default: return []
  }
}

function convertCustomMetricToCnf(column: ColumnSelectorProps,
  custom_metric_definitions: CustomDefinition[] | null): StaticFilterDisjunction[] {
  if (!custom_metric_definitions) return []
  if (!column.value || !column.value[ValueType.CUSTOM_METRIC]) return []
  const custom_metric = column.value[ValueType.CUSTOM_METRIC]
  const matching_metric = custom_metric_definitions.find((metric) => `metric_${metric.metric_idx}` === custom_metric?.metric_field)
  if (!matching_metric) return []
  switch (custom_metric?.value?.type) {
    case ValueType.CATEGORICAL:
      const categorical_values = custom_metric.value[ValueType.CATEGORICAL]?.selected_options.map((option) => option.value as number)
      if (!categorical_values) return []
      return [{'filter_type': CnfFilterType.CUSTOM_METRIC, 'negated': false, 'custom_metric_filters': categorical_values.map((value) => { return { 
        'field': `metric_${matching_metric.metric_idx}`, 
        'min_value': parseInt(value.toString()), 
        'max_value': parseInt(value.toString()) }}) }]
    case ValueType.BOOLEAN:
      const boolean_value = custom_metric.value[ValueType.BOOLEAN]?.value ? 1 : 0
      return [{
        'filter_type': CnfFilterType.CUSTOM_METRIC, 
        'negated': false, 
        'custom_metric_filters': [{ 
          'field': `metric_${matching_metric.metric_idx}`, 
          'min_value': parseInt(boolean_value.toString()), 
          'max_value': parseInt(boolean_value.toString()) 
        }] 
      }]
    case ValueType.FLOAT:
      const float_value = custom_metric.value[ValueType.FLOAT]?.fn_type
      if (!float_value) return []
      switch (float_value) {
        case FloatOption.TOTAL:
          return []
        case FloatOption.WHERE:
          const equality_fn = custom_metric.value[ValueType.FLOAT]?.equality_fn
          if (!equality_fn) return []
          const number_one = custom_metric.value[ValueType.FLOAT]?.number_one
          const number_two = custom_metric.value[ValueType.FLOAT]?.number_two
          if (!number_one) return []
          switch (equality_fn) {
            case EqualityFn.EQUAL_TO: return [{
              'filter_type': CnfFilterType.CUSTOM_METRIC, 
              'negated': false, 
              'custom_metric_filters': [{ 
                'field': `metric_${matching_metric.metric_idx}`, 
                'min_value': parseInt(number_one.toString()), 
                'max_value': parseInt(number_one.toString()) 
              }] 
            }]
            case EqualityFn.LESS_THAN_OR_EQUAL_TO: return [{
              'filter_type': CnfFilterType.CUSTOM_METRIC, 
              'negated': false, 
              'custom_metric_filters': [{ 
                'field': `metric_${matching_metric.metric_idx}`, 
                'max_value': parseInt(number_one.toString()) 
              }] 
            }]
            case EqualityFn.GREATER_THAN_OR_EQUAL_TO: return [{
              'filter_type': CnfFilterType.CUSTOM_METRIC, 
              'negated': false, 
              'custom_metric_filters': [{ 
                'field': `metric_${matching_metric.metric_idx}`, 
                'min_value': parseInt(number_one.toString()) 
              }] 
            }]
            case EqualityFn.IN_BETWEEN: return [{
              'filter_type': CnfFilterType.CUSTOM_METRIC, 
              'negated': false, 
              'custom_metric_filters': [{ 
                'field': `metric_${matching_metric.metric_idx}`, 
                'min_value': parseInt(number_one.toString()), 
                'max_value': parseInt(number_two!.toString()) 
              }] 
            }]
      }
    }
    default: return []
  }
}

function convertNonSessionMetricColumnToCnf(column: ColumnSelectorProps): StaticFilterDisjunction[] {
  switch(column.selected_option) {
    case COLUMN_OPTIONS.OBJECTIONS:
    case COLUMN_OPTIONS.REP_BEHAVIOR:
      const selected_options = column.value ? (column.value[ValueType.CATEGORICAL]?.selected_options ?? []) : []
      if (selected_options.length === 0) return []
      return [{'filter_type': CnfFilterType.REMARK, 'negated': false, 'remark_filters': selected_options.map((option) => { return { 'remark': option.value as Remark}})}]
    case COLUMN_OPTIONS.ALL_CALLS: return []
    default: return []
  }
}

export function convertColumnToCnf(column: ColumnSelectorProps, custom_metric_definitions: CustomDefinition[] | null): StaticFilterDisjunction[] {
  const selected_option = column.selected_option
  if (!selected_option) return []
  if (COLUMN_OPTION_TO_SESSION_METRIC_COL.has(selected_option)) { return convertSessionMetricsColumnToCnf(column) }
  else if (COLUMN_OPTION_TO_TYPE[selected_option] === ValueType.CUSTOM_METRIC) { return convertCustomMetricToCnf(column, custom_metric_definitions) }
  else { return convertNonSessionMetricColumnToCnf(column) }
}

function convertCustomMetricToCnfBoolean(custom_metric_value: CustomMetricValue, matching_metric: CustomDefinition): MetricSelect {
  return {
    cnf: [{
      'filter_type': CnfFilterType.CUSTOM_METRIC,
      'negated': false,
      'custom_metric_filters': [{ 
        'field': `metric_${matching_metric.metric_idx}`,
        'min_value': custom_metric_value.value ? custom_metric_value.value[ValueType.BOOLEAN]?.value ? 1 : 0 : 0,
        'max_value': custom_metric_value.value ? custom_metric_value.value[ValueType.BOOLEAN]?.value ? 1 : 0 : 0,
      }]
    }]
  }
}

function convertCustomMetricToCnfFloat(custom_metric_value: CustomMetricValue, matching_metric: CustomDefinition): MetricSelect {
  return {
    cnf: [{
      'filter_type': CnfFilterType.CUSTOM_METRIC,
      'negated': false,
      'custom_metric_filters': [{ 
        'field': `metric_${matching_metric.metric_idx}`,
        'min_value': custom_metric_value.value ? custom_metric_value.value[ValueType.FLOAT]?.number_one : null,
        'max_value': custom_metric_value.value ? custom_metric_value.value[ValueType.FLOAT]?.number_two : null,
      }]
    }]
  }
}

function convertCustomMetricToCnfCategorical(custom_metric_value: CustomMetricValue, matching_metric: CustomDefinition): MetricSelect {
  return {
    cnf: [{
      'filter_type': CnfFilterType.CUSTOM_METRIC,
      'negated': false,
      'custom_metric_filters': custom_metric_value.value ? custom_metric_value.value[ValueType.CATEGORICAL]?.selected_options.map((option) => { 
        return { 
          'field': `metric_${matching_metric.metric_idx}`,
          'min_value': parseInt(option.value.toString()),
          'max_value': parseInt(option.value.toString()),
        }}) : []
    }]
  }
}

function convertCustomMetricToMetricSelect(column: ColumnSelectorProps, 
  custom_metric_definitions: CustomDefinition[] | null): MetricSelect {
  if (!custom_metric_definitions || !custom_metric_definitions.length) return {}
  if (!column.value || !column.value[ValueType.CUSTOM_METRIC]) return {}
  const custom_metric = column.value[ValueType.CUSTOM_METRIC]
  const matching_metric = custom_metric_definitions.find((metric) => `metric_${metric.metric_idx}` === custom_metric.metric_field)
  if (!matching_metric) return {}
  switch (matching_metric.value_type) {
    case CustomMetricValueType.BOOLEAN: return convertCustomMetricToCnfBoolean(custom_metric, matching_metric)
    case CustomMetricValueType.ENUM: return convertCustomMetricToCnfCategorical(custom_metric, matching_metric)
    case CustomMetricValueType.PERCENT: 
      const float_value = custom_metric.value ? custom_metric.value[ValueType.FLOAT]?.fn_type : null
      if (!float_value) return {}
      switch (float_value) {
        case FloatOption.TOTAL: return { 'custom_metrics_col': `metric_${matching_metric.metric_idx}` }
        default: return convertCustomMetricToCnfFloat(custom_metric, matching_metric)
      }
  }
  return {}
}

export function convertColumnToMetricSelect(column: ColumnSelectorProps, 
  custom_metric_definitions: CustomDefinition[] | null): MetricSelect {
  const selected_option = column.selected_option
  if (!selected_option) return {}
  // if it's categorical.. you need to pass in an empty select along with a where
  // if it's a float, if it's an aggregate, you need to pass in the aggregate otherwise you need to pass in the where
  // if it's a boolean, you need to pass in the where
  const type = COLUMN_OPTION_TO_TYPE[selected_option]
  switch (type) {
    case ValueType.CATEGORICAL:
    case ValueType.NONE:
    case ValueType.BOOLEAN:
      return { 'cnf': convertColumnToCnf(column, custom_metric_definitions) }
    case ValueType.FLOAT:
      const selected_value = column.value ? column.value[ValueType.FLOAT] : null
      if (!selected_value) return {}
      const fn_type = selected_value.fn_type
      if (fn_type === FloatOption.TOTAL) return { 'session_metrics_col': COLUMN_OPTION_TO_SESSION_METRIC_COL.get(selected_option) }
      return { 'cnf': convertColumnToCnf(column, custom_metric_definitions) }
    case ValueType.CUSTOM_METRIC:
      return convertCustomMetricToMetricSelect(column, custom_metric_definitions)
  }
}
export function getWheres(wheres: ColumnSelectorProps[], custom_metric_definitions: CustomDefinition[] | null): StaticFilterDisjunction[] { return wheres.map((where) => convertColumnToCnf(where, custom_metric_definitions)).flat(1) }
export function convertMetricColumnToSelect(columnProps: ColumnSelectorProps, 
  where_cols: ColumnSelectorProps[], 
  custom_metric_definitions: CustomDefinition[] | null): MetricSelect {
  const wheres = getWheres(where_cols, custom_metric_definitions)
  const metric_select = convertColumnToMetricSelect(columnProps, custom_metric_definitions)
  return { ...metric_select, 'cnf': metric_select.cnf ? [...metric_select.cnf, ...wheres] : wheres }
}

export function convertColumnInfoToMetricSelects(column_info: ColumnInfo, 
  custom_metric_definitions: CustomDefinition[] | null): MetricSelect[] { 
  const numerator = convertMetricColumnToSelect(column_info.numerator, column_info.wheres, custom_metric_definitions)
  const denominator = column_info.denominator ? convertMetricColumnToSelect(column_info.denominator, column_info.wheres, custom_metric_definitions) : null
  const metric_selects: MetricSelect[] = [numerator]
  if (denominator) metric_selects.push(denominator)
  return metric_selects
}

export type GroupByOption = {
  group_by_custom_metric?: string,
  group_by_prospect_info?: PROSPECT_INFO_COLUMNS,
  group_by_session_metric?: SESSION_METRICS_COLUMNS,
  group_by_time?: boolean,
}

export function getGroupBy(customOption: CustomOptions): GroupByOption {
  if (!customOption[CustomOptionsType.GROUP_BY]) return {}
  const group_by = customOption[CustomOptionsType.GROUP_BY]
  if (!APPLICABLE_GROUP_BY_OPTIONS.includes(group_by)) return {}
  if (Object.values(SESSION_METRICS_COLUMNS).includes(group_by as SESSION_METRICS_COLUMNS)) return { group_by_session_metric: group_by as SESSION_METRICS_COLUMNS }
  if (Object.values(PROSPECT_INFO_COLUMNS).includes(group_by as PROSPECT_INFO_COLUMNS)) return { group_by_prospect_info: group_by as PROSPECT_INFO_COLUMNS }
  return { group_by_custom_metric: group_by }
}

export function formatNumber(num: number, fixed:number = 1): string { return parseFloat(num.toFixed(fixed)).toString() }

export function getMetricSelects(columns: ColumnInfo[], 
  custom_metric_definitions: CustomDefinition[] | null): MetricSelect[] {
  const metric_selects: MetricSelect[] = []
  const metric_selects_jsonified: string[] = []
  for (const metric of columns) {
    convertColumnInfoToMetricSelects(metric, custom_metric_definitions).forEach((select) => {
      const jsonified = JSON.stringify(select)
      if (metric_selects_jsonified.includes(jsonified)) return
      metric_selects.push(select)
      metric_selects_jsonified.push(jsonified)
    })
  }
  return metric_selects
}

export type Result = {
    numerator_value: number | undefined;
    denominator_value: number | undefined;
    group_by?: string | null | boolean;
};

export type Results = Result[];

export type MetricResult = {
    group: string | boolean | null;
    values: number;
};

export function getEmptyDiagnosticResult(): DiagnosticResult { return { label: '-', value: null, delta: null } }
export function calculateMetricCardValue(value: Results | null, 
  metric: ColumnInfo, 
  start: Date, 
  end: Date,
  applicableUsers: number | null,
): DiagnosticResult {
  let adjusted_value
  if (!value) return getEmptyDiagnosticResult()
  if (value.some((v) => v.numerator_value === null)) return getEmptyDiagnosticResult()
  const numerator_value = value.reduce((acc, curr) => acc + (curr.numerator_value ?? 0), 0)
  adjusted_value = numerator_value

  const denominator_value = value.reduce((acc, curr) => acc + (curr.denominator_value ?? 0), 0)

  if (metric.custom_options[CustomOptionsType.DIVIDE_BY_USER_COUNT]) {
    if (!applicableUsers) return getEmptyDiagnosticResult()
    adjusted_value = adjusted_value / applicableUsers
  }
  if (metric.denominator && adjusted_value) {
    if (denominator_value === 0) adjusted_value = 0
    else if (metric.custom_options[CustomOptionsType.RATIO]) { adjusted_value = (adjusted_value / (adjusted_value + denominator_value)) * 100 }
    else if (metric.custom_options[CustomOptionsType.DISPLAY_AS_PERCENTAGE]) adjusted_value = (adjusted_value / denominator_value) * 100
    else adjusted_value = adjusted_value / denominator_value
  }
  adjusted_value = adjusted_value ?? 0
  if (metric.custom_options[CustomOptionsType.DISPLAY_AS_DAILY_AVERAGE]) {
    const businessDays = Math.max(1, countBusinessDays(start, end) ?? 1)
    adjusted_value = adjusted_value / businessDays
  }
  if (metric.custom_options[CustomOptionsType.DISPLAY_AS_WEEKLY_AVERAGE]) {
    const businessDays = Math.max(1, countBusinessDays(start, end) ?? 1)
    adjusted_value = adjusted_value / (businessDays / 5)
  }
  let label = `${formatNumber(adjusted_value)}${metric.custom_options[CustomOptionsType.DISPLAY_AS_PERCENTAGE] ? '%' : ''}`
  if (metric.custom_options[CustomOptionsType.RATIO]) {
    label = `${formatNumber(adjusted_value, 0)}:${formatNumber(100-adjusted_value, 0)}`
  }
  return { label: label, value: adjusted_value, delta: null, lowerIsBetter: metric.custom_options[CustomOptionsType.LOWER_IS_BETTER], calls: metric.custom_options[CustomOptionsType.DISPLAY_RAW_COUNT] ? numerator_value : undefined }
}
