export const pendingRoomKey = 'trellus_pending_room'
export const noShowLeaderboardSettingKey = 'trellus_setting_showLeaderboard'
export const preferencedLeaderboardGroup = 'trellus_perferencedLeaderboardGroup'
export const teamConfigurationKey = 'teamConfigurationKey'
export const apiKeyStorageKey = 'apiKeyV2'
export const linkedInProfileCacheKey = 'linkedin_profile_cache'

export function getLocalStorage(key: string, needsJsonLoad?: boolean): any | null {
    if (localStorage) {
        try {
            const value = localStorage.getItem(key)
            return value ? needsJsonLoad ? JSON.parse(value) : value : null
        } catch {
            return null
        }
    }
    return null
}

export function setLocalStorage(key: string, content: any, needsJsonificiation?: boolean) {
    if (localStorage) {
        try {
            return localStorage.setItem(key, needsJsonificiation ? JSON.stringify(content) : content)
        } catch {
        }
    }
}


export function removeLocalStorage(key: string) {
    if (localStorage) {
        try {
            localStorage.removeItem(key)
        } catch {
        }
    }
}