import React, { Dispatch } from "react";

import { MS_TO_SEC, SEC_TO_MS } from "../../cfg/const";
import { CnfFilterType, CommentDataResult, 
  CommentParams, Keyword, KeywordPhrase, 
  SessionDataResult, SpeechTurn, 
  TemporalFilterDisjunction, UpdateType, UserDataResult } from "../../interfaces/services";
import { getServicesManager } from "../../services";
import { CounterpartInterval, PromptTypeOption, RemarkOption, ReviewOption, Transcript, TranscriptBody, TranscriptWidget } from "../../components/Widgets/Transcript";
import { Typography } from "../../interfaces/typography";
import { Display, PartyRole, Remark, REMARK_TO_HUMAN_READABLE } from "../../interfaces/db";
import { PromptType } from "../../interfaces/consts";
import { TranscriptReviewMode } from "../../components/Widgets/Transcript/TranscriptSideBar";
import uuid from "react-uuid";
import { PromptOnLeave } from "../../components/PromptOnLeave";
import { connect } from "react-redux";
import { RootState } from "../../store";
import {
  updateSessionStatus,  
  Stored,
} from '../SessionStatus/SessionStoreSetter';
import { Action, AnyAction } from "@reduxjs/toolkit";
import { prospectPartyCode } from "../../cfg/endpoints";
import { PopulationFilter } from "../../components/FilterSelector";
import { StaticFilter, convertFilterFromReduxSafe, convertFromReduxSafeUserState, convertFromReduxSafeVisibleAccounts, getTemporalCnfFromPopulationFilter } from "../../lib/redux/store";

export interface SessionDetailsProps<A extends Action = AnyAction> {
  sessionId?: string,
  
  coachingView?: boolean,
  embeddedView?: boolean,
  isInView: boolean // needed to stop audio elements since we don't unmount components in certain views... 
  viewerUserId?: string,
  dispatch: Dispatch<A>;
  user: UserDataResult | null
  isAdmin: boolean | null
  value: {[k: string]: Stored}
  populationFilters: PopulationFilter[]
  triggerAlert?: (label: string, timeInMs: number, isSuccess: boolean) => void,
  minimalView?: boolean
  pageClosed?: boolean

  keywords?: Keyword[],
  keywordPhrases?: KeywordPhrase[]
  temporalCnfForce?: TemporalFilterDisjunction[]
  filter: StaticFilter
}

type SessionDetailsState = {
  data: SessionDataResult | null,
  transcripts?: Transcript[]
  remarkOptions?: RemarkOption[]
  behavioralOptions?: PromptTypeOption[]
  triggerOptions?: ReviewOption[]
  commentOptions?: CommentDataResult[]  
  counterpartyOptions?: CounterpartInterval[], 
  selectedTranscriptIdx?: number,
  viewMode?: TranscriptReviewMode,
  lastSavedComments?: CommentDataResult[],
}


class SessionDetails extends React.Component<SessionDetailsProps, SessionDetailsState> {
  _saveTimer?: NodeJS.Timeout

  constructor(props: SessionDetailsProps) {
    super(props)
    this.state = {
      'data': null,
    }
  }

  async updateSessionData(forceCache?: boolean) {
    if (!this.props.sessionId) return
    // get data
    const cnf = this.props.temporalCnfForce ? this.props.temporalCnfForce : this.props.populationFilters.length > 0 ? getTemporalCnfFromPopulationFilter(this.props.populationFilters) : undefined
    const usefulCnf = cnf && cnf.some((v) => [CnfFilterType.COUNTERPARTS, CnfFilterType.DISPLAY, CnfFilterType.PHRASE, CnfFilterType.PROSPECT, CnfFilterType.REMARK, CnfFilterType.TIME].includes(v.filter_type))
    const data = await getServicesManager().getSessionData(this.props.sessionId, usefulCnf ? cnf : undefined, forceCache)
    if (data && typeof data !== 'number' && data.session.session_id === this.props.sessionId) this.setState(
      () => 
      {
        const turns = (data.turns ?? []).filter((v) => v.text.trim() !== '').sort((a, b) => a.start - b.start)
        const behavioralTriggers = this._computeBehavioralFilters(data, turns)
        const triggerOptions =  this._computeTriggerFilters(data, turns)
        const remarkOptions =  this._computeRemarkFilters(data, turns)
        const commentOptions = this._computeCommentFilters(data, turns)
        const counterpartyOptions = this._computeCounterpartFilters(data)

        const viewMode = commentOptions.length > 0 ? TranscriptReviewMode.COMMENTS : remarkOptions && remarkOptions.length > 0 ? TranscriptReviewMode.EVENTS : 
        triggerOptions && triggerOptions.length > 0 ? TranscriptReviewMode.TRIGGERS : 
        behavioralTriggers && behavioralTriggers.length > 0 ? TranscriptReviewMode.BEHAVIORAL : undefined
        let selectedTranscriptIdx: number | undefined = undefined

        if (data.temporal_cnf_sat && data.turns.length > 0) {
          const possibleFilterMatches = data.temporal_cnf_sat.filter((v) => v.start !== 0 || v.end !== data.turns[data.turns.length - 1].end)
          if (possibleFilterMatches.length > 0) {
            const scores = turns.map((x) => {
              return this._computeOverlap(x.start, x.end, possibleFilterMatches[0].start, possibleFilterMatches[0].end)
            })
            selectedTranscriptIdx = scores.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0)
          }
        }
        
        const transcripts = this._sessionDataResultToTranscript(data, turns, remarkOptions, behavioralTriggers, triggerOptions)
        return {
        'data': data, 'behavioralOptions': behavioralTriggers, 
        'remarkOptions': remarkOptions,
        'lastSavedComments': data.comments,
        'counterpartyOptions': counterpartyOptions, 
        'transcripts': transcripts,
        'triggerOptions': triggerOptions,
        'commentOptions': commentOptions,
        'viewMode': this.props.coachingView ? undefined : usefulCnf || viewMode === TranscriptReviewMode.COMMENTS ? viewMode : undefined,
        'selectedTranscriptIdx': this.props.coachingView ? undefined : selectedTranscriptIdx,
      }})
    else if (data && typeof data === 'number' ) {
      const sessionId = this.props.sessionId
      setTimeout(() => {if (sessionId === this.props.sessionId && !this.state.data) this.updateSessionData(true)}, Math.max(data - new Date().getTime(), 3*SEC_TO_MS))
    }
  }

  componentDidMount(): void {
    this.updateSessionData()
  }

  shouldComponentUpdate(nextProps: Readonly<SessionDetailsProps>, nextState: Readonly<SessionDetailsState>, nextContext: any): boolean {
    if (nextProps.sessionId !== this.props.sessionId) {
      if (this._saveTimer) clearInterval(this._saveTimer)
      this._saveTimer = undefined
      this._savePeriodically(true, this.state.commentOptions, this.state.lastSavedComments, this.state.transcripts)
    }
    return true
  }

  componentDidUpdate(prevProps: Readonly<SessionDetailsProps>, prevState: Readonly<SessionDetailsState>, snapshot?: any): void {
    if (prevProps.sessionId !== this.props.sessionId && this.props.sessionId) {
      this.setState({'data': null, 'behavioralOptions': undefined, 'counterpartyOptions': undefined,
      'remarkOptions': undefined,
      'commentOptions': undefined, 'lastSavedComments': undefined, 
      'selectedTranscriptIdx': undefined, viewMode: undefined, 
      'transcripts': undefined, 'triggerOptions': undefined}, this.updateSessionData)
    }

    if (prevState.data == null && prevProps.sessionId == this.props.sessionId && this.state.data) {
      this._savePeriodically();      
    }
  }

  _sessionDataResultToTranscript(data: SessionDataResult, turns: SpeechTurn[], remarkOptions: RemarkOption[], behavioralTriggers: ReviewOption[], triggerFilters: ReviewOption[]): Transcript[] | undefined {
    // get a map from party code to person
    const transcriptResult: Transcript[] = turns.map((value, idx) => {
      const partyCode = value.role === PartyRole.REP ? 1 : 0
      const defaultName = partyCode ? 'Them' : 'You'
      const personName =  data.prospect_info?.prospect_name ?? defaultName
      const remarksFiltered = remarkOptions.filter((v: RemarkOption) => idx == v.transcriptIdx)
      const behavioralFiltered = behavioralTriggers.filter((v: ReviewOption) => idx == v.transcriptIdx)
      const triggersFiltered = triggerFilters.filter((v: ReviewOption) => idx == v.transcriptIdx)
      return {
        'start': value.start*SEC_TO_MS, 'end': value.end*SEC_TO_MS, 
        'partyCode': partyCode, 
        'personName': personName,
        'content': value.text,
        'remarkDisplay': remarksFiltered.length > 0 ? remarksFiltered.map((value: RemarkOption) => value.remarks.map((v: Remark) => REMARK_TO_HUMAN_READABLE[v]).join(', ')).join('') : undefined,
        'behavioralDisplay': behavioralFiltered.length > 0 ? behavioralFiltered.map((value: ReviewOption) => value.displayText).join('') : undefined,
        'triggerDisplay': triggerFilters.length > 0 ? triggersFiltered.map((value: ReviewOption) => value.displayText).join('') : undefined,
        }
    })
    return transcriptResult
  }

  _findClosestIndex(arr: number[], X: number, ): number {
    let left = 0;
    let right = arr.length - 1;
    let closestIndex = -1;
  
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const midValue = arr[mid];
  
      if (midValue < X) {
        closestIndex = mid;
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }
  
    return closestIndex;
  }

  _computeOverlap(x0_0: number, x0_1: number, x1_0: number, x1_1: number) {
    return Math.max(0, Math.min(x0_1, x1_1) - Math.max(x0_0, x1_0))
  }

  _computeRemarkFilters(data: SessionDataResult, turns: SpeechTurn[]): RemarkOption[] { 
    const segmentToRemarks: Map<number, Remark[]> = new Map()
    data.remarks.forEach((v) => {
      const scores = turns.map((x) => {
        if (x.role !== v.role) return 0
        return this._computeOverlap(x.start, x.end, v.start, v.end)
      })
      const idxMax = scores.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0)
      if (scores[idxMax] && scores[idxMax] > 0) {
         const prompts = segmentToRemarks.get(idxMax) ?? []
        if (!prompts.includes(v.remark)) segmentToRemarks.set(idxMax, [...prompts, v.remark])
      }
    })

    const idxes = [...segmentToRemarks.keys()].sort((a: number, b: number) => a - b)
    const remarkOptions: RemarkOption[] = idxes.map((value: number) => {
      return {
        'remarks': segmentToRemarks.get(value) ?? [],
        'transcriptIdx': value
      }
    })

    return remarkOptions
  }

   _computeTriggerFilters(data: SessionDataResult, turns: SpeechTurn[]): ReviewOption[] { 
    const sequences = turns.map((value: SpeechTurn, idx: number) => [value, idx])

    const prospectSequences = sequences.filter(([a, b]) => (a as SpeechTurn).role === 'PROSPECT')
    const startTimesProspect = prospectSequences.map(([a, b]) => (a as SpeechTurn).start*SEC_TO_MS)

    const getClosestSequence = (value: Display, onProspect?: boolean): undefined | number => {
      // find nearest transcript
      const minAudioStart = data.session.scheduled_start
      const timeInCall = (value.start.getTime() - minAudioStart.getTime()) // to find the corresponding time (approx a 1 second deduction from display compute to showing it)
      const closestIdx = this._findClosestIndex(startTimesProspect, timeInCall)
      if (closestIdx === -1) return undefined
      // need to refresh
      return prospectSequences[closestIdx][1] as number
  }

    const displays = data.displays.filter((value) => [PromptType.TRIGGER].includes(value.prompt_type)).sort((a: Display, b: Display) => a.start.getTime() - b.start.getTime())
    const segmentToPromptTypes: Map<number, string[]> = new Map()
    for (const display of displays) {
      const label = (display.prompt_text ?? '').split('\n')
      if (label.length === 0 || label[0].trim() === '') continue
      const prompt = label[0]
      const getClosest = getClosestSequence(display)
      if (getClosest !== undefined) {
        const prompts = segmentToPromptTypes.get(getClosest) ?? []
        if (!prompts.includes(prompt)) segmentToPromptTypes.set(getClosest, [...prompts, prompt])
      }
    }

    const idxes = [...segmentToPromptTypes.keys()].sort((a: number, b: number) => a - b)
    const filterOptions: ReviewOption[] = idxes.map((value: number) => {
      return {
        'displayText': segmentToPromptTypes.get(value)?.join(', ') ?? '',
        'transcriptIdx': value
      }
    })

    return filterOptions
  }

  _computeCommentFilters(data: SessionDataResult, turns: SpeechTurn[]): CommentDataResult[] { 
    const segmentToComments: Map<number, CommentDataResult[]> = new Map()
    for (const comment of data.comments) {
      let idx = -1
      if (comment.start != null && comment.end != null) {
        const start = parseFloat((comment.start*MS_TO_SEC).toFixed(2))
        const end = parseFloat((comment.end*MS_TO_SEC).toFixed(2))
        idx = turns.findIndex((value) => value.start && value.end && start >= parseFloat(value.start.toFixed(2)) && end <= parseFloat(value.end.toFixed(2)))
      }
      const comments = segmentToComments.get(idx) ?? []
      segmentToComments.set(idx, [...comments, comment])
    }

    const idxes = [...segmentToComments.keys()].sort((a: number, b: number) => a - b)
    let commentOptions: CommentDataResult[] = []
    for (const idx of idxes) {
      const comment = segmentToComments.get(idx) ?? []
      const comments: CommentDataResult[] = comment.sort((a: CommentDataResult, b: CommentDataResult) => a.createdAt.getTime() - b.createdAt.getTime()).map((value: CommentDataResult) => {
        return {
          ...value,
          'transcriptIdx': idx
        }
      })
      commentOptions = commentOptions.concat(comments)
    }

    return commentOptions
  }


  _computeBehavioralFilters(data: SessionDataResult, turns: SpeechTurn[]): PromptTypeOption[] { 
    const repSequences = turns.map((value: SpeechTurn, idx: number) => [value, idx]).filter(([a, b]) => (a as SpeechTurn).role === 'REP')
    const startTimes = repSequences.map(([a, b]) => (a as SpeechTurn).start*SEC_TO_MS)
    const endTimes = repSequences.map(([a, b]) => (a as SpeechTurn).end*SEC_TO_MS)

    const getClosestSequence = (value: Display): undefined | number => {
      // find nearest transcript
      const minAudioStart = data.session.scheduled_start
      let timeInCall = (value.start.getTime() - minAudioStart.getTime() - (value.prompt_type  === PromptType.MONOLOG ? 4000 : 2000)) // to find the corresponding time (approx a 1 second deduction from display compute to showing it)
      const closestIdx = this._findClosestIndex(startTimes, timeInCall)
      // need to refresh
      if (closestIdx === -1) return undefined
      timeInCall = value.prompt_type == PromptType.MONOLOG ? startTimes[closestIdx] : timeInCall

      const endTime = endTimes[closestIdx]
      if (timeInCall > endTime) return undefined
      return repSequences[closestIdx][1] as number
  }

    const displays = data.displays.filter((value) => [PromptType.CADENCE_FAST, PromptType.MONOLOG].includes(value.prompt_type)).sort((a: Display, b: Display) => a.start.getTime() - b.start.getTime()).sort((a: Display, b:Display) => a.prompt_type.localeCompare(b.prompt_type))
    const segmentToPromptTypes: Map<number, PromptType[]> = new Map()
    for (const display of displays) {
      const getClosest = getClosestSequence(display)
      if (getClosest !== undefined) {
        const prompts = segmentToPromptTypes.get(getClosest) ?? []
        segmentToPromptTypes.set(getClosest, [...prompts, display.prompt_type])
      }
    }

    const idxes = [...segmentToPromptTypes.keys()].sort((a: number, b: number) => a - b)
    const filterOptions: PromptTypeOption[] = idxes.map((value: number) => {
      const prompts: PromptType[] = []
      const displays = segmentToPromptTypes.get(value) ?? []
      let displayText = ''
      if (displays.includes(PromptType.CADENCE_FAST) && displays.includes(PromptType.MONOLOG)) {
        prompts.push(PromptType.MONOLOG, PromptType.CADENCE_FAST)
        displayText = 'Monologue + Fast Speech'
      } else if (displays.includes(PromptType.CADENCE_FAST)) {
        prompts.push(PromptType.CADENCE_FAST)
        displayText = 'Fast Speech'
      } else {
        prompts.push(PromptType.MONOLOG)
        displayText = 'Monologue'
      }

      return {
        'displayText': displayText,
        'transcriptIdx': value,
        'prompts': prompts
      }
    })
    return filterOptions
  }

  componentWillUnmount(): void {
    if (this._saveTimer) clearInterval(this._saveTimer)
    this._saveTimer = undefined
    this._savePeriodically(true)
  }

  async _saveComments(sessionId: string, commentsToUpdate: CommentParams[]): Promise<(CommentDataResult | null)[] | null> {
    const result = await getServicesManager().updateComments(sessionId, commentsToUpdate)
    if (result !== null && (!this.props.value[sessionId] || !this.props.value[sessionId].reviewIsOpen) && (this.state.data?.session.user_id !== this.props.user?.user_id) && commentsToUpdate.find((value: CommentParams) => [UpdateType.MODIFY, UpdateType.ADD].includes(value.update))) {
      getServicesManager().updateSessionReview(sessionId, true).then((x) => {
        if (x !== null) {
          this.props.dispatch(updateSessionStatus({'sessionId': sessionId, 'reviewIsOpen': true, 'reviewOpenedByUser': true, 'star': this.state.data?.has_star}))
      }})
    }
    return result
  }

  _getCommentsToSave(lastSaved?: CommentDataResult[], currentState?: CommentDataResult[], transcripts?: Transcript[]): CommentParams[] {
    // new additions 
    const savedIds = lastSaved?.map((value) => value.commentId) ?? []
    const currentIds = currentState?.map((value) => value.commentId as string) ?? []

    // additions
    const additions: CommentParams[] = (currentState ?? []).filter((value) => value.comment.trim() !== '' && !savedIds.includes(value.commentId)).map((value: CommentDataResult) => {
      return {
        'comment': {
          'commentId': value.commentId,
          'comment': value.comment,
          'start': value.transcriptIdx !== undefined && value.transcriptIdx !== -1 && transcripts ? transcripts[value.transcriptIdx].start : undefined,
          'end': value.transcriptIdx !== undefined && value.transcriptIdx !== -1 && transcripts ? transcripts[value.transcriptIdx].end : undefined
        },
        'update': UpdateType.ADD
      }
    })

    const removals: CommentParams[] = (lastSaved ?? []).filter((value) => !currentIds.includes(value.commentId)).map((value: CommentDataResult) => {
      return {
        'comment': {
          'commentId': value.commentId,
          'comment': ''
        },
        'update': UpdateType.REMOVE
      }
    })

    const modifications: CommentParams[] = (currentState ?? []).filter((value) => {
      const matching = lastSaved?.find((v: CommentDataResult) => v.commentId === value.commentId)
      return matching && matching.comment !== value.comment
    }).map((value: CommentDataResult) => {
      return {
        'comment': {
          'commentId': value.commentId,
          'comment': value.comment,
          'start': value.transcriptIdx !== undefined && value.transcriptIdx !== -1 && transcripts ? transcripts[value.transcriptIdx].start : undefined,
          'end': value.transcriptIdx !== undefined && value.transcriptIdx !== -1 && transcripts ? transcripts[value.transcriptIdx].end : undefined
        },
        'update': UpdateType.MODIFY
      }
    })
    return [...additions, ...removals, ...modifications]
  }

  async _updateComments(stop?: boolean, co?: CommentDataResult[], ls?: CommentDataResult[], t?: Transcript[]): Promise<any> {
    if (!this.state.commentOptions || !this.props.sessionId) return null
    const lastSaved = ls ?? this.state.lastSavedComments
    const commentOptions = co ?? this.state.commentOptions
    const transcripts = t ?? this.state.transcripts
    const comments = this._getCommentsToSave(lastSaved, commentOptions, transcripts)
    const result = await this._saveComments(this.props.sessionId, comments)
    if (result == null || stop) return 
    
    this.setState((state) => {
      if (!state.commentOptions || !state.lastSavedComments) return null
      // need to add new entries
      const removedEntries = comments.filter((value: CommentParams) => value.update === UpdateType.REMOVE).map((value: CommentParams) => value.comment.commentId)
      const modifiedEntries= Object.fromEntries(comments.map((value: CommentParams, idx: number) => {return {'value': value, 'idx': idx}}).filter((value) => value.value.update === UpdateType.MODIFY).map((v) => {
        let matchingComment = (state.lastSavedComments ?? []).find((value: CommentDataResult) => value.commentId === v.value.comment.commentId)
        if (matchingComment) matchingComment = {...matchingComment, 'comment': v.value.comment.comment}
        return [v.value.comment.commentId, matchingComment]
      }))
      // need to remove removed entries (anythign null) and update modified entries
      let savedEntries: CommentDataResult[] = state.lastSavedComments.filter((value: CommentDataResult) => !removedEntries.includes(value.commentId)).map((value: CommentDataResult) => {
        const modifiedValue = modifiedEntries[value.commentId]
        if (modifiedValue) return modifiedValue
        else return value
      })
      const addEntries: CommentDataResult[] = comments.map((value: CommentParams, idx: number) => {return {'value': value, 'idx': idx}}).filter((value) => value.value.update === UpdateType.ADD).map((value) => result[value.idx] as CommentDataResult)
      savedEntries = savedEntries.concat(addEntries)

      const additions = Object.fromEntries(comments.map((value: CommentParams, idx: number) => {return {'value': value, 'idx': idx}}).filter((value) => value.value.update === UpdateType.ADD).map((value) => [value.value.comment.commentId, value.idx]))
      
      if (Object.keys(additions).length > 0) {
        const toUpdateCurrent = state.commentOptions.map((value: CommentDataResult) => {
          if (value.commentId in additions && result) {
            return {...value, 'commentId': result[additions[value.commentId]]?.commentId ?? value.commentId}
          } else {
            return {...value}
          }
        })
        return {
          'lastSavedComments': savedEntries,
          'commentOptions': toUpdateCurrent
        }
      } else {
        return {
          'lastSavedComments': savedEntries,
          
        } 
      }
    })
  }

  async _saveUpdates(stop?: boolean, commentOptions?: CommentDataResult[], lastSaved?: CommentDataResult[], transcripts?: Transcript[]) {
    const hasPending = this._hasPendingState(commentOptions ?? this.state.commentOptions, lastSaved ?? this.state.lastSavedComments)
    if (hasPending) {
      await this._updateComments(stop, commentOptions, lastSaved, transcripts)
    }
  }

  async _savePeriodically(stop?: boolean,  commentOptions?: CommentDataResult[], lastSaved?: CommentDataResult[], transcripts?: Transcript[]) {
    await this._saveUpdates(stop, commentOptions, lastSaved, transcripts)
    if (!stop)  this._saveTimer = setTimeout(() => this._savePeriodically.bind(this)(), 5*SEC_TO_MS)
  }


  _hasPendingState(commentOptions?: CommentDataResult[], lastSavedComments?: CommentDataResult[]) {
    if (!commentOptions || !lastSavedComments) return false
    // has a difference in length. (deletion or addition)
    const nontrivial = commentOptions.filter((value: CommentDataResult) => value.comment.trim() !== '')
    if (nontrivial.length !== lastSavedComments.length) return true
    // has a modified text content
    const lastSaved = lastSavedComments.map((value: CommentDataResult) => value.comment)
    lastSaved.sort()
    const currentComments = nontrivial.map((value: CommentDataResult) => value.comment)
    currentComments.sort()
    return JSON.stringify(lastSaved) !== JSON.stringify(currentComments)
  }


  _removeComment(uniqueIdentifier: string) {
    this.setState((state) => {
      if (!state.commentOptions) return null
      const idx = state.commentOptions.findIndex((value: CommentDataResult) => value.commentId === uniqueIdentifier)
      if (idx === -1) return null
      return {'commentOptions': [...state.commentOptions.slice(0, idx),...state.commentOptions.slice(idx + 1)]}
    })
  }

  _updateComment(uniqueIdentifier: string, updatedText: string) {
    this.setState((state) => {
      if (!state.commentOptions) return null
      const idx = state.commentOptions.findIndex((value: CommentDataResult) => value.commentId === uniqueIdentifier)
      if (idx === -1) return null
      return {'commentOptions': 
      [...state.commentOptions.slice(0, idx),  {...state.commentOptions[idx], 'comment': updatedText},...state.commentOptions.slice(idx + 1)]
    }
    })
  }

_addComment(selectedTranscriptIdx?: number) {
    this.setState((state) => {
      let idx = (this.state.commentOptions ?? []).findIndex((value: CommentDataResult) => (value.transcriptIdx ?? -1) > (selectedTranscriptIdx ?? -1)) 
      if (idx == -1) idx = (this.state.commentOptions ?? []).length
      return {
        'commentOptions': [...(state.commentOptions ?? []).slice(0, idx), {
          'commentId': uuid(),
          'comment': '',
          'sessionId': this.props.sessionId ?? '',
          'userId': this.props.viewerUserId ?? '',
          'createdAt': new Date(),
          'userName': 'You',
          'start': selectedTranscriptIdx !== undefined && selectedTranscriptIdx !== -1 && this.state.transcripts ? this.state.transcripts[selectedTranscriptIdx].start ?? null : null,
          'end': selectedTranscriptIdx !== undefined && selectedTranscriptIdx !== -1 && this.state.transcripts ? this.state.transcripts[selectedTranscriptIdx].end ?? null : null,
          'transcriptIdx': selectedTranscriptIdx ?? -1, 
          }, ...(state.commentOptions ?? []).slice(idx)],
        'viewMode': TranscriptReviewMode.COMMENTS,
      }
    })
  } 

  _computeCounterpartFilters(data: SessionDataResult): CounterpartInterval[] { 
    let counterpartyFilters: CounterpartInterval[] =  data.counterparts.map((value, idx) => {
      return { 
        'start': value.start*SEC_TO_MS,
        'end': value.end*SEC_TO_MS,
        'counterpart': value.counterpart,
      }
    })
    counterpartyFilters = counterpartyFilters.sort((a: CounterpartInterval, b: CounterpartInterval) => a.start - b.start)
    return counterpartyFilters
  }

  _copyTranscriptOnClick(): void {
    const transcripts = this.state.transcripts ?? []
    const content = transcripts.map((v) => (v.partyCode === prospectPartyCode ? 'Prospect: ' : 'You: ') + v.content).join('\n')
    window.navigator.clipboard.writeText(content)
    .then(() => {if (this.props.triggerAlert) this.props.triggerAlert('Copied transcript to clipboard!', 3000, true)})
    .catch(() => {if (this.props.triggerAlert) this.props.triggerAlert('Could not copy transcript to clipboard', 3000, false)})
  }
  
  _renderEmbeddedView(): JSX.Element {
    return <TranscriptBody 
    pageClosed={this.props.pageClosed} 
    noLoaderEvenOnActive={false} 
    copyTranscriptOnClick={this._copyTranscriptOnClick.bind(this)} 
    audioDetails={{
      'sessionId': this.props.sessionId, 
      'isInView': this.props.isInView, 
      'data': this.state.data}} 
      reviewMode={true} 
      sessionActive={false} 
      autoplay={false} 
      showLoaderAsDefault={true} 
      transcripts={this.state.transcripts} 
      isPureTranscriptView={true}
      />
  }

  _renderCoachingView(): JSX.Element { 
    return <TranscriptWidget
    filter={this.props.filter}
    pageClosed={this.props.pageClosed} 
    noLoaderEvenOnActive={false} 
    copyTranscriptOnClick={this._copyTranscriptOnClick.bind(this)} 
    customDefinitions={[]}
    audioDetails={{
      'sessionId': this.props.sessionId, 
      'isInView': this.props.isInView, 
      'data': this.state.data}} 
      reviewMode={true} 
      customLink={"https://" + window.location.hostname + "/transcripts?id=" + this.props.sessionId} 
      heightFull={true} 
      sessionActive={false} 
      autoplay={false} 
      showLoaderAsDefault={true} 
      transcripts={this.state.transcripts} 
      user={this.props.user}
      isAdmin={this.props.isAdmin}
      />
  }

  _renderMainView(): JSX.Element {
    return (
      <div className="flex-grow">
    <PromptOnLeave when={this._hasPendingState(this.state.commentOptions, this.state.lastSavedComments)} message={'You have unsaved changes. Sure you want to leave'}/>
    <TranscriptBody 
    customMetric={this.state.data?.customMetric ?? undefined}
    customDetail={this.state.data?.customDetail ?? undefined}
    prospectInfo={this.state.data?.prospect_info ?? undefined}
    pageClosed={this.props.pageClosed}
    copyTranscriptOnClick={this._copyTranscriptOnClick.bind(this)}
    noLoaderEvenOnActive={false}
    minimalView={this.props.minimalView}
    selectedTranscriptIdx={this.state.selectedTranscriptIdx} 
    updateSelectedTranscript={(selectedIdx: number | undefined, callback?: ()=> void) => {this.setState({'selectedTranscriptIdx': selectedIdx}, callback)}}
    remarkFilters={this.state.remarkOptions}
    triggerFilters={this.state.triggerOptions} 
    counterpartyFilters={this.state.counterpartyOptions}
    behavioralFilters={this.state.behavioralOptions} isMainView={true} 
    audioDetails={{'sessionId': this.props.sessionId, 'isInView': true, 'data': this.state.data}} 
    reviewMode={true} sessionActive={false} 
    autoplay={false} 
    showLoaderAsDefault={true} 
    transcripts={this.state.transcripts} 
    viewMode={this.state.viewMode}
    viewerUserId={this.props.viewerUserId}
    updateViewMode={(selectedViewMode: TranscriptReviewMode) => this.setState((state) => { 
      const viewMode = state.viewMode === selectedViewMode ? undefined : selectedViewMode
      if (viewMode === undefined) return {'viewMode': viewMode, 'selectedTranscriptIdx': undefined}
      else return {'viewMode': viewMode}
    })}
    commentFilters={this.state.commentOptions}
    commentFns={{'addEntry': this._addComment.bind(this), 'removeEntry': this._removeComment.bind(this), 'updateEntry': this._updateComment.bind(this)}}
    />
    </div>
    )
  }

  _renderDefault(): JSX.Element {
    return (
      <div className='w-full justify-center items-center flex' style={{'height': '100%'}}>
      <div className='w-2/3 flex-col bg-white h-44 justify-evenly rounded-md flex items-center' style={{'border': '0.5px solid #03214e'}}>
        <Typography variant='caption' style={{'textAlign': 'center'}}>
        Select a transcript
        </Typography>
      </div>
    </div>
    )
  }

  render(): JSX.Element {
    if (!this.props.sessionId) return this._renderDefault()
    return (
    <div className='flex flex-column overflow-y-auto w-full h-full' style={{'userSelect': 'text', 'backgroundColor': 'white', 'borderRadius': this.props.embeddedView ? undefined : '5px'}}>
      {this.props.embeddedView ? this._renderEmbeddedView() : this.props.coachingView ? this._renderCoachingView() : this._renderMainView()}
    </div>  
    )
  }
}


const mapStateToProps = (state: RootState) => {
  return {
    filter: convertFilterFromReduxSafe(state.filter.value),
    value: state.counter.value,
    populationFilters: state.sessionList.value,
    visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts),
    user: convertFromReduxSafeUserState(state.user),
    isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : null,
  };
};

export default connect(mapStateToProps)(SessionDetails)