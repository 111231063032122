import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Snippet {
  id: string;
  name: string;
  text: string;
}

interface SnippetsState {
  snippets: Snippet[];
}

const defaultSnippets: Snippet[] = [
  {
    id: 'greeting',
    name: 'Greeting',
    text: 'Hi {{ name }}, hope you\'re having a great day!'
  },
  {
    id: 'follow-up',
    name: 'Follow Up',
    text: 'Hey {{ name }}, just following up on our previous conversation. Any thoughts?'
  },
  {
    id: 'thanks',
    name: 'Thank You',
    text: 'Thanks {{ name }}, I really appreciate your time!'
  },
  {
    id: 'connect',
    name: 'Connection',
    text: 'Hi {{ name }}, great connecting with you! Looking forward to staying in touch.'
  }
];

const initialState: SnippetsState = {
  snippets: defaultSnippets
};

export const snippetsSlice = createSlice({
  name: 'snippets',
  initialState,
  reducers: {
    addSnippet: (state, action: PayloadAction<Snippet>) => {
      state.snippets.push(action.payload);
    },
    updateSnippet: (state, action: PayloadAction<Snippet>) => {
      const index = state.snippets.findIndex(snippet => snippet.id === action.payload.id);
      if (index !== -1) {
        state.snippets[index] = action.payload;
      }
    },
    deleteSnippet: (state, action: PayloadAction<string>) => {
      state.snippets = state.snippets.filter(snippet => snippet.id !== action.payload);
    },
    resetToDefault: (state) => {
      state.snippets = defaultSnippets;
    }
  }
});

export const { addSnippet, updateSnippet, deleteSnippet, resetToDefault } = snippetsSlice.actions;

// Helper function to process snippet text with variables
export const processSnippetText = (text: string, firstName: string): string => {
  return text.replace(/\{\{\s*name\s*\}\}/g, firstName);
};
