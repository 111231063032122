import React, { useRef, useState } from 'react';
import { Typography } from 'interfaces/typography';

interface DurationInputProps {
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
  placeholder?: string;
}

export const DurationInput: React.FC<DurationInputProps> = ({
  label,
  value,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);

  const minute = value !== null ? Math.floor(value / 60) : null;
  const seconds = value !== null ? value % 60 : null;

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const min = Math.min(Math.max(0, parseInt(e.target.value) || 0), 59);
    onChange((min * 60) + (seconds ?? 0));
  };

  const handleSecondsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sec = Math.min(Math.max(0, parseInt(e.target.value) || 0), 59);
    onChange(((minute ?? 0) * 60) + sec);
  };

  return (
    <div className="flex gap-2 w-full items-center justify-evenly">
      <label className="text-sm font-medium text-gray-700 w-10">
        {label}
      </label>
        <div className="flex items-center min-w-[120px] gap-1">
        <input
          type="number"
          min="0"
          max="59"
          value={minute ?? "-"}
          onChange={handleMinutesChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className="w-12 text-center bg-transparent focus:outline-none"
          placeholder="-"
        />
        <span className="text-gray-500">:</span>
        <input
          type="number"
          min="0"
          max="59"
          value={seconds ? seconds.toString().padStart(2, '0') : "-"}
          onChange={handleSecondsChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className="w-12 text-center bg-transparent focus:outline-none"
          placeholder="-"
        />
        <label className="text-xs font-medium text-gray-500 w-10">
          {"min"}
          </label>
        </div>
    </div>
  );
};