import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinkedInChat, LinkedInSalesNavigatorChat } from '../../../linkedin';

// LinkedIn Client Slice
export interface LinkedInClientState {
    client: LinkedInChat | null;
    hasInitializedLinkedInClient: boolean;
    salesNavigatorClient: LinkedInSalesNavigatorChat | null;
    hasInitializedSalesNavigatorClient: boolean;
  }
  
  const clientInitialState: LinkedInClientState = {
    client: null,
    hasInitializedLinkedInClient: false,
    salesNavigatorClient: null,
    hasInitializedSalesNavigatorClient: false,
  };
  
  export const linkedInClientsSlice = createSlice({
    name: 'linkedinClients',
    initialState: clientInitialState,
    reducers: {
      setLinkedInClient: (state, action: PayloadAction<LinkedInChat | null>) => {
        state.client = action.payload;
      },
      setSalesNavigatorClient: (state, action: PayloadAction<LinkedInSalesNavigatorChat | null>) => {
        state.salesNavigatorClient = action.payload;
      },
      setHasInitializedLinkedInClient: (state, action: PayloadAction<boolean>) => {
        state.hasInitializedLinkedInClient = action.payload;
      },
      setHasInitializedSalesNavigatorClient: (state, action: PayloadAction<boolean>) => {
        state.hasInitializedSalesNavigatorClient = action.payload;
      }
    }
  });
  
  export const { setLinkedInClient, setSalesNavigatorClient, setHasInitializedLinkedInClient, setHasInitializedSalesNavigatorClient } = linkedInClientsSlice.actions;