import React from 'react';

interface BooleanFilterProps {
  value: boolean | null;
  onValueChange: (value: boolean | null) => void;
}

export const BooleanFilter: React.FC<BooleanFilterProps> = ({ onValueChange, value }) => {
  const getButtonStyle = (buttonValue: boolean | null) => `
    flex-1 px-3 py-2 rounded-md transition-colors ${
    value === buttonValue
      ? `bg-${buttonValue === true ? 'green' : buttonValue === false ? 'red' : 'blue'}-100 
         text-${buttonValue === true ? 'green' : buttonValue === false ? 'red' : 'blue'}-700 
         border-2 border-${buttonValue === true ? 'green' : buttonValue === false ? 'red' : 'blue'}-200`
      : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200'
  }`;

  return (
    <div className="flex gap-2">
      <button onClick={() => onValueChange(true)} className={getButtonStyle(true)}>Yes</button>
      <button onClick={() => onValueChange(false)} className={getButtonStyle(false)}>No</button>
      <button onClick={() => onValueChange(null)} className={getButtonStyle(null)}>All</button>
    </div>
  );
};