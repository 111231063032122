import { ATTYX_TEAM_ID } from "../../cfg/const"

export enum OutputType {
    PERCENTAGE = 'PERCENTAGE',
    BOOLEAN = 'BOOLEAN',
    ENUM = 'ENUM',
}

export type CustomCode = {
  name: string
  output_type: OutputType
  enum_options: string[] | null
}

export const ATTYX_CODES: CustomCode[] = [
    {
        name: 'Score',
        output_type: OutputType.PERCENTAGE,
        enum_options: null
    },
    {
        name: 'Stage',
        output_type: OutputType.ENUM,
        enum_options: ['Beginning', 'Middle', 'End']
    },
    {
        name: 'Service',
        output_type: OutputType.ENUM,
        enum_options: ['Roofing', 'HVAC', 'Both']
    },
    {
        name: 'Channel',
        output_type: OutputType.ENUM,
        enum_options: ['Outbound', 'Inbound', 'Follow up']
    },
    ...['Has Zip', 'Has Pain When Where', 
        'Has Pain Reason', 'Has IRA', 'Has Replacement', 
        'Has Current Bill', 'Has No Cost', 'Has Credit Score', 
        'Has Homeowner', 'Has Employment', 'Has Address', 
        'Has Date Time', 'Has Next Steps'].map((name) => ({
        name,
        output_type: OutputType.BOOLEAN,
        enum_options: null
    }))
]

export function getCustomCode(team_id: string): CustomCode[] | undefined {
    if (team_id === ATTYX_TEAM_ID) return ATTYX_CODES
    return []
}