import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationId, DerivedInboxType, InboxType, SOURCE_TYPE } from '../types';

interface SelectedConversationState {
  conversationId: ConversationId | null;
  derivedInboxType: DerivedInboxType;
  selectedTags: string[];
  inboxType: InboxType;
  sourceType: SOURCE_TYPE[]
  expandMode: boolean;
  newMessages: string[];
  activeOption: string | null;
  selectedParticipant: string | null;
  profilePanelOpen: boolean;
}

export enum QUERY_PARAMS {
  CONVERSATION_ID = 'conversationId',
  CONVERSATION_SOURCE = 'conversationSource',
  INBOX_TYPE = 'inboxType',
}

export const getConversationIdFromUrl = () => {   
  const urlParams = new URLSearchParams(window.location.search);
  const conversationId = urlParams.get(QUERY_PARAMS.CONVERSATION_ID);
  const conversationSource = urlParams.get(QUERY_PARAMS.CONVERSATION_SOURCE);
  return conversationId && conversationSource ? { id: conversationId, sourceType: conversationSource as SOURCE_TYPE, lastActivity: null } : null;
}

export const getDerivedInboxTypeFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const inboxType = urlParams.get(QUERY_PARAMS.INBOX_TYPE);
  return inboxType ? inboxType as DerivedInboxType : DerivedInboxType.ALL;
}

const initialState: SelectedConversationState = {
  conversationId: getConversationIdFromUrl(),
  derivedInboxType: getDerivedInboxTypeFromUrl(),
  inboxType: InboxType.ALL,
  sourceType: [],
  selectedParticipant: null,
  expandMode: false,
  newMessages: [],
  selectedTags: [],
  activeOption: getConversationIdFromUrl()?.id || null,
  profilePanelOpen: false
};

export const selectedConversationSlice = createSlice({
  name: 'selectedConversation',
  initialState,
  reducers: {
    setSelectedConversation: (state, action: PayloadAction<ConversationId | null>) => {
      state.activeOption = action.payload?.id || null;
      state.conversationId = action.payload;
    },
    clearSelectedConversation: (state) => {
      if (state.activeOption && state.newMessages.length && !state.newMessages.includes(state.activeOption)) {
        state.activeOption = state.newMessages[0];
      }
      state.conversationId = null;
    },
    setDerivedInboxType: (state, action: PayloadAction<DerivedInboxType>) => {
      state.derivedInboxType = action.payload;
    },
    setInboxType: (state, action: PayloadAction<InboxType>) => {
      state.inboxType = action.payload;
    },
    setSourceType: (state, action: PayloadAction<SOURCE_TYPE[]>) => {
      state.sourceType = action.payload;
    },
    clearSourceType: (state) => {
      state.sourceType = [];
    },
    setExpandMode: (state, action: PayloadAction<boolean>) => {
      state.expandMode = action.payload;
    },
    addNewMessage: (state, action: PayloadAction<string>) => {
      state.newMessages.unshift(action.payload);
      state.activeOption = action.payload;
    },
    updateActiveOption: (state, action: PayloadAction<string>) => {
      state.activeOption = action.payload;
    },
    closeNewMessage: (state, action: PayloadAction<string>) => {
      state.newMessages = state.newMessages.filter(messageId => messageId !== action.payload);
      if (state.activeOption && state.activeOption === action.payload) {
        if (state.conversationId) {
          state.activeOption = state.conversationId.id;
        } else if (state.newMessages.length) {
          state.activeOption = state.newMessages[0];
        } else {
          state.activeOption = null;
        }
      }
    },
    setSelectedTags: (state, action: PayloadAction<string[]>) => {
      state.selectedTags = action.payload;
    },
    clearSelectedTags: (state) => {
      state.selectedTags = [];
    },
    setProfilePanelOpen: (state, action: PayloadAction<boolean>) => {
      state.profilePanelOpen = action.payload;
    },
    setSelectedParticipant: (state, action: PayloadAction<string>) => {
      state.activeOption = action.payload;
      state.selectedParticipant = action.payload;
    },
    clearSelectedParticipant: (state) => {
      if (state.activeOption === state.selectedParticipant) {
        if (state.conversationId) {
          state.activeOption = state.conversationId.id;
        } else if (state.newMessages.length) {
          state.activeOption = state.newMessages[0];
        } else {
          state.activeOption = null;
        }
      }
      state.selectedParticipant = null;
    },
    updateState: (state, action: PayloadAction<Partial<SelectedConversationState>>) => {
      Object.assign(state, action.payload);
    }
  },
});

export const { 
  setSelectedConversation, 
  clearSelectedConversation, 
  setDerivedInboxType, 
  setInboxType, 
  setSourceType, 
  clearSourceType, 
  setExpandMode,
  addNewMessage,
  closeNewMessage,
  updateActiveOption,
  setSelectedTags,
  clearSelectedTags,
  setProfilePanelOpen,
  setSelectedParticipant,
  clearSelectedParticipant,
  updateState
} = selectedConversationSlice.actions;
