import { useState, useEffect, useRef } from 'react'
import { ChevronDown, ChevronLeft, Calendar, Users, ListFilter, Briefcase, 
  UserCircle, Star, Filter, Clock, TimerIcon, Building2, Layers, 
  Target, ListChecks, BookMarked, MapPin, UserCheck, ThumbsUp, MessageCircle, Flag, ScrollText, PhoneCall } from 'lucide-react'
import { connect } from 'react-redux'
import { RootState } from '../../store'
import { CnfFilterType, NamedPersonTable, ProspectInfoChoices, UserDataResult, UserSessionsResult } from '../../interfaces/services'
import { CalendarComponent } from "../../components/Calendar";
import { SimplifiedMultiSelect } from '../../components/Selectors/SimplifiedMultiSelect'
import { ComparisonDateOptions, DateOptions, REVIEW_OPTIONS, 
  STARRED_OPTIONS, SpecialFilter, StaticFilter, StaticFilterKeys, 
  StaticFilterKeysProspectInfo, TimeZoneOptions, UserGroupInfo, 
  convertFilterFromReduxSafe, convertFilterToReduxSafe, convertFromReduxSafeUserState, 
  getDefaultStaticFilter, getProspectInfoOptions, roundDateDown, 
  roundDateUp, updateFilter, updateSafeFilter } from '../../lib/redux/store'
import { Dispatch, Action } from "@reduxjs/toolkit";
import { COUNTERPART_TO_HUMAN_READABLE, Counterpart, CustomMetricSource, CustomMetricValueType, OBJECTIONS_TO_HUMAN_READABLE, OBJECTION_REMARKS_ALLOWED, PROSPECT_INFO_COLUMNS, SESSION_METRICS_COLUMNS, SESSION_METRICS_COLUMNS_TO_HUMAN_READABLE, STAGE, StageToHumanReadable } from '../../interfaces/db'
import { SimplifiedSingleSelect } from '../../components/Selectors/SimplifiedSingleSelect'
import { SEC_TO_MS } from '../../cfg/const'
import { getServicesManager } from '../../services'
import { Button } from '@mui/material'
import { DurationFilter } from './DurationFilter'
import { CustomDefinition } from '../../interfaces/db'
import { CustomMetricValue, EqualityFn, FloatOption, StaticCustomMetricValue } from '../../cfg/column'
import { ValueType } from "../../lib/redux/store"
import { BooleanFilter } from './BooleanFilter'
import { FloatFilter } from './FloatFilter'
import { Option } from '../../components/Selectors/cfg'
import React from 'react'
import { MultiUserSelector } from '../Selectors/MulltiUserSelector'
import { getCustomCode, OutputType } from '../CustomScores/cfg'

interface FilterProps {
  isOpen: boolean
  onClose: () => void
  prospectInfoOptions: ProspectInfoChoices | null
  dispositionInfoOptions: ProspectInfoChoices | null
  applicableSpecialFilters?: StaticFilterKeys[]
  hiddenFilters?: string[]
  customDefinitions: CustomDefinition[] | null
  sortedUserGroupInfo: UserGroupInfo[]

  filter: StaticFilter
  user: UserDataResult | null
  dispatch: Dispatch<Action>
}

function makeProperNoun(str: string): string {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`
}

function StaticFilterImpl({ 
  isOpen, onClose, prospectInfoOptions, 
  user,
  customDefinitions,
  dispositionInfoOptions, filter,
  sortedUserGroupInfo,
  dispatch,
  applicableSpecialFilters = [],
}: FilterProps) {
  const [expandedSections, setExpandedSections] = useState<{[k in StaticFilterKeys]: boolean}>({
    [StaticFilterKeys.DATE_RANGE]: true,
    [StaticFilterKeys.START_DATE]: false,
    [StaticFilterKeys.END_DATE]: false,
    [StaticFilterKeys.SELECTED_REPS]: true,
    [StaticFilterKeys.USER_DISPOSITION]: false,
    [StaticFilterKeys.USER_PURPOSE]: false,
    [StaticFilterKeys.USER_SENTIMENT]: false,
    [StaticFilterKeys.COMPARISON_DATE_RANGE]: true,
    [StaticFilterKeys.COMPARISON_START_DATE]: false,
    [StaticFilterKeys.COMPARISON_END_DATE]: false,
    [StaticFilterKeys.COMPARISON_GROUP]: true,
    [StaticFilterKeys.TIMEZONE]: true,
    [StaticFilterKeys.IS_STARRED]: false,
    [StaticFilterKeys.IS_REVIEWED]: false,
    [StaticFilterKeys.PROSPECT_PHONE_OR_NAME]: true,
    [StaticFilterKeys.OBJECTIONS]: false,
    [StaticFilterKeys.STAGES]: false,
    [StaticFilterKeys.CNF_PASS_THROUGH]: false,
    [StaticFilterKeys.DURATION_FILTER]: false,
    [StaticFilterKeys.CADENCES]: false,
    [StaticFilterKeys.CADENCE_STEPS]: false,
    [StaticFilterKeys.INDUSTRIES]: false,
    [StaticFilterKeys.TITLES]: false,
    [StaticFilterKeys.SENIORITIES]: false,
    [StaticFilterKeys.PROSPECT_LIST]: false,
    [StaticFilterKeys.MARKET]: false,
    [StaticFilterKeys.LEAD_TYPE]: false,
    [StaticFilterKeys.COUNTERPART]: false,
    [StaticFilterKeys.CUSTOM_METRIC]: false,
    [StaticFilterKeys.CALLBACK_LIST]: false,
  })
  
  const [expandedMetricSections, setExpandedMetricSections] = useState<{[k: string]: boolean}>({})
  const [isVisible, setIsVisible] = useState(isOpen)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [numberSearch, setNumberSearch] = useState<string | null>(null)
  const [debouncedNumberSearch, setDebouncedNumberSearch] = useState<string | null>(null)
  const debounceTimeoout = useRef<NodeJS.Timeout | null>(null)
  const [numberTermSearch, setResults] = useState<{[k: string]: UserSessionsResult}>({})
  const [nameTermSearch, setNameResults] = useState<{[k: string]: NamedPersonTable}>({})

  const updateNumberSearch = (search: string) => {
    setNumberSearch(search)
    if (debounceTimeoout.current) clearTimeout(debounceTimeoout.current)
    debounceTimeoout.current = setTimeout(() => setDebouncedNumberSearch(search), 0.5*SEC_TO_MS)
  }

  const _cleanNumber = (temp: string): string => temp.replace(/\D/g, "")
  const _cleanName = (temp: string): string => temp.replace(/[0-9]/g, '')

  const _getPhoneData = async (currentSearchNumber: string) => {
    const value = await getServicesManager().getListUserSessionsV3({
      'cnf': [{'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': [{'prospect_phone_value': _cleanNumber(currentSearchNumber)}]}],
      'start': null,
      'end': null,
    })
    setResults((prev) => ({...prev, [_cleanNumber(currentSearchNumber)]: (value ?? [])}))
  }

  const _getNamedData = async (currentSearchName: string) => {
    const value = await getServicesManager().getNamedPerson(currentSearchName) ?? []
    const result = value.filter((v) => v.prospect_phone_value)
    setNameResults((prev) => ({...prev, [currentSearchName]: result}))
  }

  useEffect(() => {
    if (!debouncedNumberSearch) return
    if (debouncedNumberSearch !== numberSearch) return
    // do the search
    if (_cleanNumber(debouncedNumberSearch).trim().length >= 10) _getPhoneData(debouncedNumberSearch)
    else if (_cleanName(debouncedNumberSearch).trim() !== '') _getNamedData(debouncedNumberSearch)
  }, [debouncedNumberSearch])

  // check for partial matches (?)
  const nameSearchKeys = Object.keys(nameTermSearch)
  const partialNameSearchMatches = numberSearch && numberSearch.length > 0 ? nameSearchKeys.filter((v) => v.includes(numberSearch)) : []
  const numberSearchKeys = Object.keys(numberTermSearch)
  const partialNumberSearchMatches = numberSearch && numberSearch.length > 0 ? numberSearchKeys.filter((v) => v.includes(numberSearch)) : []

  // construct the set of options
  const phoneOptions: {label: string, value: string, noFilterOut?: boolean}[] = []
  const selectedOptions = filter[StaticFilterKeys.PROSPECT_PHONE_OR_NAME] ?? []
  if (selectedOptions.length > 0) {
    phoneOptions.push(...selectedOptions.map((v) => { return { 'label': v, 'value': v, } }))
  }
  else if (selectedOptions.length === 0 && partialNameSearchMatches.length === 0 && partialNumberSearchMatches.length === 0) {
    // if it's all numbers but not long enough tell them they need to do that...
    if (!numberSearch || numberSearch.length === 0) phoneOptions.push({label: 'Type a # or name', value: '_SEARCH_', noFilterOut: true})
    else if (_cleanNumber(numberSearch).length > 0 && _cleanNumber(numberSearch).length < 10) phoneOptions.push({label: 'Enter a full phone #', value: '_SEARCH_', noFilterOut: true})
    else phoneOptions.push({label: 'Searching...', value: '_SEARCHING_', noFilterOut: true})
  }
  else if (partialNameSearchMatches.length > 0) {
    // add the partial matches
    const results = partialNameSearchMatches.map((v) => nameTermSearch[v]).flat().map((v) => { return { 'label': `${v.person_name} ${v.prospect_phone_value ? `(${v.prospect_phone_value})` : ''}`, 'value': v.prospect_phone_value ?? '', 'noFilterOut': true }})
    phoneOptions.push(...results)
  }
  else if (partialNumberSearchMatches.length > 0) {
    // add the partial matches
    const results = partialNumberSearchMatches.map((v) => numberTermSearch[v]).flat().map((v) => { return { 'label': `${v.prospect_name} (${v.prospect_phone_value})`, 'value': v.prospect_phone_value ?? '', 'noFilterOut': true }})
    phoneOptions.push(...results)
  }

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true)
      setIsTransitioning(true)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => setIsTransitioning(false), 300)
    } else {
      setIsTransitioning(true)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        setIsVisible(false)
        setIsTransitioning(false)
      }, 300)
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [isOpen])

  const toggleSection = (section: StaticFilterKeys) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const onChange = (newFilter: StaticFilter) => dispatch(updateSafeFilter(convertFilterToReduxSafe(newFilter)))
  const getMatchingSubgroup = (user_id: string) => sortedUserGroupInfo.find((g) => g.directUserIds.includes(user_id)) 
  useEffect(() => {
    // check the comparison gropu
    if (filter[StaticFilterKeys.COMPARISON_GROUP].some((v) => v.user_id)) return
    const hasOnlyRepsSelected = filter[StaticFilterKeys.SELECTED_REPS] && filter[StaticFilterKeys.SELECTED_REPS].length > 0 && filter[StaticFilterKeys.SELECTED_REPS].every((v) => v.user_id)
    if (!hasOnlyRepsSelected) {
      // probably want to set the comparison group to empty
      if (user) onChange({...filter, [StaticFilterKeys.COMPARISON_GROUP]: [{'team_id': user.team_id, 'user_id': null, 'user_group_id': null}]})
      else onChange({...filter, [StaticFilterKeys.COMPARISON_GROUP]: []})
      return
    }
    const matchingSubgroups = filter[StaticFilterKeys.SELECTED_REPS].map((v) => v.user_id ? getMatchingSubgroup(v.user_id) : null).filter((v) => v).map((v) => v as UserGroupInfo)
    const matchingSubgroupsUnique = Array.from(new Set(matchingSubgroups.map((v) => v.group.user_group_id)))
    onChange({...filter, [StaticFilterKeys.COMPARISON_GROUP]: matchingSubgroupsUnique.map((v) => ({user_id: null, user_group_id: v, team_id: null}))})
  }, [JSON.stringify(filter.selectedReps)])
  const getUserOptions = (key: StaticFilterKeys) => {
    switch (key) {
      case StaticFilterKeys.USER_DISPOSITION: return (dispositionInfoOptions ?? []).find((info) => info.field_name === 'user_disposition')?.choices ?? []
      case StaticFilterKeys.USER_PURPOSE: return (dispositionInfoOptions ?? []).find((info) => info.field_name === 'user_purpose')?.choices ?? []
      case StaticFilterKeys.USER_SENTIMENT: return (dispositionInfoOptions ?? []).find((info) => info.field_name === 'user_sentiment')?.choices ?? []
      case StaticFilterKeys.IS_STARRED: return Object.values(STARRED_OPTIONS)
      case StaticFilterKeys.IS_REVIEWED: return Object.values(REVIEW_OPTIONS)
      default:
        if (StaticFilterKeysProspectInfo.has(key)) {
          return getProspectInfoOptions(prospectInfoOptions, key)
        }
        return []
      }
  }

  const getOptions = (key: StaticFilterKeys) => {
    switch (key) {
      case StaticFilterKeys.OBJECTIONS:
        return OBJECTION_REMARKS_ALLOWED.map((v) => { return { label: OBJECTIONS_TO_HUMAN_READABLE.get(v) ?? v, value: v }})
      case StaticFilterKeys.STAGES:
        return Object.values(STAGE).map((v) => { return { label: StageToHumanReadable[v], value: v }})
      case StaticFilterKeys.COUNTERPART:
        return [
          {label: 'Live Person', value: "live_person"},
          ...Object.values([Counterpart.GATEKEEPER, Counterpart.NOT_GATEKEEPER]).map((v) => { return { label: COUNTERPART_TO_HUMAN_READABLE[v] ?? v, value: v }})]
      case StaticFilterKeys.CALLBACK_LIST:
        return [
          {label: SESSION_METRICS_COLUMNS_TO_HUMAN_READABLE[SESSION_METRICS_COLUMNS.NEXT_C_CALL_BACK], value: SESSION_METRICS_COLUMNS.NEXT_C_CALL_BACK},
          {label: SESSION_METRICS_COLUMNS_TO_HUMAN_READABLE[SESSION_METRICS_COLUMNS.NEXT_C_CALL], value: SESSION_METRICS_COLUMNS.NEXT_C_CALL},
        ]
      default: return getUserOptions(key).map((v) => { return { label: v, value: v }})
    }
  }

  const updateDateSelection = (dateRange: DateOptions) => {
    switch (dateRange) {
      case DateOptions.TODAY: 
        onChange({...filter, [StaticFilterKeys.DATE_RANGE]: dateRange, [StaticFilterKeys.START_DATE]: roundDateDown(new Date()), [StaticFilterKeys.END_DATE]: roundDateUp(new Date())})
        break
      case DateOptions.YESTERDAY:
        onChange({...filter, [StaticFilterKeys.DATE_RANGE]: dateRange, [StaticFilterKeys.START_DATE]: roundDateDown(new Date(new Date().valueOf() - 1 * 60 * 60 * 24 * 1000)), [StaticFilterKeys.END_DATE]: roundDateUp(new Date(new Date().valueOf() - 1 * 60 * 60 * 24 * 1000))})
        break
      case DateOptions.LAST_7_DAYS:
        onChange({...filter, [StaticFilterKeys.DATE_RANGE]: dateRange, [StaticFilterKeys.START_DATE]: roundDateDown(new Date(new Date().valueOf() - 7 * 60 * 60 * 24 * 1000)), [StaticFilterKeys.END_DATE]: roundDateUp(new Date())})
        break
      case DateOptions.LAST_14_DAYS:
        onChange({...filter, [StaticFilterKeys.DATE_RANGE]: dateRange, [StaticFilterKeys.START_DATE]: roundDateDown(new Date(new Date().valueOf() - 14 * 60 * 60 * 24 * 1000)), [StaticFilterKeys.END_DATE]: roundDateUp(new Date())})
        break
      case DateOptions.LAST_30_DAYS:
        onChange({...filter, [StaticFilterKeys.DATE_RANGE]: dateRange, [StaticFilterKeys.START_DATE]: roundDateDown(new Date(new Date().valueOf() - 30 * 60 * 60 * 24 * 1000)), [StaticFilterKeys.END_DATE]: roundDateUp(new Date())})
        break
      case DateOptions.CUSTOM:
        onChange({...filter, [StaticFilterKeys.DATE_RANGE]: dateRange })
        break
    }
  }

  useEffect(() => {
    updateComparisonDateSelection()
  }, [JSON.stringify({start: filter[StaticFilterKeys.START_DATE], end: filter[StaticFilterKeys.END_DATE]})])

  const updateComparisonDateSelection = () => {
    const currentStartDate = filter[StaticFilterKeys.START_DATE]
    let updatedStartDate: Date;
    let updatedEndDate: Date = roundDateUp(new Date(currentStartDate.valueOf() - 1 * 60 * 60 * 24 * 1000)) 

    let currentDateRange = filter[StaticFilterKeys.COMPARISON_DATE_RANGE]
    if (currentDateRange !== ComparisonDateOptions.CUSTOM) {
      switch (filter[StaticFilterKeys.DATE_RANGE]) {
        case DateOptions.TODAY:
        case DateOptions.YESTERDAY:
          currentDateRange = ComparisonDateOptions.PRIOR_DAY
          break
        case DateOptions.LAST_7_DAYS:
          currentDateRange = ComparisonDateOptions.PRIOR_7_DAYS
          break
        case DateOptions.LAST_14_DAYS:
          currentDateRange = ComparisonDateOptions.PRIOR_14_DAYS
          break
        case DateOptions.LAST_30_DAYS:
          currentDateRange = ComparisonDateOptions.PRIOR_30_DAYS
          break
      }
    }

    switch (currentDateRange) {
      case ComparisonDateOptions.PRIOR_DAY:
        updatedStartDate = roundDateDown(new Date(currentStartDate.valueOf() - 1 * 60 * 60 * 24 * 1000))
        break
      case ComparisonDateOptions.PRIOR_7_DAYS:
        updatedStartDate = roundDateDown(new Date(currentStartDate.valueOf() - 2 * 7 * 60 * 60 * 24 * 1000))
        break
      case ComparisonDateOptions.PRIOR_14_DAYS:
        updatedStartDate = roundDateDown(new Date(currentStartDate.valueOf() - 4 * 7 * 60 * 60 * 24 * 1000))
        break
      case ComparisonDateOptions.PRIOR_30_DAYS:
        updatedStartDate = roundDateDown(new Date(currentStartDate.valueOf() - 8 * 7 * 60 * 60 * 24 * 1000))
        break
      case ComparisonDateOptions.CUSTOM:
        updatedStartDate = roundDateDown(filter[StaticFilterKeys.COMPARISON_START_DATE])
        updatedEndDate = roundDateUp(filter[StaticFilterKeys.COMPARISON_END_DATE])
        break
    }
    onChange({...filter, [StaticFilterKeys.COMPARISON_DATE_RANGE]: currentDateRange, [StaticFilterKeys.COMPARISON_START_DATE]: updatedStartDate, [StaticFilterKeys.COMPARISON_END_DATE]: updatedEndDate})
  }

  const getIconForFilter = (filter: StaticFilterKeys): React.ReactNode => {
    switch (filter) {
      case StaticFilterKeys.COUNTERPART: return <Users className="w-4 h-4" />
      case StaticFilterKeys.OBJECTIONS: return <Flag className="w-4 h-4" />
      case StaticFilterKeys.STAGES: return <Layers className="w-4 h-4" />
      case StaticFilterKeys.CADENCES: return <ListChecks className="w-4 h-4" />
      case StaticFilterKeys.CADENCE_STEPS: return <ScrollText className="w-4 h-4" />
      case StaticFilterKeys.INDUSTRIES: return <Building2 className="w-4 h-4" />
      case StaticFilterKeys.TITLES: return <UserCircle className="w-4 h-4" />
      case StaticFilterKeys.SENIORITIES: return <Target className="w-4 h-4" />
      case StaticFilterKeys.DURATION_FILTER: return <TimerIcon className="w-4 h-4" />
      case StaticFilterKeys.PROSPECT_LIST: return <BookMarked className="w-4 h-4" />
      case StaticFilterKeys.MARKET: return <MapPin className="w-4 h-4" />
      case StaticFilterKeys.LEAD_TYPE: return <Briefcase className="w-4 h-4" />
      case StaticFilterKeys.USER_DISPOSITION: return <UserCheck className="w-4 h-4" />
      case StaticFilterKeys.USER_PURPOSE: return <Target className="w-4 h-4" />
      case StaticFilterKeys.USER_SENTIMENT: return <ThumbsUp className="w-4 h-4" />
      case StaticFilterKeys.IS_STARRED: return <Star className="w-4 h-4" />
      case StaticFilterKeys.IS_REVIEWED: return <MessageCircle className="w-4 h-4" />
      case StaticFilterKeys.CALLBACK_LIST: return <PhoneCall className="w-4 h-4" />
      default: return <Filter className="w-4 h-4" />
    }
  }
  const getMetricField = (metric: CustomDefinition) => `metric_${metric.metric_idx}`
  const metricHasOptionSelected = (metric: CustomDefinition) => {
    const matching_filter_value = filter[StaticFilterKeys.CUSTOM_METRIC]?.find((v) => v.metric_field === getMetricField(metric))
    if (!matching_filter_value) return false
    if (matching_filter_value.value_type === ValueType.BOOLEAN) return matching_filter_value.value?.[ValueType.BOOLEAN]?.value !== undefined
    if (matching_filter_value.value_type === ValueType.FLOAT) return matching_filter_value.value?.[ValueType.FLOAT]?.number_one !== undefined || matching_filter_value.value?.[ValueType.FLOAT]?.number_two !== undefined
    if (matching_filter_value.value_type === ValueType.CATEGORICAL) return (matching_filter_value.value?.[ValueType.CATEGORICAL]?.selected_options ?? []).length > 0
    return false
  }
  const renderBooleanCustomMetric = (metric: CustomDefinition) => {
    const matching_filter_value = filter[StaticFilterKeys.CUSTOM_METRIC]?.find((v) => v.metric_field === getMetricField(metric))
    return <BooleanFilter value={matching_filter_value?.value?.[ValueType.BOOLEAN]?.value ?? null} onValueChange={(value) => {
      const update: StaticCustomMetricValue = {
        metric_field: getMetricField(metric), 
        value_type: ValueType.BOOLEAN,
        value: { type: ValueType.BOOLEAN, [ValueType.BOOLEAN]: { value: value ?? undefined } }
      }
      const updated_custom_metric: StaticCustomMetricValue[] = [...(filter[StaticFilterKeys.CUSTOM_METRIC] ?? [])]
      
      // Find index of existing metric if it exists
      const existingIndex = updated_custom_metric.findIndex(v => v.metric_field === getMetricField(metric))
      
      if (existingIndex >= 0) {
        updated_custom_metric[existingIndex] = update
      } else {
        updated_custom_metric.push(update)
      }

      onChange({...filter, [StaticFilterKeys.CUSTOM_METRIC]: updated_custom_metric})
    }} />
  }

  const renderCategoricalCustomMetric = (metric: CustomDefinition) => {
    const matching_filter_value = filter[StaticFilterKeys.CUSTOM_METRIC]?.find((v) => v.metric_field === getMetricField(metric))
    let options: string[] = []
    if (metric.value_source === CustomMetricSource.CODE) {
      options = getCustomCode(metric.team_id ?? '')?.find((v) => v.name === metric.value_code_str)?.enum_options ?? []
    } else {
      options = (metric.value_type_enum_str ?? '').split(',')
    }
    const optionsWithValueMap: {label: string, value: string}[] = options.map((option, idx) => ({label: option, value: idx.toString()}))
    return <SimplifiedMultiSelect 
      options={optionsWithValueMap} 
      value={(matching_filter_value?.value?.[ValueType.CATEGORICAL]?.selected_options ?? []).map((option) => option.value.toString())} 
      onChange={(value) => {
        const updated_selected_options: Option[] = optionsWithValueMap.filter((option) => value.includes(option.value.toString())).map((option) => ({label: option.label, value: option.value.toString()}))
        const update: StaticCustomMetricValue = {
          metric_field: getMetricField(metric), 
          value_type: ValueType.CATEGORICAL,
          value: { type: ValueType.CATEGORICAL, [ValueType.CATEGORICAL]: { selected_options: updated_selected_options } }
        }
        const updated_custom_metric: StaticCustomMetricValue[] = (filter[StaticFilterKeys.CUSTOM_METRIC] ?? []).map((v) => {
          if (v.metric_field === getMetricField(metric)) return update
          return v
        })
        if (!matching_filter_value) updated_custom_metric.push(update)
        onChange({...filter, [StaticFilterKeys.CUSTOM_METRIC]: updated_custom_metric})
      }} />
  }

  const renderFloatCustomMetric = (metric: CustomDefinition) => {
    const matching_filter_value = filter[StaticFilterKeys.CUSTOM_METRIC]?.find((v) => v.metric_field === getMetricField(metric))
    return <FloatFilter 
      min_value={matching_filter_value?.value?.[ValueType.FLOAT]?.number_one ?? null} max_value={matching_filter_value?.value?.[ValueType.FLOAT]?.number_two ?? null} onChange={(value) => {
        // if both values are null or 0, make sure to remove it...
        if ((value.min_value === null || value.min_value === 0) && (value.max_value === null || value.max_value === 0)) {
          const updated_custom_metric: StaticCustomMetricValue[] = (filter[StaticFilterKeys.CUSTOM_METRIC] ?? []).filter((v) => v.metric_field !== getMetricField(metric))
          onChange({...filter, [StaticFilterKeys.CUSTOM_METRIC]: updated_custom_metric})
          return
        }
      
        const update: StaticCustomMetricValue = {
        metric_field: getMetricField(metric), 
        value_type: ValueType.FLOAT,
        value: { type: ValueType.FLOAT, [ValueType.FLOAT]: { 
          fn_type: FloatOption.WHERE, 
          equality_fn: value.min_value !== null && value.max_value !== null ? EqualityFn.IN_BETWEEN : value.min_value !== null ? EqualityFn.GREATER_THAN_OR_EQUAL_TO : EqualityFn.LESS_THAN_OR_EQUAL_TO,
          number_one: value.min_value ?? undefined, 
          number_two: value.max_value ?? undefined 
        } },
        isPercentage: true
      }
      const updated_custom_metric: StaticCustomMetricValue[] = (filter[StaticFilterKeys.CUSTOM_METRIC] ?? []).map((v) => {
        if (v.metric_field === getMetricField(metric)) return update
        return v
      })
      if (!matching_filter_value) updated_custom_metric.push(update)
      onChange({...filter, [StaticFilterKeys.CUSTOM_METRIC]: updated_custom_metric})
    }} />
  }
  

  const renderMetric = (metric: CustomDefinition) => {
    switch (metric.value_source) {
      case CustomMetricSource.PHRASE: return renderBooleanCustomMetric(metric)
      case CustomMetricSource.GEN_AI:
        switch (metric.value_type) {
          case CustomMetricValueType.BOOLEAN: return renderBooleanCustomMetric(metric)
          case CustomMetricValueType.ENUM: return renderCategoricalCustomMetric(metric)
          case CustomMetricValueType.PERCENT: return renderFloatCustomMetric(metric)
          default: return null
        }
      case CustomMetricSource.AVERAGE: return renderFloatCustomMetric(metric)
      case CustomMetricSource.CODE:
        const code = getCustomCode(metric.team_id ?? '')?.find((v) => v.name === metric.value_code_str)
        if (!code) return null
        switch (code.output_type) {
          case OutputType.BOOLEAN: return renderBooleanCustomMetric(metric)
          case OutputType.PERCENTAGE: return renderFloatCustomMetric(metric)
          case OutputType.ENUM: return renderCategoricalCustomMetric(metric)
        }

      default: return null
    }
  }

  const canResetFilter = JSON.stringify({...filter, [StaticFilterKeys.START_DATE]: null, [StaticFilterKeys.END_DATE]: null, [StaticFilterKeys.COMPARISON_START_DATE]: null, [StaticFilterKeys.COMPARISON_END_DATE]: null}) !== JSON.stringify({...getDefaultStaticFilter(), [StaticFilterKeys.START_DATE]: null, [StaticFilterKeys.END_DATE]: null, [StaticFilterKeys.COMPARISON_START_DATE]: null, [StaticFilterKeys.COMPARISON_END_DATE]: null})
  return (
    <div 
      className={`h-full border-r transition-all duration-300 ease-in-out ${
        isVisible ? 'min-w-64 w-64 max-w-64 opacity-100' : 'max-w-0 opacity-0'
      }`}
    >
      {(isVisible || isTransitioning) && (
        <div className="h-full flex flex-col">
          <div className="px-3 py-3 border-b flex items-center justify-between">
            <div className="flex flex-row gap-2.5 items-center">
              <h2 className="text-base font-semibold text-gray-900">Filters</h2>
              <Button
                variant="outlined"
                disabled={!canResetFilter}
                onClick={() => {
                  if (!canResetFilter) return
                  onChange(getDefaultStaticFilter())
                }}
                startIcon={<Filter size={20} />}
                sx={{
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  color: 'rgba(0, 0, 0, 0.87)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.04)',
                    borderColor: 'rgba(255, 0, 0, 0.5)',
                    color: 'rgb(255, 0, 0)',
                  },
                }}
              >
                Clear
              </Button>
            </div>
            <button
              onClick={onClose}
              className="p-1 rounded-md hover:bg-gray-50 transition-colors"
              disabled={isTransitioning}
            >
              <ChevronLeft className="w-5 h-5 text-gray-500" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto">
            <div className="p-3.5 space-y-4">
              {applicableSpecialFilters.length > 0 && (
                <div className='w-full flex flex-col gap-2 border border-slate-400 p-2.5 rounded-md'>
                  {applicableSpecialFilters.includes(StaticFilterKeys.PROSPECT_PHONE_OR_NAME) && (
                    <FilterSection
                      title={`Phone # or Name`}
                      icon={<Users className="w-4 h-4" />}
                      isExpanded={expandedSections[StaticFilterKeys.PROSPECT_PHONE_OR_NAME]}
                      onToggle={() => toggleSection(StaticFilterKeys.PROSPECT_PHONE_OR_NAME)}
                      >
                        <SimplifiedMultiSelect 
                          onChange={(selected) => {
                            const selected_filter = selected.filter((v) => !v.includes('_SEARCH') && !v.includes('_SEARCHING') && v.length > 0)
                            onChange({...filter, [StaticFilterKeys.PROSPECT_PHONE_OR_NAME]: selected_filter})
                          }}
                          options={phoneOptions}
                          value={filter[StaticFilterKeys.PROSPECT_PHONE_OR_NAME] ?? []}
                          onInputChange={(input) => updateNumberSearch(input)}
                        />
                      </FilterSection>
                  )}
                  {applicableSpecialFilters.includes(StaticFilterKeys.COMPARISON_DATE_RANGE) && (
                    <FilterSection
                      title={`Comparison Date Range`}
                      icon={<Calendar className="w-4 h-4" />}
                      isExpanded={expandedSections[SpecialFilter.COMPARISON_DATE_RANGE]}
                      onToggle={() => toggleSection(StaticFilterKeys.COMPARISON_DATE_RANGE)}
                      >
                        <SimplifiedSingleSelect
                          options={Object.values(ComparisonDateOptions).map((option) => ({label: option, value: option}))}
                          value={filter[StaticFilterKeys.COMPARISON_DATE_RANGE]}
                          onChange={(option) => {
                            if (!option) return
                            onChange({...filter, [StaticFilterKeys.COMPARISON_DATE_RANGE]: option as ComparisonDateOptions})
                          }}
                        />
                        {filter[StaticFilterKeys.COMPARISON_DATE_RANGE] === ComparisonDateOptions.CUSTOM && (
                          <CalendarComponent
                            start={filter[StaticFilterKeys.COMPARISON_START_DATE]}
                            end={filter[StaticFilterKeys.COMPARISON_END_DATE]}
                            onValueUpdate={(startDate, endDate) => onChange({...filter, [StaticFilterKeys.COMPARISON_START_DATE]: roundDateDown(startDate), [StaticFilterKeys.COMPARISON_END_DATE]: roundDateUp(endDate)})}
                          />
                        )}  
                      </FilterSection>)
                      }
                    {applicableSpecialFilters.includes(StaticFilterKeys.COMPARISON_GROUP) && (
                      <FilterSection
                        title={`Comparison Group`}
                        icon={<Users className="w-4 h-4" />}
                        isExpanded={expandedSections[StaticFilterKeys.COMPARISON_GROUP]}
                        onToggle={() => toggleSection(StaticFilterKeys.COMPARISON_GROUP)}
                        >
                          <MultiUserSelector 
                            onSelectedUserOrGroupsChange={(selected) => onChange({...filter, [StaticFilterKeys.COMPARISON_GROUP]: selected})}
                            selectedUserOrGroups={filter[StaticFilterKeys.COMPARISON_GROUP]}
                          />
                        </FilterSection>
                      )}
                    {applicableSpecialFilters.includes(StaticFilterKeys.TIMEZONE) && (
                      <FilterSection
                        title={`Timezone`}
                        icon={<Clock className="w-4 h-4" />}
                        isExpanded={expandedSections[StaticFilterKeys.TIMEZONE]}
                        onToggle={() => toggleSection(StaticFilterKeys.TIMEZONE)}
                        >
                          <SimplifiedSingleSelect
                            options={Object.values(TimeZoneOptions).map((option) => ({label: option, value: option}))}
                            value={filter[StaticFilterKeys.TIMEZONE]}
                            onChange={(option) => {
                              if (!option) return
                              onChange({...filter, [StaticFilterKeys.TIMEZONE]: option as TimeZoneOptions})
                            }}
                          />
                        </FilterSection>  
                      )
                    }
                </div>
              )}
              <FilterSection
                title="Date Range"
                icon={<Calendar className="w-4 h-4" />}
                isExpanded={expandedSections[StaticFilterKeys.DATE_RANGE]}
                onToggle={() => toggleSection(StaticFilterKeys.DATE_RANGE)}
              >
                <SimplifiedSingleSelect 
                  options={Object.values(DateOptions).map((option) => ({label: option, value: option}))}
                  value={filter[StaticFilterKeys.DATE_RANGE]}
                  onChange={(option) => {
                    if (!option) return
                    updateDateSelection(option as DateOptions)
                  }}
                />
                {filter.dateRange === DateOptions.CUSTOM && (
                  <CalendarComponent 
                    start={filter.startDate}
                    end={filter.endDate}
                    onValueUpdate={(startDate, endDate) => onChange({...filter, [StaticFilterKeys.START_DATE]: roundDateDown(startDate), [StaticFilterKeys.END_DATE]: roundDateUp(endDate)})}
                  />
                )}
              </FilterSection>
              <FilterSection
                title={`Rep Filter${!expandedSections[StaticFilterKeys.SELECTED_REPS] && filter[StaticFilterKeys.SELECTED_REPS] && filter[StaticFilterKeys.SELECTED_REPS].length > 0 ? ` (${filter[StaticFilterKeys.SELECTED_REPS].length})` : ''}`}
                icon={<Users className="w-4 h-4" />}
                isExpanded={expandedSections[StaticFilterKeys.SELECTED_REPS]}
                onToggle={() => toggleSection(StaticFilterKeys.SELECTED_REPS)}
              >
                <MultiUserSelector 
                  onSelectedUserOrGroupsChange={(selected) => onChange({...filter, [StaticFilterKeys.SELECTED_REPS]: selected})}
                  selectedUserOrGroups={filter[StaticFilterKeys.SELECTED_REPS]}
                />
              </FilterSection>
              <FilterSection
                title={`Duration Filter${filter[StaticFilterKeys.DURATION_FILTER]?.min || filter[StaticFilterKeys.DURATION_FILTER]?.max ? (" (1)") : ""}`}
                icon={<TimerIcon className="w-4 h-4" />}
                isExpanded={expandedSections[StaticFilterKeys.DURATION_FILTER]}
                onToggle={() => toggleSection(StaticFilterKeys.DURATION_FILTER)}
              >
                <DurationFilter
                minDuration={filter[StaticFilterKeys.DURATION_FILTER]?.min ?? null}
                maxDuration={filter[StaticFilterKeys.DURATION_FILTER]?.max ?? null}
                onMaxChange={(duration) => onChange({...filter, [StaticFilterKeys.DURATION_FILTER]: {min: filter[StaticFilterKeys.DURATION_FILTER]?.min ?? undefined, max: duration ?? undefined}})}
                onMinChange={(duration) => onChange({...filter, [StaticFilterKeys.DURATION_FILTER]: {min: duration ?? undefined, max: filter[StaticFilterKeys.DURATION_FILTER]?.max ?? undefined}})}
                />
              </FilterSection>
              {[
                StaticFilterKeys.CALLBACK_LIST,
                StaticFilterKeys.COUNTERPART,
                StaticFilterKeys.IS_STARRED,
                StaticFilterKeys.IS_REVIEWED,
                StaticFilterKeys.CADENCES, 
                StaticFilterKeys.CADENCE_STEPS, 
                StaticFilterKeys.INDUSTRIES, 
                StaticFilterKeys.TITLES, 
                StaticFilterKeys.SENIORITIES,
                StaticFilterKeys.OBJECTIONS,
                StaticFilterKeys.STAGES,
                StaticFilterKeys.PROSPECT_LIST,
                StaticFilterKeys.MARKET,
                StaticFilterKeys.LEAD_TYPE,
                StaticFilterKeys.USER_DISPOSITION,
                StaticFilterKeys.USER_PURPOSE,
                StaticFilterKeys.USER_SENTIMENT,
              ].map((option) => {
                if (Array.from(Object.values(SpecialFilter)).map((v) => v.toString()).includes(option.toString()) && !applicableSpecialFilters.includes(option)) return null
                const options = getOptions(option)
                if (options.length === 0) return null
                const selectedOptions = (filter[option] ?? []) as string[]
                return <FilterSection
                  title={`${makeProperNoun(option)}${selectedOptions.length > 0 ? ` (${selectedOptions.length})` : ''}`}
                  icon={getIconForFilter(option)}
                  isExpanded={expandedSections[option]}
                  onToggle={() => toggleSection(option)}
                  >
                  <SimplifiedMultiSelect 
                  value={selectedOptions}
                  options={options}
                  onChange={(selected) => onChange({...filter, [option]: selected})}
                  
                  />
                  </FilterSection>
              })}
              {customDefinitions ? customDefinitions.map((metric: CustomDefinition) => {
                if (metric.value_type === CustomMetricValueType.TEXT) return null
                return <FilterSection
                  title={`${metric.label}${metricHasOptionSelected(metric) ? ` (1)` : ''}`}
                  icon={<ScrollText className="w-4 h-4" />}
                  isExpanded={expandedMetricSections[metric.custom_definition_id]}
                  onToggle={() => setExpandedMetricSections(prev => ({...prev, [metric.custom_definition_id]: !prev[metric.custom_definition_id]}))}
                  >
                    {renderMetric(metric)}
                  </FilterSection>                
                }) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

interface FilterSectionProps {
  title: string
  icon: React.ReactNode
  isExpanded: boolean
  onToggle: () => void
  children: React.ReactNode
}

function FilterSection({ title, icon, isExpanded, onToggle, children }: FilterSectionProps) {
  return (
    <div>
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between text-left group"
      >
        <div className="flex items-center space-x-3">
          <span className="text-gray-400 group-hover:text-gray-500">{icon}</span>
          <span className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{title}</span>
        </div>
        <ChevronDown
          className={`w-4 h-4 text-gray-400 group-hover:text-gray-500 transform transition-transform duration-300 ${
            isExpanded ? 'rotate-180' : ''
          }`}
        />
      </button>
      <div
        className={`transition-all duration-300 ease-in-out flex flex-col gap-1.5 items-center ${
          isExpanded ? 'max-h-[500px] opacity-100 mt-2 pointer-events-auto' : 'max-h-0 opacity-0 pointer-events-none'
        }`}
      >
        {children}
      </div>
    </div>
  )
}

export const StaticFilterComponent = connect((state: RootState) => {
  return {
    user: convertFromReduxSafeUserState(state.user),
    prospectInfoOptions: state.prospectInfoOptions.value,
    dispositionInfoOptions: state.dispositionOptions.value,
    sortedUserGroupInfo: state.userGroupInfo.sortedGroupInfo,
    filter: convertFilterFromReduxSafe(state.filter.value),
    customDefinitions: state.customDefinitions.value
  }
})(StaticFilterImpl)