import { AllInbox, Snooze, QuestionAnswer, Launch } from "@mui/icons-material";
import { LinkedInAttachment } from "interfaces/linkedin";
import { Reply, Archive } from "lucide-react";

export enum SOURCE_TYPE {
    SALES_NAVIGATOR = 'sales-navigator',
    CHAT = 'chat',
}

export enum InboxType {
    ALL = 'all',
    UNREAD = 'unread',
}

export enum DerivedInboxType {
    ALL = 'all',
    SNOOZED = 'snoozed',
    COLD_OUTBOUND = 'cold-outbound',
    ONGOING_CONVERSATIONS = 'ongoing-conversations',
    ARCHIVED = 'archived',
}

export const DerivedInboxToRequiresAnalysis: {[key in DerivedInboxType]: boolean} = {
    [DerivedInboxType.ALL]: false,
    [DerivedInboxType.SNOOZED]: false,
    [DerivedInboxType.ARCHIVED]: false,
    [DerivedInboxType.COLD_OUTBOUND]: true,
    [DerivedInboxType.ONGOING_CONVERSATIONS]: true,
}

export const DerivedInboxToIsDerivedFullyFromState: {[key in DerivedInboxType]: boolean} = {
    [DerivedInboxType.ALL]: false,
    [DerivedInboxType.SNOOZED]: true,
    [DerivedInboxType.ARCHIVED]: true,
    [DerivedInboxType.COLD_OUTBOUND]: false,
    [DerivedInboxType.ONGOING_CONVERSATIONS]: false,
}

export const INBOX_LABELS: {[key in DerivedInboxType]: string} = {
    [DerivedInboxType.ALL]: 'All Messages',
    [DerivedInboxType.SNOOZED]: 'Snoozed',
    [DerivedInboxType.ARCHIVED]: 'Archived',
    [DerivedInboxType.COLD_OUTBOUND]: 'Cold Outbound',
    [DerivedInboxType.ONGOING_CONVERSATIONS]: 'Ongoing Conversations',
}

export const INBOX_ICONS: {[key in DerivedInboxType]: React.ReactNode} = {
    [DerivedInboxType.ALL]: <AllInbox />,
    [DerivedInboxType.SNOOZED]: <Snooze />,
    [DerivedInboxType.ARCHIVED]: <Archive />,
    [DerivedInboxType.COLD_OUTBOUND]: <Launch />,
    [DerivedInboxType.ONGOING_CONVERSATIONS]: <QuestionAnswer />,
}

export type ConversationId = {
    id: string,
    lastActivity: number | null,
    sourceType: SOURCE_TYPE,
}

export type ConversationIds = ConversationId[];

export enum ERROR_MESSAGE { 
    NO_CONVERSATION_FOUND = 'No conversation found',
    NO_UPDATES_FOUND = 'No updates found',
}

export type TemporaryMessage = {
    failed: boolean,
    uuid: string,
    text: string | null,
    attachments: LinkedInAttachment[],
    files: File[],
}