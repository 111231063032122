import React, { RefObject, useEffect, useRef, useCallback } from 'react';

import { durationToString, shortenedDateToString } from 'time';
import { UserDataResult, ListUserSessionParamsV3, UserSessionsResult, UserSessionsResultItem, VisibleAccountsResult, StaticFilterDisjunction, CnfFilterType } from 'interfaces/services';
import { MIN_TO_SEC, SEC_TO_MS } from 'cfg/const';
import { getServicesManager } from 'services';
import { back, maybeBack } from 'core';
import { SessionStatus } from 'components/SessionStatus/SessionStatus';
import { RootState } from 'store';
import { connect } from "react-redux";
import { DateOptions, SpecialFilter, StaticFilter, StaticFilterKeys, convertFilterFromReduxSafe, convertFilterToStaticCnf, convertFromReduxSafeUserState, convertFromReduxSafeVisibleAccounts, reloadPendingReviewsInitialState } from 'lib/redux/store';
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { User2 } from 'lucide-react';

export type SessionListProps = {
  forceRefreshCount: number 
  onRowClick: (item: UserSessionsResultItem) => void,
  shownSessionId?: string,
  preloadedItems?: UserSessionsResultItem[]
  useTranscriptFilter?: boolean
  isCoaching?: boolean
  additiveCNF?: StaticFilterDisjunction[]

  user: UserDataResult | null
  visibleAccounts: VisibleAccountsResult | null
  filter: StaticFilter
  transcriptFilter: StaticFilter
  dispatch: Dispatch<AnyAction>
}

function _jsonifyParams(prev: any) {
  return Array.from(Object.entries(prev)).map(([key, value]) => JSON.stringify(value)).join('')
}

export function compareTwoSessionListParams (prev: ListUserSessionParamsV3, current: ListUserSessionParamsV3) {
  return _jsonifyParams(prev) !== _jsonifyParams(current)
}

type SessionListState = {
    // state associated with loaded data
    // state associated with just starred data... this is for the coaching page view
    continueFetching: boolean
  
    // state associated with what the user is viewing
    itemsPerPage: number,
    onScrollEventListenerAdded: boolean,
    fetchAdditionalItems: boolean
  }

function SessionListComponent(props: SessionListProps) {
    const _listRef: RefObject<HTMLDivElement>  = useRef<HTMLDivElement>(null);  // 
    let timeout: NodeJS.Timeout | null = null
    const [items, setItems] = React.useState<{[k: string]: {items: UserSessionsResultItem[], start_requested: Date | null, start_still_has_more: boolean}}>({})
    const [state, setState] = React.useState<SessionListState>({
      onScrollEventListenerAdded: false,
      itemsPerPage: 50,
      continueFetching: true,
      fetchAdditionalItems: false
    })
    const _getFilter = (): StaticFilter => props.useTranscriptFilter ? props.transcriptFilter : props.filter
    const _getCnf = (): StaticFilterDisjunction[] => {
      const cnf = convertFilterToStaticCnf(_getFilter(), [], [SpecialFilter.IS_REVIEWED, SpecialFilter.IS_STARRED, SpecialFilter.PROSPECT_PHONE_OR_NAME])
      if (props.isCoaching && props.user) {
        cnf.push({ 'filter_type': CnfFilterType.USER_ACCOUNT, 'negated': false, 'user_account_filters': [{'user_ids': [props.user.user_id.toString()] }]})
      }
      if (props.additiveCNF) {
        cnf.push(...props.additiveCNF)
      }
      return cnf
    }
    const _getStandardStart = (startAdjust?: Date): Date | null => {
      // check if the filter has a prospect name or number
      const phone_or_name = _getFilter()[StaticFilterKeys.PROSPECT_PHONE_OR_NAME]
      if (phone_or_name && phone_or_name.length > 0) return null
      const review = _getFilter()[StaticFilterKeys.IS_REVIEWED]
      if (review && review.length > 0) return null
      // exponential back off the start date but start with two weeks...
      const usingCustomDate = _getFilter()[StaticFilterKeys.DATE_RANGE] === DateOptions.CUSTOM
      if (usingCustomDate) return _getFilter()[StaticFilterKeys.START_DATE]
      const start = _getFilter()[StaticFilterKeys.START_DATE]
      // check if there 
      if (startAdjust) return new Date(startAdjust.getTime() - 30*24*60*60*1000)
      return start
    } 
    const _getStart = (startAdjust?: Date): Date | null => props.useTranscriptFilter ? _getFilter()[StaticFilterKeys.START_DATE] : _getStandardStart(startAdjust)
    const _getKey = (): string => JSON.stringify({ 'cnf': _getCnf(), 'start': _getStart(), 'end': _getFilter()[StaticFilterKeys.END_DATE] })
    const getItems = (): UserSessionsResultItem[] => {
      return props.preloadedItems ? props.preloadedItems : items[_getKey()]?.items ?? []
    }
    const _getParams = (forceRefresh?: boolean, adjustedStart?: Date): ListUserSessionParamsV3 => { 
      const relevantItems = items[_getKey()]?.items
      return { 'cnf': _getCnf(), 'start': _getStart(adjustedStart), 'end': !forceRefresh && relevantItems && relevantItems.length > 0 ? back(relevantItems).scheduled_start : _getFilter()[StaticFilterKeys.END_DATE] }}
    const _maybeExtendConversations = async (isKnownEndOfScroll?: boolean, forceRefresh?: boolean): Promise<undefined> => {
        if (props.preloadedItems) return
        const priorRequest = items[_getKey()]
        const priorStart = !forceRefresh && priorRequest && !priorRequest.start_still_has_more ? priorRequest.start_requested ?? undefined : undefined
        if (priorStart && !compareDateLimit(priorStart, _getStart())) return
        const params: ListUserSessionParamsV3 = _getParams(forceRefresh, priorStart)
        const key = _getKey()
        let result: UserSessionsResult | null = null
        result = await getServicesManager().getListUserSessionsV3(params, forceRefresh)
        if (compareTwoSessionListParams(params, _getParams(forceRefresh, priorStart))) return
        if (!result) return
        // check if the params are the same
        if (isKnownEndOfScroll) setState((state) => {return {...state, 'fetchAdditionalItems': false}})
        setState((state) => {return {...state, 'continueFetching': result !== null && result?.length !== 0}})
        
        setItems((prev) => {
          const matchingItems = (!forceRefresh ? prev[key]?.items ?? [] : [])
          const lastItemStart = matchingItems && matchingItems.length ? (maybeBack(matchingItems) ?? {}).scheduled_start : null
          const currentSessionIds = new Set(matchingItems.map((x) => x.session_id))
          const newItems = (lastItemStart == null ? result : result!.filter((x) => !currentSessionIds.has(x.session_id))) ?? []
          return {...prev, [key]: {items: [...matchingItems, ...newItems], start_requested: params.start, start_still_has_more: result !== null && result.length >= state.itemsPerPage }}
        })
    }

    const compareDateLimit = (date: Date, start_date: Date | null): boolean => {
      if (!start_date) return false
      return (date.getTime() - start_date.getTime()) < 12*30*24*60*60*1000
    }

    useEffect(() => {
      if (props.preloadedItems) return
      if (props.useTranscriptFilter) return 
      const result = items[_getKey()]
      if (!result) return
      if (!result.start_requested) return
      const startDate = _getFilter()[StaticFilterKeys.START_DATE]
      if (!startDate) return
      if (!_getParams(false).start) return
      const startRequested = result.start_requested
      // check that they are within 6 months apart
      if (startDate.getTime() - startRequested.getTime() > 12*30*24*60*60*1000) return
      if (result.start_still_has_more) return
      _maybeExtendConversations(true, false)
    }, [JSON.stringify(items[_getKey()])])

    const _onScrollListener = useCallback(() => {
        if (_listRef.current !== null && 
            _listRef.current.scrollTop + _listRef.current.clientHeight + 10 >= _listRef.current.scrollHeight) {
            setState((state) => ({...state, fetchAdditionalItems: true}))
        }
    }, []); // No dependencies needed since we're using ref

    // Scroll listener setup
    useEffect(() => {
        const listElement = _listRef.current;
        if (!listElement) return;
        listElement.addEventListener('scroll', _onScrollListener, { passive: true });
        return () => {
            listElement.removeEventListener('scroll', _onScrollListener);
        };
    }, [_listRef.current, _onScrollListener]); // Add _onScrollListener as dependency

    const _pollForReviewIsOpened = () => {
        reloadPendingReviewsInitialState(props.dispatch)
        timeout = setTimeout(() => _pollForReviewIsOpened(), 5*MIN_TO_SEC*SEC_TO_MS)
    }

    useEffect(() => {
      setTimeout(() => _pollForReviewIsOpened(), 10*SEC_TO_MS)
      // on unmount remove
      return () => {
        if (timeout) clearTimeout(timeout)
      }
    }, [])

    useEffect(() => {
      if (!state.fetchAdditionalItems) return
      _maybeExtendConversations(true, false)
    }, [state.fetchAdditionalItems])

    useEffect(() => {
      if (!props.forceRefreshCount) return
      setItems((prev) => {
        const newItems = {...prev}
        delete newItems[_getKey()]
        return newItems
      })
      _maybeExtendConversations(false, true)
  }, [props.forceRefreshCount])

    useEffect(() => {
      // check if the current params work with the current items
      _maybeExtendConversations(false, true)
    }, [_getKey()])

    const MessageItem = ({ item }: { item: UserSessionsResultItem }) => {
        return (
          <div className={`flex items-center px-4 py-3 transition-colors cursor-pointer group ${props.shownSessionId === item.session_id ? 'bg-gray-200' : 'hover:bg-gray-50 '}`}
            onClick={() => props.onRowClick(item)}>
            <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center mr-3">
              <User2 className="w-4 h-4 text-gray-500" />
            </div>
            <div className="flex-grow">
              <div className="flex items-center justify-between">
                <span className="font-medium text-sm text-gray-900">{item.prospect_name}</span>
                {item.has_not_gatekeeper && (
                  <span className="ml-2 mr-1.5 px-1.5 py-0.5 text-xs font-medium rounded bg-green-100 text-green-800">
                    P
                  </span>
                )}
                {!item.has_not_gatekeeper && item.has_gatekeeper && (
                  <span className="ml-2 mr-1.5 px-1.5 py-0.5 text-xs font-medium rounded bg-yellow-100 text-yellow-800">
                    G
                    </span>
                )}
              </div>
              <div className="text-sm text-gray-500">{props.visibleAccounts?.users.find((u) => u.user_id === item.user_id.toString())?.user_name ?? "Deactivated User"}</div>
            </div>
            <div className='flex flex-col gap-2 justify-between'>
                {props.user && <SessionStatus 
                isMainView={true} sessionId={item.session_id} 
                sessionUserId={item.user_id.toString()} hasStar={item.has_star} type={'sessionList'} viewerUserId={props.user.user_id} reviewIsOpen={item.review_is_open ?? undefined} 
                reviewOpenedByUser={item.review_opened_by_user ?? undefined}/>}
              <div className="text-sm text-gray-400 w-7">{durationToString((item.duration ?? 0)*SEC_TO_MS)}</div>
            </div>
          </div>
        );
    } 

    const DateHeader = ({ date }: { date: string }) => {
        return (
          <div className="sticky top-0 bg-gray-50 px-4 py-2 border-b border-gray-100">
            <h2 className="text-sm font-medium text-gray-900">{date}</h2>
          </div>
        );
    }
    
    const relevantItems = getItems()
    const itemsOrder = (relevantItems ?? []).sort((a, b) => b.scheduled_start.getTime() - a.scheduled_start.getTime())
    const dates: string[] = Array.from(new Set(itemsOrder.map((specified_item) => shortenedDateToString(specified_item.scheduled_start))))
   
    const datesToItems = dates.map((date) => {
        return {
          date,
          items: itemsOrder.filter((item) => shortenedDateToString(item.scheduled_start) === date),
        };
    })
    const hasFetched = relevantItems.length > 0 || items[_getKey()] !== undefined

    return (
        <div className="h-full bg-gray-100 flex justify-center w-full">
          {datesToItems.length > 0 ? <div className="w-full bg-white rounded-lg shadow-sm overflow-y-auto max-h-full" ref={_listRef}>
            {datesToItems.map((dateItem) => (
              <div key={dateItem.date}>
                <DateHeader date={dateItem.date} />
                <div className="divide-y divide-gray-100">
                  {dateItem.items.map((item) => (
                    <MessageItem key={item.session_id} item={item} />
                  ))}
                </div>
              </div>
            ))}</div> : hasFetched ? <div className="flex items-center justify-center h-full text-gray-500">No results found</div> : 
            <div className="flex items-center justify-center h-full text-gray-500">Loading...</div>}
        </div>
      );
    }


const mapStateToProps = (state: RootState) => {
  return {
    user: convertFromReduxSafeUserState(state.user),
    visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts, (u: UserDataResult) => u.team_is_active && u.can_dial),
    filter: convertFilterFromReduxSafe(state.filter.value),
    transcriptFilter: convertFilterFromReduxSafe(state.transcriptFilter.value)
  };
};

const ReduxWrapped = connect(mapStateToProps)(SessionListComponent)

export { ReduxWrapped as SessionList}