import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinkedInConversationParticipant, LinkedInUpload } from 'interfaces/linkedin';

export interface ConversationDraft {
  // Required fields for sending a new message
  text: string;
  toUrns: string[];
  attachments: File[];
  
  // Metadata about the draft
  id: string;
  lastModified: Date;

  participants: LinkedInConversationParticipant[];

  // Upload progress tracking
  uploadedAttachments: LinkedInUpload[];
  isUploading: boolean;
  uploadError: string | null;
}

interface NewConversationState {
  drafts: Record<string, ConversationDraft>;
}

const initialState: NewConversationState = {
  drafts: {},
};

const createDraftFromPartial = (id: string, partial: Partial<ConversationDraft>): ConversationDraft => ({
  id,
  text: '',
  toUrns: [],
  participants: [],
  attachments: [],
  lastModified: new Date(),
  uploadedAttachments: [],
  isUploading: false,
  uploadError: null,
  ...partial,
});

export const newConversationSlice = createSlice({
  name: 'newConversation',
  initialState,
  reducers: {
    createDraft: (state, action: PayloadAction<Partial<ConversationDraft>>) => {
      const { id, ...rest } = action.payload;
      if (!id) return;
      state.drafts[id] = createDraftFromPartial(id, rest);
    },
    updateDraftText: (state, action: PayloadAction<{
      draftId: string,
      text: string
    }>) => {
      const draft = state.drafts[action.payload.draftId];
      if (draft) {
        draft.text = action.payload.text;
        draft.lastModified = new Date();
      }
    },

    addDraftParticipant: (state, action: PayloadAction<{
      draftId: string,
      participant: LinkedInConversationParticipant
    }>) => {
      const draft = state.drafts[action.payload.draftId];
      if (draft) {
        const urn = action.payload.participant.hostIdentityUrn;
        if (!draft.toUrns.includes(urn)) {
          draft.toUrns.push(urn);
          draft.participants.push(action.payload.participant);
          draft.lastModified = new Date();
        }
      }
    },

    removeDraftParticipant: (state, action: PayloadAction<{
      draftId: string,
      participantUrn: string
    }>) => {
      const draft = state.drafts[action.payload.draftId];
      if (draft) {
        const urn = action.payload.participantUrn;
        draft.toUrns = draft.toUrns.filter(u => u !== urn);
        draft.participants = draft.participants.filter(p => p.hostIdentityUrn !== urn);
        draft.lastModified = new Date();
      }
    },

    addDraftAttachment: (state, action: PayloadAction<{
      draftId: string,
      file: File
    }>) => {
      const draft = state.drafts[action.payload.draftId];
      if (draft) {
        draft.attachments.push(action.payload.file);
        draft.lastModified = new Date();
      }
    },

    removeDraftAttachment: (state, action: PayloadAction<{
      draftId: string,
      fileName: string
    }>) => {
      const draft = state.drafts[action.payload.draftId];
      if (draft) {
        draft.attachments = draft.attachments.filter(f => f.name !== action.payload.fileName);
        draft.lastModified = new Date();
      }
    },

    setDraftUploadedAttachment: (state, action: PayloadAction<{
      draftId: string,
      upload: LinkedInUpload
    }>) => {
      const draft = state.drafts[action.payload.draftId];
      if (draft) {
        draft.uploadedAttachments.push(action.payload.upload);
      }
    },

    setDraftUploadState: (state, action: PayloadAction<{
      draftId: string,
      isUploading: boolean,
      error?: string | null
    }>) => {
      const draft = state.drafts[action.payload.draftId];
      if (draft) {
        draft.isUploading = action.payload.isUploading;
        draft.uploadError = action.payload.error ?? null;
      }
    },

    deleteDraft: (state, action: PayloadAction<string>) => {
      const draftId = action.payload;
      delete state.drafts[draftId];
    },

    clearAllDrafts: (state) => {
      state.drafts = {};
    }
  }
});

export const {
  createDraft,
  updateDraftText,
  addDraftParticipant,
  removeDraftParticipant,
  addDraftAttachment,
  removeDraftAttachment,
  setDraftUploadedAttachment,
  setDraftUploadState,
  deleteDraft,
  clearAllDrafts,
} = newConversationSlice.actions;