import { Loader } from 'components/Loader';
import { SessionDetails } from 'components/SessionDetails';
import { UserDataResult, TemporalFilterDisjunction, UserSessionsResult, UserSessionsResultItem } from 'interfaces/services';
import { REVIEW_OPTIONS, SpecialFilter, StaticFilter, StaticFilterKeys, convertFilterFromReduxSafe, convertFromReduxSafeUserState, updateFilter } from 'lib/redux/store';
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

import React, { useState } from 'react'
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { StaticFilterComponent } from 'components/StaticFilter';
import { Filter, RefreshCwIcon } from 'lucide-react';
import { SessionList } from 'components/SessionList/SessionList';
import { Typography } from 'interfaces/typography';

type HistoryProps = {
  heightFull?: boolean,
  noNavigation?: boolean,
  minimalView?: boolean,
  hideDefaults?: boolean
  preloadedResults?: UserSessionsResult
  hideTranscriptFilter?: boolean
  hideSessionList?: boolean
  temporalCnfForce?: TemporalFilterDisjunction[]
  noDefaultUser?: boolean
  useTranscriptFilter?: boolean

  forcedSessionId?: string

  user: UserDataResult | null
  pendingReviews: number | null
  filter: StaticFilter,
  transcriptFilter: StaticFilter
  dispatch: Dispatch<AnyAction>
}

export function HistoryImpl(props: HistoryProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const getViewingItemSessionId = () => {
    const pageSessionId = new URLSearchParams(location.search).get('id')
    return pageSessionId ? pageSessionId : undefined
  }

  const [viewingItemSessionId, setViewingItemSessionId] = useState<string | undefined>(props.forcedSessionId || getViewingItemSessionId())
  const [forceRefreshCount, setForceRefreshCount] = useState<number>(0)
  const [isFilterOpen, setIsFilterOpen] = useState(getViewingItemSessionId() ? false : true)
  const [persistFilterOpen, setPersistFilterOpen] = useState(false)

  const _renderPendingReviews = (): JSX.Element => {
    return <div onClick={() => {
      props.dispatch(updateFilter({...props.filter, [StaticFilterKeys.IS_REVIEWED]: [REVIEW_OPTIONS.REVIEW_OPENED]}))
    }} className='w-full flex flex-row items-center text-center justify-center cursor-pointer opacity-85 hover:opacity-100' style={{
      border: '0.5px solid lightpink', 'borderRadius': '5px', 'padding': '5px', 'backgroundColor': '#fcd9df'
    }}>
      <Typography variant='largeParagraph'>
        {`${props.pendingReviews ?? 0} pending reviews`}
      </Typography>
    </div>
  }

  React.useEffect(() => {
    const pageSessionId = new URLSearchParams(location.search).get('id')
    if (!props.forcedSessionId && viewingItemSessionId !== pageSessionId) setViewingItemSessionId(pageSessionId ? pageSessionId : undefined)
    }, [location])

    if (props.user == null) return <Loader />

    return (
      <div className="h-full min-w-fit w-full mx-auto flex flex-row overflow-x-auto pb-1.5">
      {!props.hideTranscriptFilter && <StaticFilterComponent
      applicableSpecialFilters={[StaticFilterKeys.PROSPECT_PHONE_OR_NAME, StaticFilterKeys.IS_REVIEWED, StaticFilterKeys.IS_STARRED]}
      isOpen={isFilterOpen}
      onClose={() => setIsFilterOpen(false)}
      />}
      {!props.hideSessionList && <div className={`flex flex-col gap-2 overflow-hidden h-full pl-1 ${viewingItemSessionId ? 'border-r' : 'flex-grow w-full' }`}>
        {!props.hideTranscriptFilter && <div className={`flex flex-col items-center gap-1.5 border-b p-1`}>
          <div className={`flex flex-row items-center gap-2.5`}>
            <h1 className="text-2xl font-bold text-gray-900 pl-2.5">Transcripts</h1>
            <button
                onClick={(e) => {
                  setPersistFilterOpen(!persistFilterOpen)
                  setIsFilterOpen(!isFilterOpen)
                  e.currentTarget.blur()
                }}
                className={`p-2 rounded-md focus:outline-none ${
                  isFilterOpen ? 'bg-indigo-100 text-indigo-600' : 'text-gray-400 hover:text-gray-500'
                }`}
                aria-label={isFilterOpen ? "Close filters" : "Open filters"}
              >
                <Filter className="h-6 w-6" />
              </button>
              <button
                onClick={() => setForceRefreshCount(forceRefreshCount + 1)}
                className="p-2 rounded-md focus:outline-none text-gray-400 hover:text-gray-500"
                aria-label="Refresh"
                >
                <RefreshCwIcon className="h-6 w-6" />
                </button>
          </div>
          {props.pendingReviews ? _renderPendingReviews() : null}
        </div>}
        <div className={`flex w-full transition-all h-full ${viewingItemSessionId ? "min-w-60 max-w-60" : "flex-grow max-w-full"}`}>
          <SessionList 
            useTranscriptFilter={props.useTranscriptFilter}
            preloadedItems={props.preloadedResults}
            shownSessionId={viewingItemSessionId} 
            forceRefreshCount={forceRefreshCount}
            onRowClick={(item: UserSessionsResultItem) => {
              if (!persistFilterOpen) setIsFilterOpen(false)
              if (props.noNavigation) setViewingItemSessionId(item.session_id)
              else navigate('/transcripts?id=' + item.session_id)
            }}
          />
        </div>
      </div>}
      {viewingItemSessionId ? <div className='w-full flex-1 flex flex-grow pl-0.5 h-full flex-col gap-2'>
          <SessionDetails 
          temporalCnfForce={props.temporalCnfForce} 
          minimalView={props.minimalView} 
          viewerUserId={props.user.user_id} 
          isInView={true} 
          sessionId={viewingItemSessionId} 
          coachingView={false}/>
          </div> : null}
      </div>
      )
  }
  
  const mapStateToProps = (state: RootState) => {
    return {
      user: convertFromReduxSafeUserState(state.user),
      pendingReviews: state.pendingReviews.value,
      filter: convertFilterFromReduxSafe(state.filter.value),
      transcriptFilter: convertFilterFromReduxSafe(state.transcriptFilter.value)
    };
  };
  
  const ReduxWrapped = connect(mapStateToProps)(HistoryImpl)
  
  export { ReduxWrapped as History}