import { MAX_LINES_TRIGGER_PROMPT } from "cfg/triggers";
import { EditableAutosizedInput, EditableList, EditableListProps } from "components/EditableList";
import { Filter, FilterOption, FilterOption__STRICT, FilterType } from "components/Filter";
import { FilterSelector, getDefaultFilterOption, getDefaultMetadataFilterOption, getDefaultMultiMetadataFilterOption, Option, PopulationFilter, PROSPECT_OPTIONS, ViewType } from "components/FilterSelector";
import { History } from "components/History";
import { Loader } from "components/Loader/Loader";
import { PromptOnLeave } from "components/PromptOnLeave";
import { sleep } from "core";
import { Action, AnyAction, Dispatch } from "@reduxjs/toolkit";
import { NO_LOOKBACK_RULE, NO_PARTY_ROLE, PartyRole, Remark, UserGroup } from "interfaces/db";
import { Condition, DEFAULT_LANGUAGE_SETTING, CnfFilterType, LookbackRule, Play, ProspectFilterTerm, TemporalFilterDisjunction, TimeFilterType, UserFilterTerm, UserSessionsResult, UserSessionsResultItem, TemporalFilterDisjunctionToStaticFilterDisjunction, TemporalFilterDisjunctionToCleanTemporalFilterDisjunction, LANGUAGE_SETTING_TO_HUMAN_READABLE, VisibleAccountsResult, ProspectInfoChoices } from "interfaces/services";
import { Typography } from "interfaces/typography";
import { convertFromReduxSafeVisibleAccounts } from "lib/redux/store";
import React from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { getServicesManager } from "services";
import { ELIGIBLE_FOR_SPANISH } from "cfg/const";
import { RootState } from "store";

enum SyncStatus {
    PENDING = 'PENDING',
    HAS_ERRORS = 'HAS_ERRORS',
    READY_TO_DEPLOY = 'READY_TO_DEPLOY',
    DEPLOYING = 'DEPLOYING',
    SAVED = 'SAVED'
}

export type RepSuggestion = {
    promptsProps: EditableListProps,
    reference?: React.RefObject<HTMLDivElement>
    focus?: boolean,
    errorFocus?: boolean,
    onErrorFocus?: () => void,
}

const checkMarkSvg = <svg width="" height="" viewBox="0 0 270 270" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30 180L90 240L240 30" stroke="white" strokeWidth="30"/>
</svg>

const _DEFAULT_DURATION_TIME = 8

function constructDefaultCondition(number: number): Condition {
    return {
        'active': true,
        'condition_group': '',
        'condition_id': _DEFAULT_ID,
        'temp_condition_id': uuid(),
        'condition_name': `Condition #${number}`,
        'created_at': new Date(),
        'filter_cnf': [],
        'team_id': '',
        'updated_at': new Date(),
    }
}

function constructDefaultPlay(condition: Condition, number: number, otherPlays: Play[]): Play {
    let minDuration = _DEFAULT_DURATION_TIME
    const otherPlayDurations = otherPlays.find((v) => v.min_duration !== null)
    if (otherPlayDurations && otherPlayDurations.min_duration !== null) minDuration = otherPlayDurations.min_duration
    else {
        const timeFilter = condition.filter_cnf.find((v) => v.filter_type === CnfFilterType.TIME && v.time_filters && v.time_filters.length > 0 && v.time_filters.filter((v) => v.start === undefined && v.end !== undefined).length === v.time_filters.length)
        if (timeFilter && timeFilter.time_filters && timeFilter.time_filters[0].end !== undefined) minDuration = _DEFAULT_DURATION_TIME    
    }

    return {
        'active': true,
        'condition_id': condition.condition_id,
        'temp_condition_id': condition.temp_condition_id,
        'created_at': new Date(),
        'min_duration': minDuration,
        'play_id': _DEFAULT_ID,
        'temp_play_id': uuid(),
        'play_name': `Suggestion #${number}`,
        'prompt_text': '',
        'team_id': '',
        'weight': 1,
        'updated_at': new Date(),
    }
}

function renderRemovalButton(onClick: () => void, showHidden?: boolean): JSX.Element {
    return ( 
    <div className="relative w-full justify-end flex h-0">
    <button onClick={onClick} tabIndex={-1} type="button" 
    style={showHidden === undefined ? {'zIndex': '999999999999999'} : {'zIndex': '999999999999999', 'display': showHidden ? 'flex' : 'none' }}
    className={
    "absolute -top-1.5 -right-2 h-3.5 w-3.5 items-center justify-center rounded-full border border-gray-200 bg-gray-100 cursor-pointer" + (showHidden === undefined ? " hidden group-hover:flex" : "")}>
        <div className="flex max-w-full grow">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="h-full w-full text-gray-500"><path d="M17 7 7 17M7 7l10 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
        </div> 
     </button>
    </div>)
}

type PlayProps = {
  play: Play
  onRemoval: () => void
  updatePlayName: (n: string) => void
  updatePlayWeight: (percentage: number) => void
  numberLeft: number
  EditableListProps: EditableListProps
}

type IndividualPlayState = {
    hovered: boolean
}

class PlayComponent extends React.Component<PlayProps, IndividualPlayState> {
    constructor(props: PlayProps) {
        super(props)
        this.state = {
            hovered: false,
        }
    }

    render() {
        return <div 
        onMouseEnter={() => this.setState({'hovered': true})}
        onMouseLeave={() => this.setState({'hovered': false})} 
        className='h-fit w-full' 
        style={{'boxShadow': '0px 3px 3px rgb(0 0 0 / 20%)', 'backgroundColor': '#FFFFFF', borderRadius: '10px', border: '1px solid rgba(91,101,123,0.25)', 'width': '320px'}}>
            {this.props.numberLeft > 1 ? renderRemovalButton(this.props.onRemoval, this.state.hovered) : null}            
            <div className="w-full flex flex-row pl-2 pr-2 pt-1 items-center justify-between gap-3">
                <div className="w-full flex flex-row gap-1 items-center">
                    <Typography variant="largeParagraph">
                        Name:
                    </Typography>
                    <EditableAutosizedInput 
                        fontSize={12}
                        editFunctions={{
                            'addEntry': () => {},
                            'removeEntry': () => {},
                            'updateEntry': (idx: number, updatedEntry: string) => {
                                this.props.updatePlayName(updatedEntry)
                            }
                        }}
                        placeholder={'Enter play name'}
                        prompt={this.props.play.play_name}
                        numberLeft={1}
                        idx={0}
                        maxWidth={"100px"}
                        minHeight={"15px"}
                    />
                </div>
                <div className="flex flex-row gap-1 items-center w-full justify-end">
                    <input 
                    onChange={(e) => {
                        let number = parseInt(e.target.value)
                        if (isNaN(number)) number = 0
                        this.props.updatePlayWeight(number)
                    }}
                    type="number" 
                    style={
                        {
                            'width': '50px',
                            'lineHeight': '0.75em',
                            'fontSize': '12px'
                        }
                    }
                    value={Math.round(100*this.props.play.weight)}
                    min={0} max={100} />
                    <Typography variant="largeParagraph" style={{'whiteSpace': 'nowrap'}}>
                        % of matches
                    </Typography>
                </div>
            </div>
            <EditableList {...this.props.EditableListProps}/>
        </div>
    }
}

type PlayConditionComponentProps = {
    plays: Play[],
    condition: Condition
    groupIdToGroup: Map<string, UserGroup>
    updateCondition: (updatedCondition: Condition) => void
    updatePlays: (updatedPlays: Play[]) => void
    onForceSave: () => void
    onRemove: () => void

    syncedStatus: SyncStatus
    syncErrors?: string[] 
    totalPlays: number
    totalPossibleMatches?: number
    onSelect: (selectedResults: UserSessionsResult) => void 
    visibleAccounts: VisibleAccountsResult | null
    prospectOptions: ProspectInfoChoices | null
}

type PlayConditionComponentState = {
    showErrors?: boolean
    showRemove: boolean
    matches?: {'params': TemporalFilterDisjunction[], 'result': {'count': number, 'items': UserSessionsResult}}
    backtestTimer: number
}

class PlayConditionComponentImpl extends React.Component<PlayConditionComponentProps, PlayConditionComponentState> {
    
    constructor(props: PlayConditionComponentProps) {
        super(props)
        this.state = {
            showRemove: false,
            showErrors: false,
            backtestTimer: 0,
        }
    }

    _jsonifyParams(params: any) {
        return Array.from(Object.entries({...params, 'deployRequested': undefined})).map(([key, value]) => JSON.stringify(value)).join('')
    }

    async _fetchData() {
        return await getServicesManager().backtestCondition(null, null, this.props.condition.filter_cnf)
    }

    componentDidMount(): void {
        this.setState({'backtestTimer': 1})
    }

    componentDidUpdate(prevProps: Readonly<PlayConditionComponentProps>, prevState: Readonly<PlayConditionComponentState>, snapshot?: any): void {
        if (this.canShowMatches() && this.state.backtestTimer - prevState.backtestTimer === 1) {
            this.backTestData(this.state.backtestTimer === 1)
        }
    }

    hasPlayErrors() {
        return this.props.syncErrors && this.props.syncErrors.some((v) => !v.includes('is missing suggestions'))
    }

    async backTestData(skipSleep: boolean) {
        if (!skipSleep) await sleep(1000)
        let result: null | UserSessionsResult = null
        const paramsUsed = this.props.condition.filter_cnf
        
        // has any bad filters - e.g., time
        if (!this.hasPlayErrors() &&
            (!this.state.matches || 
            this._jsonifyParams(this.state.matches.params) !== this._jsonifyParams(this.props.condition.filter_cnf) 
            )) {
            result = await getServicesManager().backtestCondition(null, null, this.props.condition.filter_cnf.map((v) => TemporalFilterDisjunctionToCleanTemporalFilterDisjunction(v)))
            if (!result) return // don't keep spamming the server
        }

        this.setState((state) => {
            return {
                'backtestTimer': state.backtestTimer + 1,
                'matches': result && this._jsonifyParams(paramsUsed) === this._jsonifyParams(this.props.condition.filter_cnf) ? {'params': this.props.condition.filter_cnf, 'result': {'count': result.length, 'items': result }} : state.matches
            }})
    }

    canShowMatches() {
        if (!this.props.visibleAccounts) return false
        return true
    }

    getMatches() {
        return this.state.matches && this._jsonifyParams(this.state.matches.params) === this._jsonifyParams(this.props.condition.filter_cnf) ? this.state.matches.result : undefined
    }

    _getDefaultFilterOption(option: Option) {
        return getDefaultFilterOption(option, undefined, undefined, this.props.prospectOptions ?? undefined, undefined, undefined, undefined, true)
    }

    _getDefaultMetadataFilterOption(option: Option) {
        return getDefaultMetadataFilterOption(option)
    }

    _getDefaultMetadataPlayFilterOption(
        option: Option, lookback_rule?: LookbackRule, 
        party_role?: PartyRole, lookback_horizon?: number,
        timeFilterType?: TimeFilterType) {
        return getDefaultMultiMetadataFilterOption(option, lookback_rule, party_role, lookback_horizon, timeFilterType)
    }

    _getEditableListProps(play: Play): EditableListProps {
        return {
            labels: play.prompt_text.split('\n'),
            editFunctions: {
              addEntry: () => {
                const correspondingPlayIdx = this.props.plays.findIndex((v) => (v.temp_play_id && v.temp_play_id === play.temp_play_id) || (v.play_id !== _DEFAULT_ID && v.play_id === play.play_id))
                if (correspondingPlayIdx === -1) return 
                const currentLabels = play.prompt_text.split('\n')
                const updatedLabels = [...currentLabels, '']
                const promptText: string = updatedLabels.join('\n')
                const updatedPlays: Play[] = [...this.props.plays.slice(0, correspondingPlayIdx), {...play, 'prompt_text': promptText}, ...this.props.plays.slice(correspondingPlayIdx + 1)]
                this.props.updatePlays(updatedPlays)
              },
              updateEntry: (idx: number, updatedEntry: string, forceRefreshOnEnter?: boolean) => {
                const correspondingPlayIdx = this.props.plays.findIndex((v) => (v.temp_play_id && v.temp_play_id === play.temp_play_id) || (v.play_id !== _DEFAULT_ID && v.play_id === play.play_id))
                if (correspondingPlayIdx === -1) return 
                const currentLabels = play.prompt_text.split('\n')
                if (idx >= currentLabels.length) return
                const updatedLabels = [...currentLabels.slice(0, idx), updatedEntry, ...currentLabels.slice(idx + 1)]
                const promptText: string = updatedLabels.join('\n')
                const updatedPlays: Play[] = [...this.props.plays.slice(0, correspondingPlayIdx), {...play, 'prompt_text': promptText}, ...this.props.plays.slice(correspondingPlayIdx + 1)]
                this.props.updatePlays(updatedPlays)
              },
              removeEntry: (idx: number) => {
                const correspondingPlayIdx = this.props.plays.findIndex((v) => (v.temp_play_id && v.temp_play_id === play.temp_play_id) || (v.play_id !== _DEFAULT_ID && v.play_id === play.play_id))
                if (correspondingPlayIdx === -1) return 
                const currentLabels = play.prompt_text.split('\n')
                if (idx >= currentLabels.length) return
                const updatedLabels = [...currentLabels.slice(0, idx), ...currentLabels.slice(idx + 1)]
                const promptText: string = updatedLabels.join('\n')
                const updatedPlays: Play[] = [...this.props.plays.slice(0, correspondingPlayIdx), {...play, 'prompt_text': promptText}, ...this.props.plays.slice(correspondingPlayIdx + 1)]
                this.props.updatePlays(updatedPlays)
              }
            },
            listNumberLimit: MAX_LINES_TRIGGER_PROMPT,
            onlyInputs: true,
        }
    }

    _convertFilterTypeToPopulationFilter(filterCnf: TemporalFilterDisjunction): PopulationFilter[] {
        const populationFilters: PopulationFilter[] = []
        if (filterCnf.filter_type === CnfFilterType.PROSPECT) {
            // prospect info
            if (!filterCnf.prospect_filters) return populationFilters
            const cadence_steps: string[] = []
            const cadences: string[] = []
            const industries: string[] = []
            const titles: string[] = []
            const seniorities: string[] = []
   
            for (const prospectInfo of filterCnf.prospect_filters) {
                if (prospectInfo.cadence_step) cadence_steps.push(prospectInfo.cadence_step)
                if (prospectInfo.cadence) cadences.push(prospectInfo.cadence)
                if (prospectInfo.industry) industries.push(prospectInfo.industry)
                if (prospectInfo.seniority) seniorities.push(prospectInfo.seniority)
                if (prospectInfo.title) titles.push(prospectInfo.title)
            }

            const hasCadenceStep = filterCnf.prospectFilterPrimarilyFor === 'CADENCE_STEP'
            const hasCadence = filterCnf.prospectFilterPrimarilyFor === 'CADENCE'
            const hasIndustry = filterCnf.prospectFilterPrimarilyFor === 'INDUSTRY'
            const hasTitles = filterCnf.prospectFilterPrimarilyFor === 'TITLE'
            const hasSeniorities = filterCnf.prospectFilterPrimarilyFor === 'SENIORITY'

            if (cadences.length > 0 || hasCadence) {
                populationFilters.push({
                    'option': Option.CADENCES,
                    'valueFilterOption': this._getDefaultFilterOption(Option.CADENCES).map((v) => {
                        return {...v, 'selected': cadences.includes(v.value)}
                    })
                })
            }

            if (cadence_steps.length > 0 || hasCadenceStep) {
                populationFilters.push({
                    'option': Option.CADENCE_STEPS,
                    'valueFilterOption': this._getDefaultFilterOption(Option.CADENCE_STEPS).map((v) => {
                        return {...v, 'selected': cadence_steps.includes(v.value)}
                    })
                })
            }


            if (industries.length > 0 || hasIndustry) {
                populationFilters.push({
                    'option': Option.INDUSTRIES,
                    'valueFilterOption': this._getDefaultFilterOption(Option.INDUSTRIES).map((v) => {
                        return {...v, 'selected': industries.includes(v.value)}
                    })
                })
            }

            if (titles.length > 0 || hasTitles) {
                populationFilters.push({
                    'option': Option.TITLES,
                    'valueFilterOption': this._getDefaultFilterOption(Option.TITLES).map((v) => {
                        return {...v, 'selected': titles.includes(v.value)}
                    })
                })
            }

            if (seniorities.length > 0 || hasSeniorities) {
                populationFilters.push({
                    'option': Option.SENIORITIES,
                    'valueFilterOption': this._getDefaultFilterOption(Option.SENIORITIES).map((v) => {
                        return {...v, 'selected': seniorities.includes(v.value)}
                    })
                })
            }
            return populationFilters
        } else if (filterCnf.filter_type === CnfFilterType.REMARK) {
            // remarks
            if (!filterCnf.remark_filters) return populationFilters
            const correspondingRemarks = filterCnf.remark_filters.map((v) => v.remark)
            populationFilters.push({
                'option': Option.REMARK,
                'valueFilterOption': this._getDefaultFilterOption(Option.REMARK).map((v) => {
                    return {
                        ...v, 
                        'selected': correspondingRemarks.includes(v.value as Remark)
                    }
                }), 
                'multiMetadataFilterOption': this._getDefaultMetadataPlayFilterOption(Option.REMARK, filterCnf.lookback_rule, undefined,  filterCnf.lookback_horizon)
            })
        } else if (filterCnf.filter_type === CnfFilterType.PHRASE) {
            // text option
            if (!filterCnf.phrase_filters) return populationFilters
            const partyRolesMatched = filterCnf.phrase_filters.filter((v) => v.party_role).map((v) => v.party_role as PartyRole)
            const partyRoleFilter = filterCnf.phraseFilterPrimarilyFor ? filterCnf.phraseFilterPrimarilyFor === 'PROSPECT' ? PartyRole.PROSPECT : filterCnf.phraseFilterPrimarilyFor === 'REP' ? PartyRole.REP : undefined :  undefined 
            const partyRole = partyRolesMatched.length > 0 ? partyRolesMatched[0] : partyRoleFilter
            const phraseFilters = filterCnf.phrase_filters
            populationFilters.push({
                'option': Option.TEXT,
                'valueFilterOption': phraseFilters.length > 0 ? phraseFilters.map((v) => {
                    return {
                        'label': v.text,
                        'value': v.text,
                        'selected': true,
                    }
                })  : this._getDefaultFilterOption(Option.TEXT),
                'multiMetadataFilterOption': this._getDefaultMetadataPlayFilterOption(Option.TEXT, filterCnf.lookback_rule, partyRole, filterCnf.lookback_horizon)
            })
        } else if (filterCnf.filter_type === CnfFilterType.TIME) {
            if (!filterCnf.time_filters) return populationFilters
            const startNode = filterCnf.time_filters.find((v) => v.start !== undefined)
            const endNode = filterCnf.time_filters.find((v) => v.end !== undefined)
            const preference = filterCnf.timeFilterPrimarilyFor ? filterCnf.timeFilterPrimarilyFor : (startNode && endNode ? TimeFilterType.BETWEEN : startNode ? TimeFilterType.AFTER : endNode? TimeFilterType.BEFORE : undefined) 

            populationFilters.push(
                {
                'option': Option.TIME,
                'valueFilterOption': [{
                    'label': 'START',
                    'selected': preference === TimeFilterType.BEFORE ? false : preference ? true : startNode !== undefined,
                    'value': startNode?.start ? startNode.start.toString() : ''
                }, 
                {
                    'label': 'END',
                    'selected': preference === TimeFilterType.AFTER ? false : preference ? true : endNode !== undefined,
                    'value': endNode?.end ? endNode.end?.toString() : ''
                }],
                'multiMetadataFilterOption': this._getDefaultMetadataPlayFilterOption(Option.TIME, undefined, undefined, undefined, preference)
            })
        } else if (filterCnf.filter_type === CnfFilterType.USER) {
            if (!filterCnf.user_filters) return populationFilters
            const users: string[] = []
            const subteams: string[] = []
            for (const x of filterCnf.user_filters) {
                if (x.user_id) users.push(x.user_id)
                if (x.user_group_id) subteams.push(x.user_group_id)
            }

            populationFilters.push({
                option: Option.USER_IDS,
                valueFilterOption: users.map(userId => {return {'value': userId, 'selected': true, 'label': this.props.visibleAccounts?.users?.find(u => u.user_id === userId)?.user_name ?? "(unknown user)"}}),
                groupsFilterOption: subteams.map(userGroupId => {return {'value': userGroupId, 'selected': true, 'label': this.props.groupIdToGroup.get(userGroupId)?.user_group_name ?? "(unknown team)"}}),
            })
        }
        return populationFilters
    }

    _convertConditionsToPopulationFilters(): PopulationFilter[] {
        if (!this.props.condition.filter_cnf) return []
        return this.props.condition.filter_cnf.map((v) => this._convertFilterTypeToPopulationFilter(v)).flat(1)
    }

    _convertPopulationFilterWithModifiers(populationFilter: PopulationFilter): TemporalFilterDisjunction | null {
        const partyRole = populationFilter.multiMetadataFilterOption?.PARTY_ROLE
        const lookbackRule = populationFilter.multiMetadataFilterOption?.LOOKBACK_RULE
        const timeHorizon = populationFilter.multiMetadataFilterOption?.LOOKBACK_HORIZON
        const timeFilterOption = populationFilter.multiMetadataFilterOption?.TIME_TYPE

        const partyRoleSelectedFound = partyRole ? (partyRole as FilterOption__STRICT[]).find((v) => v.selected) : undefined
        const partyRoleSelected = partyRoleSelectedFound ? partyRoleSelectedFound.value === NO_PARTY_ROLE ? undefined : partyRoleSelectedFound.value as PartyRole : undefined

        const lookbackRuleSelectedFound = lookbackRule ? (lookbackRule as FilterOption__STRICT[]).find((v) => v.selected) : undefined
        const lookbackRuleSelected = lookbackRuleSelectedFound ? lookbackRuleSelectedFound.value === NO_LOOKBACK_RULE ? undefined : lookbackRuleSelectedFound.value as LookbackRule : undefined

        const lookbackHorizon = timeHorizon ? timeHorizon as number : undefined

        if (populationFilter.option === Option.TEXT) {
            // get all the text matches
            return {
                'filter_type': CnfFilterType.PHRASE,
                'phrase_filters': populationFilter.valueFilterOption.filter((v) => v.selected).map((v) => {
                    return {
                        'language': this.getLanguageOption(),
                        'stem': this.getStemmingOption() ?? true,
                        'text': v.value.replaceAll(/[\u00b4\u2018\u2019\u2032\u2035\u275b\u275c]/g, "'").replaceAll(/[\u201c\u201d\u2033\u2036\u275d\u275e]/g, '"'),
                        'party_role': partyRoleSelected,
                    }
                }),
                'phraseFilterPrimarilyFor': partyRoleSelected === 'PROSPECT' ? 'PROSPECT' : partyRoleSelected === PartyRole.REP ? 'REP' : 'ANYONE',
                'lookback_rule': lookbackRuleSelected,
                'lookback_horizon': lookbackHorizon
            }
        } else if (populationFilter.option === Option.REMARK) {
            return {
                'filter_type': CnfFilterType.REMARK,
                'remark_filters': populationFilter.valueFilterOption.filter((v) => v.selected).map((v) => {
                    return {
                        'remark': v.value as Remark,
                        'party_role': undefined
                    }
                }),
                'lookback_rule': lookbackRuleSelected,
                'lookback_horizon': lookbackHorizon,
            }
        } else if (populationFilter.option === Option.TIME) {
            const timeRuleFound = timeFilterOption ? (timeFilterOption as FilterOption__STRICT[]).find((v) => v.selected) : undefined
            const timeRuleSelected = timeRuleFound ? timeRuleFound.value as TimeFilterType : undefined
            
            const startNode = populationFilter.valueFilterOption.find((v) => v.label === 'START' && v.selected)
            const start = startNode && !isNaN(parseInt(startNode.value)) ? parseInt(startNode?.value) : undefined
            const endNode = populationFilter.valueFilterOption.find((v) => v.label === 'END' && v.selected)
            const end = endNode && !isNaN(parseInt(endNode.value)) ? parseInt(endNode?.value) : undefined
            return {
                'filter_type': CnfFilterType.TIME,
                'time_filters': [{'start': start, 'end': end}],
                'timeFilterPrimarilyFor': timeRuleSelected
            }
        } else if ([Option.CADENCE_STEPS, Option.CADENCES, Option.SENIORITIES, Option.TITLES, Option.INDUSTRIES].includes(populationFilter.option)) {
            const selected = populationFilter.valueFilterOption.filter((v) => v.selected).map((v) => v.value)
            const prospectTempFilter: ProspectFilterTerm[] = selected.map((v) => {
                return {
                    'cadence_step': populationFilter.option === Option.CADENCE_STEPS ? v : undefined,
                    'cadence': populationFilter.option === Option.CADENCES ? v : undefined,
                    'seniority': populationFilter.option === Option.SENIORITIES ? v : undefined,
                    'title': populationFilter.option === Option.TITLES ? v : undefined, 
                    'industry': populationFilter.option === Option.INDUSTRIES ? v : undefined, 
                    'exact': true, 
                    'case_sensitive': true
                }})
            return {
                'filter_type': CnfFilterType.PROSPECT,
                'prospect_filters': prospectTempFilter,
                'prospectFilterPrimarilyFor': populationFilter.option === Option.CADENCE_STEPS ? 'CADENCE_STEP' : 
                populationFilter.option === Option.CADENCES ? 'CADENCE' : 
                populationFilter.option === Option.SENIORITIES ? 'SENIORITY' : 
                populationFilter.option === Option.TITLES ? 'TITLE' : 'INDUSTRY'
                } 
        }
        return null 
    }

    _updatePopulationFilters(filters: PopulationFilter[]) {
        // convert population filter to
        let temporalFilters: (TemporalFilterDisjunction | null)[] = filters.map((v) => this._convertPopulationFilterWithModifiers(v))
        const users: string[] = []
        const subteams: string[] = []
        const userFilters = filters.filter(v => v.option === Option.USER_IDS)
        for (const filter of userFilters) {
            users.push(...filter.valueFilterOption.filter(v => v.selected).map((v) => v.value))
            subteams.push(...(filter.groupsFilterOption?.filter(v => v.selected).map((v) => v.value) ?? []))
        }

        if (userFilters.length > 0) {
            const userFilterTerm: UserFilterTerm[] = [...users.map((v) => {return {'user_id': v}}), ...subteams.map((v) => {return {'user_group_id': v}})]
            
            // find the first user id filter and put it in there..... 
            const filterIdx = filters.findIndex(v => v.option === Option.USER_IDS)
            temporalFilters = [...temporalFilters.slice(0, filterIdx), {
                'filter_type': CnfFilterType.USER,
                'user_filters': userFilterTerm,
            }, ...temporalFilters.slice(filterIdx + 1)]
        }

        temporalFilters = temporalFilters.filter((v) => v)
        this.props.updateCondition({
            //@ts-ignore
            ...this.props.condition, 'filter_cnf': temporalFilters.filter((v) => v)
        })
    }

    _renderPlayOption(play: Play) {
        return <PlayComponent 
            key={play.temp_play_id ?? play.play_id}
            EditableListProps={this._getEditableListProps(play)}
            numberLeft={this.props.plays.length}
            onRemoval={
                () => {
                    const playToRemoveIdx = this.props.plays.findIndex((v) => (v.temp_play_id && v.temp_play_id === play.temp_play_id) || (v.play_id !== _DEFAULT_ID && v.play_id === play.play_id))
                    if (playToRemoveIdx === -1) return null
                    if (this.props.plays[playToRemoveIdx].play_id !== _DEFAULT_ID || this.props.plays[playToRemoveIdx].deployRequested) this.props.updatePlays([...this.props.plays.slice(0, playToRemoveIdx), {...this.props.plays[playToRemoveIdx], 'active': false}, ...this.props.plays.slice(playToRemoveIdx + 1)])
                    else this.props.updatePlays([...this.props.plays.slice(0, playToRemoveIdx), ...this.props.plays.slice(playToRemoveIdx + 1)])
                }
            }
            updatePlayName={(updatedName: string) => {
                const correspondingPlayIdx = this.props.plays.findIndex((v) => (v.temp_play_id && v.temp_play_id === play.temp_play_id) || (v.play_id !== _DEFAULT_ID && v.play_id === play.play_id))
                if (correspondingPlayIdx === -1) return null
                const updatedPlays: Play[] = [...this.props.plays.slice(0, correspondingPlayIdx), {...play, 'play_name': updatedName}, ...this.props.plays.slice(correspondingPlayIdx + 1)]
                this.props.updatePlays(updatedPlays)
            }}
            updatePlayWeight={(percentage: number) => {
            const correspondingPlayIdx = this.props.plays.findIndex((v) => (v.temp_play_id && v.temp_play_id === play.temp_play_id) || (v.play_id !== _DEFAULT_ID && v.play_id === play.play_id))
            if (correspondingPlayIdx === -1) return null
            if (this.props.plays.length <= 2) {
                // need to normal
                const remainingPercentage = Math.max(0, 100 - percentage)
                const updatedPlays: Play[] = this.props.plays.map((v, idx) => {
                    if (idx == correspondingPlayIdx) {
                        return {...v, 'weight': percentage / 100}
                    } else {
                        return {...v, 'weight': remainingPercentage / 100}
                    }
                })
                this.props.updatePlays(updatedPlays)
            } else {
                this.props.updatePlays([...this.props.plays.slice(0, correspondingPlayIdx), {...play, 'weight': percentage / 100}, ...this.props.plays.slice(correspondingPlayIdx + 1)])
            }
           }} 
           play={play}
        />
    }

    _addPlay() {
        this.props.updatePlays([...this.props.plays, {...constructDefaultPlay(this.props.condition, this.props.totalPlays + 1, this.props.plays), 'weight': 0}])
    }

    _renderSyncedStatus(): JSX.Element | null{
        if ([SyncStatus.SAVED].includes(this.props.syncedStatus)) {
            return <div className="w-5 h-5 flex flex-row justify-center items-center p-1.5" style={{'backgroundColor': 'green', 'borderRadius': '50%'}}>
                {checkMarkSvg}
            </div>
        }
        else if ([SyncStatus.PENDING].includes(this.props.syncedStatus)) {
            return <div className="w-5 h-5" style={{'backgroundColor': 'yellow', 'borderRadius': '50%'}}>
            </div>
        } else if ([SyncStatus.HAS_ERRORS].includes(this.props.syncedStatus)) {
            return <div onMouseEnter={() => this.setState({'showErrors': true})} onMouseLeave={() => this.setState({'showErrors': false})}
            className="pl-2 pr-2 h-5 cursor-pointer flex flex-row justify-center items-center text-center" style={{'backgroundColor': 'darkred', 'borderRadius': '10px'}}>
                <Typography variant="largeParagraph" color="white">
                    {`${this.props.syncErrors && this.props.syncErrors.length > 0 ? `Fix errors (${this.props.syncErrors.length})` : 'Fix errors!'}`}
                </Typography>
                <div className="relative w-0 h-0">
                    {this.state.showErrors && this.props.syncErrors ? <div className="absolute right-0 w-fit p-2 flex flex-col gap-2 text-left" style={{
                        'backgroundColor': 'lightpink',
                        'bottom': '15px',
                        'boxShadow': 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                        'zIndex': 999999,
                        'borderRadius': '10px',
                    }}>
                        {this.props.syncErrors.map((v, idx) => {return <Typography key={v} variant="largeParagraph" style={{'whiteSpace': 'nowrap', 'textAlign': 'left'}}>
                           {`${idx + 1}. ${v}`}
                        </Typography>})}
                    </div> : null}
                </div>
            </div>
        } else if ([SyncStatus.READY_TO_DEPLOY].includes(this.props.syncedStatus)) {
            return <div onClick={this.props.onForceSave.bind(this)}
            className="pl-2 pr-2 h-5 cursor-pointer flex flex-row justify-center items-center text-center opacity-80 hover:opacity-100" 
            style={{
                'boxShadow': 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px',
                'backgroundColor': 'rgba(30, 58, 138, 0.84)',
                'borderRadius': '10px'
            }}>
                <Typography variant="largeParagraph" color="white">
                    {this.props.condition.active ? "Click to deploy" : "Click to activate"}
                </Typography>
            </div>
        } else if ([SyncStatus.DEPLOYING].includes(this.props.syncedStatus)) {
            return <div onClick={this.props.onForceSave.bind(this)}
            className="pl-2 pr-2 h-5 flex flex-row justify-center items-center text-center" 
            style={{
                'boxShadow': 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px',
                'backgroundColor': 'lightgrey',
                'borderRadius': '10px'
            }}>
                <Typography variant="largeParagraph" color="black">
                    Deploying!
                </Typography>
            </div>
        }
        return null
    }

    _getDuration(): number {
        const durationNode = this.props.plays.find((v) => v.min_duration !== null)
        return durationNode?.min_duration ?? _DEFAULT_DURATION_TIME
    }
 
    _renderSuggestionBody(): JSX.Element {
        const duration = this._getDuration()
        return (
        <div className='flex flex-col' style={{'minWidth': '328px'}}>
            {renderRemovalButton(this.props.onRemove, this.state.showRemove)}
            <div className="h-full flex flex-col gap-2 justify-between"
                style={{'zIndex': 0} /* prevent popping over sticky elements like "New Play". Assumes descendants will not overflow. */}>
            <div className="flex flex-col gap-1.5 items-center">
            <div className="sticky top-0 w-full flex flex-row pt-0.5 pr-1.5 gap-2 items-center" style={{
                'backgroundColor': 'rgb(245 245 247)',
                'zIndex': '999999',
            }}>
            <Typography variant='h4'>
                {'Rep sees for:'}
            </Typography> 
            <div className="flex flex-row gap-2 items-center">
                <input 
                
                onChange={(e) => {
                    let number = parseInt(e.target.value)
                    if (isNaN(number)) number = 0
                    this.props.updatePlays([...this.props.plays].map((v) => {return {...v, 'min_duration': number}}))
                }}
                type="number" 
                style={
                    {
                        'width': '50px',
                        'lineHeight': '0.75em',
                        'fontSize': '12px'
                    }
                }
                value={duration ?? 0}
                min={3} max={100} />
                <Typography variant="largeParagraph" style={{'whiteSpace': 'nowrap'}}>
                    (s)
                </Typography>
            </div>
            </div>
            <div></div>
            <div className="flex flex-col gap-3 items-center pb-1.5 pt-1 pr-2">
            {this.props.plays.map((v) => this._renderPlayOption(v))}
            </div>
            </div>
            <div className="w-full flex flex-row items-center justify-between pt-1 pb-1 pl-1" style={
                {'backgroundColor': 'rgb(245 245 247)',
                'zIndex': '999999'}

            }>
                <div onClick={() => this.props.updatePlays([...this.props.plays, constructDefaultPlay(this.props.condition, this.props.totalPlays + 1, this.props.plays)])}
                className="pl-2 pr-2 h-5 cursor-pointer flex flex-row justify-center items-center text-center opacity-80 hover:opacity-100" 
                style={{
                    'boxShadow': 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px',
                    'backgroundColor': 'white',
                    'borderRadius': '10px'
                }}>
                    <Typography variant="largeParagraph" color="black">
                        {this.props.plays.length < 2 ? 'Add A/B Test' : 'Add additional A/B test'}
                    </Typography>
                </div>
                {this._renderSyncedStatus()}
                </div>
        </div>
        </div>)
    }

    getLanguageOption() {
        const phraseFilters = this.props.condition.filter_cnf.filter((v) => v.filter_type === CnfFilterType.PHRASE && v.phrase_filters)
        const languageOptions = phraseFilters.map((v) => (v.phrase_filters ?? []).map((x) => x.language).flat(1)).flat(1)
        return languageOptions.length > 0 ? languageOptions[0] : DEFAULT_LANGUAGE_SETTING.ENGLISH
    }

    canShowLanguage(): boolean {
        return this.props.visibleAccounts !== null && this.props.visibleAccounts.users.length > 0 && ELIGIBLE_FOR_SPANISH.includes(this.props.visibleAccounts.users[0].team_id) && this.props.condition.filter_cnf.find((v) => v.filter_type === CnfFilterType.PHRASE) !== undefined
    }

    getStemmingOption(): boolean | undefined {
        const phraseFilters = this.props.condition.filter_cnf.filter((v) => v.filter_type === CnfFilterType.PHRASE && v.phrase_filters && v.phrase_filters.length > 0).map((v) => (v.phrase_filters ?? [])).flat(1)
        if (phraseFilters.length === 0) return undefined
        const nonStemming = phraseFilters.find((v) => !v.stem) !== undefined
        return !nonStemming
    }

    render() {
        const matches = this.getMatches()
        const languageOption = this.getLanguageOption()
        return <div className="w-full h-fit flex flex-row p-1 gap-3" 
        onMouseEnter={() => this.setState({'showRemove': true})}
        onMouseLeave={() => this.setState({'showRemove': false})}
        style={{
            'borderRadius': '10px',
            'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)',
            'backgroundColor': 'hsl(260deg 11% 95% / 63%)',
            'maxWidth': '1200px',
        }}>
            <FilterSelector
            onMatchClick={() => {
                const matches = this.getMatches()
                if (matches) {
                    this.props.onSelect(matches.items)
                    return
                }
            }}
            showMatches={this.canShowMatches()}
            numberMatches={matches ? matches.count : undefined} 
            totalPossibleMatches={this.props.totalPossibleMatches}
            canShowOption={[Option.USER_IDS, Option.TEXT, Option.TIME, ...PROSPECT_OPTIONS]}
            populationFilters={this._convertConditionsToPopulationFilters()}
            updatePopulationFilters={this._updatePopulationFilters.bind(this)}
            isCoachingView={false}
            viewType={ViewType.TRIGGERS}
            noTextDelay={true}
            startWithExpandedFilter={this.props.condition.condition_id === _DEFAULT_ID}
            conditionName={this.props.condition.condition_name ?? undefined}
            updateConditionName={(name: string) => this.props.updateCondition({...this.props.condition, 'condition_name': name})}
            canShowLanguageOption={this.canShowLanguage()}
            updateLanguageOption={(updatedFilters: FilterOption[]) => {
                const selected = updatedFilters.find((x) => x.selected)
                if (!selected) return
                const newLanguage = selected.value as DEFAULT_LANGUAGE_SETTING 
                const updatedCnf = this.props.condition.filter_cnf.map((v) => {
                    if (v.filter_type !== CnfFilterType.PHRASE || !v.phrase_filters) return v
                    return {...v, 'phrase_filters': v.phrase_filters.map((x) => {return {...x, 'language': newLanguage}})}
                })
                this.props.updateCondition({...this.props.condition, 'filter_cnf': updatedCnf})
            }}
            stemmingOption={this.getStemmingOption()}
            updateStemmingOption={(value: boolean) => {
                const updatedCnf = this.props.condition.filter_cnf.map((v) => {
                    if (v.filter_type !== CnfFilterType.PHRASE || !v.phrase_filters) return v
                    return {...v, 'phrase_filters': v.phrase_filters.map((x) => {return {...x, 'stem': value}})}
                })
                this.props.updateCondition({...this.props.condition, 'filter_cnf': updatedCnf})
            }}
            languageOption={Object.values(DEFAULT_LANGUAGE_SETTING).map((v) => {return {'label': LANGUAGE_SETTING_TO_HUMAN_READABLE[v], 'value': v, 'selected': v === languageOption}})}
            />
            <div>
            </div>
            {this._renderSuggestionBody()}
        </div>
    }
}

const PlayConditionComponent = connect((state: RootState) => {
    return {
        visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts, (u) => u.can_dial && u.team_is_active),
        prospectOptions: state.prospectInfoOptions.value,
    }
})(PlayConditionComponentImpl)

enum TabOption {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive'
}

export type PlayState = {
    plays: Play[] | null
    conditions: Condition[] | null

    savedConditions: Condition[] | null
    savedPlays: Play[] | null
    counter: number

    showTranscript: boolean
    selectedResults?: UserSessionsResult
    selectedCondition?: Condition

    totalPossibleMatches?: number
    selectedTab: TabOption
}

const _DEFAULT_ID = '__DEFAULT__'


type PlaysProps<A extends Action = AnyAction> = {
    dispatch: Dispatch<A>;
    groupIdToGroup: Map<string, UserGroup>
    groupsHasLoaded: boolean
    visibleAccounts: VisibleAccountsResult | null
}

class Plays extends React.Component<PlaysProps, PlayState> {
    constructor(props: PlaysProps) {
        super(props)
        this.state = {
            plays: null,
            conditions: null,
            savedConditions: null,
            savedPlays: null,
            counter: 0,
            showTranscript: false,
            selectedTab: TabOption.ACTIVE
        }
    }

    componentDidUpdate(prevProps: Readonly<PlaysProps>, prevState: Readonly<PlayState>, snapshot?: any): void {
        if (this.state.counter - prevState.counter === 1) {
            this._onSave()
        }
    }

    _jsonifyParams(params: any) {
        return Array.from(Object.entries({...params, 'deployRequested': undefined})).map(([key, value]) => JSON.stringify(value)).join('')
    }

    _updateCounter() {
        this.setState((state) => {return {'counter': state.counter + 1}})
    }

    async _onSave() {
        await sleep(2000)
        if (!this.state.conditions || !this.state.plays || !this.state.savedPlays || !this.state.savedConditions) {
            this._updateCounter()
            return 
        }// get the conditions and play
        
        // check if there are any pending conditions that are ready to be saved and deploy has been requested (?) 
        // save anything with a pending tag
        const newConditions: Condition[] = []
        const updatedConditions: Condition[] = []

        const conditionStates = this.state.conditions.map((v) => this._getConditionSyncStatus(v))
        for (let i = 0; i < conditionStates.length; i++) {
            // find matching condition in the saved condition
            const conditionState = conditionStates[i]
            if ([SyncStatus.DEPLOYING].includes(conditionState.status)) {
                newConditions.push(this.state.conditions[i])
            } else if ([SyncStatus.PENDING].includes(conditionState.status)) {
                updatedConditions.push(this.state.conditions[i])
            }
        }

        const tempConditionIdToTrueConditionId: {[k: string]: string} = {}

        for (const newCondition of newConditions) {
            const newConditionUpdated = await getServicesManager().putCondition(null, newCondition.filter_cnf, newCondition.active, newCondition.condition_name, newCondition.condition_group)
            if (!newConditionUpdated) {
                // you errored out
                return 
            }
            if (newCondition.temp_condition_id) tempConditionIdToTrueConditionId[newCondition.temp_condition_id] = newConditionUpdated.condition_id
        }

        for (const updatedCondition of updatedConditions) {
            const updatedConditionResult = await getServicesManager().putCondition(updatedCondition.condition_id, updatedCondition.filter_cnf, updatedCondition.active, updatedCondition.condition_name, updatedCondition.condition_group)
            if (!updatedConditionResult) {
                return
            }
        }

        // given we now have saved some conditions, we can basically save any plays that have deploying

        const newPlays: Play[] = []
        const updatedPlays: Play[] = []

        const playStates = this.state.plays.map((v) => this._getPlaySyncStatus(v))
        for (let i = 0; i < playStates.length; i++) {
            const playState = playStates[i]
            const play = this.state.plays[i]
            if ([SyncStatus.DEPLOYING].includes(playState.status)) {
                // if it's in a deploying state 
                const correspondingCondition = this.state.conditions.find((v) => (v.condition_id !== _DEFAULT_ID && v.condition_id === play.condition_id) || (v.temp_condition_id && v.temp_condition_id === play.temp_condition_id))
                if (!correspondingCondition || 
                    (correspondingCondition.condition_id === _DEFAULT_ID && correspondingCondition.temp_condition_id && !tempConditionIdToTrueConditionId[correspondingCondition.temp_condition_id])) {
                        continue
                } 
                newPlays.push(play)
            } else if ([SyncStatus.PENDING].includes(playState.status)) {
                updatedPlays.push(play)
            }
        }


        const tempPlayIdToTruePlayId: {[k: string]: string} = {}
        
        for (const newPlay of newPlays) {
            const correspondingCondition = this.state.conditions.find((v) => (v.condition_id !== _DEFAULT_ID && v.condition_id === newPlay.condition_id) || (v.temp_condition_id && v.temp_condition_id === newPlay.temp_condition_id))
            if (!correspondingCondition) continue
            const conditionId = correspondingCondition.condition_id !== _DEFAULT_ID ? correspondingCondition.condition_id : correspondingCondition.temp_condition_id ? tempConditionIdToTrueConditionId[correspondingCondition.temp_condition_id] : undefined
            if (!conditionId) continue
            const newPlayUpdated = await getServicesManager().putPlay(null, conditionId, newPlay.active, newPlay.prompt_text, newPlay.weight, newPlay.play_name, newPlay.min_duration)
            if (!newPlayUpdated) {
                return
            }
            if (newPlay.temp_play_id) tempPlayIdToTruePlayId[newPlay.temp_play_id] = newPlayUpdated.play_id
        }

        for (const updatedPlay of updatedPlays) {
            const updatedPlayResult = await getServicesManager().putPlay(updatedPlay.play_id, updatedPlay.condition_id, updatedPlay.active, updatedPlay.prompt_text, updatedPlay.weight, updatedPlay.play_name, updatedPlay.min_duration)
            if (!updatedPlayResult) {
                return
            }
        }

        this.setState((state) => {
            if (!state.conditions || !state.savedConditions || !state.plays || !state.savedPlays) return null
            // need to adjust ids for anything where we made a new id 
            // for modifications we need to copy the new values in ... 
            const newConditionIds = newConditions.map((v) => v.temp_condition_id)
            const newPlayIds = newPlays.map((v) => v.temp_play_id ?? '')

            const update_conditions: Condition[] = [...state.conditions].map((v) => {
                return v.temp_condition_id && newConditionIds.includes(v.temp_condition_id) ? {...v, 'condition_id': tempConditionIdToTrueConditionId[v.temp_condition_id]} : v
            })

            const update_play: Play[] = [...state.plays].map((v) => {
                return {
                    ...v, 
                    'condition_id': v.temp_condition_id && newConditionIds.includes(v.temp_condition_id) ? tempConditionIdToTrueConditionId[v.temp_condition_id] : v.condition_id, 
                    'play_id': v.temp_play_id && newPlayIds.includes(v.temp_play_id) ? tempPlayIdToTruePlayId[v.temp_play_id] : v.play_id 
                }
            })

            // need to add the new one and add modifications
            const updated_saved_conditions: Condition[] = [...state.savedConditions, ...newConditions].map((v) => {
                const conditionId =  v.temp_condition_id && newConditionIds.includes(v.temp_condition_id) ? tempConditionIdToTrueConditionId[v.temp_condition_id] : v.condition_id
                const modifiedCondition = updatedConditions.find((x) => x.condition_id === v.condition_id)
                if (modifiedCondition) {
                    return {...v, ...modifiedCondition, 'condition_id': conditionId}
                } else {
                    return {...v, 'condition_id': conditionId}
                }
            })

            const updated_saved_plays: Play[] = [...state.savedPlays, ...newPlays].map((v) => {
                const conditionId =  v.temp_condition_id && newConditionIds.includes(v.temp_condition_id) ? tempConditionIdToTrueConditionId[v.temp_condition_id] : v.condition_id
                const playId = v.temp_play_id && newPlayIds.includes(v.temp_play_id) ? tempPlayIdToTruePlayId[v.temp_play_id] : v.play_id

                const modifiedPlay = updatedPlays.find((x) => x.play_id === v.play_id)
                
                if (modifiedPlay) {
                    return {...v, ...modifiedPlay, 'play_id': playId, 'condition_id': conditionId}
                } else {
                    return {...v, 'play_id': playId, 'condition_id': conditionId}
                }
            })

            return {
                'conditions': update_conditions,
                'plays': update_play,
                'savedConditions': updated_saved_conditions,
                'savedPlays': updated_saved_plays,
                'counter': state.counter + 1
            }
        })
    }

    _getPlaySyncStatus(play: Play): {'status': SyncStatus, 'errors'?: string[]} {
        if (!this.state.conditions || !this.state.plays || !this.state.savedConditions || !this.state.savedPlays) return {'status': SyncStatus.HAS_ERRORS, 'errors': ['Still loading data...']}
        const correspondingConditionSaved = this.state.savedConditions.find((v) => (v.condition_id !== _DEFAULT_ID && v.condition_id === play.condition_id))
        // if there is no saved corresponding condition, check if its because the matching condition has errors 
        if (!correspondingConditionSaved) {
            const correspondingConditionMatched = this.state.conditions.find((v) => (v.condition_id !== _DEFAULT_ID && v.condition_id === play.condition_id) || (v.temp_condition_id && v.temp_condition_id === play.temp_condition_id))
            if (!correspondingConditionMatched) return {'status': SyncStatus.HAS_ERRORS, 'errors': ['Play has no matching condition']}
        }

        // check if its in the saved plays
        const matchingSavedPlay = this.state.savedPlays.find((v) => (v.play_id !== _DEFAULT_ID && v.play_id === play.play_id))
        if (!matchingSavedPlay || 
            this._jsonifyParams(matchingSavedPlay) !== this._jsonifyParams(play)) {
            // figure out if its a pending issue or if we need to actually fix this thing... 
            if (play.prompt_text.split('\n').every((v) => v === '')) {
                return {'status': SyncStatus.HAS_ERRORS, 'errors': [`${play.play_name} is missing suggestions`]}
            } else {
                return {'status': play.play_id !== _DEFAULT_ID ? SyncStatus.PENDING : play.deployRequested ? SyncStatus.DEPLOYING : SyncStatus.READY_TO_DEPLOY} 
            }
        } else {
            return {'status': SyncStatus.SAVED}
        }
    } 

    _errorCheckCnf(filters: TemporalFilterDisjunction[]): string[] {
        const errors = []
        if (filters.length === 0) {
            errors.push('Requires a filter for condition to work')
        }

        for (const filter of filters) {
            if (filter.filter_type === CnfFilterType.USER) {
                if (!filter.user_filters || filter.user_filters.length === 0 || filter.user_filters.some((v) => !v.user_group_id && !v.user_id)) {
                    errors.push('Must select an option for users or subteams')
                }
            } else if (filter.filter_type === CnfFilterType.PROSPECT) {
                if (!filter.prospect_filters || filter.prospect_filters.length === 0 || filter.prospect_filters.some((v) => !v.cadence_step && !v.cadence && !v.industry && !v.seniority && !v.title)) {
                    errors.push(`Must select an option for ${filter.prospectFilterPrimarilyFor === 'CADENCE_STEP' ? 'cadence step' : filter.prospectFilterPrimarilyFor === 'CADENCE' ? 'cadence' : filter.prospectFilterPrimarilyFor === 'INDUSTRY' ? 'industry' : filter.prospectFilterPrimarilyFor === 'SENIORITY' ? 'seniority' : filter.prospectFilterPrimarilyFor === 'TITLE' ? 'title' : 'prospect filter'}`)
                }
            } else if (filter.filter_type === CnfFilterType.TIME) {
                if (!filter.time_filters || filter.time_filters.length === 0) errors.push('Requires a time filter')
                if (filter.time_filters && filter.time_filters.some((v) => v.start === undefined && v.end === undefined)) errors.push('Must provide a time option')
                if (filter.time_filters && filter.time_filters.some((v) => v.start && v.end && v.start >= v.end)) errors.push('Start time must be less than end time')
            } else if (filter.filter_type === CnfFilterType.PHRASE) {
                if (!filter.phrase_filters || filter.phrase_filters.length === 0) errors.push('Requires a phrase filter')
                if (filter.phrase_filters && filter.phrase_filters.map((v) => v.text).find((v) => v === '') !== undefined) errors.push('Must provide a valid text filter - no empty strings') 
            } else if (filter.filter_type === CnfFilterType.REMARK) {
                if (!filter.remark_filters || filter.remark_filters.length === 0) errors.push('Requires a remark filter')
            }
            if (filter.lookback_rule !== undefined && ![CnfFilterType.PHRASE, CnfFilterType.REMARK].includes(filter.filter_type)) {
                errors.push('Cannot apply a lookback for non-phrase/remark filters')
            }

            if (filter.lookback_horizon !== undefined && !filter.lookback_rule) {
                errors.push('Cannot specify a lookback horizon')
            }
        }
        return errors
    }

    _getConditionSyncStatus(condition: Condition): {'status': SyncStatus ,'errors'?: string[]} {
        if (!this.state.conditions || !this.state.plays || !this.state.savedConditions || !this.state.savedPlays) return {'status': SyncStatus.HAS_ERRORS, 'errors': ['Still loading data...']}
        const correspondingCondition = this.state.savedConditions.find((v) => (v.condition_id !== _DEFAULT_ID && v.condition_id === condition.condition_id))
        if (!correspondingCondition || 
            this._jsonifyParams({...correspondingCondition}) !== this._jsonifyParams({...condition})) {
            // check if the condition is ok to be saved 
            const errors = this._errorCheckCnf(condition.filter_cnf)
            if (errors.length > 0) return {'status': SyncStatus.HAS_ERRORS, 'errors': errors}
            else return {'status': condition.condition_id !== _DEFAULT_ID ? SyncStatus.PENDING : condition.deployRequested ? SyncStatus.DEPLOYING : SyncStatus.READY_TO_DEPLOY}
        } else {

            return {'status': SyncStatus.SAVED}
        }
    }

    _calculateSyncStatusAndPotentialErrors(condition: Condition, plays: Play[]): {'status': SyncStatus, 'errors'?: string[]} {
        // check if condition is ready to be saved --- if not err
        const conditionStatus = this._getConditionSyncStatus(condition)
        let playStatus = []
        if (plays.length === 0) {
            playStatus = [{'status': SyncStatus.HAS_ERRORS, 'errors': ['Missing suggestion']}]
        }
        else {
            playStatus = plays.map((v) => this._getPlaySyncStatus(v))
        }

        // if all of them are pending.... 
        const allStatuses = [conditionStatus, ...playStatus]

        // any errors? 
        const errorStatuses = allStatuses.filter((v) => v.status === SyncStatus.HAS_ERRORS)
        if (errorStatuses.length > 0) {
            const errors: string[] = errorStatuses.map((v) => (v.errors ?? [])).flat(1)
            return {'status': SyncStatus.HAS_ERRORS, 'errors': errors}
        }
        
        const pending = allStatuses.find((v) => v.status === SyncStatus.PENDING)
        if (pending !== undefined) return {'status': SyncStatus.PENDING}

        const readyToDeploy = allStatuses.find((v) => v.status === SyncStatus.READY_TO_DEPLOY)
        if (readyToDeploy !== undefined) return {'status': SyncStatus.READY_TO_DEPLOY}

        const deploying = allStatuses.find((v) => v.status === SyncStatus.DEPLOYING)
        if (deploying !== undefined) return {'status': SyncStatus.DEPLOYING}

        return {'status': SyncStatus.SAVED}
    }

    componentDidMount(): void {
        getServicesManager().getPlays().then((v) => {
            this.setState({'plays': v, 'savedPlays': v})
        })

        getServicesManager().getConditions().then((v) => {
            const conditions = v
            if (conditions) conditions.sort((a, b) => a.created_at.getTime() - b.created_at.getTime())
            this.setState({'conditions': conditions, 'savedConditions': conditions})
        })

        this.setState({'counter': 1})
    }

    _addNewCondition() {
        this.setState((state) => {
            if (!state.plays || !state.conditions) return null
            const newCondition: Condition = constructDefaultCondition(state.conditions.length + 1)
            const newPlay: Play =  constructDefaultPlay(newCondition, state.plays.length + 1, [])
            return {
                'conditions': [...state.conditions, newCondition],
                'plays': [...state.plays, newPlay]
            }
        })
    } 

    _addNewOpener() {
        this.setState((state) => {
            if (!state.plays || !state.conditions) return null
            const newCondition: Condition = constructDefaultCondition(state.conditions.length + 1)
            newCondition.filter_cnf = [{'filter_type': CnfFilterType.TIME, 'timeFilterPrimarilyFor': TimeFilterType.BEFORE, 'time_filters': [{'start': undefined, 'end': 40}]}]
            const newPlay: Play =  constructDefaultPlay(newCondition, state.plays.length + 1, [])
            return {
                'conditions': [...state.conditions, newCondition], 
                'plays': [...state.plays, newPlay]
            }
        })
    }

    _hasPending() {
        if (!this.state.conditions || !this.state.plays || !this.state.savedConditions || !this.state.savedPlays) return false
        const conditionStatus = this.state.conditions.find((v) => {
            const status = this._getConditionSyncStatus(v)
            if ([SyncStatus.PENDING, SyncStatus.DEPLOYING, SyncStatus.HAS_ERRORS].includes(status.status)) return true
            if (status.status === SyncStatus.READY_TO_DEPLOY && v.condition_id === _DEFAULT_ID) return true
            return false
         })
         if (conditionStatus) return true 

         const playStatus = this.state.plays.find((v) => {
            const status = this._getPlaySyncStatus(v)
            if ([SyncStatus.PENDING, SyncStatus.DEPLOYING, SyncStatus.HAS_ERRORS].includes(status.status)) return true
            if (status.status === SyncStatus.READY_TO_DEPLOY && v.play_id === _DEFAULT_ID) return true
            return false
         })
         if (playStatus) return true
         return false 
    }

    _renderTranscriptsOnSelect() { 
        return ( 
        <div onClick={() => this.setState({'showTranscript': false})} className='absolute w-full h-screen top-0' style={{'width': 'calc(100vw - 90px)','backgroundColor': 'transparent', 'zIndex': 9999999999}}>
        <div className='absolute w-full h-screen' style={{'left': '0px', 'top': '0px', 'width': 'calc(100vw - 90px)','backgroundColor': 'black', 'opacity': '0.2', 'zIndex': 100}} >
        </div>
        <div className='flex justify-center items-center absolute w-full h-screen' style={{'left': '0px', 'top': '0px', 'width': 'calc(100vw - 90px)','backgroundColor': 'transparent', 'zIndex': 101}} >
          <div onClick={(e) => e.stopPropagation()} className='w-5/6 h-5/6' style={{'backgroundColor': 'white', 'zIndex': '9999', 'borderRadius': '10px'}}>
              <History
              temporalCnfForce={this.state.selectedCondition?.filter_cnf}
              hideTranscriptFilter={true}
              preloadedResults={(this.state.selectedResults ?? []).slice(0, 75)} 
              hideDefaults={true} 
              minimalView={true} 
              heightFull={true} 
              noNavigation={true}/>
          </div>
        </div>
        </div>)
    }

    renderCondition(v: Condition): JSX.Element | null {
        const correspondingPlays = this.state.plays?.filter((x) => x.active && ((x.temp_condition_id && x.temp_condition_id === v.temp_condition_id) || (v.condition_id !== _DEFAULT_ID && v.condition_id === x.condition_id))) ?? []
        const correspondingConditionId = v.condition_id !== _DEFAULT_ID ? v.condition_id : v.temp_condition_id
        if (!correspondingConditionId) return null
        const status = this._calculateSyncStatusAndPotentialErrors(v, correspondingPlays)
        return <PlayConditionComponent 
        key={correspondingConditionId}
        onSelect={(selectedResults: UserSessionsResult) => {
            this.setState({
                'showTranscript': true, 
                'selectedCondition': v,
                'selectedResults': selectedResults})
        } 
        }
        totalPlays={(this.state.plays ?? []).length}
        totalPossibleMatches={this.state.totalPossibleMatches}
        onRemove={() => {
            this.setState((state) => {
                if (!state.conditions || !state.plays || !correspondingConditionId) return null
                const correspondingConditionIdx = state.conditions.findIndex((v) => (v.condition_id !== _DEFAULT_ID && v.condition_id === correspondingConditionId) || (v.temp_condition_id && v.temp_condition_id === correspondingConditionId))
                if (correspondingConditionIdx === -1) return null
                const updatedPlays = [...state.plays].filter((x) => {
                    if ((x.temp_condition_id && x.temp_condition_id === v.temp_condition_id) || (v.condition_id !== _DEFAULT_ID && v.condition_id === x.condition_id)) {
                        if (x.play_id === _DEFAULT_ID && !x.deployRequested) return false
                    } 
                    return true
                }).map((x) => {
                    if (x.temp_condition_id && x.temp_condition_id === v.temp_condition_id || (v.condition_id !== _DEFAULT_ID && v.condition_id === x.condition_id)) return {...x, 'active': false}
                    return {...x}
                })

                let updatedConditions: Condition[] = []
                const correspondingCondition = state.conditions[correspondingConditionIdx]
                if (correspondingCondition.condition_id !== _DEFAULT_ID || correspondingCondition.deployRequested) {
                    updatedConditions = [...state.conditions.slice(0, correspondingConditionIdx), {...state.conditions[correspondingConditionIdx], 'active': false}, ...state.conditions.slice(correspondingConditionIdx + 1)]
                } else {
                    updatedConditions = [...state.conditions.slice(0, correspondingConditionIdx), ...state.conditions.slice(correspondingConditionIdx + 1)]
                }

                return {
                    'conditions': [...state.conditions.slice(0, correspondingConditionIdx), {...state.conditions[correspondingConditionIdx], 'active': false}, ...state.conditions.slice(correspondingConditionIdx + 1)],
                    'plays': updatedPlays,
                }
            })
        }}
        onForceSave={() =>{
            // for all the plays and conditions... switch their requiested state to requested
            this.setState((state) => {
                if (!state.conditions || !state.plays) return null
                const correspondingConditionIdx = state.conditions.findIndex((v) => v.condition_id === correspondingConditionId || v.temp_condition_id === correspondingConditionId)
                if (correspondingConditionIdx === -1) return null
                const updatedPlays: Play[] = [...state.plays].map((v) => {
                    const includedPlay = (v.temp_condition_id && v.temp_condition_id === correspondingConditionId) || (v.condition_id && v.condition_id === correspondingConditionId)
                    return includedPlay && v.active ? {...v, 'deployRequested': true} : {...v}
                })

                return {
                    'conditions': [...state.conditions.slice(0, correspondingConditionIdx), {...state.conditions[correspondingConditionIdx], 'deployRequested': true, 'active': true}, ...state.conditions.slice(correspondingConditionIdx + 1)],
                    'plays': updatedPlays
                }
            })
        }}
        syncedStatus={status.status}
        syncErrors={status.errors}
        updateCondition={(updatedCondition: Condition) => {
            // find matching condition
            this.setState((state) => {
                if (!state.conditions || !correspondingConditionId) return null
                // find the condition 
                const correspondingConditionIdx = state.conditions.findIndex((v) => v.condition_id === correspondingConditionId || v.temp_condition_id === correspondingConditionId)
                if (correspondingConditionIdx === -1) return null
                const currentCondition = state.conditions[correspondingConditionIdx]
                return {
                    'conditions': [
                        ...state.conditions.slice(0, correspondingConditionIdx), 
                        {...updatedCondition, 'condition_id': currentCondition.condition_id, 'temp_condition_id': currentCondition.temp_condition_id},
                        ...state.conditions.slice(correspondingConditionIdx + 1), 
                    ]
                }
            })
        }} 
        updatePlays={(updatedPlays: Play[]) => {
            this.setState((state) => {
                // find corresponding plays and update the values 
                if (!state.plays) return null
                const removedPlays = correspondingPlays.filter((v) => updatedPlays.find((x) => (x.temp_play_id && x.temp_play_id === v.temp_play_id) || (x.play_id !== _DEFAULT_ID && x.play_id === v.play_id)) === undefined)
                const newPlays = updatedPlays.filter((v) => correspondingPlays.find((x) => (x.temp_play_id && x.temp_play_id === v.temp_play_id) || (x.play_id !== _DEFAULT_ID && x.play_id === v.play_id)) === undefined)
                
                let updatedStatePlays = [...state.plays].filter((v) => {
                    return removedPlays.find((x) => (x.temp_play_id && x.temp_play_id === v.temp_play_id) || (x.play_id !== _DEFAULT_ID && x.play_id === v.play_id)) === undefined
                }).map((v) => {
                    const matchedPlay = updatedPlays.find((x) => (x.temp_play_id && x.temp_play_id === v.temp_play_id) || (x.play_id !== _DEFAULT_ID && x.play_id === v.play_id))
                    return matchedPlay ? {...matchedPlay} : v
                })
                updatedStatePlays = [...updatedStatePlays, ...newPlays]
                return {
                    'plays': updatedStatePlays
                }
            })
        }}
        condition={v} 
        plays={correspondingPlays} 
        groupIdToGroup={this.props.groupIdToGroup}/>
    }

    renderTabSelection() {
        return <div className="w-full flex flex-row justify-center items-center">
            <Filter 
            filterType={FilterType.SINGLE}
            variant={'largeParagraph'}
            selectableFilterProps={{
                'showAll': true,
                'removeOuterBoxForShowAll': true,
                'filterOptions': Object.values(TabOption).map((v) => {return {'label': v, 'selected': v === this.state.selectedTab, 'value': v}}),
                'onFilterUpdate': (updatedFilters: FilterOption[]) => {
                    const selected = updatedFilters.find((v) => v.selected)
                    if (!selected) return 
                    this.setState({'selectedTab': selected.value as TabOption})
                }
            }}
            />
        </div>
    }

    render() {
        if (!this.state.plays || !this.state.savedPlays || !this.state.conditions || !this.state.savedConditions) return <Loader />
        return <div className="p-3 h-full min-w-full w-fit flex flex-col gap-5 items-center" style={{'userSelect': 'text'}}>
        {this.state.showTranscript ? this._renderTranscriptsOnSelect() : null}
        <PromptOnLeave when={this._hasPending()} message={'You have unsaved changes. Sure you want to leave'}/>
        {this.renderTabSelection()}
        {this.state.conditions && this.state.conditions.length > 0 && this.state.conditions ? this.state.conditions.filter((v) => this.state.selectedTab === TabOption.ACTIVE === v.active).map((v) => this.renderCondition(v)) : null}
       <div className="ml-auto bottom-0 p-2.5 sticky w-full flex flex-row gap-2 justify-center items-center text-center"
       style={{
        'pointerEvents': 'none',
        'zIndex': '999',
       }}
       >
        {<div onClick={this._addNewOpener.bind(this)} className={'flex opacity-80 p-3 w-fit  justify-center items-center hover:opacity-100 cursor-pointer'}  
        style={{
            'pointerEvents': 'all',
            'borderRadius': '10px', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)', 'backgroundColor': 'rgb(3, 33, 78)'}}> 
          <Typography variant="h2" color="white">
            {`(+) New Opener`}
          </Typography>
        </div>}
        {<div onClick={this._addNewCondition.bind(this)} className={'flex opacity-80 p-3 w-fit justify-center items-center hover:opacity-100 cursor-pointer'}  
        style={{
            'pointerEvents': 'all',
            'borderRadius': '10px', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)', 'backgroundColor': 'rgb(3, 33, 78)'}}> 
          <Typography variant="h2" color="white">
            {`(+) New Play`}
          </Typography>
        </div>}
        </div>
        </div>
    }
}


const ReduxWrapped = connect((state: RootState) => {
    return {
        groupIdToGroup: new Map(state.userGroupInfo.sortedGroupInfo.map(g => [g.group.user_group_id, g.group])),
        groupsHasLoaded: state.userGroupInfo.hasLoaded,
        visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts, (u) => u.can_dial && u.team_is_active)
    }
})(Plays)

export { ReduxWrapped as Plays}