import { createSlice, Dispatch, PayloadAction, combineReducers } from '@reduxjs/toolkit';
import { FilterOption__STRICT } from '../../components/Filter';
import { CALL_CATEGORY_OPTIONS, FLAGGED_FILTER, Option, PopulationFilter, PROSPECT_OPTIONS, REMARK_FIRST_ORDER_OPTION, STAR_FILTER, TEXT_FIRST_ORDER_OPTION } from '../../components/FilterSelector';
import { Counterpart, COUNTERPART_TO_HUMAN_READABLE, EmailValueProp, ExternalAccount, NO_LOOKBACK_RULE, NO_PARTY_ROLE, PartyRole, PROSPECT_INFO_COLUMNS, Remark, REMARK_TO_HUMAN_READABLE, SESSION_METRICS_COLUMNS, STAGE, Bot, BotEmotion, BotCall, UserGroup, UserGroupMembership, CustomDefinition } from '../../interfaces/db';
import {
  AdminWriteTargetsResult,
  UserDataResult, CnfFilterType, DEFAULT_LANGUAGE_SETTING, GetUserGroupResult, Keyword, KeywordPhrase,
  ListUserSessionParamsV3, LookbackRule, PhraseFilterTerm, ProspectFilterTerm, RemarkFilterTerm,
  ScopeV2Result, ProspectInfoTypes,
  StaticFilterDisjunction, TemporalFilterDisjunction, UserFilterTerm, VisibleAccountsResult, ExternalAccountMemberResult, ExternalAccountResult, 
  TeamResult, UserAccountFilterTerm, CounterpartFilter, MetadataFilter, CustomMetricFilterTerm, SettingsResult, SessionMetricFilterTerm, ProspectInfoChoices, BotSessionQueryParams,
} from '../../interfaces/services';
import { RootState } from '../../store';
import { groupByKeys } from '../../core';
import { getServicesManager } from '../../services';
import { AuthorizationInfo, getApiKeyInfo, updateAuthorizationApiKey } from '../../components/Authentication/utils';
import { v4 as uuidv4 } from 'uuid';
import { MultiUserSelect } from '../../components/Selectors/MulltiUserSelector';
import { CustomMetricValue, StaticCustomMetricValue } from '../../cfg/column';
import { enableMapSet } from 'immer'

// Add this line near the top of the file, before creating the store
enableMapSet()

export enum SpecialFilter {
  COMPARISON_DATE_RANGE = 'Comparison Date Range',
  COMPARISON_START_DATE = 'Comparison Start Date',
  COMPARISON_END_DATE = 'Comparison End Date',
  COMPARISON_GROUP = 'Comparison Group',
  TIMEZONE = 'Timezone',
  IS_STARRED = 'Starred',
  IS_REVIEWED = 'Reviewed',
  PROSPECT_PHONE_OR_NAME = 'Phone # or Name',
}

export enum DateOptions {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7_DAYS = 'Last week',
  LAST_14_DAYS = 'Last 2 weeks',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM = 'Custom',
}

export enum ComparisonDateOptions {
  PRIOR_DAY = 'Prior day',
  PRIOR_7_DAYS = 'Prior week',
  PRIOR_14_DAYS = 'Prior 2 weeks',
  PRIOR_30_DAYS = 'Prior 30 days',
  CUSTOM = 'Custom',
}

export enum TimeZoneOptions {
  LOCAL = 'Your timezone',
  PST = 'America/PST',
  MST = 'America/MST',
  CST = 'America/CST',
  EST = 'America/EST',
  GMT = 'London/GMT',
  CEST = 'Paris/CEST',
}

export enum STARRED_OPTIONS {
  STARRED = 'Starred',
  NOT_STARRED = 'Not Starred',
}

export enum REVIEW_OPTIONS {
  REVIEW_CLOSED = 'Review Closed',
  REVIEW_OPENED = 'Review Opened',
}

export enum StaticFilterKeys {
    DATE_RANGE = 'dateRange',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    SELECTED_REPS = 'selectedReps',
    USER_DISPOSITION = 'Disposition',
    USER_PURPOSE = 'Purpose',
    USER_SENTIMENT = 'Sentiment',
    OBJECTIONS = 'Objections',
    STAGES = 'Stages',
    COMPARISON_DATE_RANGE = "Comparison Date Range",
    COMPARISON_START_DATE = "Comparison Start Date",
    COMPARISON_END_DATE = "Comparison End Date",
    COMPARISON_GROUP = "Comparison Group",
    TIMEZONE = "Timezone",
    IS_STARRED = "Starred",
    IS_REVIEWED = "Reviewed",
    PROSPECT_PHONE_OR_NAME = "Prospect Phone or Name",
    DURATION_FILTER = "Call Duration",
    CNF_PASS_THROUGH = "CNF Pass Through",
    COUNTERPART = "Recipient Type",

    CADENCES = "Cadence",
    CADENCE_STEPS = "Cadence Step",
    INDUSTRIES = "Industry",
    TITLES = "Title",
    SENIORITIES = "Seniority",
    PROSPECT_LIST = "Prospect List",
    MARKET = "Market",
    LEAD_TYPE = "Lead Type",
    CALLBACK_LIST = "Callback List",

    CUSTOM_METRIC = "Custom Metric",
}

export type StaticFilter = {
  [StaticFilterKeys.DATE_RANGE]: DateOptions,
  [StaticFilterKeys.START_DATE]: Date,
  [StaticFilterKeys.END_DATE]: Date,
  [StaticFilterKeys.SELECTED_REPS]: MultiUserSelect[],
  [StaticFilterKeys.USER_DISPOSITION]?: string[] | undefined,
  [StaticFilterKeys.USER_PURPOSE]?: string[] | undefined,
  [StaticFilterKeys.USER_SENTIMENT]?: string[] | undefined,
  [StaticFilterKeys.OBJECTIONS]?: Remark[] | undefined,
  [StaticFilterKeys.STAGES]?: STAGE[] | undefined,
  [StaticFilterKeys.COMPARISON_DATE_RANGE]: ComparisonDateOptions,
  [StaticFilterKeys.COMPARISON_START_DATE]: Date,
  [StaticFilterKeys.COMPARISON_END_DATE]: Date,
  [StaticFilterKeys.COMPARISON_GROUP]: MultiUserSelect[],
  [StaticFilterKeys.TIMEZONE]: TimeZoneOptions,
  [StaticFilterKeys.IS_STARRED]?: STARRED_OPTIONS[],
  [StaticFilterKeys.IS_REVIEWED]?: REVIEW_OPTIONS[],
  [StaticFilterKeys.PROSPECT_PHONE_OR_NAME]?: string[] | null,
  [StaticFilterKeys.DURATION_FILTER]?: {min?: number, max?: number} | null,
  [StaticFilterKeys.CNF_PASS_THROUGH]?: StaticFilterDisjunction[]
  [StaticFilterKeys.COUNTERPART]?: (Counterpart | "live_person")[]

  [StaticFilterKeys.CADENCES]?: string[],
  [StaticFilterKeys.CADENCE_STEPS]?: string[],
  [StaticFilterKeys.INDUSTRIES]?: string[],
  [StaticFilterKeys.TITLES]?: string[],
  [StaticFilterKeys.SENIORITIES]?: string[],
  [StaticFilterKeys.PROSPECT_LIST]?: string[],
  [StaticFilterKeys.MARKET]?: string[],
  [StaticFilterKeys.LEAD_TYPE]?: string[],
  [StaticFilterKeys.CALLBACK_LIST]?: SESSION_METRICS_COLUMNS[],

  [StaticFilterKeys.CUSTOM_METRIC]?: StaticCustomMetricValue[]
}

export const StaticFilterKeysProspectInfo = new Map<StaticFilterKeys, PROSPECT_INFO_COLUMNS[]>([
  [StaticFilterKeys.CADENCES, [PROSPECT_INFO_COLUMNS.CADENCE, PROSPECT_INFO_COLUMNS.CADENCE_2, PROSPECT_INFO_COLUMNS.CADENCE_3]],
  [StaticFilterKeys.CADENCE_STEPS, [PROSPECT_INFO_COLUMNS.CADENCE_STEP, PROSPECT_INFO_COLUMNS.CADENCE_STEP_2, PROSPECT_INFO_COLUMNS.CADENCE_STEP_3]],
  [StaticFilterKeys.INDUSTRIES, [PROSPECT_INFO_COLUMNS.COMPANY_INDUSTRY, PROSPECT_INFO_COLUMNS.COMPANY_INDUSTRY_2, PROSPECT_INFO_COLUMNS.COMPANY_INDUSTRY_3]],
  [StaticFilterKeys.TITLES, [PROSPECT_INFO_COLUMNS.PROSPECT_TITLE]],
  [StaticFilterKeys.SENIORITIES, [PROSPECT_INFO_COLUMNS.PROSPECT_SENIORITY]],
  [StaticFilterKeys.PROSPECT_LIST, [PROSPECT_INFO_COLUMNS.PROSPECT_LIST, PROSPECT_INFO_COLUMNS.PROSPECT_LIST_2, PROSPECT_INFO_COLUMNS.PROSPECT_LIST_3]],
  [StaticFilterKeys.MARKET, [PROSPECT_INFO_COLUMNS.MARKET]],
  [StaticFilterKeys.LEAD_TYPE, [PROSPECT_INFO_COLUMNS.LEAD_TYPE]],
])

export function getProspectInfoOptions(options: ProspectInfoChoices | null, key: StaticFilterKeys): string[] {
  if (!options) return []
  if (!StaticFilterKeysProspectInfo.has(key)) return []
  const column = StaticFilterKeysProspectInfo.get(key) as PROSPECT_INFO_COLUMNS[]
  return options.find((v) => column.includes(v.field_name as PROSPECT_INFO_COLUMNS))?.choices ?? []
}

type ReduxSafeFilterResult = Omit<StaticFilter,  StaticFilterKeys.START_DATE | StaticFilterKeys.END_DATE | StaticFilterKeys.COMPARISON_START_DATE | StaticFilterKeys.COMPARISON_END_DATE> & { [StaticFilterKeys.START_DATE]: number, [StaticFilterKeys.END_DATE]: number, [StaticFilterKeys.COMPARISON_START_DATE]: number, [StaticFilterKeys.COMPARISON_END_DATE]: number }
export function convertFilterToReduxSafe(f: StaticFilter): ReduxSafeFilterResult {
  return {...f, 
    [StaticFilterKeys.START_DATE]: f[StaticFilterKeys.START_DATE].valueOf() as number, 
    [StaticFilterKeys.END_DATE]: f[StaticFilterKeys.END_DATE].valueOf() as number, 
    [StaticFilterKeys.COMPARISON_START_DATE]: f[StaticFilterKeys.COMPARISON_START_DATE].valueOf() as number, 
    [StaticFilterKeys.COMPARISON_END_DATE]: f[StaticFilterKeys.COMPARISON_END_DATE].valueOf() as number
  } as ReduxSafeFilterResult
}

export function convertFilterFromReduxSafe(f: ReduxSafeFilterResult): StaticFilter {
  return {
    ...f,
    [StaticFilterKeys.START_DATE]: new Date(f[StaticFilterKeys.START_DATE]) as Date,
    [StaticFilterKeys.END_DATE]: new Date(f[StaticFilterKeys.END_DATE]) as Date,
    [StaticFilterKeys.COMPARISON_START_DATE]: new Date(f[StaticFilterKeys.COMPARISON_START_DATE]) as Date,
    [StaticFilterKeys.COMPARISON_END_DATE]: new Date(f[StaticFilterKeys.COMPARISON_END_DATE]) as Date
  } as StaticFilter
}

export function convertFilterToStaticCnf(filter: StaticFilter, exclude_keys: StaticFilterKeys[] = [], include_special_filters: SpecialFilter[] = []): StaticFilterDisjunction[] {
  const staticCnf: StaticFilterDisjunction[] = []
  if (!exclude_keys.includes(StaticFilterKeys.SELECTED_REPS)) {
    const team_id = filter[StaticFilterKeys.SELECTED_REPS].map((u) => u.team_id).filter((u) => u !== null).map((u) => u as string)
    const user_ids = filter[StaticFilterKeys.SELECTED_REPS].map((u) => u.user_id).filter((u) => u !== null).map((u) => u as string)
    const user_accounts_ids = filter[StaticFilterKeys.SELECTED_REPS].map((u) => u.user_group_id).filter((u) => u !== null).map((u) => u as string)
    if (team_id.length === 0 && (user_ids.length > 0 || user_accounts_ids.length > 0)) {
      const userAccountFilter: UserAccountFilterTerm = {}
      if (user_ids.length > 0) userAccountFilter['user_ids'] = user_ids
      if (user_accounts_ids.length > 0) userAccountFilter['user_group_ids'] = user_accounts_ids
      staticCnf.push({'filter_type': CnfFilterType.USER_ACCOUNT, 'negated': false, 'user_account_filters': [userAccountFilter]})
    }
  }

  if (!exclude_keys.includes(StaticFilterKeys.COUNTERPART)) {
    const counterpart = (filter[StaticFilterKeys.COUNTERPART] ?? []) as (Counterpart | "live_person")[]
    const counterpart_filters = counterpart.filter((v) => v !== "live_person").map((v) => { return {'counterpart': v as Counterpart } })
    if (counterpart_filters.length > 0) {
      staticCnf.push({'filter_type': CnfFilterType.COUNTERPARTS, 'negated': false, 'counterpart_filters': counterpart_filters})
    }
    const is_live_person = counterpart.includes("live_person")
    if (is_live_person) {
      staticCnf.push({'filter_type': CnfFilterType.SESSION_METRIC, 'negated': false, 'session_metric_filters': [{'field': SESSION_METRICS_COLUMNS.IS_ANSWERED, 'in_values': [true]}]})
    }
  }

  if (!exclude_keys.includes(StaticFilterKeys.DURATION_FILTER)) {
    const duration = filter[StaticFilterKeys.DURATION_FILTER]
    if (duration && (duration.min || duration.max)) {
      if (duration.min) staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': [{'min_duration': duration.min}]})
      if (duration.max) staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': [{'max_duration': duration.max}]})
    }
  }

  for (const keys of StaticFilterKeysProspectInfo.keys()) {
    if (exclude_keys.includes(keys)) continue
    const values = (keys in filter ? filter[keys] : []) as string[]
    if (!values || values.length === 0) continue
    const column = StaticFilterKeysProspectInfo.get(keys) as PROSPECT_INFO_COLUMNS[]
    staticCnf.push({'filter_type': CnfFilterType.PROSPECT_INFO, 'negated': false, 'prospect_info_filters': column.map((v) => { return {'field': v, 'in_values': values} })})
  }

  if (!exclude_keys.includes(StaticFilterKeys.CUSTOM_METRIC)) {
    const custom_metric = (filter[StaticFilterKeys.CUSTOM_METRIC] ?? []) as StaticCustomMetricValue[]
    if (custom_metric.length > 0) {
      const custom_metric_filters: CustomMetricFilterTerm[] = custom_metric.map((v) => {
        const value = v.value
        if (!value) return null
        switch (v.value_type) { 
          case ValueType.BOOLEAN: 
            const boolean_value = value[ValueType.BOOLEAN]?.value
            if (boolean_value === undefined) return null
            return [{
              'field': v.metric_field,
              'min_value': boolean_value ? 1 : 0,
              'max_value': boolean_value ? 1 : 0
            }]
          case ValueType.FLOAT:
            let float_value = value[ValueType.FLOAT]?.number_one
            let float_value_two = value[ValueType.FLOAT]?.number_two
            if (v.isPercentage) {
              float_value = float_value ? float_value / 100 : 0
              float_value_two = float_value_two ? float_value_two / 100 : 0
            }
            return [{
              'field': v.metric_field,
              'min_value': float_value,
              'max_value': float_value_two
            }]
          case ValueType.CATEGORICAL:
            const categorical_value = value[ValueType.CATEGORICAL]?.selected_options
            if (!categorical_value || categorical_value.length === 0) return null
            return categorical_value.map((x) => { return { 'field': v.metric_field, 'min_value': parseInt(x.value.toString()), 'max_value': parseInt(x.value.toString()) } })
          default: return null
        }
      }).filter((v) => v !== null).flat(1).map((v) => v as CustomMetricFilterTerm)
      if (custom_metric_filters.length > 0) {
        staticCnf.push({'filter_type': CnfFilterType.CUSTOM_METRIC, 'negated': false, 'custom_metric_filters': custom_metric_filters})
      }
    }
  }

  for (const remarkMetaKeys of [StaticFilterKeys.USER_DISPOSITION, StaticFilterKeys.USER_PURPOSE, StaticFilterKeys.USER_SENTIMENT]) {
    if (exclude_keys.includes(remarkMetaKeys)) continue
    const values = (remarkMetaKeys in filter ? filter[remarkMetaKeys] : []) as string[]
    if (!values || values.length === 0) continue
    let field: SESSION_METRICS_COLUMNS | null = null
    switch (remarkMetaKeys) {
      case StaticFilterKeys.USER_DISPOSITION: field = SESSION_METRICS_COLUMNS.USER_DISPOSITION; break
      case StaticFilterKeys.USER_PURPOSE: field = SESSION_METRICS_COLUMNS.USER_PURPOSE; break
      case StaticFilterKeys.USER_SENTIMENT: field = SESSION_METRICS_COLUMNS.USER_SENTIMENT; break
    }
    if (field) staticCnf.push({'filter_type': CnfFilterType.SESSION_METRIC, 'negated': false, 'session_metric_filters': [{'field': field, 'in_values': values}]})
  }

  if (!exclude_keys.includes(StaticFilterKeys.OBJECTIONS)) {
    const objections = (StaticFilterKeys.OBJECTIONS in filter ? filter[StaticFilterKeys.OBJECTIONS] : []) as Remark[]
    if (objections.length > 0) {
      staticCnf.push({'filter_type': CnfFilterType.REMARK, 'negated': false, 'remark_filters': objections.map((v) => { return {'remark': v } })})
    }
  }
  
  if (!exclude_keys.includes(StaticFilterKeys.STAGES)) {
    const stages = (StaticFilterKeys.STAGES in filter ? filter[StaticFilterKeys.STAGES] : []) as STAGE[]
    if (stages.length > 0) {
      staticCnf.push({'filter_type': CnfFilterType.STAGE, 'negated': false, 'stage_filters': stages.map((v) => { return {'stage': v, 'require_ending': true } })})
    }
  }
  
  if (!exclude_keys.includes(StaticFilterKeys.CNF_PASS_THROUGH)) {
    const cnf = (StaticFilterKeys.CNF_PASS_THROUGH in filter ? filter[StaticFilterKeys.CNF_PASS_THROUGH] : []) as StaticFilterDisjunction[]
    if (cnf.length > 0) {
      staticCnf.push(...cnf)
    }
  }

  if (!exclude_keys.includes(StaticFilterKeys.CALLBACK_LIST)) {
    const callback_list = ((StaticFilterKeys.CALLBACK_LIST in filter ? filter[StaticFilterKeys.CALLBACK_LIST] : []) as SESSION_METRICS_COLUMNS[]).filter((v) => [SESSION_METRICS_COLUMNS.NEXT_C_CALL, SESSION_METRICS_COLUMNS.NEXT_C_CALL_BACK].includes(v))
    if (callback_list.length > 0) {
      staticCnf.push({'filter_type': CnfFilterType.SESSION_METRIC, 'negated': false, 'session_metric_filters': callback_list.map((v) => { return {'field': v, 'in_values': [true]} })})
    }
  }

  if (include_special_filters.includes(SpecialFilter.PROSPECT_PHONE_OR_NAME)) {
    const prospectPhoneOrName = ((filter[StaticFilterKeys.PROSPECT_PHONE_OR_NAME] ?? []) as string[]).map((v) => cleanNumber(v)).filter((v) => v && v.length > 10)
    if (prospectPhoneOrName && prospectPhoneOrName.length > 0) {
      staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': prospectPhoneOrName.map((v) => { return { 'prospect_phone_value': v } })})
    }
  }

  if (include_special_filters.includes(SpecialFilter.IS_STARRED)) {
    const options = (filter[StaticFilterKeys.IS_STARRED] ?? []) as STARRED_OPTIONS[]
    if (options.length > 0) {
      staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': options.map((v) => { return { 'has_star': v === STARRED_OPTIONS.STARRED } })})
    }
  }

  if (include_special_filters.includes(SpecialFilter.IS_REVIEWED)) {
    const options = (filter[StaticFilterKeys.IS_REVIEWED] ?? []) as REVIEW_OPTIONS[]
    if (options.length > 0) {
      staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': options.map((v) => { return { 'review_is_open': v === REVIEW_OPTIONS.REVIEW_OPENED } })})
    }
  }

  return staticCnf
}

export function cleanNumber(temp: string): string { return temp.replace(/\D/g, "") }
export function roundDateDown(date: Date): Date { return new Date(date.getFullYear(), date.getMonth(), date.getDate()) }
export function roundDateUp(d: Date) {
  const t = new Date(d)
  t.setHours(23,59,59,59);
  return t
}

export function getDefaultStaticFilter(): StaticFilter {
  return {
    [StaticFilterKeys.DATE_RANGE]: DateOptions.LAST_14_DAYS,
    [StaticFilterKeys.START_DATE]: roundDateDown(new Date(new Date().valueOf() - 14 * 60 * 60 * 24 * 1000)),
    [StaticFilterKeys.END_DATE]: roundDateUp(new Date()),
    [StaticFilterKeys.SELECTED_REPS]: [],
    [StaticFilterKeys.COMPARISON_DATE_RANGE]: ComparisonDateOptions.PRIOR_14_DAYS,
    [StaticFilterKeys.COMPARISON_END_DATE]: roundDateUp(new Date(new Date().valueOf() - 7 * 60 * 60 * 24 * 1000)),
    [StaticFilterKeys.COMPARISON_START_DATE]: roundDateDown(new Date(new Date().valueOf() - 14 * 60 * 60 * 24 * 1000)),
    [StaticFilterKeys.COMPARISON_GROUP]: [],
    [StaticFilterKeys.TIMEZONE]: TimeZoneOptions.LOCAL,
    [StaticFilterKeys.IS_STARRED]: [],
    [StaticFilterKeys.IS_REVIEWED]: [],
    [StaticFilterKeys.CADENCES]: [],
    [StaticFilterKeys.CADENCE_STEPS]: [],
    [StaticFilterKeys.INDUSTRIES]: [],
    [StaticFilterKeys.TITLES]: [],
    [StaticFilterKeys.SENIORITIES]: [],
    [StaticFilterKeys.USER_DISPOSITION]: [],
    [StaticFilterKeys.USER_PURPOSE]: [],
    [StaticFilterKeys.USER_SENTIMENT]: [],
    [StaticFilterKeys.OBJECTIONS]: [],
    [StaticFilterKeys.STAGES]: [],
    [StaticFilterKeys.PROSPECT_PHONE_OR_NAME]: [],
    [StaticFilterKeys.DURATION_FILTER]: null,
  }
}

export interface FilterState {
  value: ReduxSafeFilterResult
}

const filterInitialState: FilterState = {
  value: convertFilterToReduxSafe(getDefaultStaticFilter())
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    updateSafeFilter: (state, action: PayloadAction<ReduxSafeFilterResult>) => {
      state.value = action.payload
    },
    updateFilter: (state, action: PayloadAction<StaticFilter>) => {
      state.value = convertFilterToReduxSafe(action.payload)
    }
  }
});

export const { updateSafeFilter, updateFilter } = filterSlice.actions;

export const transcriptFilterSlice = createSlice({
  name: 'transcriptFilter',
  initialState: filterInitialState,
  reducers: {
    updateTranscriptSafeFilter: (state, action: PayloadAction<ReduxSafeFilterResult>) => {
      state.value = action.payload
    },
    updateTranscriptFilter: (state, action: PayloadAction<StaticFilter>) => {
      state.value = convertFilterToReduxSafe(action.payload)
    }
  }  
})
export const { updateTranscriptSafeFilter, updateTranscriptFilter } = transcriptFilterSlice.actions;


export interface EmailValuePropsState {
  value: EmailValueProp[] | null
}

export interface ProspectInfoOptionsState {
  value: ProspectInfoChoices | null
}

const dispositionOptionsInitialResult: ProspectInfoOptionsState = {
  value: null,
}

export const dispositionOptionSlice = createSlice({
  name: 'dispositionOptions',
  initialState: dispositionOptionsInitialResult,
  reducers: {
    updateDispositionOptions: (state, action: PayloadAction<ProspectInfoChoices>) => {
      state.value = action.payload
    },
  }
});

export function reloadDispositionOptions(dispatch: Dispatch): Promise<ProspectInfoChoices | null> { 
  const result = getServicesManager().getDispositions()
  result.then(v => {
    if (v !== null) {
      dispatch((dispositionOptionSlice.actions.updateDispositionOptions(v))) 
    }
  }) 
  return result 
}

const prospectInfoOptionsInitialResult: ProspectInfoOptionsState = {
  value: null,
}

export const prospectInfoOptionSlice = createSlice({
  name: 'prospectInfoOptions',
  initialState: prospectInfoOptionsInitialResult,
  reducers: {
    updateProspectInfoOptions: (state, action: PayloadAction<ProspectInfoChoices>) => {
      state.value = action.payload
    },
  }
});

export interface SettingsState {
  value: SettingsResult | null
}

const settingsInitialState: SettingsState = {
  value: null,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsInitialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<SettingsResult>) => {
      state.value = action.payload
    },
  }
});

export function reloadUserSettings(dispatch: Dispatch): Promise<SettingsResult | null> {
  const result = getServicesManager().getSettings()
  result.then(v => {
    if (v !== null) {
      dispatch((settingsSlice.actions.updateSettings(v)))
    }
  })
  return result
}

export function reloadProspectInfoOptions(dispatch: Dispatch): Promise<ProspectInfoChoices | null> {
  const result = getServicesManager().getProspectInfo()
  result.then(v => {
    if (v !== null) {
      dispatch((prospectInfoOptionSlice.actions.updateProspectInfoOptions(v))) 
    }
  }) 
  return result 
}

const emailValuePropsInitialResult: EmailValuePropsState = {
  value: null,
}

export const emailsValuePropsSlice = createSlice({
  name: 'email_value_props',
  initialState: emailValuePropsInitialResult,
  reducers: {
    updateEmailValueProps: (state, action: PayloadAction<EmailValueProp[]>) => {
      state.value = action.payload
    },
  }
});

export const { updateEmailValueProps } = emailsValuePropsSlice.actions;

export function reloadEmailValueProps(dispatch: Dispatch): Promise<EmailValueProp[] | null> { 
  const result = getServicesManager().getEmailValueProp()
  result.then(v => {
    if (v !== null) {
      dispatch(emailsValuePropsSlice.actions.updateEmailValueProps(v)) 
    }
  }) 
  return result 
}


export interface SessionListParamsState {
  value: PopulationFilter[];
  forceRefresh: boolean
}

const initialState: SessionListParamsState = {
  value: [] ,
  forceRefresh: false
};

export const sessionListParamsSlice = createSlice({
  name: 'sessionListParams',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateSessionListParams: (state, action: PayloadAction<{'updatedParams': PopulationFilter[]}>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload.updatedParams
    },
    updateForceRefresh: (state, action: PayloadAction<{'forceRefresh': boolean}>) => {
      state.forceRefresh = action.payload.forceRefresh
    }
  },
});

export const { updateSessionListParams, updateForceRefresh } = sessionListParamsSlice.actions;

export interface PendingReviewsState {
  value: number | null
}

const pendingReviewsInititalState: PendingReviewsState = {
  value: null,
}

export const pendingReviewsSlice = createSlice({
  name: 'pendingReviews',
  initialState: pendingReviewsInititalState,
  reducers: {
    updatePendingReviews: (state, action: PayloadAction<PendingReviewsState>) => {
      state.value = action.payload.value
    },
  }
});

export const { updatePendingReviews } = pendingReviewsSlice.actions;


export function reloadPendingReviewsInitialState(dispatch: Dispatch) { 
  const result = getServicesManager().getListUserSessionsV3({
    'start': new Date(new Date().valueOf() - 28 * 60 * 60 * 24 * 1000),
    'end': new Date(),
    'cnf': [{'metadata_filters': [{'review_is_open': true}], 'filter_type': CnfFilterType.METADATA, 'negated': false}]
  })
  result.then((v) => {
    if (v) {
      dispatch(pendingReviewsSlice.actions.updatePendingReviews({'value': v.length}))
    }
  })
}


export interface AuthorizationInfoState {
  value: AuthorizationInfo | null
}

const pendingAuthorizationInfoInititalState: AuthorizationInfoState = {
  value: null,
}

export const pendingAuthorizationInfo = createSlice({
  name: 'authorizationInfo',
  initialState: pendingAuthorizationInfoInititalState,
  reducers: {
    updateAuthorizationInfo: (state, action: PayloadAction<AuthorizationInfoState>) => {
      state.value = action.payload.value
      if (action.payload.value?.apiKey) updateAuthorizationApiKey(action.payload.value?.apiKey)
    },
  }
});

export const { updateAuthorizationInfo } = pendingAuthorizationInfo.actions;

export function reloadAuthorizationInfoInitialState(dispatch: Dispatch) { 
  const result = getApiKeyInfo()
  result.then((v) => {
    if (v) {
      dispatch(pendingAuthorizationInfo.actions.updateAuthorizationInfo({'value': v}))
    }
  })
}


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getPopulationFilters = (state: RootState) => state.sessionList.value
export const getForceRefresh = (state: RootState) => state.sessionList.forceRefresh;

type ReduxSafeUserDataResult = Omit<UserDataResult, 'created_at'> & { created_at: number }

export function convertUserDataResultToReduxSafe(u: UserDataResult): ReduxSafeUserDataResult {
  return {...u, 'created_at': u.created_at.valueOf()}
}

export interface UserState {
  value: ReduxSafeUserDataResult | null
}

const userInitialResult: UserState = {
  value: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialResult,
  reducers: {
    updateUser: (state, action: PayloadAction<ReduxSafeUserDataResult>) => {
      state.value = action.payload
    },
  }
});

export const { updateUser } = userSlice.actions;

export function reloadUser(dispatch: Dispatch): Promise<UserDataResult | null> {
  const result = getServicesManager().getUser()
  result.then(v => {
    if (v !== null) {
      dispatch((userSlice.actions.updateUser({...v, 'created_at': v.created_at.valueOf()}))) 
    }
  }) 
  return result 
}


export interface TeamState {
  value: TeamResult | null
}

const teamInitialResult: TeamState = {
  value: null,
}

export const teamSlice = createSlice({
  name: 'team',
  initialState: teamInitialResult,
  reducers: {
    updateTeam: (state, action: PayloadAction<TeamResult>) => {
      state.value = action.payload
    },
  }
});

export const { updateTeam } = teamSlice.actions;

export function reloadTeam(dispatch: Dispatch): Promise<TeamResult | null> {
  const result = getServicesManager().getTeam()
  result.then(v => {
    if (v !== null) { dispatch((teamSlice.actions.updateTeam(v))) }
  }) 
  return result 
}


export type ReduxSafeVisibleAccountsResult = {
  teams: TeamResult[],
  ext_accounts: ExternalAccountResult[],
  ext_account_members: ExternalAccountMemberResult[],
  users:ReduxSafeUserDataResult[]
}

export interface VisibleAccountsState {
  value: ReduxSafeVisibleAccountsResult | null
}

const visibleAccountsInitialState: VisibleAccountsState = {
  value: null,
}

export function convertFromReduxSafeUserResult(u: ReduxSafeUserDataResult): UserDataResult {
  return {...u, 'created_at': new Date(u.created_at)}
}

export function convertFromReduxSafeUserState(u: UserState): UserDataResult | null {
  if (u.value === null) return null
  return convertFromReduxSafeUserResult(u.value)
}

export function convertFromReduxSafeVisibleAccounts(v: VisibleAccountsState, filterFn?: (u: UserDataResult) => boolean): VisibleAccountsResult | null{
  if (v.value === null) return null
  return {
    teams: v.value.teams,
    ext_accounts: v.value.ext_accounts,
    ext_account_members: v.value.ext_account_members,
    users: v.value.users.map(u => { return convertFromReduxSafeUserResult(u) }).filter(filterFn ?? (() => true))
  }
}

export const visibleAccountsSlice = createSlice({
  name: 'visibleAccounts',
  initialState: visibleAccountsInitialState,
  reducers: {
    updateVisibleAccounts: (state, action: PayloadAction<ReduxSafeVisibleAccountsResult>) => {
      state.value = {
        teams: action.payload.teams,
        ext_accounts: action.payload.ext_accounts,
        ext_account_members: action.payload.ext_account_members,
        users: action.payload.users.slice().sort((a, b) => a.user_name.localeCompare(b.user_name) || a.created_at - b.created_at)
      }
    },
  }
});

export const { updateVisibleAccounts } = visibleAccountsSlice.actions;

export async function reloadVisibleAccounts(dispatch: Dispatch): Promise<VisibleAccountsResult | null> {
  const result = getServicesManager().getVisibleAccounts()
  result.then(v => {
    if (v !== null) {
      dispatch(visibleAccountsSlice.actions.updateVisibleAccounts(
        {
          teams: v.teams,
          ext_accounts: v.ext_accounts,
          ext_account_members: v.ext_account_members,
          users: v.users.map(u => { return {...u, 'created_at': u.created_at.valueOf()} })
        }
      ))
    }
  })
  return result
}

export interface CustomDefinitionState {
  value: CustomDefinition[] | null
}

const customDefinitionInitialState: CustomDefinitionState = {
  value: null,
}

export const customDefinitionsSlice = createSlice({
  name: 'customDefinitions',
  initialState: customDefinitionInitialState,
  reducers: {
    updateCustomDefinitions: (state, action: PayloadAction<CustomDefinition[]>) => {
      state.value = action.payload
    },
  }
});

export const { updateCustomDefinitions } = customDefinitionsSlice.actions;
  
export function reloadCustomDefinitions(dispatch: Dispatch): Promise<CustomDefinition[] | null> {
  const result = getServicesManager().getCustomDefinitions()
  result.then(v => {
    if (v !== null) {
      dispatch(customDefinitionsSlice.actions.updateCustomDefinitions(v))
    }
  })
  return result
}

export interface ScopesState {
  value: ScopeV2Result | null
}

const scopesInitialState: ScopesState = {
  value: null,
}

export const scopesSlice = createSlice({
  name: 'scopes',
  initialState: scopesInitialState,
  reducers: {
    updateScopes: (state, action: PayloadAction<ScopeV2Result>) => {
      state.value = action.payload
    },
  }
});

export const { updateScopes } = scopesSlice.actions;

export function reloadScopes(dispatch: Dispatch): Promise<ScopeV2Result | null> { 
  const result = getServicesManager().getScopes()
  result.then(v => {
    if (v !== null) {
      dispatch(scopesSlice.actions.updateScopes(v))
    }
  }) 
  return result 
}

export interface AdminWriteState {
  value: AdminWriteTargetsResult | null
}

const adminWriteInitialResult: AdminWriteState = {
  value: null,
}

export const adminWriteSlice = createSlice({
  name: 'admin_write',
  initialState: adminWriteInitialResult,
  reducers: {
    updateAdminWrite: (state, action: PayloadAction<AdminWriteTargetsResult>) => {
      state.value = action.payload
    },
  }
});

export function reloadAdminWrite(dispatch: Dispatch): Promise<AdminWriteTargetsResult | null> { 
  const result = getServicesManager().getAdminWriteTargets()
  result.then(v => {
    if (v !== null) {
      dispatch(adminWriteSlice.actions.updateAdminWrite(v)) 
    }
  }) 
  return result 
}


export type UserGroupInfo = {
  group: UserGroup
  directUserIds: string[]
  allUserIds: string[]
  parentIdxs: number[]
  subgroupIdxs: number[]
}

export interface UserGroupsState {
  sortedGroupInfo: UserGroupInfo[]
  groupIdToSortedIdx: Record<string, number>
  memberships: UserGroupMembership[]
  hasLoaded: boolean
}

const userGroupsInitialState: UserGroupsState = {
  sortedGroupInfo: [],
  groupIdToSortedIdx: {},
  memberships: [],
  hasLoaded: false,
}

export const userGroupsSlice = createSlice({
  name: 'userGroupsInfo',
  initialState: userGroupsInitialState,
  reducers: {
    updateHasLoaded: (state, action: PayloadAction<boolean>) => {
      state.hasLoaded = action.payload
    },
    updateData: (state, action: PayloadAction<GetUserGroupResult>) => {
      // restrict to memberships in known groups
      const groupIdToGroup: Map<string, UserGroup> = new Map(action.payload.groups.map(g => [g.user_group_id, g]))
      const memberships = action.payload.memberships.filter(m => groupIdToGroup.has(m.user_group_id) && (!m.member_is_group || groupIdToGroup.has(m.member_id)))
      // set up some lookups
      const groupIdToSubgroups: Map<string, UserGroup[]> = new Map([...groupByKeys(
        memberships.filter(m => m.member_is_group),
        m => m.user_group_id,
        m => groupIdToGroup.get(m.member_id)!,
      )].map(([k, v]) => [k, v.sort((g1, g2) => g1.user_group_name.localeCompare(g2.user_group_name))]))
      const groupIdToParents: Map<string, UserGroup[]> = groupByKeys(
        memberships.filter(m => m.member_is_group),
        m => m.member_id,
        m => groupIdToGroup.get(m.user_group_id)!,
      )
      const groupIdToUserIds: Map<string, string[]> = groupByKeys(
        action.payload.memberships.filter(m => !m.member_is_group),
        m => m.user_group_id,
        m => m.member_id,
      )
      // sort groups s.t. g1 in g2 implies g1 before g2 (but only as abstract graph nodes, not as sets of users)
      const groupIdToNumUnsortedSubgroups: Map<string, number> = new Map([...groupIdToSubgroups].map(([g, s]) => [g, s.length]))
      const inclusionSortedGroups: UserGroup[] = [...groupIdToGroup.values()].filter(g => !groupIdToNumUnsortedSubgroups.get(g.user_group_id))
      for (let i = 0; i < inclusionSortedGroups.length; ++i) {  // intentionally grows as we iterate
        const g = inclusionSortedGroups[i]
        for (const parent of groupIdToParents.get(g.user_group_id) ?? []) {
          const numUnsortedSiblings = groupIdToNumUnsortedSubgroups.get(parent.user_group_id)! - 1
          if (numUnsortedSiblings > 0) {
            groupIdToNumUnsortedSubgroups.set(parent.user_group_id, numUnsortedSiblings)
          } else {
            inclusionSortedGroups.push(parent)
          }
        }
      }
      // proceed if the sort succeeded (that is, no cycles so every group got sorted in)
      if (inclusionSortedGroups.length === groupIdToGroup.size) {
        // compute transitive-closure group members and write everything to state
        state.groupIdToSortedIdx = Object.fromEntries(inclusionSortedGroups.map((g, i) => [g.user_group_id, i]))
        state.sortedGroupInfo = inclusionSortedGroups.map(g => {return {
          group: g,
          directUserIds: groupIdToUserIds.get(g.user_group_id) ?? [],
          allUserIds: [],
          parentIdxs: (groupIdToParents.get(g.user_group_id) ?? []).map(p => state.groupIdToSortedIdx[p.user_group_id]).sort(),
          subgroupIdxs: (groupIdToSubgroups.get(g.user_group_id) ?? []).map(c => state.groupIdToSortedIdx[c.user_group_id]).sort(),
        }})
        for (const g of state.sortedGroupInfo) {
          g.allUserIds = Array.from(new Set([...g.allUserIds, ...g.directUserIds]))
          for (const parentIdx of g.parentIdxs) {
            state.sortedGroupInfo[parentIdx].allUserIds.push(...g.allUserIds)
          }
        }
        state.memberships = memberships
      } else {
        // if the sort failed, then don't expose groups because they'd be misleading
        state.groupIdToSortedIdx = {}
        state.sortedGroupInfo = []
        state.memberships = []
      }
    },
  }
});


export function reloadGroups(dispatch: Dispatch): Promise<GetUserGroupResult | null> {
  const result = getServicesManager().getUserGroups()
  result.then(v => {
    if (v !== null) {
      dispatch(userGroupsSlice.actions.updateData(v))
      dispatch(userGroupsSlice.actions.updateHasLoaded(true))
    }
  })
  return result
}

export interface ExternalAccountListState {
  accounts: ExternalAccount[] | null,
}

const externalAccountsInitialState: ExternalAccountListState = {
  accounts: null,
}

export const externalAccountsSlice = createSlice({
  name: 'externalAccounts',
  initialState: externalAccountsInitialState,
  reducers: {
    updateData: (state, action: PayloadAction<ExternalAccount[]>) => {
      state.accounts = action.payload.slice().sort((a, b) => a.external_account_name.localeCompare(b.external_account_name))
    },
  },
})

export function reloadExternalAccounts(dispatch: Dispatch): Promise<ExternalAccount[] | null> {
  const result = getServicesManager().getExternalAccounts()
  result.then(v => {
    if (v !== null) {
      dispatch(externalAccountsSlice.actions.updateData(v))
    }
  })
  return result
}

function getAllProspectCalls(): STAGE[] {
  return [STAGE.CONTACT, STAGE.PITCHED, STAGE.CONVERSATION, STAGE.BOOKED]
}

function convertPopulationFilterToTemporalFilter(populationFilter: PopulationFilter): TemporalFilterDisjunction | null {
  const partyRole = populationFilter.multiMetadataFilterOption?.PARTY_ROLE
  const lookbackRule = populationFilter.multiMetadataFilterOption?.LOOKBACK_RULE
  const timeHorizon = populationFilter.multiMetadataFilterOption?.LOOKBACK_HORIZON

  const partyRoleSelectedFound = partyRole ? (partyRole as FilterOption__STRICT[]).find((v) => v.selected) : undefined
  const partyRoleSelected = partyRoleSelectedFound ? partyRoleSelectedFound.value === NO_PARTY_ROLE ? undefined : partyRoleSelectedFound.value as PartyRole : undefined

  const lookbackRuleSelectedFound = lookbackRule ? (lookbackRule as FilterOption__STRICT[]).find((v) => v.selected) : undefined
  const lookbackRuleSelected = lookbackRuleSelectedFound ? lookbackRuleSelectedFound.value === NO_LOOKBACK_RULE ? undefined : lookbackRuleSelectedFound.value as LookbackRule : undefined

  const lookbackHorizon = timeHorizon ? timeHorizon as number : undefined

  if (populationFilter.option === Option.TEXT) {
      // get all the text matches
      return {
          'filter_type': CnfFilterType.PHRASE,
          'phrase_filters': populationFilter.valueFilterOption.filter((v) => v.selected).map((v) => {
              return {
                  'language': DEFAULT_LANGUAGE_SETTING.ENGLISH, // TODO FIX
                  'stem': true,
                  'text': v.value,
                  'party_role': partyRoleSelected,
              }
          }),
          'lookback_rule': lookbackRuleSelected,
          'lookback_horizon': lookbackHorizon
      }
  } else if (populationFilter.option === Option.REMARK) {
      return {
          'filter_type': CnfFilterType.REMARK,
          'remark_filters': populationFilter.valueFilterOption.filter((v) => v.selected).map((v) => {
              return {
                  'remark': v.value as Remark,
                  'party_role': undefined
              }
          }),
          'lookback_rule': lookbackRuleSelected,
          'lookback_horizon': lookbackHorizon,
      }
  } else if (populationFilter.option === Option.TIME) {
      const startNode = populationFilter.valueFilterOption.find((v) => v.label === 'START' && v.selected)
      const start = startNode && !isNaN(parseInt(startNode.value)) ? parseInt(startNode?.value) : undefined
      const endNode = populationFilter.valueFilterOption.find((v) => v.label === 'END' && v.selected)
      const end = endNode && !isNaN(parseInt(endNode.value)) ? parseInt(endNode?.value) : undefined
      return {
          'filter_type': CnfFilterType.TIME,
          'time_filters': [{'start': start, 'end': end}],
      }
  } else if ([Option.CADENCE_STEPS, Option.CADENCES, Option.SENIORITIES, Option.TITLES, Option.INDUSTRIES].includes(populationFilter.option)) {
      const selected = populationFilter.valueFilterOption.filter((v) => v.selected).map((v) => v.value)
      const prospectTempFilter: ProspectFilterTerm[] = selected.map((v) => {
          return {
              'cadence_step': populationFilter.option === Option.CADENCE_STEPS ? v : undefined,
              'cadence': populationFilter.option === Option.CADENCES ? v : undefined,
              'seniority': populationFilter.option === Option.SENIORITIES ? v : undefined,
              'title': populationFilter.option === Option.TITLES ? v : undefined, 
              'industry': populationFilter.option === Option.INDUSTRIES ? v : undefined, 
              'exact': true, 
              'case_sensitive': true
          }})
      return {
          'filter_type': CnfFilterType.PROSPECT,
          'prospect_filters': prospectTempFilter,
          } 
  }
  return null 
}

export function getTemporalCnfFromPopulationFilter(filters: PopulationFilter[]): TemporalFilterDisjunction[] {
  // convert population filter temporal terms
  const temporalFilters: TemporalFilterDisjunction[] = []
  filters.forEach((v) => {
    const temporal = convertPopulationFilterToTemporalFilter(v)
    if (temporal) temporalFilters.push(temporal)
  })

  const users: string[] = []
  const groups: string[] = []
  for (const filter of filters.filter(v => v.option === Option.USER_IDS)) {
    users.push(...filter.valueFilterOption.filter(v => v.selected).map(v => v.value))
    groups.push(...(filter.groupsFilterOption ?? []).filter(v => v.selected).map(v => v.value))
  }

  if (users.length + groups.length > 0) {  // interpret a blank user filter as not filtering, rather than as nobody
    const userFilterTerm: UserFilterTerm[] = [...users.map((v) => {return {'user_id': v}}), ...groups.map((v) => {return {'user_group_id': v}})]
    temporalFilters.push({
      'filter_type': CnfFilterType.USER,
      'user_filters': userFilterTerm,
    })
  }

  return temporalFilters
}

export function getStaticCnfFromPopulationFilter(populationFilters: PopulationFilter[], 
  keywords?: Keyword[], 
  keywordPhrases?: KeywordPhrase[],
  addEmpty?: boolean
  ): StaticFilterDisjunction[] {
  const staticCnf: StaticFilterDisjunction[] = []
  
  const userFilter = populationFilters.find(v => v.option === Option.USER_IDS)
  let users: string[] = userFilter?.valueFilterOption.filter(v => v.selected).map(v => v.value) ?? []
  const groups: string[] = userFilter?.groupsFilterOption?.filter(v => v.selected).map(v => v.value) ?? []
  const external_accounts: string[] = userFilter?.externalAccountsFilterOption?.filter(v => v.selected).map(v => v.values).flat(1) ?? []
  if ((users.length + groups.length + external_accounts.length > 0) || (userFilter && addEmpty)) {
    staticCnf.push({
      'filter_type': CnfFilterType.USER_ACCOUNT,
      'metadata_type_info': Option.USER_IDS,
      'negated': false,
      'user_account_filters': [{'user_ids': users}, {'account_ids': external_accounts}, {'user_group_ids': groups}]
    })
  }

  const stageFilter = populationFilters.find((v) => v.option === Option.STAGES)
  const populationOverall = populationFilters.find((v) => v.option === Option.CALL_CATEGORY)
  const allCallsSelected = populationOverall?.valueFilterOption.find((v) => v.selected)?.value === CALL_CATEGORY_OPTIONS.ALL_CALLS 
  let stages = allCallsSelected || populationOverall === undefined ? null : getAllProspectCalls()
  if (stageFilter) {
      stages = stageFilter.valueFilterOption.filter((v) => v.selected).map((v) => v.value as STAGE)
  }


  if (stages && (stages.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.STAGE, 'negated': false, 'stage_filters': stages.map((v) => {return {'require_ending': true, 'stage': v}})})
  }


  const remarkFilters = populationFilters.filter((v) => v.option === Option.REMARK)
  for (const filter of remarkFilters) {
      const isNegated = filter.metadataFilterOption?.find((v) => v.selected)?.value === REMARK_FIRST_ORDER_OPTION.DOES_NOT_HAVE
      const remarkFilters: RemarkFilterTerm[] = filter.valueFilterOption.filter((v) => v.selected).map((v) => {return {'remark': v.value as Remark}})
      if (addEmpty || remarkFilters.length > 0) {
        staticCnf.push({'filter_type': CnfFilterType.REMARK, 'negated': isNegated, 'remark_filters': remarkFilters})
      }
  }

  const textFilters = populationFilters.filter((v) => v.option === Option.TEXT)
  for (const filter of textFilters) {
      const partyRoleSelected = filter.metadataFilterOption?.find((v) => v.selected)?.value
      const partyRole = partyRoleSelected === TEXT_FIRST_ORDER_OPTION.REP_SAYS ? PartyRole.REP : partyRoleSelected === TEXT_FIRST_ORDER_OPTION.PROSPECT_SAYS ? PartyRole.PROSPECT : undefined
      const phraseFilters: PhraseFilterTerm[] = filter.valueFilterOption.filter((v) => v.value.trim() !== '').map((v) => {return {'party_role': partyRole, 'text': v.value, 'stem': true, 'language': DEFAULT_LANGUAGE_SETTING.ENGLISH}})
      if (addEmpty || phraseFilters.length > 0) {
        staticCnf.push({'filter_type': CnfFilterType.PHRASE, 'negated': false, 'phrase_filters': phraseFilters}) 
      }
  }

  const keywordFilters = populationFilters.filter((v) => v.option === Option.KEYWORD)
  if (keywordPhrases && keywords && keywordFilters.length > 0) {
    for (const keywordFilter of keywordFilters) {
      const keywordSelected = keywordFilter.valueFilterOption.filter((v) => v.selected).map((v) => v.value)
      const phraseFilterTerm: PhraseFilterTerm[] = []
      for (const keywordId of keywordSelected) {
        const matchingKeyword = keywords.find((v) => v.keyword_id === keywordId)
        if (!matchingKeyword) continue
        const matchingPhrases = keywordPhrases.filter((v) => v.keyword_id === keywordId) 
        if (matchingPhrases.length === 0) continue
        const keywordPhraseFilterTerm: PhraseFilterTerm[] = matchingPhrases.map((v) => {return {'language': v.language, 'party_role': matchingKeyword.party_role ?? undefined, 'text': v.keyword_phrase, 'stem': true}})
        phraseFilterTerm.push(...keywordPhraseFilterTerm)
        }

      if (phraseFilterTerm.length > 0 || addEmpty) {
        staticCnf.push({'filter_type': CnfFilterType.PHRASE, 'negated': false, 'phrase_filters': phraseFilterTerm})
      }
    }
  }
  

  const cadence_step = populationFilters.find((v) => v.option === Option.CADENCE_STEPS)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
  const cadence = populationFilters.find((v) => v.option === Option.CADENCES)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
  const industry = populationFilters.find((v) => v.option === Option.INDUSTRIES)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
  const title = populationFilters.find((v) => v.option === Option.TITLES)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
  const seniority = populationFilters.find((v) => v.option === Option.SENIORITIES)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
  const phoneNumbers = populationFilters.find((v) => v.option === Option.PHONE_NUMBERS)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null

  if (cadence_step && (cadence_step.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.PROSPECT, 'metadata_type_info': Option.CADENCE_STEPS, 'negated': false, 'prospect_filters': cadence_step.map((v) => {return {'exact': true, 'case_sensitive': true, 'cadence_step': v}})})
  }

  if (cadence && (cadence.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.PROSPECT, 'metadata_type_info': Option.CADENCES, 'negated': false, 'prospect_filters': cadence.map((v) => {return {'exact': true, 'case_sensitive': true, 'cadence': v}})})
  }

  if (industry && (industry.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.PROSPECT, 'metadata_type_info': Option.INDUSTRIES, 'negated': false, 'prospect_filters': industry.map((v) => {return {'exact': true, 'case_sensitive': true, 'industry': v}})})
  }

  if (title && (title.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.PROSPECT, 'metadata_type_info': Option.TITLES, 'negated': false, 'prospect_filters': title.map((v) => {return {'exact': true, 'case_sensitive': true, 'title': v}})})
  }

  if (seniority && (seniority.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.PROSPECT, 'metadata_type_info': Option.SENIORITIES, 'negated': false, 'prospect_filters': seniority.map((v) => {return {'exact': true, 'case_sensitive': true, 'seniority': v}})})
  }
 
  if (phoneNumbers && phoneNumbers.length > 0) {
    staticCnf.push({'filter_type': CnfFilterType.PROSPECT, 'negated': false, 'prospect_filters': phoneNumbers.map((v) => {return {'phone_number': v, 'exact': true, 'case_sensitive': true}})})
  }

  const counterparts = populationFilters.filter((v) => v.option === Option.COUNTERPARTS)
  counterparts.forEach((v) => {
    const counterpartsSelected = v.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
    if (counterpartsSelected && (counterpartsSelected.length > 0 || addEmpty)) {
      staticCnf.push({'filter_type': CnfFilterType.COUNTERPARTS, 'negated': false, 'counterpart_filters': counterpartsSelected.map((v) => {return {'counterpart': v as Counterpart}})})
    }
  })


  const dispositions = populationFilters.find((v) => v.option === Option.DISPOSITION)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
  const purposes = populationFilters.find((v) => v.option === Option.PURPOSE)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null
  const sentiments = populationFilters.find((v) => v.option === Option.SENTIMENT)?.valueFilterOption.filter((v) => v.selected).map((v) => v.value) ?? null

  const minDuration = populationFilters.find((v) => v.option === Option.MIN_DURATION)?.valueFilterOption.find((v) => v.selected)
  const starFilter = populationFilters.find((v) => v.option === Option.STARRED)?.valueFilterOption.find((v) => v.selected)
  const flaggedFilter = populationFilters.find((v) => v.option === Option.FLAGGED)?.valueFilterOption.find((v) => v.selected)

  if (dispositions && (dispositions.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.METADATA, 'metadata_type_info': Option.DISPOSITION, 'negated': false, 'metadata_filters': dispositions.map((v) => {return {'disposition': v}})})
  }
  if (purposes && (purposes.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.METADATA, 'metadata_type_info': Option.PURPOSE, 'negated': false, 'metadata_filters': purposes.map((v) => {return {'purpose': v}})})
  }

  if (sentiments && (sentiments.length > 0 || addEmpty)) {
    staticCnf.push({'filter_type': CnfFilterType.METADATA, 'metadata_type_info': Option.SENTIMENT, 'negated': false, 'metadata_filters': sentiments.map((v) => {return {'sentiment': v}})})
  }

  if (minDuration) {
    const duration = parseFloat(minDuration.value)*60
    if (!isNaN(duration)) staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': [{'min_duration': duration}]})
  }

  if (starFilter && starFilter.value === STAR_FILTER.IS_STARRED) {
    staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': [{'has_star': true}]})
  }

  if (flaggedFilter && [flaggedFilter.value === FLAGGED_FILTER.IS_FLAGGED || flaggedFilter.value === FLAGGED_FILTER.IS_REVIEWED]) {
    staticCnf.push({'filter_type': CnfFilterType.METADATA, 'negated': false, 'metadata_filters': [{'review_is_open': flaggedFilter.value === FLAGGED_FILTER.IS_FLAGGED ? true : false}]})
  }

  const pass_through = populationFilters.filter((v) => [Option.CNF_PASS_THROUGH, Option.SESSION_METRIC_PASS_THROUGH].includes(v.option))
  if (pass_through.length > 0) {
    pass_through.map((v) => v.cnf).forEach((v) => {
      if (v) staticCnf.push(...v)
    })
  }

  return staticCnf
}

export interface DiagnosticsTableIdentifier {
  label?: string
  user_group_info?: UserGroupInfo,
  users?: UserDataResult[],
  user?: UserDataResult
}

export function staticCnfToPopulationFilter(staticCnf: StaticFilterDisjunction[], 
  userIdToUser: Map<string, UserDataResult>, 
  userGroupInfo: UserGroupInfo[],
  item?: DiagnosticsTableIdentifier,
  prospectInfoOptions?: ProspectInfoChoices, 
  dispositionInfoOptions?: ProspectInfoChoices): PopulationFilter[] {
  const populationFilters: PopulationFilter[] = [];

  for (const filter of staticCnf) {
    switch (filter.filter_type) {
      case CnfFilterType.REMARK:
        if (filter.remark_filters) populationFilters.push(createRemarkFilter(filter.remark_filters, filter.negated));
        break;
      case CnfFilterType.PROSPECT:
        if (filter.prospect_filters) populationFilters.push(...createProspectFilters(filter.prospect_filters, filter.metadata_type_info, prospectInfoOptions));
        break;
      case CnfFilterType.COUNTERPARTS:
        if (filter.counterpart_filters) populationFilters.push(createCounterpartFilter(filter.counterpart_filters));
        break;
      case CnfFilterType.METADATA:
        if (filter.metadata_filters) populationFilters.push(...createMetadataFilters(filter.metadata_filters, filter.metadata_type_info, dispositionInfoOptions));
        break;
      case CnfFilterType.CUSTOM_METRIC:
        if (filter.custom_metric_filters) populationFilters.push(...createCustomMetricFilters(filter.custom_metric_filters));
        break
      case CnfFilterType.SESSION_METRIC:
        if (filter.session_metric_filters) populationFilters.push(...createSessionMetricFilters(filter.session_metric_filters));
        break
      case CnfFilterType.STAGE:
        if (filter.stage_filters) populationFilters.push(createStageFilter(filter.stage_filters));
        break;
      case CnfFilterType.USER_ACCOUNT:
        if (filter.user_account_filters) populationFilters.push(createUserAccountFilter(filter.user_account_filters, userIdToUser, userGroupInfo));
        break;
      }
  }

  if (item) {
    const users = item.user_group_info ? item.user_group_info.allUserIds : item.users ? item.users.map(u => u.user_id) : item.user ? [item.user.user_id] : [];
    if (users?.length > 0) {
      populationFilters.push({
        option: Option.USER_IDS,
        valueFilterOption: users.map(id => ({ label: userIdToUser.get(id)?.user_name ?? 'Unknown User', value: id, selected: true })),
        groupsFilterOption: [],
        externalAccountsFilterOption: [],
      });
    }
  }

  return populationFilters;
}

function createUserAccountFilter(userAccountFilters: UserAccountFilterTerm[], userIdToUser: Map<string, UserDataResult>, userGroupInfo: UserGroupInfo[]): PopulationFilter {
  let user_ids = userAccountFilters.filter(f => f.user_ids).map(f => f.user_ids).flat(1) ?? []
  let filtered_user_ids: string[] = user_ids.filter((x) => x).map((x) => x as string)
  filtered_user_ids = Array.from(new Set(filtered_user_ids))
  const group_ids = userAccountFilters.filter(f => f.user_group_ids).map(f => f.user_group_ids).flat(1) ?? []
  let filtered_group_ids: string[] = group_ids.filter((x) => x).map((x) => x as string)
  filtered_group_ids = Array.from(new Set(filtered_group_ids))
  return {
    option: Option.USER_IDS,
    uuid: uuidv4(),
    valueFilterOption: filtered_user_ids.map(id => ({ label: userIdToUser.get(id)?.user_name ?? 'Unknown User', value: id, selected: true })),
    groupsFilterOption: filtered_group_ids.map(id => ({ label: userGroupInfo.find((x) => x.group.user_group_id === id)?.group.user_group_name ?? 'Unknown group', value: id, selected: true })),
    externalAccountsFilterOption: []
  }
}

function createStageFilter(stageFilters: { stage: STAGE }[]): PopulationFilter {
  return {
    option: Option.STAGES,
    uuid: uuidv4(),
    valueFilterOption: Object.values(STAGE).map(stage => ({
      label: stage,
      value: stage,
      selected: stageFilters.some(f => f.stage === stage),
    })),
  };
}

function createRemarkFilter(remarkFilters: RemarkFilterTerm[], negated: boolean): PopulationFilter {
  return {
    option: Option.REMARK,
    uuid: uuidv4(),
    metadataFilterOption: [
      { label: 'Has', value: 'Has', selected: !negated },
      { label: 'Does not have', value: 'Does not have', selected: negated },
    ],
    valueFilterOption: Object.values(Remark).map(remark => ({
      label: REMARK_TO_HUMAN_READABLE[remark],
      value: remark,
      selected: remarkFilters.some(f => f.remark === remark),
    })),
  };
}

function getOptions(prospectInfoOptions: ProspectInfoChoices, option: Option, default_ret: string[]): string[] {
  if (PROSPECT_OPTIONS.includes(option)) return prospectInfoOptions.find((v) => v.field_name.toLowerCase() === option.toLowerCase())?.choices ?? default_ret;
  else return prospectInfoOptions.find((v) => v.field_name.toLowerCase().includes(option.toLowerCase()))?.choices ?? default_ret;
}

function createProspectFilters(prospectFilters: ProspectFilterTerm[], metadata_info?: string, prospectInfoOptions?: ProspectInfoChoices): PopulationFilter[] {
  const filters: PopulationFilter[] = [];

  const cadenceSteps = prospectFilters.filter(f => f.cadence_step).map(f => f.cadence_step!);
  if (cadenceSteps.length > 0 || metadata_info === Option.CADENCE_STEPS) {
    const options = getOptions(prospectInfoOptions ?? [], Option.CADENCE_STEPS, cadenceSteps)
    filters.push({
      option: Option.CADENCE_STEPS,
      uuid: uuidv4(),
      valueFilterOption: options.map(step => ({ label: step, value: step, selected: cadenceSteps.includes(step) })),
    });
  }

  const cadences = prospectFilters.filter(f => f.cadence).map(f => f.cadence!);
  if (cadences.length > 0|| metadata_info === Option.CADENCES) {
    const options = getOptions(prospectInfoOptions ?? [], Option.CADENCES, cadences)
    filters.push({
      option: Option.CADENCES,
      uuid: uuidv4(),
      valueFilterOption: options.map(cadence => ({ label: cadence, value: cadence, selected: cadences.includes(cadence) }))
      });
  }

  const industries = prospectFilters.filter(f => f.industry).map(f => f.industry!);
  if (industries.length > 0 || metadata_info === Option.INDUSTRIES) {
    const options = getOptions(prospectInfoOptions ?? [], Option.INDUSTRIES, industries)
    filters.push({
      option: Option.INDUSTRIES,
      uuid: uuidv4(),
      valueFilterOption: options.map(industry => ({ label: industry, value: industry, selected: industries.includes(industry) })),
    });
  }

  const titles = prospectFilters.filter(f => f.title).map(f => f.title!);
  if (titles.length > 0 || metadata_info === Option.TITLES) {
    const options = getOptions(prospectInfoOptions ?? [], Option.TITLES, titles)
    filters.push({
      option: Option.TITLES,
      uuid: uuidv4(),
      valueFilterOption: options.map(title => ({ label: title, value: title, selected: titles.includes(title) })),
    });
  }

  const seniorities = prospectFilters.filter(f => f.seniority).map(f => f.seniority!);
  if (seniorities.length > 0 || metadata_info === Option.SENIORITIES) {
    const options = getOptions(prospectInfoOptions ?? [], Option.SENIORITIES, seniorities)
    filters.push({
      option: Option.SENIORITIES,
      uuid: uuidv4(),
      valueFilterOption: options.map(seniority => ({ label: seniority, value: seniority, selected: seniorities.includes(seniority) })),
    });
  }

  const phoneNumbers = prospectFilters.filter(f => f.phone_number).map(f => f.phone_number!);
  if (phoneNumbers.length > 0) {
    filters.push({
      option: Option.PHONE_NUMBERS,
      uuid: uuidv4(),
      valueFilterOption: phoneNumbers.map(phone => ({ label: phone, value: phone, selected: true })),
    });
  }

  return filters;
}

function createCounterpartFilter(counterpartFilters: CounterpartFilter[]): PopulationFilter {
  const counterparts = counterpartFilters.map(f => f.counterpart as string);
  return {
    option: Option.COUNTERPARTS,
    uuid: uuidv4(),
    valueFilterOption: Object.values(Counterpart).map(counterpart => ({ label: COUNTERPART_TO_HUMAN_READABLE[counterpart], value: counterpart, selected: counterparts.includes(counterpart) })),
  }
}

function createMetadataFilters(metadataFilters: MetadataFilter[], metadata_type?: string, dispositionOptions?: ProspectInfoChoices): PopulationFilter[] {
  const filters: PopulationFilter[] = [];

  const dispositions = metadataFilters.filter(f => f.disposition).map(f => f.disposition as string)
  if (dispositions.length > 0 || metadata_type === Option.DISPOSITION) {
    const options = getOptions(dispositionOptions ?? [], Option.DISPOSITION, dispositions)
    filters.push({
      option: Option.DISPOSITION,
      uuid: uuidv4(),
      valueFilterOption: options.map(disposition => ({ label: disposition, value: disposition, selected: dispositions.includes(disposition) })),
    });
  }

  const purposes = metadataFilters.filter(f => f.purpose).map(f => f.purpose as string)
  if (purposes.length > 0 || metadata_type === Option.PURPOSE) {
    const options = getOptions(dispositionOptions ?? [], Option.PURPOSE, purposes)
    filters.push({
      option: Option.PURPOSE,
      uuid: uuidv4(),
      valueFilterOption: options.map(purpose => ({ label: purpose, value: purpose, selected: purposes.includes(purpose) })),
    });
  }

  const sentiments = metadataFilters.filter(f => f.sentiment).map(f => f.sentiment as string)
  if (sentiments.length > 0 || metadata_type === Option.SENTIMENT) {
    const options = getOptions(dispositionOptions ?? [], Option.SENTIMENT, sentiments)
    filters.push({
      option: Option.SENTIMENT,
      uuid: uuidv4(),
      valueFilterOption: options.map(sentiment => ({ label: sentiment, value: sentiment, selected: sentiments.includes(sentiment) })),
    });
  }

  const hasStar = metadataFilters.find(f => f.has_star !== undefined);
  if (hasStar) {
    filters.push({
      option: Option.STARRED,
      uuid: uuidv4(),
      valueFilterOption: [{ label: 'Is starred', value: STAR_FILTER.IS_STARRED, selected: true }],
    });
  }

  const reviewIsOpen = metadataFilters.find(f => f.review_is_open !== undefined);
  if (reviewIsOpen) {
    filters.push({
      option: Option.FLAGGED,
      uuid: uuidv4(),
      valueFilterOption: [{ 
        label: reviewIsOpen.review_is_open ? 'Is flagged' : 'Is reviewed', 
        value: reviewIsOpen.review_is_open ? FLAGGED_FILTER.IS_FLAGGED : FLAGGED_FILTER.IS_REVIEWED, 
        selected: true 
      }],
    });
  }

  const minDuration = metadataFilters.find(f => f.min_duration !== undefined);
  if (minDuration !== undefined) {
    filters.push({
      option: Option.MIN_DURATION,
      uuid: uuidv4(),
      valueFilterOption: [{'label': 'Duration', 'value': ((minDuration.min_duration ?? 0)/60).toString(), 'selected': true }]
    });
  }

  return filters;
}

function createCustomMetricFilters(customMetricFilters: CustomMetricFilterTerm[]): PopulationFilter[] {
  return [{
    'option': Option.CNF_PASS_THROUGH,
    'valueFilterOption': [],
    'cnf': [{'filter_type': CnfFilterType.CUSTOM_METRIC, 'negated': false, 'custom_metric_filters': customMetricFilters}],
  }]
}

function createSessionMetricFilters(sessionMetricFilters: SessionMetricFilterTerm[]): PopulationFilter[] {
  return [{
    'option': Option.SESSION_METRIC_PASS_THROUGH,
    'valueFilterOption': [],
    'cnf': [{'filter_type': CnfFilterType.SESSION_METRIC, 'negated': false, 'session_metric_filters': sessionMetricFilters}],
  }]
}


export function getListUserSessionParamsFromPopulationFilter(populationFilters: PopulationFilter[], 
  keywords?: Keyword[], 
  keywordPhrases?: KeywordPhrase[]): ListUserSessionParamsV3 {
  const start = populationFilters.find((v) => v.option === Option.START)?.selectedDate ?? null
  const end = populationFilters.find((v) => v.option === Option.END)?.selectedDate ?? null

  return {
      start: start,
      end: end,
      cnf: getStaticCnfFromPopulationFilter(populationFilters, keywords, keywordPhrases),
  }
}

export type SelectResult = {
  group: string | boolean | null;
  value: number;
}

export interface MetricDataState {
  data: {[k: string]: SelectResult[]},
  isLoading: boolean,
  error: string | null
}

const metricDataInitialState: MetricDataState = {
  data: {},
  isLoading: false,
  error: null
}

export const metricDataSlice = createSlice({
  name: 'metricData',
  initialState: metricDataInitialState,
  reducers: {
    updateMetricData: (state, action: PayloadAction<{[k: string]: SelectResult[]}>) => {
      state.data = { ...state.data, ...action.payload }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    }
  }
})

export const { updateMetricData, setLoading, setError } = metricDataSlice.actions
export enum ValueType {
  CUSTOM_METRIC = 'Custom Metric',
  CATEGORICAL = 'Categorical',
  FLOAT = 'Float',
  BOOLEAN = 'Boolean',
  NONE = 'None'
}

export interface BotsResult {
  bots: Bot[] | null,
}

const botsInitialState: BotsResult = {
  bots: null,
}

export const teamBotsSlice = createSlice({
  name: 'bots',
  initialState: botsInitialState,
  reducers: {
    updateTeamBots: (state, action: PayloadAction<Bot[]>) => {
      state.bots = action.payload
    },
    updateSingleBot: (state, action: PayloadAction<Bot>) => {
      if (state.bots) {
        state.bots = state.bots.map(bot => 
          bot.bot_id === action.payload.bot_id ? action.payload : bot
        )
      }
    },
  },
})

export const { updateTeamBots, updateSingleBot } = teamBotsSlice.actions;

export function reloadBots(dispatch: Dispatch): Promise<Bot[] | null> {
  const result = getServicesManager().getAllTeamBots()
  result.then(v => {
    if (v !== null) {
      dispatch(teamBotsSlice.actions.updateTeamBots(v))
    }
  })
  return result
}

export const globalBotsSlice = createSlice({
  name: 'globalBots',
  initialState: botsInitialState,
  reducers: {
    updateGlobalBots: (state, action: PayloadAction<Bot[]>) => {
      state.bots = action.payload
    },
    updateSingleGlobalBot: (state, action: PayloadAction<Bot>) => {
      if (state.bots) {
        state.bots = state.bots.map(bot => 
          bot.bot_id === action.payload.bot_id ? action.payload : bot
        )
      }
    },
  },
})

export const { updateGlobalBots, updateSingleGlobalBot } = globalBotsSlice.actions;

export function reloadGlobalBots(dispatch: Dispatch): Promise<Bot[] | null> {
  const result = getServicesManager().getAllGlobalBots()
  result.then(v => {
    if (v !== null) {
      dispatch(globalBotsSlice.actions.updateGlobalBots(v))
    }
  })
  return result
}

export interface BotsToEmotion {
  botToEmotions: {[k: string]: BotEmotion[]},
}

const botsToEmotionInitialState: BotsToEmotion = {
  botToEmotions: {},
}

export const botsToEmotionSlice = createSlice({
  name: 'botsToEmotion',
  initialState: botsToEmotionInitialState,
  reducers: {
    updateBotsToEmotion: (state, action: PayloadAction<{[k: string]: BotEmotion[]}>) => {
      state.botToEmotions = { ...state.botToEmotions, ...action.payload }
    },
  },
})

export function getEmotionsForBot(bot_ids: string[], dispatch: Dispatch): Promise<BotEmotion[] | null> {
  const result = getServicesManager().getAllEmotions(bot_ids)
  result.then(v => {
    if (v !== null) {
      const bot_ids = new Set(v.map(e => e.bot_id))
      const update = Object.fromEntries(Array.from(bot_ids).map(bot_id => [bot_id, v.filter(e => e.bot_id === bot_id)]))
      dispatch(botsToEmotionSlice.actions.updateBotsToEmotion(update))
    }
  })
  return result
}

export const { updateBotsToEmotion } = botsToEmotionSlice.actions;

export function convertQueryToString(query: BotSessionQueryParams): string {
  return JSON.stringify(query)
}

export interface SyntheticSessionqueryData {
  param: BotSessionQueryParams,
}

const syntheticSessionQueryDataInitialState: SyntheticSessionqueryData = {
  param: {  }
}

export const syntheticSessionQueryDataSlice = createSlice({
  name: 'syntheticSessionQueryData',
  initialState: syntheticSessionQueryDataInitialState,
  reducers: {
    updateSyntheticQueryParam: (state, action: PayloadAction<BotSessionQueryParams>) => {
      state.param = action.payload
    },
  },
})

export const { updateSyntheticQueryParam } = syntheticSessionQueryDataSlice.actions;

export interface SyntheticConversationalData {
  data: {[k: string]: BotCall[]},
  hasMore: {[k: string]: boolean}
}

const syntheticConversationalDataInitialState: SyntheticConversationalData = {
  data: {},
  hasMore: {}
}

const CALLS_PER_PAGE = 50;

export const syntheticConversationalDataSlice = createSlice({
  name: 'syntheticConversationalData',
  initialState: syntheticConversationalDataInitialState,
  reducers: {
    updateConversationData: (state, action: PayloadAction<{
      queryKey: string,
      calls: BotCall[],
      isBaseQuery: boolean
    }>) => {
      const { queryKey, calls, isBaseQuery } = action.payload
      const existing_data = (state.data[queryKey] ?? [])
      const existing_ids = existing_data.map((v) => v.bot_call_id)
      const new_data = calls.filter((v) => !existing_ids.includes(v.bot_call_id))
      // override the existing data with the new data
      const existing_data_with_new_data = existing_data.map((v) => {
        const new_call = calls.find((new_v) => new_v.bot_call_id === v.bot_call_id)
        return new_call ?? v
      })
      
      // Combine and sort all calls by start date in descending order
      state.data[queryKey] = [...existing_data_with_new_data, ...new_data].sort((a, b) => 
        new Date(b.start).getTime() - new Date(a.start).getTime()
      )
      
      // Only update hasMore for the base query
      if (isBaseQuery) {
        state.hasMore[queryKey] = calls.length === CALLS_PER_PAGE
      }
    },
  },
})

export const { updateConversationData } = syntheticConversationalDataSlice.actions;

export interface AllSyntheticConversationalData {
  data: BotCall[]
}

const allSyntheticConversationalDataInitialState: AllSyntheticConversationalData = {
  data: []
}

export const allSyntheticConversationalDataSlice = createSlice({
  name: 'allSyntheticConversationalData',
  initialState: allSyntheticConversationalDataInitialState,
  reducers: {
    updateAllConversationData: (state, action: PayloadAction<BotCall[]>) => {
      const current_ids = state.data.map((v) => v.bot_call_id)
      const new_data = action.payload.filter((v) => !current_ids.includes(v.bot_call_id))
      // override the existing data with the new data
      const existing_data_with_new_data = state.data.map((v) => {
        const new_call = action.payload.find((new_v) => new_v.bot_call_id === v.bot_call_id)
        return new_call ?? v
      })
      state.data = [...existing_data_with_new_data, ...new_data]
    },
  },
})

export const { updateAllConversationData } = allSyntheticConversationalDataSlice.actions;

export function getSyntheticConversationalData(
  query: BotSessionQueryParams, 
  dispatch: Dispatch,
  lastCallTime?: Date
): Promise<BotCall[] | null> {
  const baseQueryKey = convertQueryToString(query)
  
  const queryWithEnd = lastCallTime ? {
    ...query,
    end: lastCallTime
  } : query
  const result = getServicesManager().getSynthCalls(queryWithEnd)
  result.then(v => {
    if (v !== null) {
      dispatch(syntheticConversationalDataSlice.actions.updateConversationData({ 
        queryKey: baseQueryKey,
        calls: v,
        isBaseQuery: !lastCallTime
      }))
      dispatch(allSyntheticConversationalDataSlice.actions.updateAllConversationData(v))
    }
  })
  return result
}

// Add new slice for active call
export const activeSynthCallSlice = createSlice({
  name: 'activeSynthCall',
  initialState: {
    call: null as BotCall | null
  },
  reducers: {
    setActiveSynthCall: (state, action: PayloadAction<BotCall | null>) => {
      state.call = action.payload
    }
  }
})

export const { setActiveSynthCall } = activeSynthCallSlice.actions;

// Add new interface and slice for call analysis
export interface CallAnalysisState {
  pending: Set<string>;
  data: { [callId: string]: string };
}

const callAnalysisInitialState: CallAnalysisState = {
  pending: new Set(),
  data: {},
}

export const callAnalysisSlice = createSlice({
  name: 'callAnalysis',
  initialState: callAnalysisInitialState,
  reducers: {
    setAnalysisPending: (state, action: PayloadAction<string>) => {
      state.pending = new Set([...Array.from(state.pending), action.payload])
    },
    setAnalysisComplete: (state, action: PayloadAction<{callId: string, analysis: string}>) => {
      state.pending = new Set(Array.from(state.pending).filter(id => id !== action.payload.callId))
      state.data[action.payload.callId] = action.payload.analysis
    },
  }
})

export const { setAnalysisPending, setAnalysisComplete } = callAnalysisSlice.actions

// Add to your store configuration

// Add this new function to load calls for a specific bot
export function getBotSpecificCalls(
  botId: string,
  dispatch: Dispatch,
  lastCallTime?: Date
): Promise<BotCall[] | null> {
  // Create a bot-specific query
  const botSpecificQuery: BotSessionQueryParams = {
    bot_ids: [botId],
  }

  if (lastCallTime) {
    botSpecificQuery.end = lastCallTime
  }

  const result = getServicesManager().getSynthCalls(botSpecificQuery)
  result.then(v => {
    if (v !== null) {
      dispatch(syntheticConversationalDataSlice.actions.updateConversationData({ 
        queryKey: convertQueryToString(botSpecificQuery),
        calls: v,
        isBaseQuery: !lastCallTime,
      }))
      dispatch(allSyntheticConversationalDataSlice.actions.updateAllConversationData(v))
    }
  })
  return result
}