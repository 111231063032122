// user can select <=, in between, >=

import React from 'react';

interface FloatFilterProps {
    min_value: number | null;
    max_value: number | null;
    onChange: (value: {min_value: number | null, max_value: number | null}) => void;
}

export const FloatFilter: React.FC<FloatFilterProps> = ({ min_value, max_value, onChange }) => (
    <div className="flex items-center gap-2">
        <input
            type="number"
            value={min_value ?? ''}
            onChange={(e) => onChange({min_value: e.target.value === '' ? null : parseFloat(e.target.value), max_value})}
            className="w-16 px-2 py-1 border rounded"
            placeholder="Min"
        />
        <span>to</span>
        <input
            type="number"
            value={max_value ?? ''}
            onChange={(e) => onChange({min_value, max_value: e.target.value === '' ? null : parseFloat(e.target.value)})}
            className="w-16 px-2 py-1 border rounded"
            placeholder="Max"
        />
        {(min_value !== null || max_value !== null) && (
            <button 
                onClick={() => { onChange({min_value: null, max_value: null}) }} 
                className="text-gray-500 hover:text-gray-700"
            >✕</button>
        )}
    </div>
);