import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import { App } from './components/App';
import { AuthState } from './components/Authorization';
import { getServicesManager } from './services';
import { CHROME_EXTENSION_ACTIVATE_PAGE, CHROME_EXTENSION_EVERYWHERE_ACTIVATE_PAGE, SUGGEST_FEATURE_PAGE } from './cfg/endpoints';
import { Typography } from './interfaces/typography';
import { Provider } from 'react-redux';
import { store} from './store'

import { SpeedInsights } from "@vercel/speed-insights/react"
import { Loader } from './components/Loader';
import { EmbeddedAutomator } from './components/Embedded';
import {PrivateRoute } from './components/Authentication/Authentication';
import { ExtensionPageWrap } from './components/ExtensionPageWrap';
import { ANALYTIC_REPORTS_TYPE } from './components/Analytics/cfg';
import { SettingsPages } from './components/Settings';
import { Plays } from 'components/Plays';

const History = React.lazy(()=> import('./components/History').then(module=>({default:module.History})))
const Coaching = React.lazy(()=> import('./components/Coaching').then(module=>({default:module.Coaching})))
const Install = React.lazy(()=> import('./components/Install').then(module=>({default:module.Install})))
const Learn = React.lazy(()=> import('./components/Learn').then(module=>({default:module.Learn})))
const Authentication = React.lazy(()=> import('./components/Authorization').then(module=>({default:module.Authentication})))
const Uninstall = React.lazy(()=> import('./components/Uninstall').then(module=>({default:module.Uninstall})))
const Admin = React.lazy(()=> import('./components/Admin').then(module=>({default:module.Admin})))
const Redirect = React.lazy(()=> import('./components/Redirect').then(module=>({default:module.Redirect})))
const Demo = React.lazy(()=> import('./components/Demo').then(module=>({default:module.Demo})))
const LeaderboardTeamCreation = React.lazy(()=> import('./components/LeaderboardTeamCreation').then(module=>({default:module.LeaderboardTeamCreation})))
const AutoDialerList = React.lazy(()=> import('./components/AutoDialerList').then(module=>({default:module.AutoDialerList})))
const PaymentSuccess = React.lazy(()=> import('./components/PaymentSuccess').then(module=>({default:module.PaymentSuccess})))
const InternalTools = React.lazy(()=> import('./components/InternalTools').then(module=>({default:module.InternalTools})))
const EmbeddedIframe = React.lazy(()=> import('./components/EmbeddedIframe').then(module=>({default:module.EmbeddedIframe})))
const MultiDialer = React.lazy(()=> import('./components/MultiDialer').then(module=>({default:module.MultiDialer})))
const MultiDialerAdmin = React.lazy(()=> import('./components/MultiDialerAdmin').then(module=>({default:module.MultiDialerAdmin})))
const SynthProspect = React.lazy(()=> import('./components/SynthProspect').then(module=>({default:module.SynthProspect})))
const StartSequence = React.lazy(()=> import('./components/StartSequence').then(module=>({default:module.StartSequence})))
const Analytics = React.lazy(()=> import('./components/Analytics').then(module=>({default:module.Analytics})))
const Settings = React.lazy(()=> import('./components/Settings').then(module=>({default:module.Settings})))
const Live = React.lazy(()=> import('./components/Live').then(module=>({default:module.Live})))
const LinkedIn = React.lazy(()=> import('./components/LinkedIn').then(module=>({default:module.LinkedIn})))

// ensure the service manager is started
const serviceManager = getServicesManager() 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function wrap(x: any) {
  return <React.Suspense fallback={<div><Loader /></div>}>
    {x}
  </React.Suspense>
}

root.render(
  <React.StrictMode>
    <SpeedInsights />
    <Provider store={store}>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<PrivateRoute component={App}/>}>
        <Route index element={<PrivateRoute component={Analytics}/>} />
        <Route path="analytics" element={<PrivateRoute component={Analytics}/>} />
        <Route path="research" element={<PrivateRoute component={Analytics}/>} />
        <Route path="prime-time" element={<PrivateRoute component={() => <Analytics defaultReport={ANALYTIC_REPORTS_TYPE.WHEN_TO_CALL}/>}/>}  />
        <Route path="number-health" element={<PrivateRoute component={Analytics} />} />
        <Route path="geography" element={<PrivateRoute component={() => <Analytics defaultReport={ANALYTIC_REPORTS_TYPE.GEOGRAPHY}/>}/>}  />
        <Route path="reports" element={<PrivateRoute component={Analytics} />} />
        <Route path="one-on-ones" element={<PrivateRoute component={Analytics} />} />
        <Route path="diagnostics" element={<PrivateRoute component={() => <Analytics defaultReport={ANALYTIC_REPORTS_TYPE.CALL_FUNNEL}/>}/>}  />

        <Route path="transcripts" element={<PrivateRoute component={History}/>} />
        <Route path="transcripts/:id" element={<PrivateRoute component={History}/>} />

        <Route path="plays" element={<PrivateRoute component={Plays}/>} />
        <Route path="settings" element={<PrivateRoute component={Settings}/>} />
        <Route path='autodialer-settings' element={<PrivateRoute component={() => <Settings defaultSettings={SettingsPages.AUTODIALER_SETTINGS}/>}/>} />
        <Route path="subteams" element={<PrivateRoute component={() => <Settings defaultSettings={SettingsPages.SUBTEAMS}/>}/>} />
        <Route path="client-accounts" element={<PrivateRoute component={Settings}/>} />
        <Route path="keywords" element={<PrivateRoute component={() => <Settings defaultSettings={SettingsPages.KEYWORDS}/>}/>} />
        <Route path="user-management" element={<PrivateRoute component={() => <Settings defaultSettings={SettingsPages.USER_MANAGEMENT}/>}/>} />
        <Route path="email-settings" element={<PrivateRoute component={Settings} />} />
        <Route path="voicemail-recordings" element={<PrivateRoute component={() => <Settings defaultSettings={SettingsPages.VOICEMAILS}/>}/>} />
        <Route path="number-registration" element={<PrivateRoute component={() => <Settings defaultSettings={SettingsPages.NUMBERS}/>}/>} />

        <Route path="live" element={<PrivateRoute component={Live} />} />
        <Route path="multidialer" element={<PrivateRoute component={MultiDialer} />} />

        <Route path="multidialer-admin" element={<PrivateRoute component={MultiDialerAdmin} />} />
        <Route path="practice" element={<PrivateRoute component={SynthProspect} />} />
        <Route path="linkedin" element={<PrivateRoute component={LinkedIn} />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <div className='w-full h-full flex flex-row items-center justify-center text-center'>
              <Typography variant='largeParagraph'>Incorrect link - Select a tab</Typography>
              </div>
            </main>
          }
        />
      </Route>
      {/** anything dependent on the chrome extension content script has to be in pages for now*/}
      <Route path="/pages"> 
        <Route path="live" element={wrap(<ExtensionPageWrap component={Coaching} />)} />
        <Route path="live/index.html" element={wrap(<ExtensionPageWrap component={Coaching} />)} />
        <Route path="iframe" element={wrap(<ExtensionPageWrap component={EmbeddedIframe} onlyProgressOnAuthenticationLoad={true} onlyLoadAuthenticationInfo={true} />)} />
        <Route path="iframe/index.html" element={wrap(<ExtensionPageWrap component={EmbeddedIframe} onlyProgressOnAuthenticationLoad={true} onlyLoadAuthenticationInfo={true} />)} />
        <Route path="login" element={wrap(<Authentication />)} />
        <Route path="reset" element={wrap(<Authentication viewState={AuthState.PASSWORD_RESET} />)} />
        <Route path="signin" element={wrap(<Authentication />)} />
        <Route path="signin/index.html" element={wrap(<Authentication />)} />
        <Route path='signup' element={wrap(<Authentication />)} />   
        <Route path="upload" element={wrap(<ExtensionPageWrap component={AutoDialerList} />)} />
        <Route path="start" element={wrap(<ExtensionPageWrap component={StartSequence} />)} />
      </Route>

      <Route path='login' element={wrap(<Authentication viaMainPage={true} />)} />
      <Route path='join' element={wrap(<LeaderboardTeamCreation />)} />   
      <Route path="/uninstall" element={wrap(<Uninstall />)} />
      <Route path="/install" element={wrap(<Install />)} />
      <Route path='/free' element={wrap(<Install />)} />
      <Route path="/install-now" element={wrap(<Install />)} />
      <Route path="/get-started" element={wrap(<Install />)} />
      <Route path="/try-trellus" element={wrap(<Install />)} />
      <Route path="/get-trellus" element={wrap(<Install />)} />
      <Route path="/try-now" element={wrap(<Install />)} />
      <Route path="/integrate" element={wrap(<Redirect url={CHROME_EXTENSION_ACTIVATE_PAGE}/>)} />
      <Route path="/upgrade" element={wrap(<Redirect url={CHROME_EXTENSION_ACTIVATE_PAGE}/>)} />
      <Route path="/everywhere" element={wrap(<Redirect url={CHROME_EXTENSION_EVERYWHERE_ACTIVATE_PAGE}/>)} />
      <Route path="/suggest-feature" element={wrap(<Redirect url={SUGGEST_FEATURE_PAGE}/>)} />
      <Route path="/demo" element={wrap(<Demo />)} />
      <Route path="/success" element={wrap(<PaymentSuccess />)} />

      <Route path="/learn" element={wrap(<Learn />)} />
      <Route path="/learn-now" element={wrap(<Learn />)} />
      <Route path="/learn-more" element={wrap(<Learn />)} />
      <Route path="/learn-about" element={wrap(<Learn />)} />

      <Route path="/admin" element={wrap(<Admin />)} />
      <Route path="/internal" element={wrap(<InternalTools />)} />
      <Route path="/email" element={wrap(<EmbeddedAutomator />)} />
    </Routes>
    </BrowserRouter>
    </Provider>
    </React.StrictMode>
);

