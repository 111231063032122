import { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

import { TemporaryMessage } from "../types";
import { LinkedInAttachment } from "interfaces/linkedin";

export interface TemporarySlice {
    temporaryMessages: Record<string, TemporaryMessage[]>;
}

const initialState: TemporarySlice = {
    temporaryMessages: {},
}

export function convertToLinkedInAttachment(file: File, newMessageUUID: string) {
    return {
        vectorImage: null,
        video: null,
        audio: null,
        file: {
          assetUrn: `temp:${newMessageUUID}:${file.name}`,
          byteSize: file.size,
          mediaType: file.type,
          name: file.name,
          url: file.type.startsWith('image/') ? URL.createObjectURL(file) : `data:${file.type};attachment,${file.name}`
        }
      }
}

export const temporarySlice = createSlice({
    name: 'temporary',
    initialState,
    reducers: {
        addTemporaryMessage: (state, action: PayloadAction<{ conversationId: string, message: TemporaryMessage }>) => {
            if (!state.temporaryMessages[action.payload.conversationId]) {
                state.temporaryMessages[action.payload.conversationId] = [];
            }
            state.temporaryMessages[action.payload.conversationId].push(action.payload.message);
        },
        removeTemporaryMessage: (state, action: PayloadAction<{ conversationId: string, messageId: string }>) => {
            state.temporaryMessages[action.payload.conversationId] = state.temporaryMessages[action.payload.conversationId].filter(message => message.uuid !== action.payload.messageId);
        },
        updateTemporaryMessage: (state, action: PayloadAction<{ conversationId: string, messageId: string, update: Partial<TemporaryMessage> }>) => {
            state.temporaryMessages[action.payload.conversationId] = state.temporaryMessages[action.payload.conversationId].map(message => message.uuid === action.payload.messageId ? { ...message, ...action.payload.update } : message);
        },
    },
})

export const { addTemporaryMessage, removeTemporaryMessage, updateTemporaryMessage } = temporarySlice.actions;